import { RNS3 } from "react-native-aws3";
import mime from "react-native-mime-types";
import uuid from "react-native-uuid";
// import ImageResizer from 'react-native-image-resizer'
import { manipulateAsync, SaveFormat } from "expo-image-manipulator";

import { Platform } from "react-native";
class S3FilesUploader {
  constructor(id, _fileURL) {
    this._id = id;
    const fileURL = _fileURL;
    this.uploadConfigs = {
      bucket: "inforide-buckets-test",
      region: "us-west-1",
      accessKey: "AKIAZPBWEUTQ2S4XWOVJ",
      secretKey: "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",
      successActionStatus: 201,
    };
    let extension =
      fileURL.substring(fileURL.lastIndexOf(".") + 1, fileURL.length) ||
      fileURL;
    let fileName = `${uuid.v4()}.${extension}`;
    const getMimeType = (url) => {
      console.log(`===>Inspecting  URL: ${url}`);
      let ext = url.substring(url.lastIndexOf(".") + 1, url.length) || url;
      // console.log(`===>URL-EXTENSION:${ext}`)
      let mimetype = mime.lookup(ext);
      console.log(`MimeType=>${mimetype}`);
      return mimetype;
    };

    let mimetype = undefined;
    mimetype = getMimeType(_fileURL);
    let currentProgress = 0;
    if (mimetype === undefined) {
      mimetype = "file";
    }

    let emitProgress = (percentage) => false;
    let emitOnComplete = (error, uploadedURL) => false;

    const publishProgress = (percentage) => {
      if (emitProgress) {
        emitProgress(percentage);
      }
    };
    const publishOnComplete = (error, uploadedURL) => {
      if (emitOnComplete) {
        emitOnComplete(error, uploadedURL);
      }
    };

    this.onProgress = (uploadProgress = (percentage) => 0) => {
      emitProgress = uploadProgress;
      publishProgress(currentProgress);
      return this;
    };

    this.onComplete = (_onComplete) => {
      emitOnComplete = _onComplete;
      return this;
    };

    this.start = () => {
      // return new Promise((resolve, reject) => {
      console.log(`Uploading MimeType ${mimetype}`);
      const file = {
        // `uri` can also be a file system path (i.e. file://)
        uri: Platform.OS === "android" ? `file://${fileURL}` : fileURL,
        name: fileName,
        type: mimetype,
      };

      RNS3.put(file, this.uploadConfigs)
        .progress((progress) => {
          currentProgress = Math.round(progress.percent * 100);
          publishProgress(currentProgress);
          console.log(`Publishing Progress ${currentProgress}%`);
          //   console.log(`Percentage:${progress.percent}, Total:${progress.total}, Loaded:${progress.loaded}`)
        })
        .then((response) => {
          if (response.status !== 201) {
            console.log(response);
            publishOnComplete("Failed to upload", "");
            //   reject();
          } else {
            console.log(response.body);
            publishOnComplete(null, response.body.postResponse.location);
          }
          /**
           * {
           *   postResponse: {
           *     bucket: "your-bucket",
           *     etag : "9f620878e06d28774406017480a59fd4",
           *     key: "uploads/image.png",
           *     location: "https://your-bucket.s3.amazonaws.com/uploads%2Fimage.png"
           *   }
           * }
           */
        })
        .catch((err) => {
          console.log(`CATCH Error in RNS3.put`);
          console.log(err);
          publishOnComplete(err, "");
          //   reject(err);
        });
      // })
      return this;
    };

    this.compressImageQuality = (imageUrl) => {
      return new Promise((resolve, reject) => {
        manipulateAsync(
          imageUrl,
          [{ resize: { height: 500, width: 500 } }],
          SaveFormat.JPEG
        )
          .then((resizedImage) => {
            resolve(resizedImage.uri);
          })
          .catch((err) => {
            console.log("CATCH error in resizing image");
            console.log(err);
            reject(err);
          });
      });
    };
    this.generateAvatar = (imageUrl) => {
      return new Promise((resolve, reject) => {
        manipulateAsync(
          imageUrl,
          [{ resize: { height: 30, width: 30 } }],
          SaveFormat.JPEG
        )
          .then((resizedImage) => {
            resolve(resizedImage.uri);
          })
          .catch((err) => {
            console.log("CATCH error in resizing image");
            console.log(err);
            reject(err);
          });
      });
    };

    this.uploadFile = (uri, onProgress = (percentage) => false) => {
      return new Promise((resolve, reject) => {
        console.log(`Uploading MimeType ${mimetype}`);
        const file = {
          // `uri` can also be a file system path (i.e. file://)
          uri: Platform.OS === "android" ? `file://${fileURL}` : fileURL,
          name: fileName,
          type: mimetype,
        };
        RNS3.put(file, this.uploadConfigs)
          .progress((progress) => {
            onProgress(Math.round(progress.percent * 100));
          })
          .then((response) => {
            if (response.status !== 201) {
              console.log(response);
              reject(`Error in uploading image.`);
            } else {
              resolve(response.body.postResponse);
            }
          })
          .catch((err) => {
            console.log(`CATCH Error in RNS3.put`);
            console.log(err);
            reject(`Error in uploading image.`);
          });
      });
    };
  }
}
export default S3FilesUploader;
