import React from 'react';
import {SvgXml} from 'react-native-svg';

const ModalSvg = () => {
  return (
    <SvgXml
      xml={`<svg width="122" height="122" viewBox="0 0 122 122" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M60.9998 113.599C47.0263 113.599 33.6252 108.048 23.7445 98.1669C13.8638 88.2862 8.31284 74.8851 8.31284 60.9116C8.31284 46.9382 13.8638 33.5371 23.7445 23.6563C33.6252 13.7756 47.0263 8.2247 60.9998 8.2247C74.9732 8.2247 88.3743 13.7756 98.255 23.6563C108.136 33.5371 113.687 46.9382 113.687 60.9116C113.687 74.8851 108.136 88.2862 98.255 98.1669C88.3743 108.048 74.9732 113.599 60.9998 113.599ZM60.9998 121.125C76.9694 121.125 92.285 114.781 103.577 103.489C114.869 92.1968 121.213 76.8813 121.213 60.9116C121.213 44.942 114.869 29.6264 103.577 18.3342C92.285 7.04192 76.9694 0.697998 60.9998 0.697998C45.0301 0.697998 29.7145 7.04192 18.4223 18.3342C7.13005 29.6264 0.786133 44.942 0.786133 60.9116C0.786133 76.8813 7.13005 92.1968 18.4223 103.489C29.7145 114.781 45.0301 121.125 60.9998 121.125Z" fill="#2674E5"/>
      <path d="M83.353 38.1055C83.2993 38.1574 83.2491 38.2127 83.2024 38.2711L57.0622 71.5767L41.3088 55.8158C40.2387 54.8187 38.8233 54.2758 37.3609 54.3016C35.8984 54.3274 34.5031 54.9199 33.4688 55.9541C32.4346 56.9884 31.8421 58.3837 31.8163 59.8462C31.7905 61.3086 32.3334 62.724 33.3305 63.7941L53.2461 83.7173C53.7827 84.2528 54.4215 84.6748 55.1247 84.9581C55.8278 85.2414 56.5808 85.3801 57.3388 85.3661C58.0967 85.352 58.844 85.1855 59.5362 84.8763C60.2283 84.5672 60.8512 84.1218 61.3675 83.5668L91.4141 46.0085C92.4372 44.9347 92.9965 43.5013 92.9713 42.0184C92.946 40.5354 92.3381 39.1219 91.279 38.0836C90.2199 37.0453 88.7947 36.4655 87.3115 36.4696C85.8283 36.4737 84.4063 37.0613 83.353 38.1055Z" fill="#2674E5"/>
      </svg>
      
       `}
    />
  );
};

export default ModalSvg;
