import React from "react";
import { Platform } from "react-native";
import Svg, { SvgXml, Path, Ellipse } from "react-native-svg";
import { Colors } from "../../../themes";

const SettingsSvg = ({ isFocused }) => {
  const myFocusColor = isFocused
    ? Colors.blueprimary
    : Colors.greySecondaryFour;

  return (
    <Svg
      width="24"
      height="26"
      viewBox="0 0 24 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.2744 7.89418L21.5482 6.63407C20.9339 5.56783 19.5725 5.2 18.5048 5.8118V5.8118C17.9965 6.11119 17.3901 6.19614 16.8191 6.04789C16.2482 5.89965 15.7597 5.5304 15.4613 5.02156C15.2693 4.69813 15.1662 4.32975 15.1623 3.95367V3.95367C15.1796 3.35072 14.9521 2.76643 14.5317 2.33391C14.1112 1.90139 13.5336 1.65747 12.9304 1.65772H11.4674C10.8764 1.65771 10.3098 1.89319 9.89296 2.31206C9.47609 2.73093 9.24333 3.29865 9.24617 3.8896V3.8896C9.22866 5.10971 8.23452 6.08957 7.01429 6.08945C6.63821 6.08554 6.26983 5.98239 5.9464 5.79044V5.79044C4.8787 5.17864 3.51726 5.54647 2.90292 6.61271L2.12336 7.89418C1.50977 8.95909 1.87259 10.3197 2.93496 10.9377V10.9377C3.62551 11.3363 4.05091 12.0731 4.05091 12.8705C4.05091 13.6679 3.62551 14.4047 2.93496 14.8034V14.8034C1.87394 15.4172 1.51072 16.7745 2.12336 17.8362V17.8362L2.86021 19.107C3.14805 19.6264 3.631 20.0096 4.20218 20.172C4.77337 20.3343 5.38571 20.2623 5.90368 19.972V19.972C6.41289 19.6749 7.01968 19.5935 7.58919 19.7459C8.1587 19.8983 8.64373 20.2718 8.93648 20.7836C9.12844 21.107 9.23159 21.4754 9.23549 21.8515V21.8515C9.23549 23.0841 10.2347 24.0833 11.4674 24.0833H12.9304C14.1589 24.0834 15.1564 23.0906 15.1623 21.8621V21.8621C15.1594 21.2693 15.3936 20.7 15.8128 20.2808C16.232 19.8616 16.8013 19.6274 17.3942 19.6303C17.7693 19.6403 18.1362 19.743 18.462 19.9293V19.9293C19.5269 20.5429 20.8875 20.18 21.5055 19.1177V19.1177L22.2744 17.8362C22.572 17.3254 22.6537 16.717 22.5014 16.1457C22.349 15.5745 21.9753 15.0875 21.4628 14.7927V14.7927C20.9503 14.4979 20.5766 14.011 20.4242 13.4397C20.2719 12.8685 20.3536 12.2601 20.6512 11.7492C20.8447 11.4114 21.1249 11.1312 21.4628 10.9377V10.9377C22.5188 10.32 22.8808 8.96737 22.2744 7.90485V7.90485V7.89418Z"
        stroke={myFocusColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Ellipse
        cx="12.2042"
        cy="12.8706"
        rx="3.07551"
        ry="3.07551"
        stroke={myFocusColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default SettingsSvg;
