import React, { useEffect, useState } from "react";
import { StyleSheet, Text, View, ActivityIndicator } from "react-native";
import { moderateScale } from "react-native-size-matters";
import BackBtnSvg from "../../Assets/Icons/backBtnSvg";
import AbstractButton from "../../Components/AbstractComponents/abstractButton";
import AbstractContentContainer from "../../Components/AbstractComponents/abstractContentContainer";
import AbstractHeaders from "../../Components/AbstractComponents/abstractHeaders";
import FocusAwareStatusBar from "../../Components/AbstractComponents/statusbarConfiguration";
import DropDownWithLabel from "../../Components/ModuleComponents/dropDownWithLabel";
import { Colors, Fonts } from "../../themes";
import ScreenContainer from "../../Components/AbstractComponents/abstractScreenContainer";
import { goBack } from "../../Navigation/mainNavigation";
import { useSelector } from "react-redux";
import VehiclesController, {
  useVehicleFilters,
} from "../../Controllers/vehiclesController";
import RangeSlider from "../../Components/ModuleComponents/twoWayRangeSlider/rangeSlider";
import ReduxDispatchController from "../../Controllers/reduxDispatchController";

const FilterScreen = () => {
  const [selectedYear, setSelectedYear] = useState("All");
  const [selectedStartYear, setSelectedStartYear] = useState(undefined);
  const [selectedEndYear, setSelectedEndYear] = useState(undefined);
  const [years, setYears] = useState([
    // {label: 'All', value: "All"},
    // {label: '2000', value: '2000'},
    // {label: '2021', value: '2021'},
    // {label: '2022', value: '2022'},
  ]);

  const [selectedVehicleMake, setSelectedMake] = useState("All");
  const [vehicleMakes, setMakes] = useState([
    // {label: 'All', value: "All"},
    // {label: 'Toyota', value: 'Toyota'},
    // {label: 'Honda', value: 'Honda'},
    // {label: 'Suzuki', value: 'Suzuki'},
  ]);

  const [selectedVehicleType, setSelectedType] = useState("All");
  const [typeOfVehicles, setTypeOfVehicles] = useState([
    // {label: 'All', value: "All"},
    // {label: 'Sedan', value: 'Sedan'},
    // {label: 'SUV', value: 'SUV'},
    // {label: 'Lorry', value: 'Lorry'},
  ]);

  const [selectedMile, setSelectedMile] = useState(0);
  const [miles, setMiles] = useState([
    { label: "No Limit", value: 0 },
    { label: "10 mi", value: 10 },
    { label: "50 mi", value: 50 },
    { label: "100 mi", value: 100 },
    { label: "150 mi", value: 150 },
    { label: "200 mi", value: 200 },
  ]);

  const searchFilters = useSelector(
    (state) => state.VehiclesReducer.searchFilters
  );
  const {
    loadingFilters,
    yearsMeta,
    makesMeta,
    vehicleTypesMeta,
    filtersLoadedOnce,
  } = useVehicleFilters();
  const handleApplyFilter = () => {
    VehiclesController.applyFilters(
      selectedYear,
      selectedVehicleMake,
      selectedVehicleType,
      selectedMile,
      selectedStartYear,
      selectedEndYear
    );
    VehiclesController.handleUpdateHorizontalFilters({
      endYear: selectedEndYear,
      startYear: selectedStartYear,
    });
    goBack();
  };

  useEffect(() => {
    if (!filtersLoadedOnce) {
      setTimeout(() => {
        VehiclesController.handleLoadVehicleFilters();
      }, 2000);
    }
  }, [filtersLoadedOnce]);

  useEffect(() => {
    if (searchFilters.startYear) setSelectedStartYear(searchFilters.startYear);
    if (searchFilters.endYear) setSelectedEndYear(searchFilters.endYear);
    if (searchFilters.year) setSelectedYear(searchFilters.year);
    if (searchFilters.miles) setSelectedMile(searchFilters.miles);
    if (searchFilters.make) setSelectedMake(searchFilters.make);
    if (searchFilters.typeOfVehicle)
      setSelectedType(searchFilters.typeOfVehicle);
  }, [searchFilters]);

  useEffect(() => {
    setYears(yearsMeta);
    setMakes(makesMeta);
    setTypeOfVehicles(vehicleTypesMeta);
  }, [loadingFilters]);
  return (
    <ScreenContainer
      upperStyle={{ backgroundColor: Colors.screenBgColor }}
      bottomStyle={{ backgroundColor: Colors.screenBgColor }}
    >
      <View style={styles.mainContainer}>
        <FocusAwareStatusBar
          barStyle="dark-content"
          backgroundColor={"transparent"}
          translucent={true}
        />

        <AbstractHeaders
          leftChild={() => (
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                // justifyContent: "space-between",
              }}
            >
              <BackBtnSvg />
              <Text style={[styles.textOne, { marginLeft: 4 }]}>Back</Text>
            </View>
          )}
          onPressLeft={() => goBack()}
          middleChild={() => (
            <View>
              <Text style={styles.textTwo}>Filters</Text>
            </View>
          )}
        />

        <AbstractContentContainer>
          <View style={{ marginTop: 10, marginBottom: 25 }}>
            <RangeSlider
              years={years}
              selectedRange={(range) => {
                setSelectedStartYear(range[0]);
                setSelectedEndYear(range[1]);
              }}
            />
          </View>

          {/* <DropDownWithLabel
              placeHolder={'Select year'}
              label={'Year'}
              items={years}
              value={selectedYear}
              onChange={setSelectedYear}
              zIndex={3000}
              zIndexInverse={1000}
            /> */}

          {/* <DropDownWithLabel
            placeHolder={'Select make'}
            label={'Make'}
            items={vehicleMakes}
            value={selectedVehicleMake}
            onChange={setSelectedMake}
            zIndex={2000}
            zIndexInverse={2000}
          /> */}

          {/* <DropDownWithLabel
            label={'Type of vehicle'}
            placeHolder={'Select vehicle'}
            items={typeOfVehicles}
            value={selectedVehicleType}
            onChange={setSelectedType}
            zIndex={1000}
            zIndexInverse={3000}
          /> */}

          <DropDownWithLabel
            label={"Distance"}
            placeHolder={"Select vehicle"}
            items={miles}
            value={selectedMile}
            onChange={setSelectedMile}
            zIndex={1000}
            zIndexInverse={3000}
          />

          <View style={{ height: 30 }} />

          <AbstractButton onPress={handleApplyFilter} label={"Filter"} />
        </AbstractContentContainer>
        {loadingFilters ? (
          <View
            style={[
              StyleSheet.absoluteFillObject,
              {
                backgroundColor: "rgba(0,0,0,0.3)",
                zIndex: 9999,
                justifyContent: "center",
                alignItems: "center",
              },
            ]}
          >
            <ActivityIndicator color={"white"} size="large" />
          </View>
        ) : (
          false
        )}
      </View>
    </ScreenContainer>
  );
};

export default FilterScreen;

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: Colors.screenBgColor,
  },
  textOne: {
    fontFamily: Fonts.default,
    color: "black",
    fontSize: moderateScale(19, 0.1),
    paddingRight: 20,
  },
  textTwo: {
    fontFamily: Fonts.bold,
    color: "black",
    fontSize: moderateScale(19, 0.1),
  },
});
