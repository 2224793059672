import React, { useEffect, useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import AbstractHeaders from "../../Components/AbstractComponents/abstractHeaders";
import { Colors, Fonts, PLATFORM_DETECT } from "../../themes";
import ScreenContainer from "../../Components/AbstractComponents/abstractScreenContainer";
import FocusAwareStatusBar from "../../Components/AbstractComponents/statusbarConfiguration";
import BackBtnSvg from "../../Assets/Icons/backBtnSvg";
import { goBack, navigate } from "../../Navigation/mainNavigation";
import { moderateScale } from "react-native-size-matters";
import AbstractContentContainer from "../../Components/AbstractComponents/abstractContentContainer";
import AbstractTextInput from "../../Components/AbstractComponents/abstractTextInput";
import AbstractButton from "../../Components/AbstractComponents/abstractButton";
import AuthController from "../../Controllers/authController";
import MyImagePicker from "../../Components/CompositeComponents/imagePicker";
import useIsMobileBrowser from "../../../src/Utils/useIsMobileBrowser";
import { useSelector } from "react-redux";

const SettingsEditScreen = () => {
  const { deviceType } = useSelector((state) => state.DeviceTypeReducer);
  const [fullName, setFullName] = useState("");
  const [defImageUrl, setImageUrl] = useState(undefined);
  const [isProfilePicturUpdated, setIsProfilePictureUpdated] = useState(false);
  const [isFormUpdated, setIsFormUpdated] = useState(false);
  const [isPictureValid, setIsPictureValid] = useState(true);
  const [isFullNameValid, setIsFullNameValid] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleOnChangeProfilePicture = (imageSource) => {
    setIsProfilePictureUpdated(true);
    setImageUrl(imageSource);
    setIsFormUpdated(true);
  };

  useEffect(() => {
    let currentUser = AuthController.getCurrentUser();
    setFullName(currentUser.fullName);
    setImageUrl({
      ...currentUser?.profilePicture?.avatar,
      uri: currentUser?.profilePicture?.image.url,
    });
  }, []);

  const onSubmit = () => {
    let isValid = true;
    if (isProfilePicturUpdated) {
      if (defImageUrl?.uri) {
        setIsPictureValid(true);
      } else {
        setIsPictureValid(false);
        isValid = false;
      }
    }
    if (fullName.length === 0) {
      setIsFullNameValid(false);
      isValid = false;
    } else {
      setIsFullNameValid(true);
    }
    if (isValid) handleSave();
  };

  const handleSave = () => {
    setProcessing(true);
    AuthController.updateProfileInfo_web(
      fullName,
      isProfilePicturUpdated ? defImageUrl : undefined
    )
      .then((savedProfileData) => {
        setProcessing(false);
        setIsProfilePictureUpdated(false);
        setIsFormUpdated(false);
        setErrorMessage("");
        // console.log(`Successfully saved profile data.`);
      })
      .catch((err) => {
        setProcessing(false);
        console.log(`CATCH Error in saving profile.`);
        setErrorMessage("Network Error Check Your Internet");
        console.log(err);
      });
    // } else {
    //   AuthController.updateProfileInfo(
    //     fullName,
    //     isProfilePicturUpdated ? { defImageUrl } : undefined
    //   )
    //     .then((savedProfileData) => {
    //       setProcessing(false);
    //       setIsProfilePictureUpdated(false);
    //       setIsFormUpdated(false);
    //       setErrorMessage("");
    //       console.log(`Successfully saved profile data.`);
    //     })
    //     .catch((err) => {
    //       setProcessing(false);
    //       console.log(`CATCH Error in saving profile.`);
    //       setErrorMessage("Network Error Check Your Internet");
    //       console.log(err);
    //     });
    // }
  };

  return (
    <ScreenContainer
      upperStyle={{
        backgroundColor: "#FFFFFF",
        // backgroundColor: Colors.screenBgColor
      }}
      bottomStyle={{
        backgroundColor: "#FFFFFF",
        // backgroundColor: Colors.screenBgColor
      }}
    >
      <View style={styles.mainContainer}>
        <FocusAwareStatusBar
          barStyle="dark-content"
          backgroundColor={"transparent"}
          translucent={true}
        />

        <AbstractHeaders
          leftChild={() => (
            <View
              style={{
                flexDirection: "row",
                // backgroundColor: "red",
                alignItems: "center",
                // justifyContent: "space-between",
              }}
            >
              <BackBtnSvg />
              <Text style={styles.textOne}>Back</Text>
            </View>
          )}
          onPressLeft={() => goBack()}
          middleChild={() => (
            <View>
              <Text style={styles.textTwo}>Edit profile</Text>
            </View>
          )}
        />
        <AbstractContentContainer>
          <View style={{ alignSelf: "center", marginTop: 25 }}>
            <MyImagePicker
              imageUrl={defImageUrl?.uri}
              isValid={isPictureValid}
              onChange={handleOnChangeProfilePicture}
            />
          </View>

          <View style={{ marginTop: 30, width: "100%", alignItems: "center" }}>
            <AbstractTextInput
              label={"Full Name"}
              placeHolder={"full name"}
              value={fullName}
              onChangeText={(text) => {
                setIsFormUpdated(true);
                setFullName(text);
              }}
              overrideErrorMessage={
                !isFullNameValid ? "*Full Name is required" : ""
              }
            />
          </View>
        </AbstractContentContainer>
      </View>
      <View
        style={{
          marginTop: 20,
          width: "90%",
          alignSelf: "center",
          backgroundColor: "#FFFFFF",
          alignItems: "center",
          paddingBottom: 30,
        }}
      >
        {isFormUpdated ? (
          <>
            <AbstractButton
              label={"Save"}
              width={deviceType ? "50%" : "100%"}
              onPress={onSubmit}
              processing={processing}
            />
            <View
              style={{
                width: "100%",
                alignItems: "center",
                marginTop: 10,
              }}
            >
              <Text style={{ fontFamily: Fonts.medium, color: "red" }}>
                {errorMessage}
              </Text>
            </View>
          </>
        ) : (
          false
        )}
      </View>
    </ScreenContainer>
  );
};

export default SettingsEditScreen;

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: "#FFFFFF",
  },
  textOne: {
    fontFamily: Fonts.default,
    color: "black",
    fontSize: moderateScale(19, 0.1),
    paddingRight: 20,
  },
  textTwo: {
    fontFamily: Fonts.default,
    color: "black",
    fontSize: moderateScale(19, 0.1),
    fontWeight: "800",
  },
});
