import {
  FlatList,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ScrollView,
  NativeModules,
  ActivityIndicator,
} from "react-native";
import React, { useEffect, useState } from "react";
import ScreenContainer from "../../Components/AbstractComponents/abstractScreenContainer";
import { Colors, Fonts, PLATFORM_DETECT } from "../../themes";
import AbstractContentContainer from "../../Components/AbstractComponents/abstractContentContainer";
import FocusAwareStatusBar from "../../Components/AbstractComponents/statusbarConfiguration";
import AbstractHeaders from "../../Components/AbstractComponents/abstractHeaders";
import { moderateScale } from "react-native-size-matters";
import AbstractButton from "../../Components/AbstractComponents/abstractButton";
import AuctionFireSvg from "../../Assets/Icons/auctionFireSvg";
import AuctionArrowForwardSvg from "../../Assets/Icons/auctionArrowForwardSvg";
import { useSelector } from "react-redux";
import AuctionCardItem from "../../Components/ModuleComponents/AuctionComponents/auctionCardItem";
import ImagePlacer from "../../Components/ModuleComponents/imagePlacer";
import Checkbox from "expo-checkbox";
import {
  getFirstImage,
  useVehicleFilters,
} from "../../Controllers/vehiclesController";
import { LinearGradient } from "expo-linear-gradient";
import BackBtnSvg from "../../Assets/Icons/backBtnSvg";
import { goBack, navigate } from "../../Navigation/mainNavigation";
import CardSwipe from "../../Components/ModuleComponents/AuctionComponents/cardSwipe";
import AuctionController from "../../Controllers/auctionController";
import CardSwipeSkeletonPlaceholder from "../../Components/ModuleComponents/AuctionComponents/cardSwipeSkeletonPlaceholder";
import NoCarAvailableSvg from "../../Assets/Icons/noCarAvailableSvg";
import FilterSvg from "../../Assets/Icons/filterSvg";
import AbstractBottomSheet from "../../Components/AbstractComponents/abstractBottomSheetV2";
import { SheetManager } from "react-native-actions-sheet";
import RangeSlider from "../../Components/ModuleComponents/twoWayRangeSlider/rangeSliderForAuctionVehicle";
import FilterMakeItem from "../../Components/ModuleComponents/filterMakeItem";
import DateFormatter from "../../Utils/dateFormatter";
import * as Linking from "expo-linking";
import SingleLinePlaceholder from "../../Components/Placeholders/singleLinePlaceholder";

const AUCTION_FILTER_SHEET = "auctionFilterSheet";
const { StatusBarManager } = NativeModules;
let defaultMake = { label: "All", value: "All", checked: true };
const AuctionDetailScreen = ({ route }) => {
  const AuctionId = route.params?.auctionId;
  const [totalNoPage, setTotalNoPage] = useState(undefined);
  const [currentPage, setCurrentPage] = useState(0);
  const { deviceType } = useSelector((state) => state.DeviceTypeReducer);

  const [auctionVehiclesList, setAuctionVehiclesList] = useState([]);
  const [item, setItem] = useState({});
  const [loaderFirstTimePlaceHolder, setLoaderFirstTimePlaceHolder] =
    useState(true);
  const [years, setYears] = useState([]);
  const [vehicleMakes, setMakes] = useState([]);
  const [selectedStartYear, setSelectedStartYear] = useState(undefined);
  const [selectedEndYear, setSelectedEndYear] = useState(undefined);
  const [arrayOfMakes, setArrayOfMakes] = useState([]);
  const [overlayLoading, setOverLayLoading] = useState(false);
  const [singleAuctionLoading, setSingleAuctionLoading] = useState(true);

  useEffect(() => {
    if (item?.modalYear?.length > 0) {
      setSelectedStartYear(item?.modalYear[0]);
      setSelectedEndYear(
        item?.modalYear[item?.modalYear?.length - 1]
          ? item?.modalYear[item?.modalYear?.length - 1]
          : item?.modalYear[0]
      );
      setYears(item?.modalYear);
    }
    if (item?.make?.length > 0) {
      const makesNewArr = item?.make?.map((item) => ({
        ...item,
        checked: true,
        label: item,
        value: item,
      }));
      setMakes([defaultMake, ...makesNewArr]);
    }
  }, [item]);

  useEffect(() => {
    setTimeout(() => {
      handleFirstTimeLoadVehicle();
      AuctionController.getSingleAuction(AuctionId)
        .then((res) => {
          setItem(res);
          setSingleAuctionLoading(false);
        })
        .catch((err) => {
          setSingleAuctionLoading(false);

          console.log(err, "error in getting single Auction detail");
        });
    }, 500);
  }, []);
  const handleFirstTimeLoadVehicle = () => {
    AuctionController.handleFilterAuctionVehicleByMakeModal(AuctionId)
      .then((data) => {
        setAuctionVehiclesList(data.auctionedVehicle);
        setTotalNoPage(data.noOfPages);
        setLoaderFirstTimePlaceHolder(false);
        setOverLayLoading(false);
      })
      .catch((err) => {
        setLoaderFirstTimePlaceHolder(false);
        setOverLayLoading(false);
        console.log(err);
      });
  };

  const onPressFilterButton = () => {
    SheetManager.show(AUCTION_FILTER_SHEET);
  };

  const onChecked = (it, index) => {
    if (!index) {
      console.log("T1");
      const newFilteredArray = vehicleMakes.map((item) => ({
        ...item,
        checked: it.checked,
      }));
      setMakes(newFilteredArray);
      setArrayOfMakes([]);
    } else {
      console.log("T2");

      var newFilteredArray = vehicleMakes.map((item) =>
        item.label == "All" ? { ...item, checked: false } : { ...item }
      );
      newFilteredArray[index] = it;
      let arrfilteredArray = newFilteredArray.filter(
        (item) => item.checked == false
      );

      if (arrfilteredArray.length == 1 && arrfilteredArray[0].label == "All") {
        let newARR = newFilteredArray.map((item) =>
          item.label == "All" ? { ...item, checked: true } : { ...item }
        );
        setMakes(newARR);
        setArrayOfMakes([]);

        console.log("T3");
      } else {
        console.log("T4");

        setMakes(newFilteredArray);
        let queryData = [];
        newFilteredArray.map((item) => {
          if (item.checked) {
            queryData.push(item.value);
          }
        });
        setArrayOfMakes(queryData);
      }
    }
  };

  const onPressSearch = () => {
    console.log("ArrayOfMakes:", arrayOfMakes);
    // console.log("start:", selectedStartYear);
    // console.log("end:", selectedEndYear);

    if ((selectedStartYear && selectedEndYear) || arrayOfMakes.length > 0) {
      setCurrentPage(0);
      setOverLayLoading(true);
      SheetManager.hide(AUCTION_FILTER_SHEET);
      AuctionController.handleFilterAuctionVehicleByMakeModal(
        AuctionId,
        arrayOfMakes,
        {
          startYear: selectedStartYear,
          endYear: selectedEndYear,
        },
        0
      )
        .then((data) => {
          setAuctionVehiclesList(data.auctionedVehicle);
          setTotalNoPage(data.noOfPages);
          setOverLayLoading(false);
        })
        .catch((err) => {
          console.log((err) => {
            setOverLayLoading(false);
            console.log(err, "error in getting auction vehicle by filters");
          });
        });
    }
  };

  const handleLoadMore = () => {
    if (currentPage < totalNoPage - 1 && auctionVehiclesList.length > 0) {
      let temp = currentPage + 1;
      setCurrentPage((current) => current + 1);
      setOverLayLoading(true);
      SheetManager.hide(AUCTION_FILTER_SHEET);
      AuctionController.handleFilterAuctionVehicleByMakeModal(
        AuctionId,
        arrayOfMakes,
        {
          startYear: selectedStartYear,
          endYear: selectedEndYear,
        },
        temp
      )
        .then((data) => {
          setAuctionVehiclesList([
            ...auctionVehiclesList,
            ...data.auctionedVehicle,
          ]);
          setTotalNoPage(data.noOfPages);
          setOverLayLoading(false);
        })
        .catch((err) => {
          console.log((err) => {
            setOverLayLoading(false);
            console.log(err, "error in getting auction vehicle by filters");
          });
        });
    }
  };
  const resetFilter = () => {
    SheetManager.hide(AUCTION_FILTER_SHEET);
    setOverLayLoading(true);
    setCurrentPage(0);
    if (item?.modalYear?.length > 0) {
      setSelectedStartYear(item?.modalYear[0]);
      setSelectedEndYear(
        item?.modalYear[item?.modalYear?.length - 1]
          ? item?.modalYear[item?.modalYear?.length - 1]
          : item?.modalYear[0]
      );
      setYears(item?.modalYear);
    }
    if (item?.make?.length > 0) {
      const makesNewArr = item?.make?.map((item) => ({
        ...item,
        checked: true,
        label: item,
        value: item,
      }));
      setMakes([defaultMake, ...makesNewArr]);
    }

    handleFirstTimeLoadVehicle();
  };
  const makeUrl = () => {
    console.log(route);
    const url = Linking.createURL(
      `${window.location.pathname}${AuctionId}`,
      false
    );
  };
  // console.log(item);
  return (
    <View style={styles.mainContainer}>
      <FocusAwareStatusBar
        barStyle="dark-content"
        backgroundColor={"transparent"}
        translucent={true}
      />
      <View style={{ height: PLATFORM_DETECT.SH * 0.35 }}>
        <ImagePlacer
          withNoBorder={true}
          imageUrl={getFirstImage(item?.pictures)?.image?.url}
          avatarUrl={getFirstImage(item?.pictures)?.avatar?.url}
          type={"large"}
        />

        <View style={{ ...StyleSheet.absoluteFillObject }}>
          <LinearGradient
            style={{ flex: 1, height: "100%" }}
            // Button Linear Gradient
            colors={[
              "rgba(255,255,255,.1)",
              "rgba(255,255,255,.2)",
              "rgba(255,255,255,1)",
            ]}
          >
            <View
              style={{
                height:
                  Platform.OS === "android" || Platform.OS === "ios"
                    ? StatusBarManager.HEIGHT
                    : 10,
              }}
            />
            <AbstractContentContainer>
              <View
                style={{
                  width: "100%",
                  // backgroundColor: "red",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingVertical: 10,
                }}
              >
                <TouchableOpacity
                  onPress={() => goBack()}
                  // onPress={makeUrl}
                  style={{
                    // marginTop: 50,
                    flexDirection: "row",
                    padding: 5,
                    borderRadius: 8,
                    alignItems: "center",
                    backgroundColor: "rgba(255,255,255,.7)",
                    alignSelf: "flex-start",
                  }}
                >
                  <BackBtnSvg color={Colors.blackPrimary} />
                  <Text
                    style={[
                      styles.textOne,
                      { fontSize: moderateScale(16, 0.1), marginLeft: 4 },
                    ]}
                  >
                    Back
                  </Text>
                </TouchableOpacity>
                {item?.make?.length > 1 || item?.modalYear?.length > 1 ? (
                  <TouchableOpacity
                    onPress={onPressFilterButton}
                    style={{ paddingHorizontal: 5 }}
                  >
                    <FilterSvg color={Colors.whitePrimary} />
                  </TouchableOpacity>
                ) : (
                  false
                )}
              </View>
            </AbstractContentContainer>
          </LinearGradient>
        </View>

        <View
          style={{
            position: "absolute",
            paddingVertical: 6,
            paddingHorizontal: 13,
            borderRadius: 5,
            alignItems: "center",
            alignSelf: "center",
            bottom: 10,
          }}
        >
          <>
            {!item?.title && singleAuctionLoading ? (
              <SingleLinePlaceholder
                h={deviceType ? 40 : 20}
                w={deviceType ? 350 : 250}
              />
            ) : (
              <Text style={[styles.textOne, { marginVertical: 5 }]}>
                {item?.title}
              </Text>
            )}
            {!item.auctionStartDateTime && singleAuctionLoading ? (
              <>
                <SingleLinePlaceholder
                  mv={5}
                  h={20}
                  w={deviceType ? 300 : 200}
                />
              </>
            ) : (
              <Text style={styles.auctionDateTxt}>
                Start date:{" "}
                {item?.auctionStartDateTime
                  ? DateFormatter.formatDateWithUSAFormatAndAtTheRate(
                      item?.auctionStartDateTime,
                      true
                    )
                  : "-- -- --"}
                {"  "}
                {deviceType ? "       " : "\n"} End date:{" "}
                {item?.auctionEndDateTime
                  ? DateFormatter.formatDateWithUSAFormatAndAtTheRate(
                      item?.auctionEndDateTime,
                      true
                    )
                  : "-- -- --"}
              </Text>
            )}
          </>
        </View>
      </View>

      <AbstractContentContainer>
        <View style={styles.secondContainer}>
          <View style={{ alignItems: "center" }}>
            {/* {item?.numberOfAuctionCars || item?.numberOfAuctionCars == 0 ? (
              <>
                <Text style={styles.dealValue}>
                  {item?.numberOfAuctionCars
                    ? item?.numberOfAuctionCars
                    : auctionVehiclesList?.length}
                </Text>
                <Text style={styles.small}>Cars</Text>
              </>
            ) : (
              <>
                <SingleLinePlaceholder h={15} w={30} />
                <SingleLinePlaceholder mv={5} h={20} w={50} />
              </>
            )} */}
            {!item?.numberOfAuctionCars && singleAuctionLoading ? (
              <>
                <SingleLinePlaceholder h={15} w={30} />
                <SingleLinePlaceholder mv={5} h={20} w={50} />
              </>
            ) : (
              <>
                <Text style={styles.dealValue}>
                  {auctionVehiclesList?.length}
                </Text>
                <Text style={styles.small}>Cars</Text>
              </>
            )}
          </View>

          {/* <View style={{ alignItems: "center" }}>
            <Text style={styles.dealValue}>{"10"}</Text>
            <Text style={styles.small}>Deals</Text>
          </View>
          <View style={{ alignItems: "center" }}>
            <Text style={styles.dealValue}>{"200"}</Text>
            <Text style={styles.small}>Watching</Text>
          </View> */}
        </View>
      </AbstractContentContainer>

      {loaderFirstTimePlaceHolder ? (
        <CardSwipeSkeletonPlaceholder
          suggestionCard={false}
          data={[0, 0, 0, 0, 0]}
        />
      ) : auctionVehiclesList?.length > 0 ? (
        <CardSwipe
          suggestionCard={true}
          data={auctionVehiclesList}
          loadMore={handleLoadMore}
        />
      ) : (
        <View
          style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
        >
          <NoCarAvailableSvg label={"No cars found!"} />
        </View>
      )}

      {overlayLoading ? (
        <View
          style={{
            ...StyleSheet.absoluteFillObject,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(1,1,1,0.3)",
          }}
        >
          <ActivityIndicator size={"large"} color={Colors.blueprimary} />
        </View>
      ) : (
        false
      )}

      <AbstractBottomSheet
        customHeaderNotVisible
        gestureEnable={false}
        id={AUCTION_FILTER_SHEET}
      >
        <AbstractContentContainer>
          <View style={{ width: "100%" }}>
            <>
              <View
                style={{
                  width: "100%",
                  // justifyContent: "flex-start",
                  // backgroundColor: "red",
                  // flexDirection: "row",
                  // alignItems: "center",
                  marginBottom: 10,
                  marginTop: 20,
                }}
              >
                <Text
                  style={[
                    styles.textTwo,
                    { alignSelf: "center", fontSize: moderateScale(22, 0.1) },
                  ]}
                >
                  Filters
                </Text>
                <Text
                  onPress={resetFilter}
                  style={[
                    styles.textTwo,
                    {
                      position: "absolute",
                      right: 0,
                      color: Colors.blueprimary,
                      textDecorationLine: "underline",
                      fontSize: moderateScale(14, 0.1),
                    },
                  ]}
                >
                  Reset
                </Text>
              </View>
            </>

            {item?.modalYear?.length > 1 && (
              <RangeSlider
                years={years}
                searchFilters={{
                  endYear: selectedEndYear,
                  startYear: selectedStartYear,
                }}
                selectedRange={(range) => {
                  setSelectedStartYear(range[0]);
                  setSelectedEndYear(range[1]);
                }}
              />
            )}

            {item?.make?.length > 0 && (
              <>
                <View style={{ marginTop: 20, marginBottom: 10 }}>
                  <Text style={styles.textFour}>Make</Text>
                </View>
                <View style={{ width: "100%", maxHeight: 250 }}>
                  <ScrollView showsVerticalScrollIndicator={false}>
                    {vehicleMakes.map((item, index) => {
                      return (
                        <FilterMakeItem
                          item={item}
                          key={index}
                          index={index}
                          onChecked={onChecked}
                        />
                      );
                    })}
                  </ScrollView>
                </View>
              </>
            )}
            <View style={{ height: 25 }} />
          </View>
          <AbstractButton onPress={onPressSearch} label={"Search"} />
          <View style={{ height: 20 }}></View>
        </AbstractContentContainer>
      </AbstractBottomSheet>
    </View>
  );
};

export default AuctionDetailScreen;

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: Colors.whitePrimary,
    paddingBottom: 30,
  },
  secondContainer: {
    flexDirection: "row",
    width: "100%",
    paddingVertical: 5,
    justifyContent: "space-around",
  },
  thirdContainer: {
    flex: 1,
  },

  textOne: {
    fontSize: moderateScale(20, 0.1),
    color: "black",
    fontFamily: Fonts.medium,
    fontWeight: "600",
  },
  auctionDateTxt: {
    fontSize: moderateScale(14, 0.1),
    color: Colors.greySecondaryFour,
    fontFamily: Fonts.medium,
    fontWeight: "500",
  },
  textTwo: {
    fontFamily: Fonts.medium,
    color: Colors.blackPrimary,
    fontSize: moderateScale(20, 0.1),
    paddingBottom: 10,
    fontWeight: "600",
  },
  textThree: {
    fontFamily: Fonts.medium,
    color: Colors.blackPrimary,
    fontSize: moderateScale(20, 0.1),
    paddingRight: 10,
    fontWeight: "500",
  },
  small: {
    fontSize: moderateScale(14, 0.1),
    color: Colors.greySecondaryFour,
    fontFamily: Fonts.default,
    fontWeight: "600",
    paddingBottom: 5,
  },
  dealValue: {
    fontSize: moderateScale(16, 0.1),
    color: Colors.blackPrimary,
    fontFamily: Fonts.bold,
    fontWeight: "600",
    paddingBottom: 5,
  },
  textFour: {
    fontSize: moderateScale(13, 0.1),
    fontFamily: Fonts.bold,
    fontWeight: "900",
  },
});
