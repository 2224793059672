import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  TextInput,
  Platform,
} from "react-native";
import React, { useEffect, useState } from "react";
import { moderateScale } from "react-native-size-matters";
import { Colors, Fonts } from "../../../themes";
import CrossSvg from "../../../Assets/Icons/crossSvg";
import AbstractButton from "../../AbstractComponents/abstractButton";
import AuthController from "../../../Controllers/authController";
import { SheetManager } from "react-native-actions-sheet";
import AddCardSheet from "../payment/AddCard";
import OffersController from "../../../Controllers/offersController";
const BidInfoSheetV2 = ({
  singleAuction,
  initialPriceRange,
  close,
  lastBidAmount,
  setMyBidAmount,
  setInitialPriceRange,
  bidGap,
}) => {
  const vehicle = singleAuction?.vehicle;
  const [bidValue, setBidValue] = useState(0);
  const [error, setError] = useState("");
  const [processing, setProcessing] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [openCardSheet, setOpenCardSheet] = useState(false);
  const [cardSetupMeta, setCardSetupMeta] = useState({});

  const submitBidAmount = (bidAmount) => {
    const auctionVehicle = singleAuction._id;
    const auction = singleAuction?.auction?._id;
    const vehicle = singleAuction?.vehicle?._id;
    // console.log(
    //   bidAmount,
    //   auctionVehicle,
    //   auction,
    //   vehicle,
    //   "data to create bid"
    // );
    setProcessing(true);
    setErrorMessage("");
    AuthController.addCard()
      .then((res) => {
        if (res.paymentMethodVerified) {
          console.log(res, "res of add card api");
          OffersController.createAnBidOnAuction(
            bidAmount,
            auctionVehicle,
            auction,
            vehicle
          )
            .then((created) => {
              console.log(`Bid Create Success`, created);
              if (created.exception) {
                setMyBidAmount(bidAmount);
                const temp_range = created.lastBidAmount
                  ? created.lastBidAmount + created?.bidGap
                  : created.initialBidAmount;
                const temp_bid = created.lastBidAmount
                  ? created.lastBidAmount
                  : created.initialBidAmount;
                setMyBidAmount(temp_bid);
                setInitialPriceRange(temp_range);
                SheetManager.hide("Car-Detail-PLace-Bid-Sheet");
                alert(`Latest bid is ${temp_bid}, please try again.`);
              } else {
                setMyBidAmount(bidAmount);
                setInitialPriceRange(bidAmount + bidGap);
                SheetManager.hide("Car-Detail-PLace-Bid-Sheet");
              }
              setProcessing(false);
            })
            .catch(({ lowBalance, message }) => {
              console.log(`Bid create fail`, message);
              setErrorMessage(message);
              setProcessing(false);
            });
        } else {
          console.log("got setupIntent:", res);
          setCardSetupMeta(res);
          setOpenCardSheet(true);
        }
      })
      .catch((message) => {
        console.log(message);
        console.log(`check status of payment is failed`, message);

        setErrorMessage(message);
        setProcessing(false);
      });
  };
  useEffect(() => {
    if (initialPriceRange) {
      setBidValue(initialPriceRange);
    }
  }, []);

  const onPressSubmit = () => {
    // console.log(bidValue);

    if (bidValue && bidValue >= initialPriceRange) {
      submitBidAmount(Number(bidValue));
    }
  };
  const onChangeText = (txt) => {
    let temp = Number(txt);

    if (temp < initialPriceRange) {
      setError("Price is low!");
    } else {
      setError("");
    }
    setBidValue(temp);
  };

  const handleDismissPaymentSheet = () => {
    setOpenCardSheet(false);
    setProcessing(false);
    setErrorMessage("You cannot place bid without adding Card.");
  };

  const handleSuccessPaymentSheet = () => {
    setOpenCardSheet(false);
    let bidAmount = bidValue;
    const auctionVehicle = singleAuction._id;
    const auction = singleAuction?.auction?._id;
    const vehicle = singleAuction?.vehicle?._id;
    OffersController.createAnBidOnAuction(
      bidAmount,
      auctionVehicle,
      auction,
      vehicle
    )
      .then((created) => {
        setProcessing(false);
        if (created.exception) {
          // console.log(`Bid Create Success`, created);
          setMyBidAmount(bidAmount);
          const temp_range = created.lastBidAmount
            ? created.lastBidAmount + created?.bidGap
            : created.initialBidAmount;
          const temp_bid = created.lastBidAmount
            ? created.lastBidAmount
            : created.initialBidAmount;
          setMyBidAmount(temp_bid);
          setInitialPriceRange(temp_range);
          SheetManager.hide("Car-Detail-PLace-Bid-Sheet");
          alert(`Latest bid is ${temp_bid}, please try again.`);
        } else {
          setMyBidAmount(bidAmount);
          setInitialPriceRange(bidAmount + bidGap);
          SheetManager.hide("Car-Detail-PLace-Bid-Sheet");
        }
      })
      .catch(({ lowBalance, message }) => {
        console.log(`Bid create fail`, message);
        setErrorMessage(message);
        setProcessing(false);
      });
  };

  return (
    <View style={styles.mainContainer}>
      <View style={[styles.viewOne, { marginVertical: 15 }]}>
        <View>
          <Text style={styles.textOne}>Place your bid</Text>
        </View>
        <View>
          <TouchableOpacity onPress={close} style={{ padding: 0 }}>
            <CrossSvg size={20} color={"white"} />
          </TouchableOpacity>
        </View>
      </View>

      {/* <View style={{ marginBottom: 10, marginTop: 10 }}>
        <Text style={styles.textTwo}>
          Last bid:
          <Text style={{ color: Colors.blueSecondaryTwo }}>
            {` $${lastBidAmount}`}
          </Text>
        </Text>
      </View> */}

      <View style={{ marginBottom: 20, marginTop: 10 }}>
        <Text style={styles.textTwo}>
          Bid starting at: {`$${initialPriceRange}`}
        </Text>
      </View>

      <View>
        <TextInput
          style={{
            borderRadius: 8,
            width: "100%",
            height: moderateScale(55, 0.1),
            borderWidth: 1,
            outline: 0,
            borderColor: "#e5e5e5",
            paddingLeft: 5,
            fontFamily: Fonts.medium,
            color: "#e5e5e5",
            fontSize: moderateScale(17, 0.1),
          }}
          keyboardType="number-pad"
          returnKeyType="done"
          placeholderTextColor={"#e5e5e5"}
          placeholder={`Amount atleast ${initialPriceRange}`}
          defaultValue={initialPriceRange?.toString()}
          value={bidValue}
          onChangeText={onChangeText}
        />
      </View>
      <View style={{ marginVertical: 5 }}>
        {error ? (
          <View style={{ height: 20 }}>
            <Text style={styles.errorText}>{error}</Text>
          </View>
        ) : (
          <View style={{ height: 20 }} />
        )}
      </View>

      <View style={{ height: 150, width: "100%" }}>
        <View style={{ marginTop: 5 }}>
          <AbstractButton
            onPress={onPressSubmit}
            processing={processing}
            label={"Submit"}
          />
        </View>
        <View
          style={{ width: "100%", alignItems: "center", marginVertical: 5 }}
        >
          {errorMessage ? (
            <View style={{}}>
              <Text style={styles.errorText}>{errorMessage}</Text>
            </View>
          ) : (
            <View style={{ height: 10 }} />
          )}
        </View>
      </View>
      {openCardSheet && (
        <AddCardSheet
          handleDismissPaymentSheet={handleDismissPaymentSheet}
          intent={cardSetupMeta}
          onClose={handleDismissPaymentSheet}
          onSuccess={handleSuccessPaymentSheet}
        />
      )}
    </View>
  );
};

export default BidInfoSheetV2;

const styles = StyleSheet.create({
  mainContainer: {
    width: "90%",
    height: Platform.select({ native: 300 }),
    alignSelf: "center",
  },
  viewOne: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  textOne: {
    color: "white",
    fontSize: moderateScale(25, 0.1),
    fontFamily: Fonts.bold,
  },
  textTwo: {
    color: "white",
    fontSize: moderateScale(18, 0.1),
    fontFamily: Fonts.medium,
  },
  errorText: {
    color: "red",
    fontSize: moderateScale(15, 0.1),
    fontFamily: Fonts.default,
  },
});
