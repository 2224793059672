import {
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  ActivityIndicator,
} from "react-native";
import React, { useEffect, useState } from "react";
import SearchIconSvg from "../../Assets/Icons/searchIconSvg";
import { Colors, Fonts } from "../../themes";
import { ScrollView } from "react-native-gesture-handler";
import GooglePlacesInput from "../AbstractComponents/googleAutoPlacesApi";
import AuctionController from "../../Controllers/auctionController";
import { useSelector } from "react-redux";

const SearchLocationBarWeb = ({ onSearchAuction, resetFilter }) => {
  const [location, setLocation] = useState("");
  const [locationArray, setLocationArray] = useState([]);
  const [clear, setClear] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [focusInput, setFocusInput] = useState(false);
  const { deviceType } = useSelector((state) => state.DeviceTypeReducer);

  const handleChangeText = (txt) => {
    setLocation(txt);
    if (txt.trim()) {
      setProcessing(true);
      AuctionController.googlePlacesApi(txt)
        .then((response) => {
          setLocationArray(response);
          setProcessing(false);
        })
        .catch((err) => {
          console.log(err, "error:in getting google places " + err);
          setProcessing(false);
        });
    }
  };

  const handlePressOnSelectedText = (obj) => {
    const { place_id, description } = obj;
    setLocation(description);
    setLocationArray([]);
    AuctionController.getSelectedPlaceDes(place_id)
      .then((response) => {
        // console.log(response);
        onSearchAuction(description, {
          latitude: response.lat,
          longitude: response.lng,
        });
      })
      .catch((err) => {
        console.log(err, "error:in getting google  place details" + err);
      });
  };

  const onClear = () => {
    setLocation("");
    setClear(false);
    resetFilter();
  };

  useEffect(() => {
    if (location) {
      setClear(true);
    } else {
      setClear(false);
      setLocationArray([]);
    }
  }, [location]);

  return (
    <View style={[styles.mainContainer, { width: deviceType ? 300 : 220 }]}>
      <View
        style={{
          width: 35,
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {processing ? (
          <ActivityIndicator size={"small"} color={Colors.blueprimary} />
        ) : (
          <SearchIconSvg color={Colors.blueprimary} />
        )}
      </View>
      <View
        style={{
          flex: 1,
          width: "100%",
          height: "100%",
          borderTopRightRadius: 20,
          borderBottomRightRadius: 20,
        }}
      >
        <TextInput
          placeholderTextColor={Colors.blueprimary}
          placeholder="Search Nearest location"
          style={{
            height: "100%",
            width: "100%",
            color: Colors.blueprimary,
            outline: 0,
            fontSize: 14,
            // backgroundColor:"pink"
          }}
          onBlur={() => {
            setTimeout(() => setFocusInput(false), 250);
          }}
          onFocus={() => setFocusInput(true)}
          value={location}
          onChangeText={(txt) => handleChangeText(txt)}
        />

        <View
          style={{
            backgroundColor: Colors.whitePrimary,
            width: deviceType ? 240 : 180,
            position: "absolute",
            top: 30,
            borderBottomLeftRadius: 5,
            borderBottomRightRadius: 5,
          }}
        >
          <ScrollView
            showsVerticalScrollIndicator={false}
            style={{
              paddingHorizontal: 7,
            }}
          >
            {focusInput && locationArray.length > 0 && (
              <View style={{ height: 5 }} />
            )}
            {focusInput &&
              locationArray.map((item, index) => (
                <TouchableOpacity
                  onPress={() => handlePressOnSelectedText(item)}
                  key={index}
                  style={{
                    width: "100%",
                    backgroundColor: "transparent",
                    paddingVertical: 8,
                    borderBottomWidth:
                      locationArray.length - 1 === index ? 0 : 1,
                    borderBottomColor: "lightgrey",
                  }}
                >
                  <Text
                    numberOfLines={1}
                    style={{ fontSize: 12, fontFamily: Fonts.default }}
                  >
                    {item.description}
                  </Text>
                </TouchableOpacity>
              ))}
            {locationArray.length > 0 && <View style={{ height: 8 }} />}
          </ScrollView>
        </View>
      </View>
      <View
        style={{
          width: 30,
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {clear ? (
          <TouchableOpacity
            onPress={onClear}
            style={{
              width: 15,
              height: 15,
              backgroundColor: "grey",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 30,
            }}
          >
            <Text style={{ color: Colors.whitePrimary, fontSize: 12 }}>X</Text>
          </TouchableOpacity>
        ) : (
          false
        )}
      </View>
    </View>
  );
};

export default SearchLocationBarWeb;

const styles = StyleSheet.create({
  mainContainer: {
    width: 300,
    height: 35,
    backgroundColor: Colors.whitePrimary,
    borderRadius: 20,
    flexDirection: "row",
    alignItems: "center",
  },
});
