import React from "react";
import Svg, { Path, SvgXml } from "react-native-svg";

const DeleteSvgLarge = ({ disabled }) => {
  const defDisabled = disabled ? "#8D8D8D" : "#FF5E51";

  return (
    <Svg
      width="27"
      height="30"
      viewBox="0 0 27 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path
        d="M9 1.5C9 0.671573 9.67157 0 10.5 0H16.5C17.3284 0 18 0.671573 18 1.5V3H25.5C26.3284 3 27 3.67157 27 4.5C27 5.32843 26.3284 6 25.5 6H1.5C0.671574 6 0 5.32843 0 4.5C0 3.67157 0.671573 3 1.5 3H9V1.5Z"
        fill="#FF5E51"
      />
      <Path
        d="M4.5 9C5.32843 9 6 9.67157 6 10.5V25.5C6 26.3284 6.67157 27 7.5 27H19.5C20.3284 27 21 26.3284 21 25.5V10.5C21 9.67157 21.6716 9 22.5 9C23.3284 9 24 9.67157 24 10.5V25.5C24 27.9853 21.9853 30 19.5 30H7.5C5.01472 30 3 27.9853 3 25.5V10.5C3 9.67157 3.67157 9 4.5 9Z"
        fill="#FF5E51"
      />
      <Path
        d="M9 13.5C9 12.6716 9.67157 12 10.5 12C11.3284 12 12 12.6716 12 13.5V22.5C12 23.3284 11.3284 24 10.5 24C9.67157 24 9 23.3284 9 22.5V13.5Z"
        fill="#FF5E51"
      />
      <Path
        d="M16.5 12C15.6716 12 15 12.6716 15 13.5V22.5C15 23.3284 15.6716 24 16.5 24C17.3284 24 18 23.3284 18 22.5V13.5C18 12.6716 17.3284 12 16.5 12Z"
        fill="#FF5E51"
      />
    </Svg>
  );
};

export default DeleteSvgLarge;
