import axios from "axios";
import ReduxDispatchController from "./reduxDispatchController";
import { ERRORS } from "./errorMessages";
import AuthController from "./authController";
import { useSelector } from "react-redux";

class VehiclesController {
  static getDummyVehicle() {
    return {
      _id: `${Math.random() * 1000000000 * Math.random()}`,
      title: "Tesla Model 3 2021",
      priceRange: {
        max: 10000,
        min: 11000,
      },
      vehiclePictures: [
        {
          avatar: {
            url: "",
            key: "",
          },
          image: {
            url: "",
            key: "",
          },
        },
      ],
      VIN: "JF1GPAD60D1803590",
      driveTrain: "AWD",
      interior: "steering",
      exterior: "Cowl",
      transmission: "Manual",
      engine: "2.0L H4 SMPI",
      fuleType: "Gasoline",
      mileageCity: "25 miles/gallon",
      mileageHighway: "33 miles/gallon",
      description: `Be sure to bring the black plug adapter (this is the part that actually plugs into the car, watch pickup video we sent in messages) with you they are $100 to replace. 
            Please be sure to put the chargepoint card back into the car at the end of the trip `,
      year: "2022",
      modal: "Impreza Premium",
      make: "Subaru",
      trimLevel: "2.0i Premium PZEV",
      style: "Wagon 4D",
      maedeIn: "Japan",
      driveType: "AWD",
      enabledStatus: true,
    };
  }
  static getDummyVehicles(length = 10) {
    let v = [];
    for (let index = 0; index < length; index++) {
      v.push(VehiclesController.getDummyVehicle());
    }
    return v;
  }
  static applyFilters(year, make, typeOfVehicle, miles, startYear, endYear) {
    ReduxDispatchController.VEHICLES.setFilter({
      year,
      make,
      typeOfVehicle,
      miles,
      startYear,
      endYear,
    });
    ReduxDispatchController.VEHICLES.setVehiclesLoading(true);
    VehiclesController.getVehiclesByFilters(
      year,
      make,
      typeOfVehicle,
      miles,
      startYear,
      endYear
    )
      .then((vehicles) => {
        ReduxDispatchController.VEHICLES.setAll(vehicles);
        ReduxDispatchController.VEHICLES.setVehiclesLoading(false);
      })
      .catch((err) => {
        console.log(`CATCH Error in loading vehicles.`, err);
        ReduxDispatchController.VEHICLES.setVehiclesLoading(false);
      });
  }

  static getVehiclesByFilters(
    year,
    make,
    typeOfVehicle,
    miles,
    startYear,
    endYear
  ) {
    return new Promise((resolve, reject) => {
      let filters = {};
      if (year !== "All") filters = { ...filters, year };
      if (startYear) filters = { ...filters, startYear };
      if (endYear) filters = { ...filters, endYear };
      if (make !== "All") filters = { ...filters, make };
      if (typeOfVehicle !== "All")
        filters = { ...filters, type: typeOfVehicle };
      filters = { ...filters, distance: miles };
      const currentUserId = AuthController.getCurrentUser()._id;
      // console.log(`Fetching with miles: ${miles}`);
      // console.log(filters, "filtersObjForApi");

      axios
        .get(`/vehicle/buyer/get-vehicle-by-filters/${currentUserId}`, {
          params: filters,
        })
        .then((res) => {
          if (res.data.success) {
            resolve(res.data.data);
          } else {
            console.log(`Error in getting filters`, res.data);
            reject(res.data.error.message);
          }
        })
        .catch((err) => {
          console.log("NetworkError Loading Vehicle", err);
          reject(ERRORS.INTERNET_CONNECTIVITY);
        });
    });
  }

  static closeDealSuccessfully(offerId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/offer/offer/close/${offerId}`)
        .then((res) => {
          if (res.data.success) {
            /// res.data.data.vehicle
          } else {
            reject(res.data.error.message);
          }
        })
        .catch((err) => {
          reject(ERRORS.INTERNET_CONNECTIVITY);
        });
    });
  }
  static handleUpdateHorizontalFilters = (year) => {
    // console.log(year, "horizontal Filter");
    ReduxDispatchController.FILTERS_META.setLoading(true);
    VehiclesController.updateHorizontalVehicleFiltersApi(year)
      .then((filters) => {
        // console.log(filters);
        ReduxDispatchController.FILTERS_META.set(filters);
        ReduxDispatchController.FILTERS_META.setLoading(false);
      })
      .catch((err) => {
        ReduxDispatchController.FILTERS_META.setLoading(false);
      });
  };

  //handle horizontal filter
  static handleLoadVehicleFilters() {
    ReduxDispatchController.FILTERS_META.setLoading(true);
    VehiclesController.getVehicleFilters()
      .then((filters) => {
        ReduxDispatchController.FILTERS_META.set(filters);
        ReduxDispatchController.FILTERS_META.setLoading(false);
      })
      .catch((err) => {
        ReduxDispatchController.FILTERS_META.setLoading(false);
      });
  }
  // horizontal filter
  static updateHorizontalVehicleFiltersApi(params) {
    return new Promise((resolve, reject) => {
      let preparedFilters = {
        years: [{ label: "All", value: "All" }],
        makes: [{ label: "All", value: "All", active: true }],
        vehicleTypes: [{ label: "All", value: "All" }],
      };
      // console.log(params, "???OOOO");
      axios
        .get(`/vehicle/buyer/get-distinct-year-make-drivetype`, { params })
        .then((res) => {
          if (res.data.success) {
            // console.log(res.data.data, "updatedrivetype");
            let filters = res.data.data;
            preparedFilters.years = [
              ...preparedFilters.years,
              ...filters.year.map((v) => ({ label: `${v}`, value: `${v}` })),
            ];
            // preparedFilters.makes = [...preparedFilters.makes, ...filters.make.map(v => ({label: v, value: v}))]
            preparedFilters.makes = [
              ...preparedFilters.makes,
              ...filters.makeWithCounts.map((v) => ({
                label: v.make,
                value: v.make,
                count: v.count,
              })),
            ];
            preparedFilters.vehicleTypes = [
              ...preparedFilters.vehicleTypes,
              ...filters.vehicleType.map((v) => ({ label: v, value: v })),
            ];
            resolve(preparedFilters);
          } else {
            resolve(preparedFilters);
          }
        })
        .catch((err) => {
          console.log(`CATCH Error in loading filters meta`, err);
          reject(ERRORS.INTERNET_CONNECTIVITY);
        });
    });
  }
  // horizontal filter
  static getVehicleFilters() {
    return new Promise((resolve, reject) => {
      let preparedFilters = {
        years: [{ label: "All", value: "All" }],
        makes: [{ label: "All", value: "All", active: true }],
        vehicleTypes: [{ label: "All", value: "All" }],
      };
      axios
        .get(`/vehicle/buyer/get-distinct-year-make-drivetype`)
        .then((res) => {
          if (res.data.success) {
            // console.log(res.data.data, "drivetype");
            let filters = res.data.data;
            preparedFilters.years = [
              ...preparedFilters.years,
              ...filters.year.map((v) => ({ label: `${v}`, value: `${v}` })),
            ];
            // preparedFilters.makes = [...preparedFilters.makes, ...filters.make.map(v => ({label: v, value: v}))]
            preparedFilters.makes = [
              ...preparedFilters.makes,
              ...filters.makeWithCounts.map((v) => ({
                label: v.make,
                value: v.make,
                count: v.count,
              })),
            ];
            preparedFilters.vehicleTypes = [
              ...preparedFilters.vehicleTypes,
              ...filters.vehicleType.map((v) => ({ label: v, value: v })),
            ];
            resolve(preparedFilters);
          } else {
            resolve(preparedFilters);
          }
        })
        .catch((err) => {
          console.log(`CATCH Error in loading filters meta`, err);
          reject(ERRORS.INTERNET_CONNECTIVITY);
        });
    });
  }

  static getCarFaxReport(vin) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/vehicle/buyer/carfax-report/${vin}`)
        .then((res) => {
          if (res.data.success) {
            resolve(res.data.report.icr.carfaxLink);
          } else {
            resolve(undefined);
          }
        })
        .catch((err) => {
          reject(ERRORS.INTERNET_CONNECTIVITY);
        });
    });
  }
}

export default VehiclesController;

export const useVehicleFilters = () => {
  const { filters, loading, loadedOnce } = useSelector((state) => ({
    filters: state.VehiclesReducer.allFilters,
    loading: state.VehiclesReducer.loadingVehicleFilters,
    loadedOnce: state.VehiclesReducer.loadedOnceVehicleFilters,
  }));
  const { years, makes, vehicleTypes } = filters;
  // console.log(filters, "?????PPPPIII");
  return {
    yearsMeta: years,
    makesMeta: makes,
    vehicleTypesMeta: vehicleTypes,
    loadingFilters: loading,
    filtersLoadedOnce: loadedOnce,
  };
};

export const getFirstImage = (images) => {
  if (images?.length > 0) {
    return images[0];
  } else {
    return {
      image: {
        url: undefined,
        key: undefined,
      },
      avatar: {
        url: undefined,
        key: undefined,
      },
    };
  }
};
