import { View } from "react-native";
import React from "react";
import OfferInfoSheet from "../ModuleComponents/BottomSheets/offerInfoSheet";
import ActionSheet from "react-native-actions-sheet";
import { Colors } from "../../themes";

function SubmitOfferStepsSheet({
  vehicle,
  offer,
  isCreated,
  onClose = () => false,
}) {
  return (
    <ActionSheet
      id="Car-Detail-Sheet-v2"
      initialOffsetFromBottom={1}
      statusBarTranslucent={true}
      delayActionSheetDrawTime={0}
      openAnimationSpeed={1}
      bounceOnOpen={true}
      containerStyle={{
        backgroundColor: Colors.blueSecondary,
        borderTopLeftRadius: 25,
        borderTopRightRadius: 25,
      }}
      bounciness={2}
      indicatorStyle={{ width: 70, height: 4 }}
      // onClose={onClose}
      overlayColor={"grey"}
      defaultOverlayOpacity={0.4}
    >
      <OfferInfoSheet
        vehicle={vehicle}
        offer={offer}
        onClose={onClose}
        isCreateMode={isCreated}
      />
    </ActionSheet>
  );
}

export default SubmitOfferStepsSheet;
