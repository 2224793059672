import React from "react";
import StripeNativeProvider from "./stripePaymentProvider";
const Index = ({ onClose, onSuccess, handleDismissPaymentSheet, intent }) => {
  // console.log(`I4`);

  return (
    <StripeNativeProvider
      handleDismissPaymentSheet={handleDismissPaymentSheet}
      onClose={onClose}
      onSuccess={onSuccess}
      intent={intent}
    />
  );
};

export default Index;
