import React, { useEffect, useState } from "react";
import { StyleSheet, Text, View, Image, Linking } from "react-native";
import { moderateScale } from "react-native-size-matters";
import { Colors, Fonts } from "../../themes";
import { SvgUri } from "react-native-svg";
import CarFaxSVG from "../../Assets/Icons/carfaxSVG";
import { navigate } from "../../Navigation/mainNavigation";
import VehiclesController from "../../Controllers/vehiclesController";
const CarFaxReport = ({ label, details, type, vin }) => {
  const defLabel = label ? label : "textHere";
  const defdetails = details ? details : "textHere";
  const [processing, setProcessing] = useState(false);
  const [report, setReport] = useState(undefined);

  const handleOpenLink = () => {
    navigate("CustomWebViewScreen", { url: report.url });
  };

  const handleLoadReport = () => {
    setProcessing(true);
    VehiclesController.getCarFaxReport(vin)
      .then((report) => {
        if (report) {
          setReport(report);
        }
        setProcessing(false);
      })
      .catch((err) => {
        console.log(err);
        setProcessing(false);
      });
  };

  useEffect(() => {
    handleLoadReport();
  }, [vin]);

  if (report) {
    return (
      <React.Fragment>
        <View style={styles.minContainer}>
          <View>
            <View
              style={{
                width: 100,
                height: 70,
                borderRadius: 10,
                backgroundColor: "#f0f0f022",
                overflow: "hidden",
              }}
            >
              {/* <SvgUri
                onError={(error) => console.log("CARFAX:Svg Load Error",error) }
                  width="100%"
                  height="100%"
                  uri="https://partnerstatic.carfax.com/img/valuebadge/showme.svg"
                /> */}
              <CarFaxSVG width={"100%"} height={"100%"} />
            </View>
            {/* <Text style={styles.textOne}>{defLabel}</Text> */}
          </View>
          <View>
            <Text
              onPress={handleOpenLink}
              style={[
                styles.textOne,
                {
                  textDecorationLine: "underline",
                  textDecorationColor: "white",
                },
              ]}
            >
              View report
            </Text>
          </View>
        </View>
      </React.Fragment>
    );
  } else {
    if (processing) {
      return (
        <React.Fragment>
          <View style={styles.minContainer}>
            <View>
              <View
                style={{
                  width: 100,
                  height: 70,
                  borderRadius: 10,
                  backgroundColor: "#f0f0f055",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Text style={{ fontSize: 8, color: "#f0f0f0" }}>
                  Checking report...
                </Text>
              </View>
            </View>
            <View>
              <View
                style={{
                  width: 120,
                  height: 30,
                  backgroundColor: "#f0f0f055",
                  borderRadius: 10,
                }}
              />
            </View>
          </View>
        </React.Fragment>
      );
    } else {
      return false;
    }
  }
};

export default CarFaxReport;

const styles = StyleSheet.create({
  minContainer: {
    width: "100%",
    paddingVertical: 20,
    borderBottomColor: "#5A616C",
    borderBottomWidth: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textOne: {
    fontSize: moderateScale(17, 0.1),
    color: Colors.lightBluePrimary,
    fontFamily: Fonts.default,
  },
});
