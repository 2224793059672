import { VEHICLES } from "../types";
import produce from "immer";

const state = {
  vehicles: [],
  searchFilters: {
    year: null,
    startYear: null,
    endYear: null,
    make: null,
    typeOfVehicle: null,
    miles: null,
  },
  loadingVehicles: false,
  allFilters: {
    years: [
      //   { label: "All", value: "All" },
      //   { label: "2000", value: "2000" },
      //   { label: "2021", value: "2021" },
      //   { label: "2022", value: "2022" },
    ],
    // years: [1928,2013],
    makes: [
      //   { label: "All", value: "All" },
      //   { label: "Toyota", value: "Toyota" },
      //   { label: "Honda", value: "Honda" },
      //   { label: "Suzuki", value: "Suzuki" },
    ],
    vehicleTypes: [
      //   { label: "All", value: "All" },
      //   { label: "Sedan", value: "Sedan" },
      //   { label: "SUV", value: "SUV" },
      //   { label: "Lorry", value: "Lorry" },
    ],
  },
  loadingVehicleFilters: true,
  loadedOnceVehicleFilters: false,
  isLocationPermissionGranted: false,
};

const VehiclesReducer = (mState = { ...state }, action) => {
  switch (action.type) {
    case VEHICLES.SET_KEY_VALUE:
      return produce(mState, (draftState) => {
        draftState[action.payload.key] = action.payload.value;
      });
    case VEHICLES.SET_FILTER:
      return produce(mState, (draftState) => {
        draftState.searchFilters = action.payload;
      });
    case VEHICLES.SET_ALL:
      return produce(mState, (draftState) => {
        draftState.vehicles = action.payload;
      });
    default:
      return { ...mState };
  }
};
export default VehiclesReducer;
