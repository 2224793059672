import React, { useState } from "react";
import {
  StyleSheet,
  TextInput,
  TouchableOpacity,
  View,
  KeyboardAvoidingView,
  Platform,
  Keyboard,
} from "react-native";
import { moderateScale } from "react-native-size-matters";
import SendBtnSvg from "../../Assets/Icons/chatFooterSvgs/sendBtnSvg";
import MessageController from "../../Controllers/messageController";
import { Colors, Fonts, PLATFORM_DETECT } from "../../themes";
import { useSelector } from "react-redux";
const ChatFooter = ({ conversation }) => {
  const [messageBody, setMessageBody] = useState("");
  const { deviceType } = useSelector((state) => state.DeviceTypeReducer);

  const handleSendMessage = () => {
    if (messageBody) {
      new MessageController(
        messageBody,
        conversation?._id,
        conversation?.business?._id
      ).send();
      setMessageBody("");
    }
  };

  return (
    <KeyboardAvoidingView
      enabled={true}
      keyboardVerticalOffset={
        Platform.OS === "android" ? 0 : PLATFORM_DETECT.WEB ? 0 : 50
      }
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      style={[
        styles.viewLast,
        {
          paddingHorizontal: deviceType ? 50 : 10,
          marginBottom: PLATFORM_DETECT.WEB ? 0 : 0,
        },
      ]}
    >
      <View style={styles.viewLastB}>
        <TextInput
          returnKeyLabel="Enter"
          // returnKeyType="send"

          style={
            Platform.OS === "web"
              ? {
                  outlineWidth: 0,
                  ...styles.txtInputView,
                }
              : styles.txtInputView
          }
          placeholder="Type message ..."
          placeholderTextColor={"#50555C"}
          value={messageBody}
          onSubmitEditing={deviceType ? handleSendMessage : () => false}
          onChangeText={setMessageBody}
        />
      </View>
      <View style={styles.viewLastC}>
        <TouchableOpacity
          style={{
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
          onPress={handleSendMessage}
        >
          <SendBtnSvg />
        </TouchableOpacity>
      </View>
    </KeyboardAvoidingView>
  );
};

export default ChatFooter;

const styles = StyleSheet.create({
  viewLast: {
    width: "100%",
    paddingVertical: 10,
    flexDirection: "row",
    backgroundColor: Colors.screenBgColor,
  },
  viewLastA: {
    width: "15%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: 10,
    backgroundColor: Colors.screenBgColor,
  },
  viewLastB: {
    // width: "100%",
    flex: 1,
    paddingLeft: 0,
    paddingBottom: 5,
    height: 55,
    justifyContent: "center",
    // backgroundColor: Colors.screenBgColor,
    // backgroundColor: "red",
  },
  viewLastC: {
    // width: "15%",
    height: 55,
    paddingBottom: 4,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 10,
    backgroundColor: Colors.screenBgColor,
    // backgroundColor: "pink",
  },
  txtInputView: {
    width: "99%",
    height: "100%",
    backgroundColor: "white",
    // backgroundColor: 'pink',
    borderRadius: 50,
    paddingLeft: 15,
    fontFamily: Fonts.default,
    fontSize: moderateScale(14, 0.1),
    color: "#50555C",
  },
});
