import "react-native-gesture-handler";
import "react-native-reanimated";
import React from "react";
import axios from "axios";
import { Provider } from "react-redux";
import store from "./src/Store";
import Home from "./home";
import { useFonts } from "expo-font";
import { BASE_URL } from "@env";

axios.defaults.baseURL = BASE_URL;
if (BASE_URL == "https://api-inforide.herokuapp.com") {
  console.log = () => {};
}
console.log(BASE_URL, "@BASE_URL");

const App = () => {
  const [fontsLoaded, error] = useFonts({
    "Roboto-Bold": require("./assets/Fonts/Roboto-Bold.ttf"),
    "Roboto-Medium": require("./assets/Fonts/Roboto-Medium.ttf"),
    "Roboto-Regular": require("./assets/Fonts/Roboto-Regular.ttf"),
    "Roboto-BoldItalic": require("./assets/Fonts/Roboto-BoldItalic.ttf"),
    "Roboto-Thin": require("./assets/Fonts/Roboto-Thin.ttf"),
  });

  if (!fontsLoaded) {
    return false;
  }
  return (
    <React.StrictMode>
      <Provider store={store}>
        <Home />
      </Provider>
    </React.StrictMode>
  );
};

export default App;
