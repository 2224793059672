import { AUCTION } from "../types";
import produce from "immer";
const state = {
  auctionList: [],
  myTotalBids: [],
  searchLoading: false,
};

const AuctionReducer = (mState = { ...state }, action) => {
  switch (action.type) {
    case AUCTION.SET_ALL_AUCTION:
      return produce(mState, (draftState) => {
        draftState.auctionList = action.payload;
      });
    case AUCTION.SET_SEARCH_LOADING:
      return produce(mState, (draftState) => {
        draftState.searchLoading = action.payload;
      });

      case AUCTION.GET_MY_ALL_BIDS:
        return produce(mState, (draftState) => {
          draftState.myTotalBids = action.payload;
        });



    default:
      return { ...mState };
  }
};
export default AuctionReducer;
