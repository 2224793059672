import { StyleSheet, Text, View,Platform,Dimensions } from 'react-native'
import React from 'react'
// import AbstractButton from '../AbstractComponents/abstractButton'
// import AddIcon from '../../Assets/Icons/addIcon'
import { moderateScale } from 'react-native-size-matters'
import { Colors, Fonts } from '../../themes'
// import WalletNewCardSvg from '../../Assets/Icons/walletNewCardSvg'
// import { formatCurrency } from 'react-native-format-currency';


const AvailableOffersCard = ({onPress,currentBalance}) => {


    return (
        <>
            <View style={{ ...StyleSheet.absoluteFillObject }}>
             <View style={{ width: '100%',height: moderateScale(230, 0.1),borderRadius:20}}>   
            {/* <WalletNewCardSvg  /> */}
            </View>
            </View>

            <View style={{ ...StyleSheet.absoluteFillObject }}>
                <View style={styles.cardView} >
                    <View style={styles.innerCardView}>
                        <View >
                            <Text style={styles.textTwo}>Available Offers</Text>
                        </View>
                        <View style={{ marginTop: 20 }} >
                        {/* <Text style={styles.textThree}>{`${ formatCurrency({ amount: currentBalance, code: "USD"})[1] }`}</Text> */}
                        <Text style={styles.textThree}>{currentBalance}</Text>
                        </View>

                        <View style={{ marginTop: 10 }}>
                            <Text style={styles.textFour}>5 offers/$4.99*</Text>
                        </View>

                        <View style={{ marginTop: 20 }}>
                            {/* <AbstractButton
                                label={availableOfferButtonText.label}
                                iconLeft={() => availableOfferButtonText.active? false :<AddIcon />}
                                height={moderateScale(38, 0.1)}
                                width={moderateScale(174, 0.1)}
                                txtSize={moderateScale(21, 0.1)}
                                onPress={onPress}
                                // bgcolor={availableOfferButtonText.active?Colors.redPrimary:Colors.blueprimary}
                            /> */}
                        </View>
                    </View>

                </View>
            </View>
        </>
    )
}

export default AvailableOffersCard

const styles = StyleSheet.create({
    cardView: {
        width: '100%',
        height: moderateScale(230, 0.1),
        borderRadius: 20,
        justifyContent: 'center',
        alignItems: "center"
    },
    textTwo: {
        fontSize: moderateScale(21, 0.1),
        fontFamily: Fonts.bold,
        color: Colors.whitePrimary
    },
    textThree: {
        fontSize: moderateScale(45, 0.1),
        fontFamily: Fonts.bold,
        color: Colors.whitePrimary
    },
    textFour: {
        fontSize: moderateScale(21, 0.1),
        fontFamily: Fonts.bold,
        color: Colors.whitePrimary,
        fontStyle: 'italic'
    },
    innerCardView: {
        width: '90%',
        height: '85%',
        alignItems: 'center',
        justifyContent:'center'
    }
})