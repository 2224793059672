import React from "react";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import HomeScreen from "../Screens/AppScreens/homeScreen";
import AuctionScreen from "../Screens/AppScreens/auctionScreen";
import SavedScreen from "../Screens/AppScreens/savedScreen";
import MessagesScreen from "../Screens/AppScreens/messagesScreen";
import SettingsScreen from "../Screens/AppScreens/settingsScreen";
import CustomBottomTabBar from "./customBottomTab";

const Tab = createBottomTabNavigator();

const BottomTabNavigation = () => {
  return (
    <Tab.Navigator
      tabBar={(props) => <CustomBottomTabBar {...props} />}
      screenOptions={{
        headerShown: false,
      }}
      initialRouteName={"Auction"}
    >
      <Tab.Screen name="Auction" component={AuctionScreen} />
      <Tab.Screen name="Offers" component={SavedScreen} />
      <Tab.Screen name="Messages" component={MessagesScreen} />
      <Tab.Screen name="Settings" component={SettingsScreen} />
    </Tab.Navigator>
  );
};

export default BottomTabNavigation;
