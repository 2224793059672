import React, { Suspense, useEffect, useState, lazy } from "react";
import {
  StyleSheet,
  Text,
  View,
  Dimensions,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import { moderateScale } from "react-native-size-matters";
import CrossSvg from "../../../Assets/Icons/crossSvg";
import AbstractContentContainer from "../../AbstractComponents/abstractContentContainer";
import { Colors, Fonts } from "../../../themes";
import AbstractButton from "../../AbstractComponents/abstractButton";
import ImagePlacer from "../imagePlacer";
import { getFirstImage } from "../../../Controllers/vehiclesController";
import { useSelector } from "react-redux";

const PriceRangePicker = lazy(() =>
  import("../PriceRangePicker/bidPriceRange")
);

const SW = Dimensions.get("window").width;

const BidInfoSheet = ({
  vehicle,
  initialPriceRange,
  close,
  onSubmit,
  processing,
  errorMessage,
}) => {
  const [offerAmountData, setAmountData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { deviceType } = useSelector((state) => state.DeviceTypeReducer);

  const handleCreateOffer = () => {
    if (offerAmountData) {
      onSubmit(offerAmountData, vehicle.auctionVehicle, vehicle._id);
    } else {
      // console.log(`No offer amount data`, offerAmountData);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  if (vehicle) {
    const minPriceRange = initialPriceRange;
    const maxPriceRange = initialPriceRange + 10000;
    return (
      <View style={styles.mainContainer}>
        <AbstractContentContainer>
          <View style={{ width: "100%", alignItems: "center" }}>
            <View
              style={{
                alignItems: "flex-end",
                position: "absolute",
                right: 10,
                top: 10,
              }}
            >
              <TouchableOpacity onPress={close} style={{ padding: 0 }}>
                <CrossSvg color={"white"} />
              </TouchableOpacity>
            </View>
            <View>
              <Text style={styles.textOne}>Place a Bid</Text>
            </View>

            <View style={{ marginBottom: 20 }}>
              <Text style={[styles.textOne, { fontWeight: "800" }]}>
                {vehicle.title}
              </Text>
            </View>

            <View>
              <View
                style={{
                  width: 200,
                  height: 150,
                  borderRadius: 30,
                  overflow: "hidden",
                }}
              >
                <ImagePlacer
                  imageUrl={getFirstImage(vehicle.pictures).image.url}
                  avatarUrl={getFirstImage(vehicle.pictures).avatar.url}
                />
              </View>
            </View>

            {/* <View
              style={{
                flexDirection: "row",
                marginTop: 20,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <View>
                <Text style={[styles.textThree, { paddingLeft: 10 }]}>
                  Suggested
                </Text>
              </View>
            </View> */}

            {/* <View
              style={{
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              {vehicle?.priceRange?.min != undefined ? (
                <Text
                  style={[styles.textOne, { color: Colors.blueSecondaryTwo }]}
                >{`$${vehicle?.priceRange?.min}`}</Text>
              ) : (
                false
              )}
            </View> */}

            <View>
              <View style={{ height: 10 }} />
              <Text style={styles.textOne}>My Bid</Text>
              <View style={{ height: 10 }} />
            </View>
            <View style={{ marginVertical: 20 }}>
              <Suspense
                fallback={<ActivityIndicator size={"large"} color="white" />}
              >
                <PriceRangePicker
                  onChange={setAmountData}
                  minRange={minPriceRange}
                  maxRange={maxPriceRange}
                />
              </Suspense>
            </View>

            <View style={{ height: 15 }} />

            <View
              style={{
                marginBottom: 10,
                width: "100%",
              }}
            >
              <View
                style={{
                  width: "100%",
                  alignItems: "center",
                }}
              >
                {/* {!loading ? ( */}
                <AbstractButton
                  processing={processing}
                  onPress={handleCreateOffer}
                  width={deviceType ? 140 : SW * 0.52}
                  height={deviceType ? 60 : SW * 0.52 * 0.23}
                  label={"Submit"}
                />
                {/* ) : (
                  false
                )} */}

                <View style={{ marginTop: 10, height: 18 }}>
                  <Text numberOfLines={1} style={{ color: "red" }}>
                    {errorMessage}
                  </Text>
                </View>
              </View>
            </View>

            <View style={{ height: 50 }} />
          </View>
        </AbstractContentContainer>
      </View>
    );
  } else {
    return false;
  }
};

export default BidInfoSheet;

const styles = StyleSheet.create({
  mainContainer: {
    backgroundColor: Colors.blueSecondary,
    paddingTop: 10,
  },

  textOne: {
    color: "white",
    fontSize: moderateScale(18, 0.1),
    fontFamily: Fonts.default,
    marginTop: 10,
  },
  textTwo: {
    color: "white",
    fontSize: moderateScale(17, 0.1),
    fontFamily: Fonts.default,
    marginTop: 10,
    textAlign: "center",
  },
  textThree: {
    color: "white",
    fontSize: moderateScale(17, 0.1),
    fontFamily: Fonts.default,
    textAlign: "center",
  },
});
