class GlobalConstants {
  constructor() {}

  static OFFER_STATUS = {
    PENDING: "pending",
    DRAFT: "draft",
    REJECTED: "rejected",
    CLOSED: "closed", /// added later
    CONVERSATION: "conversation",
    EXPIRED: "expired",
    PAYMENT_REFUNDED: "payment_refunded",
    CONFLICT: "conflict", /// added later
    ACTIVE: "active",
    ACCEPTED: "accepted",
    COUNTER: "counter",
    PAYMENT_PROCESSING: "Payment_Processing",
    PAYMENT_REFUNDABLE: "payment_refundable",
  };

  static COMISSION_AMOUNT = {
    BUYER: 5,
    BUSINESS: 10,
  };

  static TRANSACTION_STATUS = {
    ACTIVE: "active",
    RESOLVED: "resolved",
    DISPUTED: "disputed",
    CANCELLED: "cancelled",
    PARTIALLY_CANCELLED: "partially_cancelled",
  };

  static BUSINESS_APPROVAL_STATUS = {
    PENDING: "pending",
    APPROVED: "approved",
    BLOCKED: "blocked",
    REJECTED: "rejected",
  };

  static VEHICLE_SELLING_STATUS = {
    DISPLAY: "display",
    NEGOTIATION: "negotiation",
    SOLD: "sold",
  };
}
export default GlobalConstants;
