import React from "react";
import {
  StyleSheet,
  View,
  TouchableWithoutFeedback,
  Dimensions,
  Modal,
} from "react-native";
// import Modal from 'react-native-modal';

const SW = Dimensions.get("window").width;
const SH = Dimensions.get("window").height;

const AbstractModal = (props) => {
  const defaultStyle = props.customStyleContainer
    ? props.customStyleContainer
    : styles.modalView;

  return (
    <Modal
      backdropColor="rgba(73, 73, 73, 0.7)"
      backdropOpacity={0.8}
      animationIn="zoomInDown"
      animationOut="zoomOutUp"
      animationInTiming={600}
      animationOutTiming={600}
      backdropTransitionInTiming={600}
      backdropTransitionOutTiming={600}
      transparent={true}
      isVisible={props.isVisible}
    >
      <TouchableWithoutFeedback
        onPress={() => {
          props.setModalVisiblity();
        }}
      >
        <View style={styles.centeredView}>
          <View style={defaultStyle}>{props.children}</View>
        </View>
      </TouchableWithoutFeedback>
    </Modal>
  );
};

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  modalView: {
    margin: 20,
    height: SW * (344 / 366),
    width: SW * 0.88,
    backgroundColor: "white",
    borderRadius: 15,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    justifyContent: "center",
  },
});

export default AbstractModal;
