import React from "react";
import Svg, { Circle, Path } from "react-native-svg";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
const SpeedSvg = () => {
  return (
    <Svg
      width="20"
      height="15"
      viewBox="0 0 24 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path
        d="M13.534 11.4287L11.5965 10.3037L16.4091 4.42859L13.534 11.4287Z"
        fill="white"
        stroke="white"
        strokeWidth="0.125002"
      />
      <Path
        d="M2.34634 12.3662H0.596313V12.1162V11.5698C0.874704 9.49926 1.48974 7.7242 2.34634 6.24157M21.5342 12.3662H23.4717V11.5698C23.2583 9.50649 22.6829 7.72918 21.8471 6.24113M21.8471 6.24113L20.2842 7.24114M21.8471 6.24113C20.8136 4.40084 19.3818 3.00294 17.7438 2.05355M17.7438 2.05355L16.7841 3.74108M17.7438 2.05355C15.9973 1.0412 14.0164 0.538827 12.034 0.553856M12.034 0.553856V2.49106M12.034 0.553856C9.99238 0.569334 7.94921 1.13361 6.15891 2.25481M6.15891 2.25481L7.15893 3.74108M6.15891 2.25481C4.66154 3.19256 3.34106 4.5199 2.34634 6.24157M2.34634 6.24157L3.78387 7.24114"
        stroke="white"
        strokeWidth="1.00002"
      />
      <Circle cx="11.909" cy="12.1162" r="2.06254" fill="white" />
    </Svg>
  );
};

export default SpeedSvg;
