import React from "react";
import { StyleSheet, Text, View, Platform } from "react-native";
// import CheckBox from '@react-native-community/checkbox';
import Checkbox from "expo-checkbox";

import { moderateScale } from "react-native-size-matters";
import { Colors, Fonts } from "../../themes";
import { navigate } from "../../Navigation/mainNavigation";

const TermsAndConditionsCheckBox = ({ checked, setChecked, error }) => {
  const bu = "https://www.inforide.app";
  const tou = `https://www.inforide.app/terms-of-use`;
  const pp = "https://www.inforide.app/privacy-policy";
  const eul = "https://www.inforide.app/user-license";
  return (
    <View style={styles.mainContainer}>
      <View style={styles.viewOne}>
        <View style={{ width: 2 }} />
        <Checkbox
          tintColors={{ true: Colors.blueprimary, false: "lightgrey" }}
          onCheckColor={Colors.darkBluePrimary}
          onTintColor={Colors.darkBluePrimary}
          shouldRasterizeIOS
          style={{ transform: [{ scale: 0.7 }], marginBottom: -5 }}
          disabled={false}
          boxType="square"
          value={checked}
          onValueChange={setChecked}
        />
        <View style={{ width: "100%" }}>
          <Text onPress={() => setChecked(!checked)}>
            <Text>
              By clicking “I Agree” I understand and agree to the InfoRide
            </Text>
            {/* <Text onPress={() => navigate("EUAScreen")} style={styles.textTwo}>
              {` End User License Agreement`}
            </Text>
            <Text>{`, `}</Text> */}
            <Text
              onPress={() => navigate("TermsOfUseScreen")}
              style={styles.textTwo}
            >
              Terms of Use
            </Text>
            <Text>{` and `}</Text>
            <Text
              onPress={() => navigate("PrivacyPolicyScreen")}
              style={styles.textTwo}
            >
              Privacy Policy.”
            </Text>
          </Text>
          {error}
        </View>
      </View>
    </View>
  );
};

export default TermsAndConditionsCheckBox;

const styles = StyleSheet.create({
  mainContainer: {
    width: "100%",
    flexDirection: "row",
  },
  viewOne: {
    width: "90%",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  viewTwo: {
    width: "90%",
    alignItems: "flex-end",
    justifyContent: "center",
  },
  textOne: {
    fontFamily: Fonts.default,
    fontSize: moderateScale(13, 0.1),
    color: Colors.greySecondary,
  },
  textTwo: {
    fontFamily: Fonts.default,
    fontSize: moderateScale(13, 0.1),
    color: Colors.blueprimary,
  },
});
