import React from "react";
import { Svg, Path } from "react-native-svg";

const CheckMarkSvg = ({ checked, color }) => {
  const mColor = color ? color : "black";
  // const defBg = checked ? '#2674E5' : 'black'
  // const defBorder = checked ? '#2674E5' : 'black'
  const defTick = checked ? "white" : "black";

  const defBg = mColor;
  const defBorder = mColor;

  return (
    <Svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path
        d="M19.6245 9.15076H18.1942C17.8831 9.15076 17.5872 9.3002 17.4042 9.55639L12.6098 16.2051L10.4383 13.1918C10.2553 12.9387 9.96254 12.7862 9.6484 12.7862H8.21801C8.01977 12.7862 7.90387 13.0119 8.01977 13.1735L11.8199 18.4437C11.9097 18.569 12.028 18.6711 12.1651 18.7416C12.3022 18.812 12.4542 18.8487 12.6083 18.8487C12.7624 18.8487 12.9144 18.812 13.0515 18.7416C13.1886 18.6711 13.3069 18.569 13.3967 18.4437L19.8197 9.53809C19.9387 9.37645 19.8228 9.15076 19.6245 9.15076Z"
        fill={`${defBg}`}
      />
      <Path
        d="M13.9213 0.336548C6.37587 0.336548 0.257812 6.4546 0.257812 14C0.257812 21.5454 6.37587 27.6635 13.9213 27.6635C21.4667 27.6635 27.5847 21.5454 27.5847 14C27.5847 6.4546 21.4667 0.336548 13.9213 0.336548ZM13.9213 25.3455C7.65682 25.3455 2.57572 20.2644 2.57572 14C2.57572 7.73555 7.65682 2.65445 13.9213 2.65445C20.1857 2.65445 25.2668 7.73555 25.2668 14C25.2668 20.2644 20.1857 25.3455 13.9213 25.3455Z"
        fill={`${defBorder}`}
      />
    </Svg>
  );
};

export default CheckMarkSvg;
