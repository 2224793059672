import React from 'react';
import { StyleSheet, View, Image, Dimensions } from 'react-native';
import { moderateScale } from 'react-native-size-matters';
import { Colors } from '../../themes';
import AbstractAvatar from '../AbstractComponents/abstractAvatar';
import AbstractButton from '../AbstractComponents/abstractButton';

const SW = Dimensions.get('window').width;


const ImageUploader = ({ avatarUrl, imageUrl,imagePickedFromGallery }) => {

  return (
    <View style={styles.mainContainer}>

      <View style={styles.outerContainer}>
        <AbstractAvatar size={"100%"} url={imageUrl} placeholder={avatarUrl}/>
      </View>

      <View style={{ marginTop: 20 }}>
        <AbstractButton
          txtColor={Colors.blueprimary}
          txtSize={moderateScale(16, 0.1)}
          label={'Upload'}
          height={SW * 0.21 * 0.35}
          width={SW * 0.21}
          type={'transparentBlue'}
          onPress={imagePickedFromGallery}
        />
      </View>
    </View>
  );
};

export default ImageUploader;

const styles = StyleSheet.create({
  mainContainer: {
    alignItems: 'center',
  },
  outerContainer: {
    width: SW * 0.24,
    height: SW * 0.24,
    borderRadius: 60,
  },
  viewOne: {
    width: SW * 0.24,
    height: SW * 0.24,
    borderRadius: 60,
    // backgroundColor: 'yellow',
  },
});
