import React from "react";
import Svg, { Path } from "react-native-svg";

const ClearTextSvg = () => {
  return (
    <Svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 0C4.5 0 0 4.5 0 10C0 15.5 4.5 20 10 20C15.5 20 20 15.5 20 10C20 4.5 15.5 0 10 0ZM14.2 12.8C14.6 13.2 14.5 13.9 14.1 14.2C13.7 14.5 13.2 14.5 12.8 14.2L10 11.4L7.2 14.2C6.8 14.6 6.2 14.7 5.8 14.3C5.4 13.9 5.3 13.3 5.7 12.9L5.8 12.8L8.6 10L5.8 7.2C5.4 6.8 5.4 6.1 5.9 5.8C6.3 5.5 6.8 5.5 7.2 5.8L10 8.6L12.8 5.8C13.2 5.4 13.9 5.5 14.2 5.9C14.5 6.3 14.5 6.8 14.2 7.2L11.4 10L14.2 12.8Z"
        fill="#8F9BB3"
      />
    </Svg>
  );
};

export default ClearTextSvg;
