import {
  Keyboard,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  TouchableWithoutFeedback,
  View,
} from "react-native";
import React, { useState } from "react";
import CheckMarkSvg from "../../../Assets/Icons/checkMarkSvg";
import { moderateScale } from "react-native-size-matters";
import { Fonts } from "../../../themes";
import CrossSvg from "../../../Assets/Icons/crossSvg";
import AbstractButton from "../../AbstractComponents/abstractButton";
import { dummyConflicts } from "../../../Utils/Moc";

const options = [
  {
    _id: 0,
    label: "The  user is not responding",
    active: true,
  },
  {
    _id: 1,
    label: "The user is not interested in purchasing at agreed price",
    active: false,
  },
  {
    _id: 2,
    label: "Other",
    active: false,
  },
];

const Conflicts = ({ item, detail, onPress, checked }) => {
  let color = "black";
  if (item.active) {
    color = "#2674E5";
  }
  console.log(color);
  return (
    <React.Fragment>
      <TouchableOpacity onPress={onPress} style={styles.conflictView}>
        <View>
          <CheckMarkSvg color={item.active ? "#2674E5" : "black"} />
        </View>
        <View style={{ paddingLeft: 10, paddingRight: 8 }}>
          <Text style={styles.textOne}>{item.label}</Text>
        </View>
      </TouchableOpacity>
    </React.Fragment>
  );
};

const ReportSheet = ({ onSubmit, processing, errorMessage }) => {
  const [selectConflict, setSelectConflict] = useState(options);
  const [reasonText, setReasonText] = useState("");
  const handleSelect = (itemId) => {
    const opts = [
      ...selectConflict.map((item) => ({
        ...item,
        active: item._id === itemId,
      })),
    ];
    setSelectConflict(opts);
    // console.log(opts);
  };

  const handleSubmit = () => {
    const activeIndex = selectConflict.findIndex((i) => i.active);
    if (activeIndex >= 0) {
      if (activeIndex === selectConflict.length - 1) {
        onSubmit(true, reasonText);
      } else {
        onSubmit(true, selectConflict[activeIndex].label);
      }
    }
  };

  return (
    <TouchableWithoutFeedback onPress={() => Keyboard.dismiss()}>
      <View>
        <View
          style={{
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: 10,
          }}
        >
          <Text style={styles.textTwo}>
            Report a{" "}
            <Text style={[styles.textTwo, { color: "#2674E5" }]}>conflict</Text>{" "}
          </Text>
        </View>
        <View style={{ marginTop: 20 }}>
          {selectConflict.map((item) => (
            <Conflicts
              key={item._id}
              item={item}
              onPress={() => handleSelect(item._id)}
            />
          ))}
        </View>

        {selectConflict[selectConflict.length - 1].active ? (
          <View style={styles.textAreaView}>
            <TextInput
              placeholder="Type reason here ..."
              multiline
              autoFocus
              value={reasonText}
              style={{
                width: "100%",
                height: "100%",
                outline: 0,
                textAlignVertical: "top",
                paddingLeft: 10,
                paddingTop: 15,
              }}
              onChangeText={setReasonText}
            />
          </View>
        ) : (
          false
        )}

        <View style={{ marginBottom: 35, marginTop: 35 }}>
          <AbstractButton
            processing={processing}
            onPress={handleSubmit}
            label={"Submit"}
          />
          <Text style={{ color: "red", alignSelf: "center", height: 30 }}>
            {errorMessage}
          </Text>
        </View>
      </View>
    </TouchableWithoutFeedback>
  );
};

export default ReportSheet;

const styles = StyleSheet.create({
  conflictView: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    marginVertical: 10,
  },
  textOne: {
    fontSize: moderateScale(17, 0.1),
    fontFamily: Fonts.medium,
    color: "black",
  },
  textTwo: {
    fontSize: moderateScale(21, 0.1),
    fontFamily: Fonts.medium,
    color: "black",
  },
  textAreaView: {
    width: "100%",
    height: moderateScale(205, 0.1),
    borderRadius: 20,
    borderWidth: 1,
    borderColor: "#C2C2C2",
    marginVertical: 20,
  },
});
