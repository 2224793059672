import React, { useRef, useEffect } from "react";
import { View, Text, TouchableOpacity, Platform, Animated } from "react-native";
import AbstractBottomTabButton from "../Components/AbstractComponents/abstractBottomTabButton";
import HomeSvg from "../Assets/Icons/bottomTabsSvgs/homeSvg";
import MessagesSvg from "../Assets/Icons/bottomTabsSvgs/messagesSvg";
import SavedSvg from "../Assets/Icons/bottomTabsSvgs/savedSvg";
import SettingsSvg from "../Assets/Icons/bottomTabsSvgs/settingsSvg";

const CustomBottomTabBar = ({ state, descriptors, navigation }) => {
  return (
    <View
      style={{
        height: Platform.OS === "android" ? 80 : 90,
        width: "100%",
        flexDirection: "row",
        backgroundColor: "transparent",
        position: "absolute",
        justifyContent: "center",
        alignItems: "center",
        bottom: 0,
        left: 0,
        right: 0,
      }}
    >
      <View
        style={{
          flex: 1,
          height: "100%",
          backgroundColor: "black",
          borderTopLeftRadius: 15,
          borderTopRightRadius: 15,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-around",
          zIndex: 1,
        }}
      >
        {state.routes.map((route, index) => {
          const { options } = descriptors[route.key];
          const label =
            options.tabBarLabel !== undefined
              ? options.tabBarLabel
              : options.title !== undefined
              ? options.title
              : route.name;

          const isFocused = state.index === index;

          const onPress = () => {
            const event = navigation.emit({
              type: "tabPress",
              target: route.key,
              canPreventDefault: true,
            });

            if (!isFocused && !event.defaultPrevented) {
              navigation.navigate({ name: route.name, merge: true });

              // if(route.name === "Home"){
              //   navigation.navigate({name: route.name, merge: true})
              // }
              // else if(route.name === "Saved"){
              //   navigation.navigate({name: route.name, merge: true})
              // }
              // else if(route.name === "Messages"){
              //   navigation.navigate({name: route.name, merge: true})
              // }
              // else if(route.name === "Settings"){
              //   navigation.navigate({name: route.name, merge: true})
              // }
              // else {
              //   hideBar()
              // }
            }
          };

          const onLongPress = () => {
            navigation.emit({
              type: "tabLongPress",
              target: route.key,
            });
          };

          switch (route.name) {
            case "Auction":
              return (
                <AbstractBottomTabButton
                  label={"Home"}
                  svg={() => <HomeSvg isFocused={isFocused} />}
                  onPress={onPress}
                  key={route.key}
                  isFocused={isFocused}
                />
              );
            case "Home":
              return (
                <AbstractBottomTabButton
                  label={"Home"}
                  svg={() => <HomeSvg isFocused={isFocused} />}
                  onPress={onPress}
                  key={route.key}
                  isFocused={isFocused}
                />
              );
            case "Offers":
              return (
                <AbstractBottomTabButton
                  label={"Offers"}
                  svg={() => <SavedSvg isFocused={isFocused} />}
                  onPress={onPress}
                  key={route.key}
                  isFocused={isFocused}
                />
              );
            case "Messages":
              return (
                <AbstractBottomTabButton
                  label={"Messages"}
                  svg={() => <MessagesSvg isFocused={isFocused} />}
                  onPress={onPress}
                  key={route.key}
                  isFocused={isFocused}
                />
              );
            case "Settings":
              return (
                <AbstractBottomTabButton
                  label={"Settings"}
                  svg={() => <SettingsSvg isFocused={isFocused} />}
                  onPress={onPress}
                  key={route.key}
                  isFocused={isFocused}
                />
              );
            default:
              return false;
          }
        })}
      </View>
    </View>
  );
};

export default CustomBottomTabBar;
