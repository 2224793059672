import React, { useEffect } from "react";
import { useState, useRef } from "react";
import {
  StyleSheet,
  Text,
  View,
  TextInput,
  TouchableOpacity,
} from "react-native";
import { GooglePlacesAutocomplete } from "react-native-google-places-autocomplete";
import { moderateScale } from "react-native-size-matters";
import { useSelector } from "react-redux";
import AuctionController from "../../Controllers/auctionController";
import { Colors, Fonts } from "../../themes";

const GooglePlacesInput = ({
  width,
  setLocationValue,
  locationValue,
  BAR_HEIGHT,
  isWeb,
  onSearchAuction,
}) => {
  const placeholderRef = useRef();
  const { deviceType } = useSelector((state) => state.DeviceTypeReducer);

  const selectedLocation = (data, location) => {
    let address = location.geometry.location;
    let addressTitle = data.description;
    console.log(address, addressTitle);
    onSearchAuction(addressTitle, {
      latitude: address.lat,
      longitude: address.lng,
    });
    setLocationValue(addressTitle);
  };

  useEffect(() => {
    if (locationValue) {
      setLocationValue(locationValue);
    }
  }, []);

  return (
    <GooglePlacesAutocomplete
      ref={placeholderRef}
      fetchDetails={true}
      placeholder={"Nearest auction location"}
      textInputProps={{
        autoFocus: true,
        style: {
          placeholderTextColor: Colors.greySecondaryFour,
          fontFamily: Fonts.medium,
          fontSize: moderateScale(14, 0.1),
          color: Colors.blueprimary,
          paddingLeft: 10,
          height: BAR_HEIGHT,
          outline: 0,
          width: "100%",
        },
        clearButtonMode: "never",
        value: locationValue,
        onChangeText: (txt) => setLocationValue(txt),
      }}
      styles={{
        row: {
          paddingLeft: 5,
          backgroundColor: Colors.whitePrimary,
        },
        container: {
          width: deviceType ? 200 : width - 30,
          position: isWeb ? undefined : "absolute",
          top: isWeb ? undefined : 0,
          left: isWeb ? undefined : 0,
          right: isWeb ? 100 : undefined,
        },
        listView: {
          width: isWeb ? 250 : undefined,
          zIndex: 9999,
        },
        textInputContainer: {
          width: width - 35,
          // backgroundColor:'red'
        },
      }}
      enableHighAccuracyLocation
      enablePoweredByContainer={false}
      onPress={(data, details = null) => {
        // 'details' is provided when fetchDetails = true
        selectedLocation(data, details);
      }}
      // suppressDefaultStyles
      query={{
        key: "AIzaSyDYL1lb12inoSM8uVoI5N1W0cZwcbtEM54",
        language: "en",
      }}
    />
  );
};

export default GooglePlacesInput;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  textInputContainer: {
    flexDirection: "row",
  },
  textInput: {
    // backgroundColor: "#FFFFFF",
    backgroundColor: "red",
  },
});
