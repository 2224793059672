import SkeletonLoader from 'expo-skeleton-loader';
import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { moderateScale } from 'react-native-size-matters';
import { Colors, Fonts } from '../../themes';

const DetailScreenDetaiItem = ({ label, details, type, isDataNotAvailable }) => {
  const defLabel = label ? label : 'textHere';
  const defdetails = details ? details : 'textHere';
  if (details) {
    return (
      <React.Fragment>
        {type == 'simple' ? (
          <View style={styles.minContainer}>
            <View>
              <Text style={styles.textOne}>{defLabel}</Text>
            </View>
            <View style={{ width: '66%', alignItems: 'flex-end' }} >
              {/* <Text style={styles.textOne}>{defdetails}</Text> */}
              {isDataNotAvailable ?
                <SkeletonLoader
                  backgroundColor={"grey"}
                  highlightColor={"#5A616C"}
                  boneColor={"grey"}
                  duration={1000}
                >
                  <SkeletonLoader.Item
                    style={{
                      height: moderateScale(20, 0.1),
                      width: 80,
                      borderRadius:8
                    }}
                  />
                </SkeletonLoader>

                :
                <Text numberOfLines={1} style={styles.textOne}>{defdetails}</Text>
              }
            </View>
          </View>
        ) : (
          <View
            style={[
              styles.minContainer,
              {
                flexDirection: 'column',
                alignItems: 'flex-start',
                borderBottomColor: Colors.blueSecondary,
              },
            ]}
          >
            <View>
              <Text style={[styles.textOne, { marginTop: 10, marginBottom: 15 }]}>
                {defLabel}
              </Text>
            </View>
            <View style={{width:"100%"}}>
            {isDataNotAvailable ?
                <SkeletonLoader
                  backgroundColor={"grey"}
                  highlightColor={"#5A616C"}
                  boneColor={"grey"}
                  duration={1000}
                >
                  <SkeletonLoader.Item
                    style={{
                      height: moderateScale(100, 0.1),
                      width: "100%",
                      borderRadius:8
                    }}
                  />
                </SkeletonLoader>
                :
              <Text style={styles.textOne}>{defdetails}</Text>
                  } 
                   
                </View>
          </View>
        )}
      </React.Fragment>
    );
  }
  else {
    return false
  }
};

export default DetailScreenDetaiItem;

const styles = StyleSheet.create({
  minContainer: {
    width: '100%',
    paddingVertical: 20,
    borderBottomColor: '#5A616C',
    borderBottomWidth: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  textOne: {
    fontSize: moderateScale(17, 0.1),
    color: Colors.lightBluePrimary,
    fontFamily: Fonts.default,
  },
});
