import React from "react";
import Svg, { Path } from "react-native-svg";
import { Colors } from "../../../themes";

const MessagesSvg = ({ isFocused, size, color }) => {
  let myFocusColor = isFocused ? Colors.blueprimary : Colors.greySecondaryFour;
  if (color) myFocusColor = color;

  const mSize = size ? size : 25;

  return (
    <Svg
      width={mSize}
      height={mSize}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path
        d="M21.3333 0.333313H2.66665C1.38331 0.333313 0.333313 1.38331 0.333313 2.66665V23.6666L4.99998 19H21.3333C22.6166 19 23.6666 17.95 23.6666 16.6666V2.66665C23.6666 1.38331 22.6166 0.333313 21.3333 0.333313ZM20.1666 16.6666H4.99998L2.66665 19V3.83331C2.66665 3.19165 3.19165 2.66665 3.83331 2.66665H20.1666C20.8083 2.66665 21.3333 3.19165 21.3333 3.83331V15.5C21.3333 16.1416 20.8083 16.6666 20.1666 16.6666Z"
        fill={myFocusColor}
      />
    </Svg>
  );
};

export default MessagesSvg;
