import { StyleSheet, Text, TouchableOpacity, Dimensions } from "react-native";
import React from "react";
import { moderateScale } from "react-native-size-matters";
import { Colors, Fonts } from "../../themes";

const SH = Dimensions.get("window").height;
const SW = Dimensions.get("window").width;

const tileWidth = SW * (105 / 414);
const tileHeight = tileWidth * (42 / 100);

const nonActiveStyle = {
  backgroundColor: "transparent",
  borderWidth: 1,
  borderColor: Colors.blueprimary,
};

const CarWithCountTile = ({ item, onPress }) => {
  return (
    <TouchableOpacity
      activeOpacity={0.8}
      onPress={onPress}
      style={[
        styles.mainContainer,
        item?.active ? {} : nonActiveStyle,
        // { height: tileHeight },
      ]}
    >
      <Text
        style={[
          styles.textOne,
          { color: item?.active ? Colors.whitePrimary : Colors.blackPrimary },
        ]}
      >
        {`${item?.label}`}
        <Text
          style={{
            color: item?.active ? Colors.whitePrimary : Colors.blackPrimary,
            fontWeight: "bold",
          }}
        >
          {item?.count ? ` (${item?.count})` : ""}
        </Text>
      </Text>
    </TouchableOpacity>
  );
};

export default CarWithCountTile;

const styles = StyleSheet.create({
  mainContainer: {
    height: moderateScale(40, 0.1),
    borderRadius: 80,
    backgroundColor: Colors.blueprimary,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 25,
    marginRight: 13,
  },
  textOne: {
    color: Colors.whitePrimary,
    fontSize: moderateScale(13, 0.1),
    fontWeight: "500",
    fontFamily: Fonts.medium,
  },
});
