import { StyleSheet, Text, View } from "react-native";
import React, { useState } from "react";
import ActionSheet, { SheetManager } from "react-native-actions-sheet";
import { Colors, Fonts } from "../../../themes";
import AbstractButton from "../../AbstractComponents/abstractButton";
import AbstractContentContainer from "../../AbstractComponents/abstractContentContainer";
import { moderateScale } from "react-native-size-matters";
import OfferInfoSheet from "./offerInfoSheet";

const BuyNowSheet = ({
  id,
  vehicle,
  buyNowPrice,
  isCreateMode,
  offer,
  fromInitializeMessageFlow,
  onClose,
}) => {
  return (
    <ActionSheet
      id={id}
      initialOffsetFromBottom={1}
      statusBarTranslucent={true}
      delayActionSheetDrawTime={0}
      openAnimationSpeed={1}
      bounceOnOpen={true}
      containerStyle={{
        backgroundColor: Colors.blueSecondary,
        borderRadius: 28,
      }}
      bounciness={2}
      indicatorStyle={{ width: 70, height: 4 }}
      gestureEnabled={true}
      overlayColor={"grey"}
      defaultOverlayOpacity={0.4}
    >
      <OfferInfoSheet
        id={id}
        vehicle={vehicle}
        offerType="buyNow"
        buyNowPrice={buyNowPrice}
        offer={offer}
        onClose={onClose}
        isCreateMode={isCreateMode}
        fromInitializeMessageFlow={fromInitializeMessageFlow}
      />
    </ActionSheet>
  );
};

export default BuyNowSheet;

const styles = StyleSheet.create({
  textOne: {
    color: Colors.whitePrimary,
    fontSize: moderateScale(23, 0.1),
    fontFamily: Fonts.bold,
    fontWeight: "900",
  },
  textTwo: {
    color: Colors.whitePrimary,
    fontSize: moderateScale(18, 0.1),
    fontFamily: Fonts.default,
    fontWeight: "900",
  },
});
