import React, { Component, useEffect, useState } from "react";
import {
  View,
  Animated,
  StyleSheet,
  Text,
  useWindowDimensions,
  Dimensions,
} from "react-native";
import {
  WIDTH,
  HEIGHT,
  GEAR_WIDTH,
  GEAR_MIDDLE_HEIGHT,
  GEAR_UPPER_HEIGHT,
} from "./configs";
import SideSwipe from "react-native-sideswipe";
import AuctionCardItem, {
  CARD_LOWER_PART_HEIGHT,
  CARD_UPPER_PART_HEIGHT,
} from "./auctionCardItem";
import { TouchableOpacity } from "react-native-gesture-handler";
import { Colors, PLATFORM_DETECT } from "../../../themes";
import { useSelector } from "react-redux";
import SkeletonLoader from "expo-skeleton-loader";

const { height } = Dimensions.get("window");

const WIDTH_FOR_CARD_SWIPE_CARD = 300;

const GearItem = ({
  index,
  animatedValue,
  item,
  onPress,
  suggestionCard,
  currentIndex,
  deviceType,
}) => {
  // console.log(height, "jnh");
  const myIndex = index;
  const animatedScale = animatedValue.interpolate({
    inputRange: [myIndex - 1, myIndex, myIndex + 1],
    outputRange: [0.8, 0.9, 0.8],
    extrapolate: "clamp",
  });
  const animatedOpacity = animatedValue.interpolate({
    inputRange: [
      myIndex - 3,
      myIndex - 2,
      myIndex - 1,
      myIndex,
      myIndex + 1,
      myIndex + 2,
      myIndex + 3,
    ],
    outputRange: [0.5, 0.5, 0.5, 1, 0.5, 0.5, 0.5],
    extrapolate: "clamp",
  });
  const animatedTransform = animatedValue.interpolate({
    inputRange: [myIndex - 1, myIndex, myIndex + 1],
    outputRange: [10, 0, -10],
    extrapolate: "clamp",
  });

  const verticallOffetAnimated = animatedValue.interpolate({
    inputRange: [
      myIndex - 3,
      myIndex - 2,
      myIndex - 1,
      myIndex,
      myIndex + 1,
      myIndex + 2,
      myIndex + 3,
    ],
    outputRange: [30, 20, 10, 0, 10, 20, 30],
    extrapolate: "clamp",
  });

  return (
    <Animated.View
      style={[
        {
          opacity: animatedOpacity,
          transform: [{ scale: animatedScale }],
          width: WIDTH_FOR_CARD_SWIPE_CARD,
          height:
            PLATFORM_DETECT.WEB && !deviceType
              ? 315
              : PLATFORM_DETECT.WEB
              ? 376
              : CARD_UPPER_PART_HEIGHT + CARD_LOWER_PART_HEIGHT,
          borderRadius: 25,
        },
      ]}
    >
      <SkeletonLoader
        backgroundColor={"white"}
        highlightColor={"#e5e5e5"}
        boneColor={"white"}
        duration={1000}
      >
        <SkeletonLoader.Item
          style={{
            width: WIDTH_FOR_CARD_SWIPE_CARD,
            height:
              PLATFORM_DETECT.WEB && !deviceType
                ? 315
                : PLATFORM_DETECT.WEB
                ? 376
                : CARD_UPPER_PART_HEIGHT + CARD_LOWER_PART_HEIGHT,
            marginVertical: 3,
            borderRadius: 25,
          }}
        />
      </SkeletonLoader>
    </Animated.View>
  );
};

GearItem.WIDTH = GEAR_WIDTH;

const CardSwipeSkeletonPlaceholder = (props) => {
  const contentOffset = (WIDTH - GearItem.WIDTH) / 2;
  const contentOffsetNew = (WIDTH - WIDTH_FOR_CARD_SWIPE_CARD) / 2;
  const [currentIndex, setCurrentIndex] = useState(0);
  const { deviceType } = useSelector((state) => state.DeviceTypeReducer);

  const shouldSwipe = ({ dx, dy }) => {
    return dx < -30 || dx > 30;
  };

  return (
    <SideSwipe
      index={currentIndex}
      itemWidth={WIDTH_FOR_CARD_SWIPE_CARD}
      data={props.data}
      contentOffset={contentOffsetNew}
      threshold={0}
      extractKey={(item, index) => `${item._id},-${index}`}
      shouldRelease={(state) => false}
      useVelocityForIndex={false}
      useNativeDriver={true}
      shouldCapture={(gestureState) => shouldSwipe(gestureState)}
      renderItem={({ itemIndex, currentIndex, item, animatedValue }) => (
        <GearItem
          onPress={() => {
            setCurrentIndex(itemIndex);
          }}
          suggestionCard={props.suggestionCard}
          item={item}
          index={itemIndex}
          currentIndex={currentIndex}
          animatedValue={animatedValue}
          deviceType={deviceType}
        />
      )}
    />
  );
};
export default CardSwipeSkeletonPlaceholder;

const styles = StyleSheet.create({
  gearPinContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 2,
  },
});
