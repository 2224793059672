import store from "../../src/Store";
import {
  AUTH,
  BOTTOMTABNAVIGATION,
  CONVERSATIONS,
  OFFERS,
  VEHICLES,
  WALLET,
  DEVICE,
  AUCTION,
} from "../../src/Store/types";

class ReduxDispatchController {
  static AUTH = {
    setCurrentUser: (user) => {
      store.dispatch({
        type: AUTH.SET_CURRENT_USER,
        payload: user,
      });
    },
  };
  static DEVICE = {
    setDeviceType: (type) => {
      store.dispatch({
        type: DEVICE.DEVICE_TYPE,
        payload: type,
      });
    },
  };

  static AUCTION = {
    setAuctionList: (list) => {
      store.dispatch({
        type: AUCTION.SET_ALL_AUCTION,
        payload: list,
      });
    },
    setSearchLoading: (loading) => {
      store.dispatch({
        type: AUCTION.SET_SEARCH_LOADING,
        payload: loading,
      });
    },
  };

  static VEHICLES = {
    setKeyValue: (key, value) => {
      store.dispatch({
        type: VEHICLES.SET_KEY_VALUE,
        payload: { key, value },
      });
    },
    setAll: (vehicles) => {
      store.dispatch({
        type: VEHICLES.SET_ALL,
        payload: vehicles,
      });
    },
    setVehiclesLoading: (isLoading) => {
      store.dispatch({
        type: VEHICLES.SET_KEY_VALUE,
        payload: {
          key: "loadingVehicles",
          value: isLoading,
        },
      });
    },
    setFilter: (filters) => {
      store.dispatch({
        type: VEHICLES.SET_FILTER,
        payload: filters,
      });
    },
  };
  static FILTERS_META = {
    set: (filters) => {
      console.log("filtersfiltersfilters", filters);
      ReduxDispatchController.VEHICLES.setKeyValue("allFilters", filters);
      ReduxDispatchController.VEHICLES.setKeyValue(
        "loadedOnceVehicleFilters",
        true
      );
    },
    setLoading: (isLoading) => {
      ReduxDispatchController.VEHICLES.setKeyValue(
        "loadingVehicleFilters",
        isLoading
      );
    },
  };
  static OFFERS = {
    setAll: (offers) => {
      store.dispatch({
        type: OFFERS.SET_ALL,
        payload: offers,
      });
    },
    pushNew: (offer) => {
      store.dispatch({
        type: OFFERS.PUSH_NEW_OFFER,
        payload: offer,
      });
    },
    updateOffer: (offer) => {
      store.dispatch({
        type: OFFERS.UPDATE_OFFER,
        payload: offer,
      });
    },
    updateAllBuyNowOfferStatus: (vehicleID, offerId) => {
      store.dispatch({
        type: OFFERS.UPDATE_BUY_NOW_OFFERS_STATUS,
        payload: { vehicleID, offerId },
      });
      store.dispatch({
        type: CONVERSATIONS.UPDATE_BUY_NOW_CONVERSATIONS_STATUS,
        payload: { vehicleID, offerId },
      });
    },
    setOffersLoading: (isLoading) => {
      store.dispatch({
        type: OFFERS.SET_KEY_VALUE,
        payload: {
          key: "offersLoading",
          value: isLoading,
        },
      });
    },
    deleteOffer: (offerId) => {
      store.dispatch({
        type: OFFERS.DELETE_OFFER,
        payload: offerId,
      });
    },
    createConflict: (offerId, conflictReason) => {
      store.dispatch({
        type: OFFERS.CONFLICT_OFFER,
        payload: { offerId, conflictReason },
      });
    },
  };
  static WALLET = {
    setKeyValue: (key, value) => {
      store.dispatch({
        type: WALLET.SET_KEY_VALUE,
        payload: { key, value },
      });
    },
    set: (currentBalance, comission) => {
      store.dispatch({
        type: WALLET.SET_WALLET_SUMMERY,
        payload: { currentBalance, comission },
      });
    },
    setProcess: (processing, message) => {
      ReduxDispatchController.WALLET.setKeyValue("process", {
        processing,
        message,
      });
    },
    addFunds: (amount) => {
      store.dispatch({
        type: WALLET.ADD_FUNDS,
        payload: amount,
      });
    },
    removeFunds: (amount) => {
      store.dispatch({
        type: WALLET.REMOVE_FUNDS,
        payload: amount,
      });
    },
  };
  static CONVERSATIONS = {
    set: (conversations) => {
      store.dispatch({
        type: CONVERSATIONS.SET_CONVERSATIONS,
        payload: conversations,
      });
    },
    setSingle: (conversation) => {
      store.dispatch({
        type: CONVERSATIONS.SET_SINGLE_CONVERSTION,
        payload: conversation,
      });
    },
    pushMessage: (offerId, message) => {
      store.dispatch({
        type: CONVERSATIONS.PUSH_MESSAGE,
        payload: { offerId, message },
      });
    },
    updateMessage: (offerId, messageId, message) => {
      store.dispatch({
        type: CONVERSATIONS.UPDATE_MESSAGE,
        payload: { offerId, messageId, message },
      });
    },
    setKeyValue: (key, value) => {
      store.dispatch({
        type: CONVERSATIONS.SET_KEY_VALUE,
        payload: { key, value },
      });
    },
    setProcessing: (processing) => {
      ReduxDispatchController.CONVERSATIONS.setKeyValue("loading", processing);
    },
    setSingleConversationProcessing: (processing) => {
      ReduxDispatchController.CONVERSATIONS.setKeyValue(
        "loadingSingleConversation",
        processing
      );
    },
    markSeen: (offerId) => {
      store.dispatch({
        type: CONVERSATIONS.MARK_SEEN,
        payload: { offerId },
      });
    },
    pushSingleConversation: (conversation) => {
      store.dispatch({
        type: CONVERSATIONS.PUSH_SINGLE_CONVERSATION,
        payload: conversation,
      });
    },
    updateConversationStatus: (conversation, status) => {
      store.dispatch({
        type: CONVERSATIONS.CONVERSATION_STATUS_UPDATE,
        payload: { conversation, status },
      });
    },
    deleteConversation: (conversationId) => {
      store.dispatch({
        type: CONVERSATIONS.DELETE_CONVERSATION,
        payload: conversationId,
      });
    },
  };
}
export default ReduxDispatchController;
