import React, { useState, useRef } from "react";
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Dimensions,
  Linking,
} from "react-native";
import BackBtnSvg from "../../Assets/Icons/backBtnSvg";
import AbstractHeaders from "../../Components/AbstractComponents/abstractHeaders";
import { Colors, Fonts, PLATFORM_DETECT } from "../../themes";
import AbstractContentContainer from "../../Components/AbstractComponents/abstractContentContainer";
import AbstractH3 from "../../Components/AbstractComponents/AbstractHeadings/abstractH3";
import AbstractButton from "../../Components/AbstractComponents/abstractButton";
import FocusAwareStatusBar from "../../Components/AbstractComponents/statusbarConfiguration";
import { moderateScale } from "react-native-size-matters";
import { goBack, navigate } from "../../Navigation/mainNavigation";
import ScreenContainer from "../../Components/AbstractComponents/abstractScreenContainer";
import AbstractPhoneTextInput from "../../Components/AbstractComponents/abstractPhoneTextInput";
import { ScrollView } from "react-native-gesture-handler";
import AuthController from "../../Controllers/authController";
import { useSelector } from "react-redux";

const SH = Dimensions.get("window").height;

const SignInScreen = () => {
  const { deviceType } = useSelector((state) => state.DeviceTypeReducer);

  const [phoneNumber, setPhoneNumber] = useState(
    ""
    // PLATFORM_DETECT.WEB ? "19172568215" : "9172568215"
  ); //"19172568215"
  const phoneRef = useRef();
  const [error, setError] = useState("");
  const [processing, setProcessing] = useState(false);
  const _handleNext = () => {
    phoneRef.current
      .validate()
      .then((phoneNo) => {
        setError("");
        // console.log(phoneNo);
        setProcessing(true);

        AuthController.sendOTP(phoneNo)
          .then((sent) => {
            // console.log(`OTP sent successfully!`);
            setProcessing(false);
          })
          .catch((err) => {
            setProcessing(false);
            setError(err);
          });
      })
      .catch((err) => setError(``));
  };

  const handleOpenContactUsEmail = () => {
    Linking.openURL("mailto:evan@inforide.app")
      .then((success) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <ScreenContainer
      upperStyle={{ backgroundColor: Colors.screenBgColor }}
      bottomStyle={{ backgroundColor: Colors.screenBgColor }}
    >
      <AbstractHeaders
        onPressLeft={() => goBack()}
        leftChild={() => <BackBtnSvg />}
      />
      <FocusAwareStatusBar
        barStyle={"dark-content"}
        backgroundColor={"transparent"}
        translucent={true}
      />
      <View
        style={[
          {
            flex: 1,
            backgroundColor: Colors.screenBgColor,
            paddingTop: deviceType ? PLATFORM_DETECT.SH * 0.1 : 0,
            alignItems: "center",
          },
        ]}
      >
        <View
          style={[
            {
              backgroundColor: Colors.screenBgColor,
              width: deviceType ? "50%" : "100%",
              padding: deviceType ? 50 : 0,
              borderRadius: deviceType ? 10 : 0,
            },
            deviceType
              ? {
                  shadowColor: Colors.blueprimary,
                  shadowOffset: {
                    width: 0,
                    height: 4,
                  },
                  shadowOpacity: 0.38,
                  shadowRadius: 12.84,
                  elevation: 2,
                }
              : {},
          ]}
        >
          <AbstractContentContainer>
            <View style={{}}>
              <AbstractH3 txtColor={Colors.darkBluePrimary}>
                Login to your account
              </AbstractH3>
              <TouchableOpacity style={{ marginTop: 5 }}>
                <Text style={styles.txtOne}>
                  Please enter your phone number below to receive your
                  verification code.
                </Text>
              </TouchableOpacity>
            </View>

            <View
              style={{
                marginTop: 50,
                height: moderateScale(69, 0.1),
              }}
            >
              <AbstractPhoneTextInput
                placeHolder={"Mobile number"}
                label={"Verification"}
                value={phoneNumber}
                onChangeText={(txt) => setPhoneNumber(txt)}
                ref={phoneRef}
              />
            </View>

            <View style={{ marginTop: 50 }}>
              <AbstractButton
                processing={processing}
                onPress={_handleNext}
                label={"Next"}
              />
            </View>

            <View style={{ alignSelf: "center", marginTop: 10 }}>
              <Text style={styles.errorStyle}>{error}</Text>
            </View>
          </AbstractContentContainer>
        </View>
      </View>

      <View style={styles.containerBottom}>
        <View style={{ justifyContent: "center", alignItems: "center" }}>
          <Text style={styles.textTwo}>Can’t Login?</Text>
          <TouchableOpacity
            activeOpacity={0.7}
            onPress={handleOpenContactUsEmail}
          >
            <Text style={styles.textThree}>
              Please contact :{" "}
              <Text
                onPress={handleOpenContactUsEmail}
                style={[
                  styles.textThree,
                  { color: Colors.blueprimary, fontFamily: Fonts.bold },
                ]}
              >
                evan@inforide.app
              </Text>
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </ScreenContainer>
  );
};

export default SignInScreen;

const styles = StyleSheet.create({
  containerBottom: {
    width: "100%",
    // flex: 1,
    backgroundColor: Colors.screenBgColor,
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: 20,
  },
  txtOne: {
    fontSize: moderateScale(12, 0.1),
    fontFamily: Fonts.default,
    color: Colors.darkBluePrimary,
    // width: "80%",
    lineHeight: 20,
  },
  textTwo: {
    fontSize: moderateScale(12, 0.1),
    fontFamily: Fonts.bold,
    color: Colors.darkBluePrimary,
  },
  textThree: {
    fontSize: moderateScale(12, 0.1),
    fontFamily: Fonts.default,
    color: Colors.darkBluePrimary,
  },
  errorStyle: {
    fontFamily: Fonts.default,
    fontSize: moderateScale(12.5, 0.1),
    color: "red",
    fontWeight: "600",
    paddingLeft: 10,
  },
});
