import React from "react";
import { Svg,Path } from "react-native-svg";

const AddIcon = ({ color, size }) => {
  const defColor = color ? color : "#5A616C";

  return (
    <Svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <Path d="M9.51833 1.18421C9.51833 0.870138 9.39387 0.56893 9.17233 0.346847C8.95078 0.124765 8.6503 0 8.33698 0C8.02367 0 7.72319 0.124765 7.50164 0.346847C7.2801 0.56893 7.15563 0.870138 7.15563 1.18421V6.31579H2.03645C1.72314 6.31579 1.42266 6.44055 1.20111 6.66264C0.979566 6.88472 0.855103 7.18593 0.855103 7.5C0.855103 7.81407 0.979566 8.11528 1.20111 8.33736C1.42266 8.55945 1.72314 8.68421 2.03645 8.68421H7.15563V13.8158C7.15563 14.1299 7.2801 14.4311 7.50164 14.6532C7.72319 14.8752 8.02367 15 8.33698 15C8.6503 15 8.95078 14.8752 9.17233 14.6532C9.39387 14.4311 9.51833 14.1299 9.51833 13.8158V8.68421H14.6375C14.9508 8.68421 15.2513 8.55945 15.4729 8.33736C15.6944 8.11528 15.8189 7.81407 15.8189 7.5C15.8189 7.18593 15.6944 6.88472 15.4729 6.66264C15.2513 6.44055 14.9508 6.31579 14.6375 6.31579H9.51833V1.18421Z" fill="white"/>
      </Svg>
     
  );
};

export default AddIcon;
