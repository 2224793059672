import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import CarFaxReport from '../../CompositeComponents/carFaxReport'
import DetailScreenDetaiItem from '../detailScreenDetaiItem'

const Details = ({vehicle,isDataNotAvailable}) => {
    return (
        <View>
            <CarFaxReport vin={vehicle?.VIN} />
              <DetailScreenDetaiItem
                  type={"simple"}
                  label={"Drivetrain"}
                  details={vehicle?.driveTrain}
                  isDataNotAvailable={isDataNotAvailable}
                />

                <DetailScreenDetaiItem
                  type={"simple"}
                  label={"Interior"}
                  details={vehicle?.interior}
                  isDataNotAvailable={isDataNotAvailable}
                />

                <DetailScreenDetaiItem
                  type={"simple"}
                  label={"Exterior"}
                  details={vehicle?.exterior}
                  isDataNotAvailable={isDataNotAvailable}
                />

                <DetailScreenDetaiItem
                  type={"simple"}
                  label={"Transmission"}
                  details={vehicle?.transmission}
                  isDataNotAvailable={isDataNotAvailable}
                />

                <DetailScreenDetaiItem
                  type={"simple"}
                  label={"Engine"}
                  details={vehicle?.engine}
                  isDataNotAvailable={isDataNotAvailable}
                />

                <DetailScreenDetaiItem
                  type={"simple"}
                  label={"mpgCity"}
                  details={vehicle?.mileageCity}
                  isDataNotAvailable={isDataNotAvailable}
                />

                <DetailScreenDetaiItem
                  type={"simple"}
                  label={"mpgHighway"}
                  details={vehicle?.mileageHighway}
                  isDataNotAvailable={isDataNotAvailable}
                />

                <DetailScreenDetaiItem
                  type={"expand"}
                  label={"Overview"}
                  details={vehicle?.description}
                  isDataNotAvailable={isDataNotAvailable}
                /> 

        </View>
    )
}

export default Details

const styles = StyleSheet.create({})