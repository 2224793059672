import React, { useEffect, useState } from "react";
import {
  View,
  ActivityIndicator,
  StyleSheet,
  Text,
  TouchableOpacity,
} from "react-native";
import { WebView } from "react-native-webview";
import BackBtnSvg from "../../Assets/Icons/backBtnSvg";
import ScreenContainer from "../../Components/AbstractComponents/abstractScreenContainer";

import { goBack } from "../../Navigation/mainNavigation";
import { PLATFORM_DETECT } from "../../themes";

const PPWebView = ({ uri, title }) => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (PLATFORM_DETECT.WEB) {
      setTimeout(() => {
        setIsLoading(false);
      }, 1500);
    }
  }, []);
  return (
    <ScreenContainer upperStyle={{ backgroundColor: "white" }}>
      <View
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          zIndex: 9999,
        }}
      >
        <View
          style={{
            height: 80,
            alignItems: "center",
            flexDirection: "row",
            backgroundColor: "white",
          }}
        >
          <TouchableOpacity
            onPress={() => goBack()}
            style={{
              // flex: 1,
              width: 60,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <BackBtnSvg color="black" />
          </TouchableOpacity>
          <Text style={{ fontSize: 20, fontWeight: "bold" }}>{title}</Text>
        </View>
      </View>
      {PLATFORM_DETECT.WEB ? (
        <iframe src={uri} style={{ flex: 1, paddingTop: 13 }} />
      ) : (
        <WebView
          source={{
            uri,
          }}
          onLoadStart={() => setIsLoading(true)}
          onLoadEnd={() => {
            setTimeout(() => {
              setIsLoading(false);
            }, 1000);
          }}
          style={{ flex: 1, marginTop: -15 }}
          onError={(err) => {
            console.log(`Error in Loading....`);
            console.log(err);
          }}
        />
      )}
      {isLoading ? (
        <View
          style={[
            StyleSheet.absoluteFillObject,
            { justifyContent: "center", alignItems: "center" },
          ]}
        >
          <ActivityIndicator color={"black"} size="large" />
        </View>
      ) : (
        false
      )}
    </ScreenContainer>
  );
};
export default PPWebView;
