import {
  Keyboard,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Platform,
} from "react-native";
import React from "react";
import ScreenContainer from "../../Components/AbstractComponents/abstractScreenContainer";
import { Colors } from "../../themes";
import AbstractContentContainer from "../../Components/AbstractComponents/abstractContentContainer";
import GooglePlacesInput from "../../Components/AbstractComponents/googleAutoPlacesApi";
import BackBtnSvg from "../../Assets/Icons/backBtnSvg";
import { goBack } from "../../Navigation/mainNavigation";
import FocusAwareStatusBar from "../../Components/AbstractComponents/statusbarConfiguration";

import { getStatusBarHeight } from "react-native-status-bar-height";
import AbstractButton from "../../Components/AbstractComponents/abstractButton";
import AuctionController from "../../Controllers/auctionController";

const STATUS_BAR_HEIGHT = getStatusBarHeight();

const GoogleLocSearch = () => {
  return (
    <ScreenContainer
      upperStyle={{ backgroundColor: Colors.screenBgColor }}
      bottomStyle={{ backgroundColor: Colors.screenBgColor }}
    >
      <View style={styles.mainContainer}>
        <FocusAwareStatusBar
          barStyle="dark-content"
          backgroundColor={"transparent"}
          translucent={true}
        />
        <View
          style={{
            height: Platform.OS === "android" ? STATUS_BAR_HEIGHT : 15,
          }}
        />
        <GooglePlacesInput onChange={() => {}} />
        <View style={{ width: "100%" }}>
          {/* <AbstractContentContainer>
            <View style={{ width: "100%", flexDirection: "row" }}>
              <View>
                <TouchableOpacity
                  activeOpacity={0.9}
                  onPress={() => {
                    Keyboard.dismiss();
                    goBack();
                  }}
                  style={{
                    // backgroundColor: "red",
                    height: 45,
                    width: 35,
                    // backgroundColor: "pink",
                    justifyContent: "center",
                    alignItems: "flex-start",
                  }}
                >
                  <BackBtnSvg color={Colors.blackPrimary} />
                </TouchableOpacity>
              </View>
              <GooglePlacesInput onChange={() => {}} />
            </View>
          </AbstractContentContainer> */}
        </View>
        {/* <AbstractContentContainer>
          <View>
            <AbstractButton
              onPress={() => AuctionController.handleSearchAuctionByLocation()}
              label={"Search"}
            />
          </View>
        </AbstractContentContainer> */}
      </View>
    </ScreenContainer>
  );
};

export default GoogleLocSearch;

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: Colors.screenBgColor,
  },
});
