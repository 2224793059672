import React from "react";
import {
  createStackNavigator,
  CardStyleInterpolators,
} from "@react-navigation/stack";
// import SetupWalletScreen from "../Screens/AppScreens/setupWalletScreen";
import WelcomeScreen from "../Screens/AuthScreens/welcomeScreen";
import TermsAndConditionsScreen from "../Screens/AppScreens/termsAndConditionsScreen";
import SignInScreen from "../Screens/AuthScreens/signInScreen";
import OTPVerificationScreen from "../Screens/AuthScreens/otpVerificationScreen";
import CreateAccountScreen from "../Screens/AuthScreens/createAccountScreen";
import TermsOfUseScreen from "../Screens/AuthScreens/Terms/termsOfUserScreen";
import PrivacyPolicyScreen from "../Screens/AuthScreens/Terms/privacyPolicyScreen";
import EULScreen from "../Screens/AuthScreens/Terms/eulScreen";
const Stack = createStackNavigator();

const AuthStack = () => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: false,
        headerStyle: { height: 80 },
      }}
    >
      <Stack.Screen
        name="Welcome"
        key={"Welcome-key"}
        component={WelcomeScreen}
        options={{
          cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
          gestureEnabled: true,
          gestureDirection: "horizontal",
        }}
      />

      <Stack.Screen
        name="SignIn"
        key={"Signup-key"}
        component={SignInScreen}
        options={{
          cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
          gestureEnabled: true,
          gestureDirection: "horizontal",
        }}
      />

      {/* <Stack.Screen
        name="SetupWallet"
        component={SetupWalletScreen}
        options={{
          cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
          gestureEnabled: true,
          gestureDirection: "horizontal",
        }}
      /> */}

      <Stack.Screen
        name="Verification"
        component={OTPVerificationScreen}
        options={{
          cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
          gestureEnabled: true,
          gestureDirection: "horizontal",
        }}
      />

      <Stack.Screen
        name="TermsAndConditions"
        component={TermsAndConditionsScreen}
        options={{
          cardStyleInterpolator: CardStyleInterpolators.forVerticalIOS,
          gestureEnabled: true,
          gestureDirection: "horizontal",
        }}
      />

      <Stack.Screen
        name="TermsOfUseScreen"
        component={TermsOfUseScreen}
        options={{
          cardStyleInterpolator: CardStyleInterpolators.forVerticalIOS,
          gestureEnabled: true,
          gestureDirection: "horizontal",
        }}
      />
      <Stack.Screen
        name="PrivacyPolicyScreen"
        component={PrivacyPolicyScreen}
        options={{
          cardStyleInterpolator: CardStyleInterpolators.forVerticalIOS,
          gestureEnabled: true,
          gestureDirection: "horizontal",
        }}
      />
      <Stack.Screen
        name="EUAScreen"
        component={EULScreen}
        options={{
          cardStyleInterpolator: CardStyleInterpolators.forVerticalIOS,
          gestureEnabled: true,
          gestureDirection: "horizontal",
        }}
      />

      <Stack.Screen
        name="Setup Profile"
        component={CreateAccountScreen}
        options={{
          cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
          gestureEnabled: true,
          gestureDirection: "horizontal",
        }}
      />
    </Stack.Navigator>
  );
};

export default AuthStack;
