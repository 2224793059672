import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  TextInput,
  Dimensions,
  TouchableOpacity,
  Platform,
} from "react-native";
import { moderateScale } from "react-native-size-matters";
import { Colors, Fonts, PLATFORM_DETECT } from "../../themes";
import CountryPicker, {
  FlagButton,
  getCallingCode,
} from "react-native-country-picker-modal";
import ArrowDownSvg from "../../Assets/Icons/TextInputSvgs/arrowDownSvg";
import { phone } from "phone";
// import * as RNLocalize from "react-native-localize";
import * as Cellular from "expo-cellular";
import { useSelector } from "react-redux";

const SW = Dimensions.get("window").width;

const AbstractPhoneTextInput = React.forwardRef((props, ref) => {
  const defLabel = props.label ? props.label : "TextHere";
  const defplaceHolder = props.placeHolder ? props.placeHolder : "Text";
  const [modalVisible, setModalVisible] = useState(false);
  const [postalCode, setPostalCode] = useState("1");
  const [flagOfCountry, setFlagOfCountry] = useState("US");
  const [focus, setFocus] = useState(false);
  const [isValidNo, setIsValidNo] = useState(true);
  const { deviceType } = useSelector((state) => state.DeviceTypeReducer);

  // useEffect(() => {
  //   if (!PLATFORM_DETECT.WEB) {
  //     handleSetCurrentCountry();
  //   }
  // }, []);

  const handleSetCurrentCountry = () => {
    Cellular.getIsoCountryCodeAsync()
      .then((res) => {
        setFlagOfCountry(res?.toUpperCase());
        getCallingCodeInNumber(res?.toUpperCase());
      })
      .catch((err) => console.log(err, "Error in getting country code "));
  };
  const getCallingCodeInNumber = (cc) => {
    getCallingCode(`${cc.toUpperCase()}`)
      .then((code) => setPostalCode(code))
      .catch((err) => console.log(err));
  };
  const _selected = (country) => {
    setFlagOfCountry(country.cca2);
    getCallingCodeInNumber(country.cca2);
  };

  const _phoneValidations = () => {
    const isItAValidNumber = phone(`+${postalCode}${props.value}`, {
      country: flagOfCountry,
    }).isValid;
    setIsValidNo(isItAValidNumber);
  };

  const validate = () => {
    return new Promise((resolve, reject) => {
      const isItAValidNumber = phone(`+${postalCode}${props.value}`, {
        country: flagOfCountry,
      }).isValid;
      setIsValidNo(isItAValidNumber);
      if (isItAValidNumber) {
        resolve(`${postalCode}${props.value}`);
      } else {
        reject(false);
      }
    });
  };

  ref.current = { validate };

  return (
    <View style={styles.mainContainer}>
      <View style={styles.viewOne}>
        <Text style={[styles.txtOne, { color: Colors.darkBluePrimary }]}>
          {defLabel}
        </Text>
      </View>
      <View
        style={[
          styles.viewTwo,
          {
            backgroundColor: props.value ? Colors.screenBgColor : "white",
            borderColor: focus ? "#2674E5" : Colors.txtInputBorderColor,
          },
        ]}
      >
        <TouchableOpacity
          onPress={() => setModalVisible(true)}
          style={styles.flagSurroundingButton}
        >
          <CountryPicker
            withModal
            withFlag
            withFilter
            withCloseButton={true}
            renderFlagButton={(flagProps) =>
              flagOfCountry ? (
                <View
                  style={{
                    width: 22,
                    height: 22,
                    marginLeft: 5,
                    borderRadius: 15,
                    overflow: "hidden",
                    marginLeft: 10,
                  }}
                >
                  <View
                    style={{
                      transform: [
                        { scale: 1.7 },
                        { translateY: -3 },
                        { translateX: -2 },
                      ],
                    }}
                  >
                    <FlagButton countryCode={flagOfCountry} />
                  </View>
                </View>
              ) : (
                <View
                  style={{
                    width: 22,
                    height: 22,
                    backgroundColor: "black",
                    marginLeft: 5,
                    borderRadius: 15,
                  }}
                />
              )
            }
            onSelect={_selected}
            visible={modalVisible}
            onClose={() => setModalVisible(false)}
          />
          <View style={styles.countryCodeView}>
            <Text>{` +${postalCode}`}</Text>
          </View>

          <View style={{ marginLeft: 6 }}>
            <ArrowDownSvg />
          </View>

          <View
            style={{
              width: 1,
              height: "70%",
              backgroundColor: "#D3DAE5",
              marginLeft: 5,
            }}
          />
        </TouchableOpacity>

        <View
          style={{
            width: SW * 0.75,
            height: "100%",
            paddingLeft: 5,
            justifyContent: "center",
          }}
        >
          <TextInput
            onFocus={() => setFocus(true)}
            onBlur={() => {
              _phoneValidations();
              setFocus(false);
            }}
            placeholder={defplaceHolder}
            selectionColor={"#2674E5"}
            keyboardType={"phone-pad"}
            placeholderTextColor={Colors.greyPrimary}
            style={
              Platform.OS === "web"
                ? {
                    // outlineWidth: 0,
                    // borderWidth: 0,
                    // borderRadius: 0,
                    // border: 0,
                    outline: 0,
                    // borderColor: "transparent",
                    // background: "transparent",
                    color: Colors.darkBluePrimary,
                    paddingLeft: 5,
                    fontSize: moderateScale(15, 0.1),
                    fontFamily: Fonts.default,
                    width: deviceType ? "30%" : "100%",
                  }
                : {
                    color: Colors.darkBluePrimary,
                    paddingLeft: 5,
                    fontSize: moderateScale(15, 0.1),
                    fontFamily: Fonts.default,
                    width: deviceType ? "30%" : "100%",
                  }
            }
            value={props.value}
            onChangeText={props.onChangeText}
            returnKeyType="done"
          />
        </View>
      </View>

      {isValidNo ? (
        false
      ) : (
        <Text style={[styles.errorStyle]}>*Not a Valid Number</Text>
      )}
    </View>
  );
});

export default AbstractPhoneTextInput;

const styles = StyleSheet.create({
  mainContainer: {
    height: moderateScale(92, 0.1),
    width: "100%",
  },

  viewOne: {
    width: "100%",
    height: moderateScale(25, 0.1),
  },

  viewTwo: {
    width: "100%",
    height: moderateScale(48, 0.1),
    borderRadius: 8,
    borderWidth: 1,
    borderColor: Colors.txtInputBorderColor,
    flexDirection: "row",
  },

  flagSurroundingButton: {
    alignItems: "center",
    flexDirection: "row",
    zIndex: 1,
    justifyContent: "space-between",
  },

  countryCodeView: {
    height: "38%",
    alignItems: "center",
    flexDirection: "row",
    zIndex: 0,
    justifyContent: "space-between",
    marginBottom: 2,
  },
  errorStyle: {
    fontFamily: Fonts.default,
    fontSize: moderateScale(12.5, 0.1),
    color: "red",
    fontWeight: "600",
    paddingLeft: 10,
  },
  txtOne: {
    fontSize: moderateScale(13, 0.1),
    fontFamily: Fonts.bold,
    fontWeight: "900",
  },
  txtTwo: {
    fontSize: moderateScale(13, 0.1),
    fontFamily: Fonts.bold,
    fontWeight: "900",
  },
});
