import React from 'react';
import SpeedSvg from '../Assets/Icons/FeedCardSvgs/speedSvg';
import AlertSvg from '../Assets/Icons/FeedCardSvgs/alertSvg';
import CompassSvg from '../Assets/Icons/FeedCardSvgs/compassSvg';

export const generateRandomId = () => {
  return `${Math.random() * Math.random() * 1000000000}`
}

export const specViews = [
  {
    id: 0,
    name: '92.1k miles',
    icon: () => <SpeedSvg />,
  },
  {
    id: 1,
    name: '1 Alert',
    icon: () => <AlertSvg />,
  },
  {
    id: 2,
    name: '23 mi',
    icon: () => <CompassSvg />,
  },
];

export const carDetailsData = [
  { labelText: 'Drivetrain', itsDetail: '4WD', type: 'simple' },
  { labelText: 'Interior', itsDetail: 'Medium Earth Gray', type: 'simple' },
  { labelText: 'Exterior', itsDetail: 'Oxford White', type: 'simple' },
  { labelText: 'Transmission', itsDetail: '4WD', type: 'simple' },
  { labelText: 'Engine', itsDetail: '4WD', type: 'simple' },
  { labelText: 'mpgCity', itsDetail: '4WD', type: 'simple' },
  { labelText: 'miles', itsDetail: '4WD', type: 'simple' },
  {
    type: 'expand',
    labelText: 'Overview',
    itsDetail:
      'Like the majority of sales people, I visit a huge number of clients and prospects every month, some end up buying some do not.Like the majority of sales people, I visit a huge number of clients and prospects every month, some end up buying some do not.Like the majority of sales people, I visit a huge number of clients and prospects every month, some end up buying some do not.Like the majority of sales people, I visit a huge number of clients and prospects every month, some end up buying some do not.Like the majority of sales people, I visit a huge number of clients and prospects every month, some end up buying some do not',
  },
];

export const carsCards = [
  {
    id: generateRandomId(),
    carImage: require('../Assets/Images/imageDetails.png'),
    priceRange: '$26,500 - $28,500',
    carName: '2016 Ford F-150 XLT 01',
    disabled: false
  },
  {
    id: generateRandomId(),
    carImage: require('../Assets/Images/imageDetails.png'),
    priceRange: '$26,500 - $28,500',
    carName: 'Corolla XLI 02',
    disabled: true
  },
  {
    id: generateRandomId(),
    carImage: require('../Assets/Images/imageDetails.png'),
    priceRange: '$26,500 - $28,500',
    carName: 'City Aspire 03',
    disabled: false
  },
  {
    id: generateRandomId(),
    carImage: require('../Assets/Images/imageDetails.png'),
    priceRange: '$26,500 - $28,500',
    carName: 'Corolla XLI 04',
    disabled: false
  },
  {
    id: generateRandomId(),
    carImage: require('../Assets/Images/imageDetails.png'),
    priceRange: '$26,500 - $28,500',
    carName: 'Corolla XLI 05',
    disabled: true
  },
  {
    id: generateRandomId(),
    carImage: require('../Assets/Images/imageDetails.png'),
    priceRange: '$26,500 - $28,500',
    carName: 'Corolla XLI 06',
    disabled: false
  },
  {
    id: generateRandomId(),
    carImage: require('../Assets/Images/imageDetails.png'),
    priceRange: '$26,500 - $28,500',
    carName: 'Corolla XLI 07',
    disabled: true
  },
  {
    id: generateRandomId(),
    carImage: require('../Assets/Images/imageDetails.png'),
    priceRange: '$26,500 - $28,500',
    carName: 'Corolla XLI 08',
    disabled: false
  },
  {
    id: generateRandomId(),
    carImage: require('../Assets/Images/imageDetails.png'),
    priceRange: '$26,500 - $28,500',
    carName: 'Corolla XLI 09',
    disabled: true
  },
  {
    id: generateRandomId(),
    carImage: require('../Assets/Images/imageDetails.png'),
    priceRange: '$26,500 - $28,500',
    carName: 'Corolla XLI 10',
    disabled: false
  },

  // {
  //   id: generateRandomId(),
  //   carImage: require('../Assets/Images/imageDetails.png'),
  //   priceRange: '$26,500 - $28,500',
  //   carName: 'Corolla XLI 05',
  //   disabled:false
  // },
  // {
  //   id: generateRandomId(),
  //   carImage: require('../Assets/Images/imageDetails.png'),
  //   priceRange: '$26,500 - $28,500',
  //   carName: 'City Aspire 06',
  //   disabled:false
  // },
  // {
  //   id: generateRandomId(),
  //   carImage: require('../Assets/Images/imageDetails.png'),
  //   priceRange: '$26,500 - $28,500',
  //   carName: 'Corolla XLI 07',
  //   disabled:true
  // },
  // {
  //   id: generateRandomId(),
  //   carImage: require('../Assets/Images/imageDetails.png'),
  //   priceRange: '$26,500 - $28,500',
  //   carName: 'Corolla XLI 08',
  //   disabled:false
  // }
];

export const dummyMessages = [
  {
    id: 0,
    isSender: true,
    type: 'Text',
    messageBody: 'Hello, this is Mike with Ford.',
    date: '24:00',
  },
  {
    id: 1,
    isSender: true,
    type: 'Text',
    messageBody: 'How Are you?',
    date: '24:00',
  },
  {
    id: 2,
    isSender: false,
    type: 'Text',
    messageBody: 'Hello Mike, I’d like to purchase the F150',
    date: '24:00',
  },
  {
    id: 3,
    isSender: false,
    type: 'Text',
    messageBody: 'Sure',
    date: '24:00',
  }
];


export const dummyConflicts = [
  {
    id: 0,
    label: 'The  user in not responing',
    active: false
  },
  {
    id: 1,
    label: 'The user is not interested in purchasing at agreed price',
    active: false
  },
  {
    id: 2,
    label: 'Other',
    active: false
  },
]



export const dummyOfferDetails = [
  {
    _id: 0,
    label: "5 Offers",
    offers: 5,
    price: 4.99
  },
  {
    _id: 1,
    label: "20 Offers",
    offers: 20,
    price: 9.99
  },
  {
    _id: 2,
    label: "50 Offers",
    offers: 50,
    price: 19.99
  },

]






export const makeFilterData = [
  {
    _id: generateRandomId(),
    companyName: "All Makes",
  },
  {
    _id: generateRandomId(),
    companyName: "Ford",
    quantity: 10
  },
  {
    _id: generateRandomId(),
    companyName: "Audi",
    quantity: 20
  },
  {
    _id: generateRandomId(),
    companyName: "Ferrari",
    quantity: 25
  },
  {
    _id: generateRandomId(),
    companyName: "BMW",
    quantity: 21
  },
  {
    _id: generateRandomId(),
    companyName: "KIA",
    quantity: 15
  },

]






export const dummyVehicleForBid = {
  KMDriven: 49383,
  VIN: "621630b645cea7dfec1f82a5",
  __v: 0,
  _id: "63978d81f34683c9f30cec22",
  businessId: null,
  coords: [],
  description: "High Performent Vehicle",
  driveTrain: "RWD",
  driveType: "EQC 400 4MATIC",
  enabledStatus: 1,
  engine: "In-Line Engine",
  exterior: "Gray",
  fuleType: "Petrol",
  highestOfferPlaced: 32680,
  interior: "Ambient light",
  location: {
    address: "",
    coordinates: [0, 0],
    coords: { "latitude": 0, "longitude": 0 }, "type": "Point"
  },
  madeIn: "German",
  make: "Mercedes",
  mileageCity: 23,
  mileageHighway: 29,
  modal: "2010",
  numberOfOffers: 6,
  pictures: [{_id: "63978d81f34683c9f30cec23", avatar: {key: "liam-1670876545581.png", url: "https://inforide-buckets-test.s3.us-west-1.amazonaws.com/liam-1670876545581.png"}, image: {key: "liam-martens-uUi5RQCyUdM-unsplash-1670876540778.jpg", url: "https://inforide-buckets-test.s3.amazonaws.com/liam-martens-uUi5RQCyUdM-unsplash-1670876540778.jpg"}}],
  priceRange: { "max": 35000, "min": 29000 },
  style: "Stylish",
  title: "GRAY MERCEDES",
  transmission: "Auto",
  trimLevel: "Sport",
  vehicleSellingStatus: "display",
  vehicleType: "Sedans",
  year: 2010
}



const topHeaderCarDetails = [{
  _id:0,
  label:"Details",
  active:"true"
}]