import {
  StyleSheet,
  Text,
  View,
  Animated,
  TouchableOpacity,
  Platform,
} from "react-native";
import React, { useEffect, useRef, useState } from "react";
import { Colors } from "../../themes";
import SearchIconSvg from "../../Assets/Icons/searchIconSvg";
import GooglePlacesInput from "../AbstractComponents/googleAutoPlacesApi";
import ArrowForwardSvg from "../../Assets/Icons/arrowForwardSvg";
import CrossSvg from "../../Assets/Icons/crossSvg";
import AuctionArrowForwardSvg from "../../Assets/Icons/auctionArrowForwardSvg";
import { useSelector } from "react-redux";

const NORMAL_WIDTH = 30;
const EXPANDED_WIDTH_MOBILE = Platform.OS == "web" ? 350 : 240;
const TEXT_INPUT_EXPANDABLE_WIDTH = EXPANDED_WIDTH_MOBILE - 30;
const BAR_HEIGHT = 30;

const ExpandableSearchBar = ({ onSearchAuction, resetFilter }) => {
  const [touchBar, setTouchBar] = useState(false);
  const [visibleTypingBar, setVisibleTypingBar] = useState(false);
  const animatedWidthValue = useRef(new Animated.Value(NORMAL_WIDTH)).current;
  const animatedTextInputWidthValue = useRef(new Animated.Value(0)).current;
  const { deviceType } = useSelector((state) => state.DeviceTypeReducer);
  const [locationValue, setLocationValue] = useState("");

  const expandIt = () => {
    setTimeout(
      () => {
        setVisibleTypingBar(true);
      },
      deviceType ? 300 : 0
    );
    Animated.parallel([
      Animated.timing(animatedWidthValue, {
        toValue: EXPANDED_WIDTH_MOBILE,
        duration: 400,
        useNativeDriver: false,
      }),
      Animated.timing(animatedTextInputWidthValue, {
        toValue: TEXT_INPUT_EXPANDABLE_WIDTH,
        duration: 400,
        useNativeDriver: false,
      }),
    ]).start();
  };

  const backToNormal = () => {
    setVisibleTypingBar(false);
    Animated.parallel([
      Animated.timing(animatedWidthValue, {
        toValue: NORMAL_WIDTH,
        duration: 400,
        useNativeDriver: false,
      }),
      Animated.timing(animatedTextInputWidthValue, {
        toValue: 0,
        duration: 400,
        useNativeDriver: false,
      }),
    ]).start();
  };

  const onPressBar = () => {
    setTouchBar((prev) => !prev);
    if (touchBar) {
      backToNormal();
    } else {
      expandIt();
    }
  };

  const onCrossPress = () => {
    setLocationValue("");
    resetFilter();
  };

  const opacityInterpolate = animatedTextInputWidthValue.interpolate({
    inputRange: [0, TEXT_INPUT_EXPANDABLE_WIDTH],
    outputRange: [0, 1],
    extrapolate: "clamp",
  });

  return (
    <TouchableOpacity
      activeOpacity={0.8}
      onPress={() => onPressBar()}
      style={{ alignItems: "flex-end", width: 200 }}
    >
      <Animated.View
        onPress={() => console.log("yes")}
        style={[
          styles.mainContainer,
          {
            width: animatedWidthValue,
            backgroundColor: visibleTypingBar
              ? Colors.whitePrimary
              : Colors.blueprimary,
          },
        ]}
      >
        <View style={{ paddingLeft: visibleTypingBar ? 10 : 5 }}>
          {visibleTypingBar ? (
            <AuctionArrowForwardSvg size={15} />
          ) : (
            <SearchIconSvg
              color={visibleTypingBar ? Colors.blueprimary : "white"}
            />
          )}
        </View>
        <Animated.View
          style={{
            width: animatedTextInputWidthValue,
            height: BAR_HEIGHT,
            opacity: opacityInterpolate,
          }}
        >
          {visibleTypingBar ? (
            <GooglePlacesInput
              width={EXPANDED_WIDTH_MOBILE}
              onSearchAuction={onSearchAuction}
              setLocationValue={setLocationValue}
              locationValue={locationValue}
              BAR_HEIGHT={BAR_HEIGHT}
            />
          ) : (
            false
          )}
        </Animated.View>
        <TouchableOpacity
          onPress={onCrossPress}
          style={{ position: "absolute", right: 10 }}
        >
          {locationValue && <CrossSvg size={11} color="lightgrey" />}
        </TouchableOpacity>
      </Animated.View>
    </TouchableOpacity>
  );
};

export default ExpandableSearchBar;

const styles = StyleSheet.create({
  mainContainer: {
    width: NORMAL_WIDTH,
    height: 30,
    backgroundColor: Colors.blueprimary,
    // justifyContent: "center",
    alignItems: "center",
    borderRadius: 30,
    flexDirection: "row",
  },
});
