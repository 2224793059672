import React from "react";
import Svg, { Path } from "react-native-svg";

const CrossSvg = ({ color, size }) => {
  const defColor = color ? color : "black";
  const defSize = size ? size : 24;

  return (
    <Svg
      width={defSize}
      height={defSize}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path
        d="M23.0253 0.690447C22.3428 0.00794718 21.2403 0.00794718 20.5578 0.690447L12.0003 9.23045L3.44275 0.672947C2.76025 -0.00955322 1.65775 -0.00955322 0.975254 0.672947C0.292754 1.35545 0.292754 2.45795 0.975254 3.14045L9.53275 11.6979L0.975254 20.2554C0.292754 20.9379 0.292754 22.0404 0.975254 22.7229C1.65775 23.4054 2.76025 23.4054 3.44275 22.7229L12.0003 14.1654L20.5578 22.7229C21.2403 23.4054 22.3428 23.4054 23.0253 22.7229C23.7078 22.0404 23.7078 20.9379 23.0253 20.2554L14.4678 11.6979L23.0253 3.14045C23.6903 2.47545 23.6903 1.35545 23.0253 0.690447Z"
        fill={defColor}
      />
    </Svg>
  );
};

export default CrossSvg;
