import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  View,
  ActivityIndicator,
  Keyboard,
  FlatList,
  Text,
  Dimensions,
} from "react-native";
import ScreenContainer from "../../Components/AbstractComponents/abstractScreenContainer";
import FocusAwareStatusBar from "../../Components/AbstractComponents/statusbarConfiguration";
import { Colors, PLATFORM_DETECT } from "../../themes";
import ChatHeader from "../../Components/ModuleComponents/chatHeader";
import ChatFooter from "../../Components/ModuleComponents/chatFooter";
import { goBack } from "../../Navigation/mainNavigation";
import MyMessage from "../../Components/ModuleComponents/myMessage";
import MessgaeBody from "../../Components/ModuleComponents/myMessage/messgaeBody";
import ConversationsController, {
  useSingleConversation,
} from "../../Controllers/conversationsController";
import AuthController from "../../Controllers/authController";
import MessageController from "../../Controllers/messageController";
const { height } = Dimensions.get("screen");

const ChatScreen = (props) => {
  const { offer, offerType } = props.route.params;
  const { conversation, messages, loadSingleConversation } =
    useSingleConversation(offer._id);
  const currentUser = AuthController.getCurrentUser();
  console.log(offer._id);
  const handleResendMessage = (message) => {
    new MessageController(
      message.body,
      conversation._id,
      conversation.business._id,
      message._id
    ).reSend();
  };

  useEffect(() => {
    if (conversation) {
      ConversationsController.handleMarkSeen(conversation._id);
    } else {
      ConversationsController.loadConversations(() => {
        loadSingleConversation();
      });
    }
  }, [conversation]);

  if (!conversation) {
    return (
      <View
        style={[
          StyleSheet.absoluteFillObject,
          {
            backgroundColor: "rgba(0,0,0,0.2)",
            zIndex: 9999,
            justifyContent: "center",
            alignItems: "center",
          },
        ]}
      >
        <ActivityIndicator color={"white"} size="large" />
      </View>
    );
  }

  return (
    <ScreenContainer
      upperStyle={{ backgroundColor: Colors.screenBgColor }}
      bottomStyle={{ backgroundColor: Colors.screenBgColor }}
    >
      <View style={styles.mainContainer}>
        <View
          style={{
            width: "100%",
          }}
        >
          <FocusAwareStatusBar
            barStyle="dark-content"
            backgroundColor={"transparent"}
            translucent={true}
          />

          <ChatHeader
            offer={offer}
            conversation={conversation}
            offerType={offerType}
            onPressBack={() => {
              Keyboard.dismiss();
              goBack();
            }}
          />
        </View>
        <View
          style={{
            flex: 1,
          }}
        >
          <FlatList
            data={messages}
            showsVerticalScrollIndicator={false}
            style={{
              height: height / 3,
            }}
            inverted
            renderItem={({ item, index }) => (
              <MyMessage
                key={item._id}
                message={item}
                currentUser={currentUser}
                onResend={handleResendMessage}
              >
                <MessgaeBody message={item} />
              </MyMessage>
            )}
            keyExtractor={(item) => item._id}
          />
        </View>
        <ChatFooter conversation={conversation} />
      </View>
    </ScreenContainer>
  );
};

export default ChatScreen;

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: Colors.screenBgColor,
    overflow: "hidden",
  },
});
