import React from "react";
import Svg, { Path } from "react-native-svg";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";

const CompassSvg = () => {
  return (
    <Svg
      width="14"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path
        d="M8 7.175C7.5425 7.175 7.175 7.5425 7.175 8C7.175 8.4575 7.5425 8.825 8 8.825C8.4575 8.825 8.825 8.4575 8.825 8C8.825 7.5425 8.4575 7.175 8 7.175ZM8 0.5C3.86 0.5 0.5 3.86 0.5 8C0.5 12.14 3.86 15.5 8 15.5C12.14 15.5 15.5 12.14 15.5 8C15.5 3.86 12.14 0.5 8 0.5ZM9.6425 9.6425L3.5 12.5L6.3575 6.3575L12.5 3.5L9.6425 9.6425Z"
        fill="white"
      />
    </Svg>
  );
};

export default CompassSvg;
