import React from "react";
import { ActivityIndicator, Text, TouchableOpacity, View } from "react-native";
import { moderateScale } from "react-native-size-matters";
import { Colors, Fonts } from "../../themes";

const AbstractButton = ({
  width,
  height,
  icon,
  iconLeft,
  bgcolor,
  label,
  txtSize,
  txtColor,
  onPress,
  BorderRad,
  withSadow,
  type,
  processing,
  disabled,
  borderStyle,
}) => {
  const defaultHeight = height ? height : moderateScale(55, 0.1);
  const defaultWidth = width ? width : "100%";
  const defaultColor = bgcolor ? bgcolor : Colors.blueprimary;
  const defaultLabel = label ? label : "TextHere";
  const defaultLabelTextSize = txtSize ? txtSize : moderateScale(17, 0.1);
  const defaultLabelTextColor = txtColor ? txtColor : Colors.whitePrimary;
  const defaultIcon = icon ? icon : undefined;
  const defaultIconLeft = iconLeft ? iconLeft : undefined;
  const defBorderRad = BorderRad ? BorderRad : 8;

  const btnType = {
    transparent: {
      backgroundColor: "transparent",
      borderWidth: 1,
      borderColor: Colors.whitePrimary,
    },
    normal: {
      backgroundColor: defaultColor,
    },
    transparentBlue: {
      backgroundColor: "transparent",
      borderWidth: 1,
      borderColor: Colors.blueprimary,
    },
  };

  const typeChecker = (typeHere) => {
    switch (typeHere) {
      case "transparent":
        return btnType.transparent;
        break;
      case "normal":
        return btnType.normal;
        break;
      case "transparentBlue":
        return btnType.transparentBlue;
        break;
      default:
        btnType.normal;
    }
  };

  if (processing) {
    return (
      <TouchableOpacity
        onPress={onPress}
        disabled
        style={[
          {
            width: defaultWidth,
            height: defaultHeight,
            backgroundColor: defaultColor,
            justifyContent: "center",
            borderRadius: defBorderRad,
            alignItems: "center",
            flexDirection: "row",
            ...borderStyle,
          },
          withSadow
            ? {
                shadowColor: Colors.blueprimary,
                shadowOffset: {
                  width: 0,
                  height: 12,
                },
                shadowOpacity: 0.58,
                shadowRadius: 16.84,

                elevation: 5,
                ...borderStyle,
              }
            : {},
          typeChecker(type),
        ]}
      >
        <ActivityIndicator size={"small"} color={"white"} />
      </TouchableOpacity>
    );
  } else {
    return (
      <TouchableOpacity
        onPress={onPress}
        disabled={processing || disabled}
        style={[
          {
            width: defaultWidth,
            height: defaultHeight,
            backgroundColor: defaultColor,
            justifyContent: "center",
            borderRadius: defBorderRad,
            alignItems: "center",
            flexDirection: "row",
            ...borderStyle,
          },
          withSadow
            ? {
                shadowColor: Colors.blueprimary,
                shadowOffset: {
                  width: 0,
                  height: 12,
                },
                shadowOpacity: 0.58,
                shadowRadius: 16.84,

                elevation: 5,
                ...borderStyle,
              }
            : {},
          typeChecker(type),
        ]}
      >
        {iconLeft ? (
          <View style={{ marginRight: 10 }}>{defaultIconLeft()}</View>
        ) : (
          <View></View>
        )}

        <Text
          style={{
            fontSize: defaultLabelTextSize,
            color: defaultLabelTextColor,
            textAlignVertical: "center",
            fontFamily: Fonts.default,
            // paddingHorizontal: 3,
            textAlign: "center",
          }}
        >
          {defaultLabel}
        </Text>

        {icon ? (
          <View style={{ marginLeft: 10 }}>{defaultIcon()}</View>
        ) : (
          <View></View>
        )}
      </TouchableOpacity>
    );
  }
};

export default AbstractButton;
