import { StyleSheet, Text, TextInput, TouchableOpacity, View } from 'react-native';
import React,{useState} from 'react';
import CheckMarkSvg from '../../../Assets/Icons/checkMarkSvg';
import { moderateScale } from 'react-native-size-matters';
import { Fonts } from '../../../themes';
import AbstractButton from '../../AbstractComponents/abstractButton';

const Conflicts = ({item,detail,onPress,checked}) => {

    let color = "black"
    if(item.type === "conflict"){
        if(item.active){
            color = 'red';
        }else{
            color = "black";
        }
    }else if(item.type === "info"){
        if(item.active){
            color = 'green';
        }
    }

    return (
        <React.Fragment>
            <TouchableOpacity
            onPress={onPress}
            style={styles.conflictView}>
                <View>
                    <CheckMarkSvg color={color} />
                </View>
                <View style={{paddingLeft:10}}>
                    <Text style={styles.textOne}>{item.label}</Text>
                </View>
            </TouchableOpacity>
        </React.Fragment>
    )
}


const options = [
    {
      _id:0,
      label:'Close deal successfully.',
      type: "info",
      active:true
    },
    {
      _id:1,
      label:'Create conflict.',
      type: "conflict",
      active:false
    }
  ]



const CloseDealSheet = ({onSubmit, processing}) => {
    const [selectConflict,setSelectConflict] = useState(options)
    const handleSelect = (itemId) => {
        const opts = [...selectConflict.map(item => ({...item, active: item._id === itemId}))];
        setSelectConflict(opts);
    }

    const handleSubmit = () => {
        onSubmit(false ,selectConflict[1].active);
    }

    return (
        <View>
            <View style={{marginTop:20}}>
                {selectConflict.map(item => <Conflicts key={item._id} item={item} onPress={()=>handleSelect(item._id)}   />)}
            </View>
            <View style={{marginBottom:35, marginTop: 35}}>
                <AbstractButton processing={processing} onPress={handleSubmit} label={'Submit'} />
            </View>
        </View>
    );
};

export default CloseDealSheet;

const styles = StyleSheet.create({
conflictView:{
    width:'100%',
    flexDirection:'row',
    alignItems:'center',
    marginVertical:10
},
textOne:{
    fontSize:moderateScale(17,0.1),
    fontFamily:Fonts.medium,
    color:'black'
},
textTwo:{
    fontSize:moderateScale(21,0.1),
    fontFamily:Fonts.medium,
    color:'black'
},
textAreaView:{
    width:'100%',
    height:moderateScale(205,0.1),
    borderRadius:20,
    borderWidth:1,
    borderColor:'#C2C2C2',
    marginVertical:20
}
});
