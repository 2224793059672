import React, { useEffect, useRef, useState } from "react";
import {
  View,
  FlatList,
  Text,
  Dimensions,
  StyleSheet,
  Pressable,
} from "react-native";
import { formatCurrency } from "react-native-format-currency";
import { PLATFORM_DETECT } from "../../../themes";

const SW = Dimensions.get("window").width;
const PICKER_WIDTH = SW * 0.9;

const PICKER_WEIGHT = 100;
const PICKER_PIN_WIDTH = 15;
const STARTING_OFFSET = PICKER_WIDTH / 2;
const UNIT_VALUE = PICKER_WEIGHT / PICKER_PIN_WIDTH;
const toOffset = (currentOffset) => {
  return currentOffset;
};

const currentOffsetToValue = (minValue, currentOffset) => {
  return Math.round(minValue + toOffset(currentOffset) * UNIT_VALUE);
};

const valueToCurrentOffset = (minValue, targetValue) => {
  const calc = (targetValue - minValue) / UNIT_VALUE;
  return calc;
};

const indexToValue = (minValue, currentIndex) => {
  return minValue + currentIndex * PICKER_WEIGHT;
};

const PRICE_DELTA = 1000;
const PriceItem = ({ value, index, length, minRange, onPress }) => {
  let firstOrLast = index === 0 || index + 1 === length;
  return (
    <Pressable
      cur
      onPress={() => onPress(value, index)}
      style={{
        height: 70,
        width: firstOrLast ? STARTING_OFFSET : PICKER_PIN_WIDTH,
        justifyContent: "flex-end",
        alignItems: "center",
      }}
    >
      {index % 5 ? (
        false
      ) : !firstOrLast ? (
        <Text
          selectable={false}
          style={{
            color: "white",
            position: "absolute",
            top: 0,
            zIndex: 999999,
            width: 70,
            textAlign: "center",
          }}
        >
          {formatCurrency({ amount: value, code: "USD" })[0]}
        </Text>
      ) : (
        false
      )}
      {firstOrLast ? (
        <View
          style={{
            transform: [{ translateX: PICKER_PIN_WIDTH / 2 - 1 }],
            height: index % 5 ? 30 : 50,
            width: STARTING_OFFSET,
            backgroundColor: "transparent",
          }}
        />
      ) : (
        <View
          style={{
            transform: [{ translateX: PICKER_PIN_WIDTH / 2 - 1 }],
            height: index % 5 ? 30 : 50,
            width: 2.5,
            backgroundColor: "#8D8D8D",
          }}
        />
      )}
    </Pressable>
  );
};

const PriceRangePicker = ({
  minRange,
  maxRange,
  onChange,
  initialValue,
  swipperLoading,
  setSwipperLoading,
}) => {
  const [data, setData] = useState([]);
  const [value, setValue] = useState(0);

  const listRef = useRef();
  const _min = minRange ? minRange : 2000;
  const _max = maxRange ? maxRange : 10000;
  let min = 2000;
  let max = 10000;
  if (_min - PRICE_DELTA >= 0) {
    min = _min - PRICE_DELTA;
  } else {
    min = 0;
  }
  max = _max + PRICE_DELTA;

  useEffect(() => {
    setTimeout(() => {
      let d = [];
      let i = 0;
      let iterations = max - min;
      let dumyArr = [...Array(Math.floor(iterations / PICKER_WEIGHT)).keys()];
      d = dumyArr.map((i) => ({ index: i, value: indexToValue(min, i) }));
      if (setSwipperLoading)
        setTimeout(() => {
          setSwipperLoading();
        }, 1000);
      setData(d);
    }, 300);
  }, []);

  useEffect(() => {
    if (data.length) listRef?.current.scrollToIndex({ index: 0 });
    handleChange();
    if (initialValue > min) {
      setTimeout(() => {
        listRef?.current.scrollToOffset({
          offset: valueToCurrentOffset(min, initialValue),
        });
      }, 500);
    } else {
      setValue(min);
    }
  }, [data]);

  const handleScroll = ({ nativeEvent }) => {
    setValue(currentOffsetToValue(min, nativeEvent.contentOffset.x));
  };

  const handleChange = (val) => {
    if (onChange) onChange(val ? val : value);
  };
  const handlePressItem = (_value, index) => {
    setTimeout(() => {
      setValue(_value);
      handleChange(_value);
    }, 300);
    listRef?.current.scrollToIndex({ index });
  };

  useEffect(() => {
    // if (PLATFORM_DETECT.WEB) {
    setTimeout(() => {
      handleChange(value);
    }, 300);
    // }
  }, [value]);

  return (
    <View style={{ width: PICKER_WIDTH, alignSelf: "center" }}>
      <Text
        selectable={false}
        style={{
          alignSelf: "center",
          marginBottom: 20,
          fontSize: 40,
          color: "#e5e5e5",
        }}
      >
        {formatCurrency({ amount: value, code: "USD" })[0]}
      </Text>
      <FlatList
        ref={listRef}
        showsHorizontalScrollIndicator={false}
        horizontal
        decelerationRate={0.99}
        data={data}
        onScrollEndDrag={() => handleChange(value)}
        onMomentumScrollEnd={() => handleChange(value)}
        onScroll={handleScroll}
        renderItem={({ item, index }) => (
          <PriceItem
            onPress={handlePressItem}
            {...item}
            {...{ length: data.length, minRange, maxRange }}
          />
        )}
        keyExtractor={(item) => item.value}
        getItemLayout={(d, index) => ({
          length: index === 0 ? STARTING_OFFSET : PICKER_PIN_WIDTH,
          offset: PICKER_PIN_WIDTH * index,
          index,
        })}
      />
      <View
        style={{
          width: 2.5,
          height: 80,
          backgroundColor: "#00D387",
          position: "absolute",
          bottom: 0,
          transform: [{ translateX: -1 }],
          alignSelf: "center",
        }}
      />

      {swipperLoading ? (
        <View
          style={[
            StyleSheet.absoluteFillObject,
            {
              backgroundColor: "rgb(28,42,74)",
              zIndex: 9999,
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 8,
              marginVertical: -5,
            },
          ]}
        ></View>
      ) : (
        false
      )}
    </View>
  );
};
export default PriceRangePicker;
