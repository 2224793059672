import React from "react";
import { StyleSheet, Text, View, TouchableOpacity } from "react-native";
import { moderateScale } from "react-native-size-matters";
import { Colors, Fonts } from "../../themes";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import { useSelector } from "react-redux";
import { getFirstImage } from "../../Controllers/vehiclesController";
import ImagePlacer from "./imagePlacer";
import DateFormatter from "../../Utils/dateFormatter";
const convertDate = (date_) => {
  return new Date(date_).toLocaleString();
};
const MyBidItem = ({ bid, onPress }) => {
  const { deviceType } = useSelector((state) => state.DeviceTypeReducer);
  const { vehicle, isExpired } = bid;

  // console.log(bid?.createdAt);
  return (
    <TouchableOpacity onPress={onPress}>
      <View
        style={[
          styles.mainContainer,
          {
            backgroundColor: Colors.screenBgColor,
            marginBottom: 20,
          },
        ]}
      >
        <View
          style={[
            styles.viewOne,
            {
              paddingHorizontal: deviceType ? 20 : 10,
            },
          ]}
        >
          <View
            style={[
              styles.imgView,
              {
                width: moderateScale(deviceType ? 130 : 72, 0.1),
                height: moderateScale(deviceType ? 100 : 55, 0.1),
                marginVertical: deviceType ? 10 : 10,
              },
            ]}
          >
            <View style={{ ...StyleSheet.absoluteFillObject }}>
              <ImagePlacer
                imageUrl={getFirstImage(vehicle?.pictures).image.url}
                avatarUrl={getFirstImage(vehicle?.pictures).avatar.url}
                type={"small"}
              />
              {isExpired && (
                <View
                  style={{
                    position: "absolute",
                    backgroundColor: "rgba(0,0,0,0.6)",
                    padding: 3,
                    margin: 3,
                    borderRadius: 5,
                  }}
                >
                  <Text
                    style={{
                      color: "white",
                      fontSize: 10,
                      fontFamily: Fonts.default,
                    }}
                  >
                    Expired
                  </Text>
                </View>
              )}
            </View>
          </View>

          <View
            style={{
              width: "55%",
              flex: 1,
              paddingHorizontal: 10,
              justifyContent: "center",
            }}
          >
            <View>
              <Text
                numberOfLines={2}
                style={[
                  styles.txtTwo,
                  {
                    color: "#16478E",
                    fontSize: deviceType ? 20 : wp("4.2%"),
                  },
                ]}
              >
                {vehicle?.title}
              </Text>
            </View>
            <View style={{ paddingTop: 3 }}>
              <Text
                style={[
                  styles.txtThree,
                  {
                    color: "#030303",
                    fontSize: deviceType ? 20 : wp("3.5%"),
                  },
                ]}
              >
                {`$${bid?.bidAmount}`}
              </Text>
            </View>
            <View style={{ alignSelf: "flex-end" }}>
              <Text
                style={[
                  styles.txtThree,
                  {
                    color: "#8D8D8D",
                    fontSize: deviceType ? 15 : wp("2.8%"),
                  },
                ]}
              >
                {DateFormatter.formatDateWithUSAFormat(bid?.createdAt)}
              </Text>
            </View>
          </View>
        </View>
      </View>
    </TouchableOpacity>
  );
};

export default MyBidItem;

const styles = StyleSheet.create({
  mainContainer: {
    width: "100%",
    alignSelf: "center",
    borderRadius: 15,
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 1,
    borderColor: "#e5e5e5",
    // shadowColor: "#000",
    // shadowOffset: {
    //   width: 0,
    //   height: 1,
    // },
    // shadowOpacity: 0.18,
    // shadowRadius: 1.0,
    // elevation: 1,

    marginTop: 1,
  },
  viewOne: {
    width: "100%",
    flexDirection: "row",
    // backgroundColor: "red",
    // alignItems: "center",
  },
  imgView: {
    borderRadius: 11,
  },
  deleteBtnView: {
    width: moderateScale(30, 0.1),
    height: moderateScale(21, 0.1),
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Colors.pinkPrimary,
    borderRadius: 5,
  },
  editBtnView: {
    width: moderateScale(56, 0.1),
    height: moderateScale(21, 0.1),
    backgroundColor: "#D4E3FA",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    borderRadius: 5,
  },
  txtOne: {
    color: Colors.blueSecondaryOne,
    fontSize: moderateScale(11, 0.1),
    paddingLeft: 5,
    fontFamily: Fonts.default,
  },
  txtTwo: {
    color: "#16478E",

    fontWeight: "700",
    fontFamily: Fonts.default,
  },
  txtThree: {
    color: "#030303",

    fontWeight: "700",
    fontFamily: Fonts.default,
  },
  newBtnsView: {
    width: 25,
    height: 25,
    backgroundColor: "#D4E3FA",
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  textThree: {
    fontFamily: Fonts.bold,
    color: Colors.blackPrimary,
    fontSize: moderateScale(19, 0.1),
    paddingBottom: 10,
  },
});
