import React, { useEffect } from "react";
import { StyleSheet, View, Image, Dimensions, Linking } from "react-native";
import { moderateScale } from "react-native-size-matters";
import LogoSvg from "../../Assets/Icons/logoSvg";
import AbstractButton from "../../Components/AbstractComponents/abstractButton";
import AbstractH1 from "../../Components/AbstractComponents/AbstractHeadings/abstractH1";
import { navigate } from "../../Navigation/mainNavigation";
import FocusAwareStatusBar from "../../Components/AbstractComponents/statusbarConfiguration";
import ScreenContainer from "../../Components/AbstractComponents/abstractScreenContainer";
// import changeNavigationBarColor from "react-native-navigation-bar-color";
import { PLATFORM_DETECT } from "../../themes";
import AuthController from "../../Controllers/authController";
const SW = Dimensions.get("window").width;
const SH = Dimensions.get("window").height;

const WelcomeScreen = () => {
  useEffect(() => {
    // changeNavigationBarColor("black");
    console.log(AuthController.isLinkAvailAble, "available");
    // window.location.replace(AuthController.isLinkAvailAble);
  }, []);

  const _handleGetStarted = () => {
    navigate("SignIn");
  };

  return (
    <ScreenContainer
      upperStyle={{ backgroundColor: "black" }}
      bottomStyle={{ backgroundColor: "black" }}
    >
      <View style={styles.mainContainer}>
        <FocusAwareStatusBar
          translucent={true}
          backgroundColor={"transparent"}
          barStyle="light-content"
        />
        <View style={{ ...StyleSheet.absoluteFillObject }}>
          <View style={{ flex: 1 }}>
            <Image
              resizeMode="stretch"
              source={require("../../Assets/Images/bgImage.png")}
              style={{ width: "100%", height: "100%" }}
            />
          </View>
        </View>

        <View style={{ ...StyleSheet.absoluteFillObject }}>
          <View
            style={{
              justifyContent: "flex-end",
              alignItems: "center",
              flex: 1,
              marginBottom: 30,
            }}
          >
            <View
              style={{
                width: "90%",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: 20,
                paddingBottom: 10,
              }}
            >
              <View>
                <LogoSvg />
              </View>

              <View style={{ width: "70%", marginBottom: 10 }}>
                <AbstractH1>Welcome to InfoRide</AbstractH1>
              </View>

              <View style={{ flexDirection: "row", alignSelf: "center" }}>
                <AbstractButton
                  onPress={_handleGetStarted}
                  height={PLATFORM_DETECT.WEB ? 60 : SW * 0.32 * 0.36}
                  BorderRad={50}
                  txtSize={PLATFORM_DETECT.WEB ? 30 : moderateScale(21, 0.1)}
                  width={PLATFORM_DETECT.WEB ? 200 : SW * 0.4}
                  label={"Get Started"}
                />
              </View>
            </View>
          </View>
        </View>
      </View>
    </ScreenContainer>
  );
};

export default WelcomeScreen;

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
  },
});
