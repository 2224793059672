import React from 'react';
import {SvgXml} from 'react-native-svg';

const DropDownArrowSvg = () => {
  return (
    <SvgXml
      xml={`<svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.495471 2.60642L4.82387 6.55416C5.47563 7.14861 6.52849 7.14861 7.18025 6.55416L11.5086 2.60642C12.5615 1.64616 11.8095 0 10.3221 0H1.66531C0.177944 0 -0.557382 1.64616 0.495471 2.60642Z" fill="black"/>
      </svg>      
       `}
    />
  );
};

export default DropDownArrowSvg;
