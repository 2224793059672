import React from "react";
import {
  StyleSheet,
  Text,
  View,
  Dimensions,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import TagSvg from "../../Assets/Icons/FeedCardSvgs/tagSvg";
import DislikeSvg from "../../Assets/Icons/FeedCardSvgs/dislikeSvg";
import LikeSvg from "../../Assets/Icons/FeedCardSvgs/likeSvg";
import DetailsSvg from "../../Assets/Icons/FeedCardSvgs/detailsSvg";
import { Colors, Fonts, PLATFORM_DETECT } from "../../themes";
import { specViews } from "../../../src/Utils/Moc";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import ImagePlacer from "./imagePlacer";
import SpeedSvg from "../../Assets/Icons/FeedCardSvgs/speedSvg";
import CompassSvg from "../../Assets/Icons/FeedCardSvgs/compassSvg";
import { formatCurrencyToUSD } from "../../../src/Utils/common";
import { getFirstImage } from "../../Controllers/vehiclesController";
import { useSelector } from "react-redux";
import { moderateScale } from "react-native-size-matters";
import useIsMobileBrowser from "../../../src/Utils/useIsMobileBrowser";

const SW = Dimensions.get("window").width;
const SH = Dimensions.get("window").height;

export const VEHICLE_CARD_UPPER_PART_WIDTH = SW * (350 / 414);
export const VEHICLE_CARD_UPPER_PART_HEIGHT =
  VEHICLE_CARD_UPPER_PART_WIDTH * (248 / 350);

export const VEHICLE_CARD_LOWER_PART_WIDTH = SW * (350 / 414);
export const VEHICLE_CARD_LOWER_PART_HEIGHT =
  VEHICLE_CARD_LOWER_PART_WIDTH * (284 / 350);

const SpecItem = ({ icon, label, myStyle }) => {
  return (
    <View style={[styles.conatinerView]}>
      <View style={[styles.specView, { ...myStyle }]}>
        <View style={{ paddingLeft: 6, paddingRight: 5, paddingVertical: 6.5 }}>
          {icon ? icon() : null}
        </View>
        <View style={{ paddingRight: 7 }}>
          <Text selectable={false} style={styles.textThree}>
            {label}
          </Text>
        </View>
      </View>
    </View>
  );
};

const DetailItem = ({ priceValue, title, textOpacityControl }) => {
  const defaultPrice = priceValue ? priceValue : "$0.00";
  const defaultTitle = title ? title : "(text)";

  return (
    <View
      style={{ flexDirection: "row", alignItems: "center", marginVertical: 5 }}
    >
      <View style={{ marginRight: 10 }}>
        <Text selectable={false} style={styles.priceText}>
          {defaultPrice}
        </Text>
      </View>

      <View style={{ flex: 1 }}>
        <Text
          numberOfLines={1}
          adjustsFontSizeToFit
          selectable={false}
          style={[
            styles.detailText,
            {
              color: textOpacityControl
                ? "rgba(255, 255, 255, 0.6)"
                : Colors.whitePrimary,
            },
          ]}
        >
          {defaultTitle}
        </Text>
      </View>
    </View>
  );
};
const NewDesignFeedItem = ({
  onPressDislike,
  onPressDetails,
  item,
  onPressLike,
}) => {
  const { deviceType } = useSelector((state) => state.DeviceTypeReducer);
  const CARD_WIDTH = deviceType ? 340 : 310;

  const { loading, vehicles, isLocationPermissionGranted } = useSelector(
    (state) => ({
      loading: state.VehiclesReducer.loadingVehicles,
      vehicles: state.VehiclesReducer.vehicles,
      isLocationPermissionGranted:
        state.VehiclesReducer.isLocationPermissionGranted,
    })
  );
  // console.log(
  //   getFirstImage(item?.vehicle?.pictures).avatar.url,
  //   "AVATAR... suggest"
  // );
  // console.log(getFirstImage(item?.pictures).avatar?.url, "AVATAR...");
  return (
    <View
      style={[
        styles.mainContainer,
        {
          // minWidth: deviceType ? wp("30%") : wp("90%"),
          // height: deviceType ? hp("65.5%") : hp("61.5%"),
          width: CARD_WIDTH,
        },
      ]}
    >
      <View
        style={{
          shadowColor: "#000",
          shadowOffset: {
            width: 0,
            height: 12,
          },
          shadowOpacity: 0.32,
          shadowRadius: 5.95,
          elevation: 10,
          borderRadius: 25,
        }}
      >
        <View
          style={[
            styles.viewOne,

            {
              width: "100%",
              height:
                PLATFORM_DETECT.WEB && !deviceType
                  ? 220
                  : PLATFORM_DETECT.WEB
                  ? 248
                  : VEHICLE_CARD_UPPER_PART_HEIGHT,
            },
          ]}
        >
          <ImagePlacer
            avatarUrl={getFirstImage(item?.pictures).avatar?.url}
            imageUrl={getFirstImage(item?.pictures).image?.url}
            type={"large"}
          />
        </View>

        <View
          style={[
            styles.viewTwo,
            {
              paddingTop: 13,
              height:
                PLATFORM_DETECT.WEB && !deviceType
                  ? 250
                  : PLATFORM_DETECT.WEB
                  ? 250
                  : VEHICLE_CARD_LOWER_PART_HEIGHT,
            },
          ]}
        >
          <View style={styles.viewThreeWeb}>
            <View>
              <View style={{ paddingVertical: 5 }}>
                <Text
                  numberOfLines={1}
                  selectable={false}
                  style={styles.textTwo}
                >
                  {item?.title}
                </Text>
              </View>
              <DetailItem
                priceValue={formatCurrencyToUSD(item?.priceRange?.min)}
                title={"(Suggested price / average)"}
              />
              {item?.highestOfferPlaced ? (
                <DetailItem
                  priceValue={`${formatCurrencyToUSD(
                    item?.highestOfferPlaced
                  )}`}
                  title={"(Highest offer placed)"}
                  textOpacityControl={true}
                />
              ) : (
                false
              )}
            </View>

            <View
              style={{
                flexDirection: "row",
                width: "100%",
                flexWrap: "wrap",
                alignItems: "center",
                marginTop: 10,
                // marginBottom: 20,
              }}
            >
              {isLocationPermissionGranted ? (
                <SpecItem
                  key={"2"}
                  icon={() => <CompassSvg />}
                  label={`${
                    item?.distance?.miles ? item?.distance?.miles : 0
                  } miles from you`}
                />
              ) : (
                false
              )}

              {item.KMDriven ? (
                <SpecItem
                  key={"1"}
                  icon={() => <SpeedSvg />}
                  label={`mileage: ${item?.KMDriven ? item.KMDriven : "0"}`}
                  // label={`mileage: 12345678`}
                />
              ) : (
                false
              )}
            </View>
          </View>

          <View style={[styles.viewFour]}>
            <TouchableOpacity
              onPress={() => onPressDislike(item)}
              style={styles.btnsView}
            >
              <DislikeSvg />
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => onPressDetails(item)}
              style={styles.smallBtnView}
            >
              <DetailsSvg />
            </TouchableOpacity>

            <TouchableOpacity
              onPress={() => onPressLike(item)}
              style={styles.btnsView}
            >
              <LikeSvg />
            </TouchableOpacity>
          </View>
        </View>
        {loading ? (
          <View style={{ ...StyleSheet.absoluteFillObject }}>
            <View
              style={{
                width: CARD_WIDTH,
                height:
                  PLATFORM_DETECT.WEB && !deviceType
                    ? 470
                    : PLATFORM_DETECT.WEB
                    ? 498
                    : VEHICLE_CARD_UPPER_PART_HEIGHT +
                      VEHICLE_CARD_LOWER_PART_HEIGHT,
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(0,0,0,0.2)",
                borderRadius: 25,
              }}
            >
              <ActivityIndicator color={"white"} size="large" />
              <View style={{ height: 5 }} />
              <Text selectable={false} style={{ color: "white" }}>
                Searching...
              </Text>
            </View>
          </View>
        ) : (
          false
        )}
      </View>
    </View>
  );
};

export default NewDesignFeedItem;
export { SpecItem };

const styles = StyleSheet.create({
  mainContainer: {
    borderRadius: 25,
    // marginHorizontal: 20,
    // alignSelf: "center",
  },
  viewOne: {
    borderTopRightRadius: 25,
    borderTopLeftRadius: 25,
  },
  viewTwo: {
    width: "100%",
    // height: hp("64%"),
    backgroundColor: Colors.blueSecondary,
    borderBottomRightRadius: 25,
    borderBottomLeftRadius: 25,
    justifyContent: "center",
    alignItems: "center",
    // borderTopColor: 'white',
    // borderTopWidth: 1.5,
  },
  viewThree: {
    width: "90%",
    height: "88%",
    justifyContent: "space-around",
  },
  viewThreeWeb: {
    width: "90%",
    height: "70%",
    // justifyContent: "space-around",
  },
  textOne: {
    color: Colors.greySecondaryTwo,
    fontSize: hp("2"),
    fontFamily: Fonts.default,
  },
  textTwo: {
    color: Colors.whitePrimary,
    fontSize: hp("2.7"),
    fontFamily: Fonts.default,
    fontWeight: "900",
  },
  conatinerView: {
    justifyContent: "space-between",
    marginRight: 5,
    paddingBottom: 3,
  },
  specView: {
    minWidth: "20%",
    width: "100%",
    borderRadius: 15,
    backgroundColor: Colors.greySecondaryThree,
    alignItems: "center",
    flexDirection: "row",
  },
  textThree: {
    color: Colors.whitePrimary,
    fontSize: hp("1.6"),
    fontFamily: Fonts.default,
  },
  viewFour: {
    width: "70%",
    height: "25%",
    alignSelf: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: 10,
    // backgroundColor: "red",
  },
  btnsView: {
    justifyContent: "center",
    alignItems: "center",
  },
  smallBtnView: {
    width: SW * 0.1,
    height: SW * 0.1 * 1,
    justifyContent: "center",
    alignItems: "center",
  },
  priceText: {
    fontFamily: Fonts.bold,
    color: Colors.blueSecondaryTwo,
    fontSize: hp("2.2"),
  },
  detailText: {
    fontFamily: Fonts.medium,
    color: Colors.whitePrimary,
    fontSize: hp("1.9"),
  },
});

// <View
//   style={[
//     styles.mainContainer,
//     {
//       width: deviceType ? 560 : wp("90%"),
//       height: deviceType ? hp("11.5%") : hp("61.5%"),
//     },
//   ]}
// >
//   <View
//     style={[
//       styles.viewOne,

//       {
//         width: "100%",
//         height: deviceType ? hp("37.5%") : hp("33.5%"),
//         backgroundColor: "red",
//       },
//     ]}
//   >
//     <ImagePlacer
//       avatarUrl={getFirstImage(item?.pictures).avatar?.url}
//       imageUrl={getFirstImage(item?.pictures).image?.url}
//       type={"large"}
//     />
//   </View>

//   <View style={[styles.viewTwo, { paddingTop: 0, minHeight: hp("32%") }]}>
//     <View style={styles.viewThreeWeb}>
//       <View style={{}}>
//         <View style={{ paddingVertical: 5 }}>
//           <Text style={styles.textTwo}>{item?.title}</Text>
//         </View>
//         <DetailItem
//           priceValue={formatCurrencyToUSD(item?.priceRange?.min)}
//           title={"(Suggested price / average)"}
//         />
//         {item?.highestOfferPlaced ? (
//           <DetailItem
//             priceValue={`${formatCurrencyToUSD(item?.highestOfferPlaced)}`}
//             title={"(Highest offer placed)"}
//             textOpacityControl={true}
//           />
//         ) : (
//           false
//         )}
//       </View>

//       <View
//         style={{
//           flexDirection: "row",
//           width: "100%",
//           // height: hp("4.3%"),
//           flexWrap: "wrap",
//           alignItems: "center",
//           marginTop: 20,
//           marginBottom: 20,
//         }}
//       >
//         {isLocationPermissionGranted ? (
//           <SpecItem
//             key={"2"}
//             icon={() => <CompassSvg />}
//             label={`${
//               item?.distance?.miles ? item?.distance?.miles : 0
//             } miles from you`}
//           />
//         ) : (
//           false
//         )}

//         {item.KMDriven ? (
//           <SpecItem
//             key={"1"}
//             icon={() => <SpeedSvg />}
//             label={`mileage: ${item?.KMDriven ? item.KMDriven : "0"}`}
//             // label={`mileage: 12345678`}
//           />
//         ) : (
//           false
//         )}
//       </View>
//     </View>

//     <View style={[styles.viewFour]}>
//       <TouchableOpacity
//         onPress={() => onPressDislike(item)}
//         style={styles.btnsView}
//       >
//         <DislikeSvg />
//       </TouchableOpacity>
//       <TouchableOpacity
//         onPress={() => onPressDetails(item)}
//         style={styles.smallBtnView}
//       >
//         <DetailsSvg />
//       </TouchableOpacity>

//       <TouchableOpacity
//         onPress={() => onPressLike(item)}
//         style={styles.btnsView}
//       >
//         <LikeSvg />
//       </TouchableOpacity>
//     </View>
//   </View>

//   {loading ? (
//     <View style={{ ...StyleSheet.absoluteFillObject }}>
//       <View
//         style={{
//           // width: "100%",
//           // height: "100%",
//           width: deviceType ? 560 : wp("90%"),
//           height: deviceType ? hp("69%") : hp("65.5%"),
//           justifyContent: "center",
//           alignItems: "center",
//           backgroundColor: "rgba(0,0,0,0.2)",
//           borderRadius: 25,
//         }}
//       >
//         <ActivityIndicator color={"white"} size="large" />
//         <View style={{ height: 5 }} />
//         <Text style={{ color: "white" }}>Searching...</Text>
//       </View>
//     </View>
//   ) : (
//     false
//   )}
// </View>
