import React from "react";
import Svg, { Path } from "react-native-svg";

const FilterSvg = ({ color }) => {
  const defaultColor = color ? color : "black";

  return (
    <Svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path
        d="M0.125 20.75C0.125 21.5063 0.74375 22.125 1.5 22.125H8.375V19.375H1.5C0.74375 19.375 0.125 19.9938 0.125 20.75ZM0.125 4.25C0.125 5.00625 0.74375 5.625 1.5 5.625H13.875V2.875H1.5C0.74375 2.875 0.125 3.49375 0.125 4.25ZM13.875 23.5V22.125H23.5C24.2563 22.125 24.875 21.5063 24.875 20.75C24.875 19.9938 24.2563 19.375 23.5 19.375H13.875V18C13.875 17.2438 13.2563 16.625 12.5 16.625C11.7438 16.625 11.125 17.2438 11.125 18V23.5C11.125 24.2563 11.7438 24.875 12.5 24.875C13.2563 24.875 13.875 24.2563 13.875 23.5ZM5.625 9.75V11.125H1.5C0.74375 11.125 0.125 11.7438 0.125 12.5C0.125 13.2563 0.74375 13.875 1.5 13.875H5.625V15.25C5.625 16.0063 6.24375 16.625 7 16.625C7.75625 16.625 8.375 16.0063 8.375 15.25V9.75C8.375 8.99375 7.75625 8.375 7 8.375C6.24375 8.375 5.625 8.99375 5.625 9.75ZM24.875 12.5C24.875 11.7438 24.2563 11.125 23.5 11.125H11.125V13.875H23.5C24.2563 13.875 24.875 13.2563 24.875 12.5ZM18 8.375C18.7563 8.375 19.375 7.75625 19.375 7V5.625H23.5C24.2563 5.625 24.875 5.00625 24.875 4.25C24.875 3.49375 24.2563 2.875 23.5 2.875H19.375V1.5C19.375 0.74375 18.7563 0.125 18 0.125C17.2438 0.125 16.625 0.74375 16.625 1.5V7C16.625 7.75625 17.2438 8.375 18 8.375Z"
        fill={defaultColor}
      />
    </Svg>
  );
};

export default FilterSvg;
