import React, {useState} from 'react';
import {StyleSheet, Text, TouchableOpacity, View} from 'react-native';
import BackBtnSvg from '../../Assets/Icons/backBtnSvg';
import AbstractHeaders from '../../Components/AbstractComponents/abstractHeaders';
import {Colors, Fonts} from '../../themes';
import AbstractContentContainer from '../../Components/AbstractComponents/abstractContentContainer';
import AbstractH3 from '../../Components/AbstractComponents/AbstractHeadings/abstractH3';
import AbstractButton from '../../Components/AbstractComponents/abstractButton';
import FocusAwareStatusBar from '../../Components/AbstractComponents/statusbarConfiguration';
import {moderateScale} from 'react-native-size-matters';
import PaymentBtnSvg from '../../Assets/Icons/paymentBtnSvg';
import AbstractModal from '../../Components/AbstractComponents/abstractModal';
import CrossSvg from '../../Assets/Icons/crossSvg';
import ModalSvg from '../../Assets/Icons/modalSvg';
import {clearAndNavigate, goBack} from '../../Navigation/mainNavigation';
import ScreenContainer from '../../Components/AbstractComponents/abstractScreenContainer';

const SetupWalletScreen = ({route}) => {

  const {backGroundColor} = route.params
  const [hideModal, setHidemodal] = useState(false);

  return (
    <ScreenContainer
      upperStyle={{backgroundColor: Colors.screenBgColor}}
      bottomStyle={{backgroundColor: Colors.screenBgColor}}
    >
      <View style={[styles.mainContainer,{backgroundColor:backGroundColor}]}>
        <FocusAwareStatusBar
          barStyle={'dark-content'}
          backgroundColor={'transparent'}
          translucent={true}
        />
        <AbstractHeaders
          onPressLeft={() => goBack()}
          leftChild={() => <BackBtnSvg />}
        />
        <AbstractContentContainer>
          <View>
            <AbstractH3 txtColor={Colors.darkBluePrimary}>
              Set up your wallet
            </AbstractH3>
            <TouchableOpacity style={{marginTop: 5}}>
              <Text style={styles.txtOne}>
                Please click on setup button to setup your stripe account.
              </Text>
            </TouchableOpacity>
          </View>

          <View style={{marginTop: 30}}>
            <AbstractButton
              label={'Add a payment method'}
              icon={() => <PaymentBtnSvg />}
            />
          </View>

          <View style={{marginTop: 20, alignItems: 'center'}}>
            <TouchableOpacity
              onPress={() => {
                clearAndNavigate('App');
              }}
              activeOpacity={0.8}
              style={{marginTop: 5}}
            >
              <Text style={styles.textTwo}>Skip for now</Text>
            </TouchableOpacity>
          </View>
        </AbstractContentContainer>

        <AbstractModal
          isVisible={hideModal}
          setModalVisiblity={() => {
            setHidemodal(preState => (preState = !preState));
          }}
        >
          <View style={styles.viewOne}>
            <View style={styles.viewTwo}>
              <CrossSvg />
            </View>

            <View style={styles.viewThree}>
              <View style={{alignItems: 'center'}}>
                <Text style={styles.textThree}>Wallet</Text>
                <View>
                  <Text style={styles.textFour}>$50 added to wallet</Text>
                </View>
              </View>

              <View style={{marginVertical: 25}}>
                <ModalSvg />
              </View>

              <View>
                <AbstractButton
                  width={moderateScale(219, 0.1)}
                  height={moderateScale(49, 0.1)}
                  label={'Continue'}
                />
              </View>
            </View>
          </View>
        </AbstractModal>
      </View>
    </ScreenContainer>
  );
};

export default SetupWalletScreen;

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: Colors.screenBgColor,
  },

  txtOne: {
    fontSize: moderateScale(12, 0.1),
    fontFamily: Fonts.default,
    color: Colors.darkBluePrimary,
    width: '85%',
    lineHeight: 20,
  },
  textTwo: {
    fontSize: moderateScale(12, 0.1),
    fontFamily: Fonts.default,
    color: Colors.blueprimary,
    textDecorationLine: 'underline',
  },
  viewOne: {
    width: '90%',
    height: '90%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  viewTwo: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  viewThree: {
    width: '70%',
    height: '90%',
    alignItems: 'center',
  },
  textThree: {
    fontSize: moderateScale(21, 0.1),
    fontFamily: Fonts.default,
    color: 'black',
    paddingBottom: 5,
  },
  textFour: {
    fontSize: moderateScale(21, 0.1),
    fontFamily: Fonts.default,
    color: 'black',
    fontWeight: 'bold',
  },
});
