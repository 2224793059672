import {
  ActivityIndicator,
  StyleSheet,
  Text,
  View,
  Dimensions,
  Platform,
} from "react-native";
import React, { useRef } from "react";
import CardStack, { Card } from "react-native-card-stack-swiper";
import { useSelector } from "react-redux";
import VehicleWheelSvg from "../../../Assets/Icons/vehicleWheelSvg";
import { navigate } from "../../../Navigation/mainNavigation";
import NewDesignFeedItem, {
  VEHICLE_CARD_LOWER_PART_HEIGHT,
  VEHICLE_CARD_UPPER_PART_HEIGHT,
} from "../newDesignFeedItem";
import { Colors, PLATFORM_DETECT } from "../../../themes";
import NoCarAvailableSvg from "../../../Assets/Icons/noCarAvailableSvg";

const SW = Dimensions.get("window").width;
const HORIZONTAL_THRESHOLD = SW / 4;

const Loader = ({ isLoading }) => {
  if (isLoading) {
    return (
      <View
        style={[
          StyleSheet.absoluteFillObject,
          {
            position: "absolute",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 999999,
          },
        ]}
      >
        <ActivityIndicator
          color={Colors.blueSecondary}
          size="large"
          style={{ marginTop: -150 }}
        />
        <View style={{ height: 5 }} />
        <Text style={{ color: Colors.blueSecondary }}>Searching...</Text>
      </View>
    );
  } else {
    return false;
  }
};

const NewDesignCardSwipperV2 = ({ swippingRight }) => {
  const { deviceType } = useSelector((state) => state.DeviceTypeReducer);

  const swiperRef = useRef();
  const CARD_WIDTH = deviceType ? 340 : 310;

  const { loading, vehicles } = useSelector((state) => ({
    loading: state.VehiclesReducer.loadingVehicles,
    vehicles: state.VehiclesReducer.vehicles,
  }));

  const _handleDisLike = (item) => {
    swiperRef.current.swipeLeft();
  };

  const _handleDetails = (vehicle) => {
    navigate("CarDetails", { vehicle });
  };

  const _handleLike = (item) => {
    swiperRef.current.swipeRight();
  };

  return vehicles.length > 0 ? (
    <CardStack
      style={{
        width: CARD_WIDTH,
        height:
          PLATFORM_DETECT.WEB && !deviceType
            ? 470
            : PLATFORM_DETECT.WEB
            ? 498
            : VEHICLE_CARD_UPPER_PART_HEIGHT + VEHICLE_CARD_LOWER_PART_HEIGHT,
        alignItems: "center",
        marginTop: 20,
      }}
      loop={true}
      disableTopSwipe={true}
      disableBottomSwipe={true}
      verticalSwipe={false}
      // disableLeftSwipe={true}
      // disableRightSwipe={true}
      horizontalSwipe={true}
      // horizontalThreshold={PLATFORM_DETECT.SW / 4}
      horizontalThreshold={CARD_WIDTH / 4}
      onSwipedRight={(cardIndex) => swippingRight(vehicles[cardIndex])}
      duration={350}
      secondCardZoom={0.98}
      ref={swiperRef}
    >
      {vehicles.map((item, index) => {
        // console.log(item, "KKKKK");
        return (
          <Card key={item._id}>
            <NewDesignFeedItem
              onPressLike={_handleLike}
              onPressDislike={_handleDisLike}
              onPressDetails={_handleDetails}
              item={item}
            />
          </Card>
        );
      })}
    </CardStack>
  ) : (
    <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
      {loading ? (
        <Loader isLoading={loading} />
      ) : vehicles.length == 0 ? (
        <View style={{ marginBottom: 100 }}>
          <NoCarAvailableSvg label={"No Car Available!"} />
        </View>
      ) : (
        false
      )}
    </View>
  );
};

export default NewDesignCardSwipperV2;

const styles = StyleSheet.create({});
