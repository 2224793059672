import React, { useState } from "react";
import { StyleSheet, Text, View } from "react-native";
import { moderateScale } from "react-native-size-matters";
import { Fonts } from "../../themes";
import AbstractDropDown from "../AbstractComponents/abstractDropDown";

const DropDownWithLabel = (props) => {
  const defLabel = props.label ? props.label : "textHere";
  const defPlaceHolder = props.placeHolder ? props.placeHolder : "textHere";
  return (
    <React.Fragment>
      <View style={styles.mainContainer}>
        {props.label && (
          <View style={styles.viewOne}>
            <Text style={[styles.txtOne]}>{defLabel}</Text>
          </View>
        )}
        <View style={[styles.viewTwo]}>
          <View
            style={[
              styles.viewTwoA,
              {
                width: props?.cWidth ? props?.cWidth : "100%",
                height: props?.cHeight
                  ? props?.cHeight
                  : moderateScale(48, 0.1),
              },
            ]}
          >
            <AbstractDropDown
              defPlaceHolder={defPlaceHolder}
              area={props?.area}
              cWidth={props?.cWidth}
              items={props.items}
              setItems={props.setItems}
              value={props.value}
              onChange={props.onChange}
              zIndex={props.zIndex}
              zIndexInverse={props.zIndexInverse}
              sheetWihDisableGesture={props.sheetWihDisableGesture}
              setSelectedArea={props.setSelectedArea}
            />
          </View>
        </View>
      </View>
    </React.Fragment>
  );
};

export default DropDownWithLabel;

const styles = StyleSheet.create({
  mainContainer: {
    height: moderateScale(92, 0.1),
    width: "100%",
  },
  viewOne: {
    width: "100%",
    height: moderateScale(25, 0.1),
  },
  viewTwo: {
    flexDirection: "row",
  },
  viewTwoA: {
    width: "100%",
    height: "100%",
  },
  viewTwoB: {
    width: "10%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  txtOne: {
    fontSize: moderateScale(13, 0.1),
    fontFamily: Fonts.bold,
    fontWeight: "900",
  },
});
