import {
  StyleSheet,
  Text,
  View,
  ActivityIndicator,
  FlatList,
  Dimensions,
  Platform,
} from "react-native";
import React, { useEffect, useState } from "react";
import FocusAwareStatusBar from "../../Components/AbstractComponents/statusbarConfiguration";
import AbstractHeaders from "../../Components/AbstractComponents/abstractHeaders";
import BackBtnSvg from "../../Assets/Icons/backBtnSvg";
import ScreenContainer from "../../Components/AbstractComponents/abstractScreenContainer";
import { Colors, Fonts, PLATFORM_DETECT } from "../../themes";
import { moderateScale } from "react-native-size-matters";
import AbstractContentContainer from "../../Components/AbstractComponents/abstractContentContainer";
import AvailableOffersCard from "../../Components/ModuleComponents/availableOffersCard";
import { goBack } from "../../Navigation/mainNavigation";
import { useSelector } from "react-redux";
import WalletController from "../../Controllers/walletController";
import OfferDetailTile from "../../Components/ModuleComponents/offerDetailTile";
import { dummyOfferDetails } from "../../Utils/Moc";
import { SheetManager } from "react-native-actions-sheet";
// import StripeWebElementWarper from "../../Components/ModuleComponents/wallet/addWalletSheet.native";

const SW = Dimensions.get("window").width;

const WalletNewScreen = () => {
  const { process, currentBalance, comission } = useSelector((state) => ({
    process: state.WalletReducer.process,
    currentBalance: state.WalletReducer.currentBalance,
    comission: state.WalletReducer.comission,
  }));
  const [amount, setAmount] = useState(0);
  const [offers, setOffers] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      WalletController.loadWallet(true);
    }, 1000);
  }, []);

  const openPaymentSheet = ({ price, offers }) => {
    if (PLATFORM_DETECT.WEB) {
      setAmount(price);
      setOffers(offers);
      SheetManager.show("web-payment");
    } else {
      setAmount(price);
      setOffers(offers);
      SheetManager.show("offer-payment-sheet");
    }
  };

  return (
    <ScreenContainer
      upperStyle={{ backgroundColor: Colors.blackPrimary }}
      bottomStyle={{ backgroundColor: Colors.blackPrimary }}
    >
      <View style={styles.mainContainer}>
        <FocusAwareStatusBar
          barStyle="light-content"
          backgroundColor={"transparent"}
          translucent={true}
        />

        <AbstractHeaders
          leftChild={() => (
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <BackBtnSvg color={Colors.whitePrimary} />
              <Text style={styles.textOne}>Back</Text>
            </View>
          )}
          onPressLeft={() => goBack()}
          middleChild={() => (
            <View>
              <Text style={styles.textTwo}>Wallet</Text>
            </View>
          )}
        />
        <AbstractContentContainer>
          <View style={{ width: "100%", height: moderateScale(240, 0.1) }}>
            <AvailableOffersCard currentBalance={currentBalance} />
          </View>
        </AbstractContentContainer>

        <View style={styles.viewOne}>
          <AbstractContentContainer>
            <View style={{ marginTop: 25 }}>
              <Text style={styles.textThree}>Offer Deals</Text>
            </View>
            <FlatList
              showsVerticalScrollIndicator={false}
              data={dummyOfferDetails}
              keyExtractor={(item) => item._id}
              renderItem={({ item }) => (
                <OfferDetailTile
                  onPress={openPaymentSheet}
                  label={item.label}
                  price={item.price}
                  offers={item.offers}
                />
              )}
            />
          </AbstractContentContainer>
        </View>
      </View>

      {/* <StripeWebElementWarper
        amount={amount}
        offers={offers}
        onClose={() => {
          SheetManager.hide("offer-payment-sheet");
        }}
      /> */}

      {process.processing ? (
        <View
          style={[
            StyleSheet.absoluteFill,
            {
              backgroundColor: "rgba(0,0,0,0.5)",
              justifyContent: "center",
              alignItems: "center",
            },
          ]}
        >
          <ActivityIndicator color={"white"} size="large" />
          <Text
            style={{ color: "white", fontStyle: "italic" }}
          >{`${process.message}`}</Text>
        </View>
      ) : (
        false
      )}
    </ScreenContainer>
  );
};

export default WalletNewScreen;

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: Colors.blackPrimary,
  },
  textOne: {
    fontFamily: Fonts.default,
    color: Colors.whitePrimary,
    fontSize: moderateScale(19, 0.1),
    paddingRight: 20,
  },
  textTwo: {
    fontFamily: Fonts.bold,
    color: Colors.whitePrimary,
    fontSize: moderateScale(19, 0.1),
  },
  textThree: {
    fontFamily: Fonts.bold,
    color: Colors.blackPrimary,
    fontSize: moderateScale(22, 0.1),
  },
  viewOne: {
    width: "100%",
    height: "60%",
    backgroundColor: Colors.whitePrimary,
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
  },
  modalViewContainer: {
    margin: 20,
    height: SW * (195 / 366),
    width: SW * 0.88,
    backgroundColor: Colors.whitePrimary,
    borderRadius: 15,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    justifyContent: "center",
  },
});
