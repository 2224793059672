import AuthController from "./authController";
import ConversationsController from "./conversationsController";
import ReduxDispatchController from "./reduxDispatchController";
class MessageController {
  static MESSAGE_STATUS = {
    SENDING: 1,
    SENT: 2,
    FAILD: 3,
  };
  constructor(messageBody, offerId, businessId, messageId = "") {
    this.currentUser = AuthController.getCurrentUser();
    this.offerId = offerId;
    this.businessId = businessId;
    this.messageId = messageId
      ? messageId
      : `${Math.random() * 100000 * Math.random()}-${
          Math.random() * 100000 * Math.random()
        }`;
    this.messageObject = {
      _id: this.messageId,
      buyer: this.currentUser._id,
      business: businessId,
      body: messageBody,
      sentTime: new Date(),
      status: MessageController.MESSAGE_STATUS.SENDING,
    };
  }
  send(_callback = () => false) {
    /// send to reducer.
    // console.log(`T0: Sending message locally`)
    ReduxDispatchController.CONVERSATIONS.pushMessage(
      this.offerId,
      this.messageObject
    );
    //// send to database
    // console.log(`T1: Sending message server`)
    ConversationsController.sendMessage(this.offerId, this.messageObject)
      .then((message) => {
        ///  then > update in reducer.
        // console.log(`T2: sent`)
        ReduxDispatchController.CONVERSATIONS.updateMessage(
          this.offerId,
          this.messageId,
          { ...message, status: MessageController.MESSAGE_STATUS.SENT }
        );
        // console.log(`T3: updated`)
        _callback();
      })
      .catch((err) => {
        console.log("CATCH Error in sendin message", err);
        console.log(err);
        ReduxDispatchController.CONVERSATIONS.updateMessage(
          this.offerId,
          this.messageId,
          {
            ...this.messageObject,
            status: MessageController.MESSAGE_STATUS.FAILD,
          }
        );
        console.log(`T2: fail`);
        _callback();
      });
  }
  reSend() {
    ReduxDispatchController.CONVERSATIONS.updateMessage(
      this.offerId,
      this.messageId,
      {
        ...this.messageObject,
        status: MessageController.MESSAGE_STATUS.SENDING,
      }
    );
    //// send to database
    ConversationsController.sendMessage(this.offerId, this.messageObject)
      .then((message) => {
        ///  then > update in reducer.
        ReduxDispatchController.CONVERSATIONS.updateMessage(
          this.offerId,
          this.messageId,
          { ...message, status: MessageController.MESSAGE_STATUS.SENT }
        );
      })
      .catch((err) => {
        ReduxDispatchController.CONVERSATIONS.updateMessage(
          this.offerId,
          this.messageId,
          {
            ...this.messageObject,
            status: MessageController.MESSAGE_STATUS.FAILD,
          }
        );
      });
  }
}
export default MessageController;
