import store from "../../src/Store";
import ReduxDispatchController from "./reduxDispatchController";
import axios from "axios";
import { ERRORS } from "./errorMessages";
import { useSelector } from "react-redux";
import React from "react";
import { goBack } from "../Navigation/mainNavigation";
import { Keyboard } from "react-native";
import AuthController from "./authController";

class AuctionController {
  static handleAllAuctionList = (_callback) => {
    AuctionController.getAllAuctionList()
      .then((list) => {
        ReduxDispatchController.AUCTION.setAuctionList(list);
        _callback();
      })
      .catch((err) => {
        console.log(err, "Error in getting Auctions list");
        _callback(err);
      });
  };
  static getAllAuctionList = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`/auction`)
        .then((res) => {
          console.log(res.data, "res of search-without location");
          if (res.data.success) {
            resolve(res.data.data);
          } else {
            reject(res.data.error.message);
          }
        })
        .catch((err) => {
          reject(ERRORS.INTERNET_CONNECTIVITY);
        });
    });
  };
  static getDistinctAreaList = () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`/auction/distinct/areas`)
        .then((res) => {
          console.log(res.data, "res of distinct Area");
          if (res.data.success) {
            resolve(res.data.data);
          } else {
            reject(res.data.error.message);
          }
        })
        .catch((err) => {
          reject(ERRORS.INTERNET_CONNECTIVITY);
        });
    });
  };
  static getAuctionWithDistinctAreaFilter = (area, currentPage) => {
    console.log(area, currentPage);
    return new Promise((resolve, reject) => {
      axios
        .get(`/auction?area=${area}&pageNumber=${currentPage}&pageSize=30`)
        .then((res) => {
          if (res.data.success) {
            resolve(res.data.data);
          } else {
            reject(res.data.error.message);
          }
        })
        .catch((err) => {
          reject(ERRORS.INTERNET_CONNECTIVITY);
        });
    });
  };
  static googlePlacesApi = (txt) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `https://web-production-1edb.up.railway.app/https://maps.googleapis.com/maps/api/place/queryautocomplete/json?&key=AIzaSyDYL1lb12inoSM8uVoI5N1W0cZwcbtEM54&input=${txt}`
        )
        .then((res) => {
          if (res.data.status == "OK") {
            resolve(res.data.predictions);
          } else {
            reject(res.data.status);
          }
        })
        .catch((err) => {
          console.warn(err);
          reject(ERRORS.INTERNET_CONNECTIVITY);
        });
    });
  };

  static getSelectedPlaceDes = (id) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `https://web-production-1edb.up.railway.app/https://maps.googleapis.com/maps/api/place/details/json?&key=AIzaSyDYL1lb12inoSM8uVoI5N1W0cZwcbtEM54&place_id=${id}`
        )
        .then((res) => {
          if (res.data.status == "OK") {
            resolve(res.data.result.geometry.location);
          } else {
            reject(res.data.status);
          }
        })
        .catch((err) => {
          console.warn(err);
          reject(ERRORS.INTERNET_CONNECTIVITY);
        });
    });
  };

  static getSingleAuction = (auctionId) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`/auction/${auctionId}`)
        .then((res) => {
          console.log(res.data, "single  auction");
          if (res.data.success) {
            resolve(res.data.data);
          } else {
            reject(res.data.error.message);
          }
        })
        .catch((err) => {
          reject(ERRORS.INTERNET_CONNECTIVITY);
        });
    });
  };

  static getSingleAuctionPublic = (auctionId) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`/auction/${auctionId}/public`)
        .then((res) => {
          // console.log(res.data, "single  auction");
          if (res.data.success) {
            resolve(res.data.data);
          } else {
            reject(res.data.error.message);
          }
        })
        .catch((err) => {
          reject(ERRORS.INTERNET_CONNECTIVITY);
        });
    });
  };

  static getMyAllBids = (currentPage) => {
    const currentUserId = AuthController.getCurrentUser()?._id;
    return new Promise((resolve, reject) => {
      axios
        .get(
          `/auction/bid/get-bids-by-filters?pageSize=30&pageNumber=${currentPage}&buyer=${currentUserId}`
        )
        .then((res) => {
          if (res.data.success) {
            resolve(res.data.data);
          } else {
            reject(res.data.error.message);
          }
        })
        .catch((err) => {
          reject(ERRORS.INTERNET_CONNECTIVITY);
        });
    });
  };

  static getSingleAuctionCar = (auctionCarId) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`/auction/vehicle/${auctionCarId}`)
        .then((res) => {
          if (res.data.success) {
            resolve(res.data.data);
          } else {
            reject(res.data.error.message);
          }
        })
        .catch((err) => {
          reject(ERRORS.INTERNET_CONNECTIVITY);
        });
    });
  };
  static getSingleAuctionCarPublic = (auctionCarId) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`/auction/vehicle/${auctionCarId}/public`)
        .then((res) => {
          if (res.data.success) {
            resolve(res.data.data);
          } else {
            reject(res.data.error.message);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(ERRORS.INTERNET_CONNECTIVITY);
        });
    });
  };

  static searchAuctionByLocation = (queryObject) => {
    return new Promise((resolve, reject) => {
      axios
        .post(`/auction/near-by/auctions?distance=0`, queryObject)
        .then((res) => {
          console.log(res.data, "res of search-by location");
          if (res.data.success) {
            resolve(res.data.data.auctions);
          } else {
            reject(res.data.error.message);
          }
        })
        .catch((err) => {
          console.log(err, "Error in searchByLocationApi");
          reject(ERRORS.INTERNET_CONNECTIVITY);
        });
    });
  };

  static handleSearchAuctionByLocation = (
    description,
    coords,
    _callback = () => false
  ) => {
    const queryObject = {
      location: {
        description,
        coords,
      },
    };

    ReduxDispatchController.AUCTION.setSearchLoading(true);
    Keyboard.dismiss();
    AuctionController.searchAuctionByLocation(queryObject)
      .then((results) => {
        ReduxDispatchController.AUCTION.setAuctionList(results);
        ReduxDispatchController.AUCTION.setSearchLoading(false);
        _callback();
      })
      .catch((err) => {
        console.log(err, "error in getAuctionListbylocation");
        ReduxDispatchController.AUCTION.setSearchLoading(false);
        _callback();
      });
  };

  static handleFilterAuctionVehicleByMakeModal = (
    _id,
    make = [],
    year = {},
    currentPage = 0
  ) => {
    let body = {};
    if (make.length > 0) body.make = make;
    if (year.startYear && year.endYear) body.year = year;
    console.log(body, "body");
    return new Promise((resolve, reject) => {
      axios
        .post(
          `/auction/vehicle/auction-vehicles/by-filters/${_id}?pageSize=30&pageNumber=${currentPage}`,
          body
        )
        .then((res) => {
          if (res.data.success) {
            console.log(res.data.data, "+++++++");
            resolve(res.data.data);
          } else {
            reject(res.data.error.message);
          }
        })
        .catch((err) => {
          console.log(err, "Error in searchByLocationApi");
          reject(ERRORS.INTERNET_CONNECTIVITY);
        });
    });
  };
  static handleFilterAuctionVehicleByMakeModalPublic = (
    _id,
    make = [],
    year = {},
    currentPage = 0
  ) => {
    let body = {};
    if (make.length > 0) body.make = make;
    if (year.startYear && year.endYear) body.year = year;

    return new Promise((resolve, reject) => {
      axios
        .post(
          `/auction/vehicle/auction-vehicles/by-filters/${_id}/public?pageSize=30&pageNumber=${currentPage}`,
          body
        )
        .then((res) => {
          // console.log(res.data.data, "+++++++");
          if (res.data.success) {
            resolve(res.data.data);
          } else {
            reject(res.data.error.message);
          }
        })
        .catch((err) => {
          console.log(err, "Error in searchByLocationApi");
          reject(ERRORS.INTERNET_CONNECTIVITY);
        });
    });
  };
}

export default AuctionController;

export const useAllAuctionsList = () => {
  const { auctionList } = useSelector((state) => state.AuctionReducer);
  // console.log(auctionList, "????");
  return auctionList;
};

export const useCountDown = (countDownDate) => {
  const [days, setDays] = React.useState("0");
  const [hours, setHours] = React.useState("0");
  const [minutes, setMinutes] = React.useState("0");
  const [seconds, setSeconds] = React.useState("0");

  React.useEffect(() => {
    commingSoonTime();
    // const interval = setInterval(() => {
    // }, 1000);
    // return () => clearInterval(interval);
  }, []);
  const commingSoonTime = () => {
    let endTime = new Date(countDownDate);

    let endTimeParse = Date.parse(endTime) / 1000;
    let now = new Date();
    let nowParse = Date.parse(now) / 1000;
    let timeLeft = endTimeParse - nowParse;
    let days = Math.floor(timeLeft / 86400);
    let hours = Math.floor((timeLeft - days * 86400) / 3600);
    let minutes = Math.floor((timeLeft - days * 86400 - hours * 3600) / 60);
    let seconds = Math.floor(
      timeLeft - days * 86400 - hours * 3600 - minutes * 60
    );
    if (hours < "10") {
      hours = "0" + hours;
    }
    if (minutes < "10") {
      minutes = "0" + minutes;
    }
    if (seconds < "10") {
      seconds = "0" + seconds;
    }
    if (days < 0) {
      setDays(0);
      setHours(0);
      setMinutes(0);
      setSeconds(0);
    } else {
      setDays(days);
      setHours(hours);
      setMinutes(minutes);
      setSeconds(seconds);
    }
  };
  return { days, hours, minutes, seconds };
};
