import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  View,
  Text,
  Image,
  Dimensions,
  Platform,
} from "react-native";
import { moderateScale } from "react-native-size-matters";
import { Colors, PLATFORM_DETECT } from "../../themes";
import AbstractButton from "../AbstractComponents/abstractButton";
import * as ImagePicker from "expo-image-picker";

import MyPermissionsController from "../../../src/Permissions/permissionsController";
import AbstractAvatar from "../AbstractComponents/abstractAvatar";
import useIsMobileBrowser from "../../../src/Utils/useIsMobileBrowser";
import { useSelector } from "react-redux";

const SW = Dimensions.get("window").width;

const MyImagePicker = ({ avatarUrl, imageUrl, onChange, isValid }) => {
  const { deviceType } = useSelector((state) => state.DeviceTypeReducer);

  const [imageSource, setImageSource] = useState(null);

  useEffect(() => {
    if (imageUrl) {
      setImageSource({ uri: imageUrl });
    }
  }, [imageUrl]);
  useEffect(() => {
    (async () => {
      if (Platform.OS == "web") {
        const { status } =
          await ImagePicker.requestMediaLibraryPermissionsAsync({
            base64: false,
          });
        if (status !== "granted") {
          alert("Sorry, we need camera roll permissions to make this work!");
        }
      }
    })();
  }, []);
  const handlePickImage = async () => {
    ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
      // base64: true,
    })
      .then((result) => {
        if (!result.canceled) {
          let imgSource = {
            uri: result.assets[0].uri,
            type: "image/jpeg",
            name: result.assets[0].uri.replace(/^.*[\\\/]/, ""),
            // base64Url: result.assets[0].base64,
          };
          // console.log(imgSource);
          setImageSource({ uri: result.assets[0].uri });
          if (onChange) onChange(imgSource);
        }
      })
      .catch((err) => console.log(err, "????Img"));
  };

  return (
    <View style={styles.mainContainer}>
      <View
        style={{
          width: deviceType ? 200 : SW * 0.24,
          height: deviceType ? 200 : SW * 0.24,
          borderRadius: 200,
        }}
      >
        <AbstractAvatar url={imageSource?.uri} size={"100%"} />
      </View>
      <View style={{ marginTop: 20 }}>
        <AbstractButton
          txtColor={Colors.blueprimary}
          label={"Upload"}
          txtSize={deviceType ? 20 : moderateScale(16, 0.1)}
          height={deviceType ? 40 : SW * 0.21 * 0.35}
          width={deviceType ? 130 : SW * 0.21}
          type={"transparentBlue"}
          onPress={handlePickImage}
        />
      </View>
      {!isValid ? (
        <Text style={{ color: "red" }}>*Plesae select profile image</Text>
      ) : (
        <View style={{ height: 15 }} />
      )}
    </View>
  );
};

export default MyImagePicker;

const styles = StyleSheet.create({
  mainContainer: {
    alignItems: "center",
  },
});
