import React, { useState } from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { moderateScale } from "react-native-size-matters";
import { useIsConversationSeen } from "../../Controllers/conversationsController";
import { Colors } from "../../themes";

const AbstractBottomTabButton = ({
  svg,
  width,
  label,
  myStyle,
  isFocused,
  onPress,
}) => {
  const defLabel = label ? label : "text";
  const defWidth = width ? width : "25%";
  const myFocusColor = isFocused
    ? Colors.blueprimary
    : Colors.greySecondaryFour;
  const messagesUnseen = useIsConversationSeen();
  return (
    <TouchableOpacity
      onPress={onPress}
      style={[styles.mainContainer, { width: defWidth }, { ...myStyle }]}
    >
      {defLabel === "Messages" && messagesUnseen ? (
        <View
          style={{
            height: 8,
            width: 8,
            borderRadius: 50,
            backgroundColor: "red",
            // position: "absolute",
            left: 15,
            marginTop: 5,
            // top: 20,
          }}
        />
      ) : (
        false
      )}

      {svg ? svg() : null}
      <Text style={[styles.textOne, { color: myFocusColor }]}>{defLabel}</Text>
    </TouchableOpacity>
  );
};

export default AbstractBottomTabButton;

const styles = StyleSheet.create({
  mainContainer: {
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1,
    marginTop: -13,
  },
  textOne: {
    fontFamily: "Roboto-Regular",
    fontSize: moderateScale(12, 0.1),
    color: Colors.whitePrimary,
    paddingTop: 5,
  },
});
