import mime from "react-native-mime-types";
import uuid from "react-native-uuid";
import { manipulateAsync, SaveFormat } from "expo-image-manipulator";
import { PLATFORM_DETECT } from "../../themes";
import { createObjectURL, base64StringToBlob } from "blob-util";

class ImageManipulationController {
  static ResizeImg(pictureUrl) {
    return new Promise((resolve, reject) => {
      const resizeImg =
        ImageManipulationController.compressImageQuality(pictureUrl);
      // const resizeAvatar =
      //   ImageManipulationController.generateAvatar(pictureUrl);

      Promise.all([resizeImg])
        .then((images) => {
          if (PLATFORM_DETECT.WEB) {
            const contentType = "image/png";
            var imageURL = images[0].split(",");
            // var avatarUrl = images[1].split(",");
            const imageBlob = base64StringToBlob(imageURL[1], contentType);
            // const avatarBlob = base64StringToBlob(avatarUrl[1], contentType);
            var imgUrl = createObjectURL(imageBlob);
            // var avaUrl = createObjectURL(avatarBlob);
            // console.log(imgUrl, "imageUrl");
            resolve([imageBlob]);
          }

          resolve(images);
        })
        .catch((err) => {
          console.log(err, "resize img error");
          reject(err);
        });
    });
  }

  static compressImageQuality = (imageUrl) => {
    return new Promise((resolve, reject) => {
      manipulateAsync(
        imageUrl,
        [{ resize: { height: 500, width: 500 } }],
        SaveFormat.JPEG
      )
        .then((resizedImage) => {
          resolve(resizedImage.uri);
        })
        .catch((err) => {
          console.log("CATCH error in resizing image", err);
          reject(err);
        });
    });
  };
  static generateAvatar = (imageUrl) => {
    return new Promise((resolve, reject) => {
      manipulateAsync(
        imageUrl,
        [{ resize: { height: 30, width: 30 } }],
        SaveFormat.JPEG
      )
        .then((resizedImage) => {
          resolve(resizedImage.uri);
        })
        .catch((err) => {
          console.log("CATCH error in resizing avatar", err);
          reject(err);
        });
    });
  };
}
export default ImageManipulationController;
