import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import store from "../Store";
import AuthController from "./authController";
import { ERRORS } from "./errorMessages";
import ReduxDispatchController from "./reduxDispatchController";
class ConversationsController {
  static onNewMessage({ vehicleId, offerId, message }) {
    ReduxDispatchController.CONVERSATIONS.pushMessage(offerId, message);
  }

  static getAllConversations = () => {
    return new Promise((resolve, reject) => {
      const currentUserId = AuthController.getCurrentUser()._id;
      axios
        .get(
          `/chat/message/get-by-buyer-sorted-by-last-message/${currentUserId}`
        )
        .then((res) => {
          // console.log(res.data)
          if (res.data.success) {
            const conversations = res.data.data.filter((c) => ({
              ...c.conversation,
              offerStatus: c.status,
            }));
            // const conversations = res.data.data.filter(c => {c.conversation})
            ReduxDispatchController.CONVERSATIONS.set(conversations);
            resolve(conversations);
          } else {
            console.log(
              `error in loading convesations :${res.data.error.message}`
            );
            reject(res.data.error.message);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(ERRORS.INTERNET_CONNECTIVITY);
        });
    });
  };

  static getSingleConversation = (offerId) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`/chat/message/get/${offerId}`)
        .then((res) => {
          // console.log("LOADED CONVERSATION =>", res.data)
          if (res.data.success) {
            ReduxDispatchController.CONVERSATIONS.setSingle(res.data.data);
            resolve(res.data.data);
          } else {
            reject(res.data.error.message);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(ERRORS.INTERNET_CONNECTIVITY);
        });
    });
  };

  static loadConversations = (callback_) => {
    ReduxDispatchController.CONVERSATIONS.setProcessing(true);

    ConversationsController.getAllConversations()
      .then((conversations) => {
        ReduxDispatchController.CONVERSATIONS.set(conversations);
        ReduxDispatchController.CONVERSATIONS.setProcessing(false);
        if (callback_) callback_();
      })
      .catch((err) => {
        console.log(`CATCH error in loading all conversations`);
        console.log(err);
        ReduxDispatchController.CONVERSATIONS.setProcessing(false);
        if (callback_) callback_();
      });
  };

  static loadSingleConversation = (offerId) => {
    ReduxDispatchController.CONVERSATIONS.setSingleConversationProcessing(true);
    ConversationsController.getSingleConversation(offerId)
      .then((conversation) => {
        ReduxDispatchController.CONVERSATIONS.setSingleConversationProcessing(
          false
        );
      })
      .catch((err) => {
        console.log(`CATCH error in loading conversations`);
        console.log(err);
        ReduxDispatchController.CONVERSATIONS.setSingleConversationProcessing(
          false
        );
      });
  };

  static sendMessage = (offerId, message) => {
    return new Promise((resolve, reject) => {
      const data = {
        body: message.body,
        sentTime: new Date(),
        senderType: "buyer",
      };
      axios
        .post(`/chat/message/send/${offerId}`, data)
        .then((res) => {
          if (res.data.success) {
            resolve(res.data.data);
          } else {
            reject(res.data.error.message);
          }
        })
        .catch((err) => {
          reject(ERRORS.INTERNET_CONNECTIVITY);
        });
    });
  };
  static markSeen(offerId) {
    return new Promise((resolve, reject) => {
      const data = {
        lastSeenType: "buyer",
      };
      axios
        .post(`/chat/message/mark-last-seen/${offerId}`, data)
        .then((res) => {
          if (res.data.success) {
            resolve(true);
          } else {
            reject(res.data.error.message);
          }
        })
        .catch((err) => {
          console.log(`Error in marking seen`, err);
        });
    });
  }
  static handleMarkSeen = (offerId) => {
    ConversationsController.markSeen(offerId)
      .then((res) => {
        ReduxDispatchController.CONVERSATIONS.markSeen(offerId);
      })
      .catch((err) => {
        console.log("Mark seen fail...");
        console.log(err);
      });
  };

  static getAlreadyCreatedConversationFromReduxById = (vehicleId) => {
    const conversations = store.getState().ConversationsReducer.conversations;
    return conversations?.find((i) => i?.vehicle?._id === vehicleId);
  };
}
export default ConversationsController;

export const useConversations = (_callback = () => false) => {
  useEffect(() => {
    setTimeout(() => {
      ConversationsController.loadConversations(_callback);
    }, 500);
  }, []);
  const loadConversations = ConversationsController.loadConversations;
  const { conversations, loading } = useSelector((state) => ({
    conversations: state.ConversationsReducer.conversations,
    loading: state.ConversationsReducer.loading,
  }));
  //sort conversations by date
  return { conversations, loading, loadConversations };
};

export const useSingleConversation = (offerId) => {
  const loadSingleConversation = () => {
    ConversationsController.loadSingleConversation(offerId);
  };

  useEffect(() => {
    loadSingleConversation();
  }, []);

  let conversation = null;
  let messages = [];
  const { conversations, loading } = useSelector((state) => ({
    conversations: state.ConversationsReducer.conversations,
    loading: state.ConversationsReducer.loadingSingleConversation,
  }));

  const conversationIndex = conversations.findIndex((c) => c._id === offerId);
  if (conversationIndex >= 0) {
    conversation = conversations[conversationIndex];
    if (conversation?.messages) {
      messages = conversation.messages;
    }
  }
  return { loading, conversation, messages, loadSingleConversation };
};

export const useIsConversationSeen = () => {
  const seeIndex = useSelector((state) =>
    state.ConversationsReducer.conversations.findIndex(
      (c) => c.conversation?.buyerLastSeen === false
    )
  );
  return seeIndex >= 0;
};
