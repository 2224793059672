import React from "react";
import Svg, { Path } from "react-native-svg";
import { Colors } from "../../../themes";

const SavedSvg = ({ isFocused, size }) => {
  const myFocusColor = isFocused
    ? Colors.blueprimary
    : Colors.greySecondaryFour;
  const defaultSize = size ? size : 22;

  return (
    <Svg
      width="18"
      height="22"
      viewBox="0 0 18 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.66475 17.6316L2.7334 20.881C2.25983 21.1271 1.6764 20.9529 1.41532 20.4874V20.4874C1.33979 20.3432 1.29904 20.1833 1.29633 20.0206V5.62241C1.29633 2.87641 3.17276 1.77802 5.87298 1.77802H12.2162C14.8341 1.77802 16.7929 2.80319 16.7929 5.43934V20.0206C16.7929 20.2803 16.6897 20.5294 16.506 20.7131C16.3224 20.8968 16.0732 21 15.8135 21C15.6478 20.9974 15.4849 20.9566 15.3375 20.881L9.36955 17.6316C9.14964 17.5128 8.88466 17.5128 8.66475 17.6316Z"
        stroke={myFocusColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default SavedSvg;
