import { DEVICE } from "../types";
import produce from "immer";
const state = {
  deviceType: false,
};

const DeviceTypeReducer = (mState = { ...state }, action) => {
  switch (action.type) {
    case DEVICE.DEVICE_TYPE:
      return produce(mState, (draftState) => {
        draftState.deviceType = action.payload;
      });
    default:
      return { ...mState };
  }
};
export default DeviceTypeReducer;
