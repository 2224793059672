import S3FilesUploader from "./s3FilesUploader";
import { base64StringToBlob, createObjectURL } from "blob-util";

class UploadController {
  static uploadPicture(pictureUrl, onProgress = (percentage) => false) {
    return new Promise((resolve, reject) => {
      // console.log("pictureUrl", pictureUrl);
      let imageUploader = new S3FilesUploader("", pictureUrl.uri);
      let avatarUploader = new S3FilesUploader("", pictureUrl.uri);

      Promise.all([
        imageUploader.compressImageQuality(pictureUrl.uri),
        avatarUploader.generateAvatar(pictureUrl.uri),
      ]).then((images) => {
        // console.log(images, "?");
        const contentType = "image/png";
        var imageURL = images[0].split(",");
        var avatarUrl = images[1].split(",");
        const imageBlob = base64StringToBlob(imageURL[1], contentType);
        const avatarBlob = base64StringToBlob(avatarUrl[1], contentType);
        var imgUrl = createObjectURL(imageBlob);
        var avaUrl = createObjectURL(avatarBlob);
        // console.log(imgUrl, "imageUrl", avaUrl);
      });
    });
  }
}
export default UploadController;
