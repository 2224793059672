import React from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {moderateScale} from 'react-native-size-matters';
import {Fonts} from '../../../themes';

const AbstractH1 = ({txtColor, children}) => {
  const defTxtColor = txtColor ? txtColor : 'white';

  return (
    <View>
      <Text style={[styles.textOne, {color: defTxtColor}]}>{children}</Text>
    </View>
  );
};

export default AbstractH1;

const styles = StyleSheet.create({
  textOne: {
    fontSize: moderateScale(40, 0.1),
    fontFamily: Fonts.bold,
    textAlign: 'center',
    fontWeight: '600',
  },
});
