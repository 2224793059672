import React, { useEffect, useRef, useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  ActivityIndicator,
  Platform,
  NativeModules,
} from "react-native";
import { moderateScale } from "react-native-size-matters";
import { Colors, Fonts, PLATFORM_DETECT } from "../../themes";
import FilterSvg from "../../Assets/Icons/filterSvg";
import AbstractContentContainer from "../../Components/AbstractComponents/abstractContentContainer";
import ScreenContainer from "../../Components/AbstractComponents/abstractScreenContainer";
import FocusAwareStatusBar from "../../Components/AbstractComponents/statusbarConfiguration";
import { goBack, navigate, push } from "../../Navigation/mainNavigation";
import SubmitOfferStepsSheet from "../../Components/CompositeComponents/submitOfferStepsSheetV2";
import VehiclesController from "../../Controllers/vehiclesController";
import { SheetManager } from "react-native-actions-sheet";
import AuthController from "../../Controllers/authController";
import OffersController from "../../Controllers/offersController";
import ReduxDispatchController from "../../Controllers/reduxDispatchController";
import NewDesignCardSwipperV2 from "../../Components/ModuleComponents/NewDesignCardSwipperV2";
import MakeFilterRedesigned from "../../Components/ModuleComponents/makeFilterRedesigned";
import AbstractBottomSheet from "../../Components/AbstractComponents/abstractBottomSheetV2";
import AbstractOptionButton from "../../Components/AbstractComponents/abstractOptionButton";
import MessagesSvg from "../../Assets/Icons/bottomTabsSvgs/messagesSvg";
import SavedSvg from "../../Assets/Icons/bottomTabsSvgs/savedSvg";
import { useSelector } from "react-redux";
import BackBtnSvg from "../../Assets/Icons/backBtnSvg";
import ConversationsController, {
  useConversations,
} from "../../Controllers/conversationsController";

const { StatusBarManager } = NativeModules;
const HomeScreenHeader = ({ cStyle }) => {
  return (
    <AbstractContentContainer>
      <View style={styles.headerView}>
        <TouchableOpacity
          onPress={() => goBack()}
          style={[
            {
              alignItems: "center",
              justifyContent: "center",
              height: "75%",
              flexDirection: "row",
            },
            cStyle,
          ]}
        >
          <BackBtnSvg color={Colors.blackPrimary} />
        </TouchableOpacity>
        <Text style={styles.txtOne}>My Feed</Text>
        <TouchableOpacity
          onPress={() => navigate("Filter")}
          style={[
            {
              alignItems: "flex-end",
              justifyContent: "center",
              height: "75%",
            },
            cStyle,
          ]}
        >
          <FilterSvg />
        </TouchableOpacity>
      </View>
    </AbstractContentContainer>
  );
};

const HomeScreen = (props) => {
  const {} = useConversations();
  const sheetRef = useRef();

  const { deviceType } = useSelector((state) => state.DeviceTypeReducer);

  const [vehicleDetails, setVehicleDetails] = useState({});
  const [sendMessageLoading, setSendMessageLoading] = useState(false);

  const swippingRight = (card) => {
    setVehicleDetails(card);
    SheetManager.show("likeOptions");
  };

  const onPressSendOffer = () => {
    SheetManager.hide("likeOptions");
    setTimeout(() => {
      SheetManager.show("Car-Detail-Sheet-v2");
    }, 300);
  };

  const onPressSendMessage = () => {
    SheetManager.hide("likeOptions");
    const offer =
      ConversationsController.getAlreadyCreatedConversationFromReduxById(
        vehicleDetails._id
      );
    if (offer) {
      setSendMessageLoading(false);
      navigate("Chat", { offer: offer, offerType: offer?.offerType });
    } else {
      setSendMessageLoading(true);
      OffersController.handleInitializeConversation(
        vehicleDetails._id,
        false,
        vehicleDetails?.title
      )
        .then((offer) => {
          SheetManager.hide("likeOptions");
          setSendMessageLoading(false);
          navigate("Chat", { offer: offer, offerType: offer?.offerType });
        })
        .catch((error) => {
          setSendMessageLoading(false);
          console.log(error);
        });
    }
  };
  useEffect(() => {
    ReduxDispatchController.VEHICLES.setVehiclesLoading(true);
    setTimeout(() => {
      VehiclesController.applyFilters("All", "All", "All", 0);
    }, 500);
  }, []);
  return (
    <ScreenContainer
      upperStyle={{ backgroundColor: Colors.screenBgColor }}
      bottomStyle={{ backgroundColor: Colors.screenBgColor }}
    >
      <View style={styles.mainContainer}>
        {Platform.OS === "android" ? (
          <View style={{ height: StatusBarManager.HEIGHT }} />
        ) : (
          false
        )}
        <FocusAwareStatusBar
          barStyle="dark-content"
          backgroundColor={"transparent"}
          translucent={true}
        />
        <View
          style={
            deviceType
              ? {
                  paddingBottom: 10,
                  shadowColor: Colors.blueprimary,
                  shadowOffset: {
                    width: 0,
                    height: 1,
                  },
                  shadowOpacity: 0.32,
                  shadowRadius: 5.95,
                  elevation: 10,
                  borderRadius: 15,
                }
              : {}
          }
        >
          <HomeScreenHeader cStyle={{ marginTop: deviceType ? 0 : 0 }} />
          <View style={{ marginVertical: 5 }}>
            <MakeFilterRedesigned />
          </View>
        </View>

        <View style={{ flex: 1, alignItems: "center" }}>
          <NewDesignCardSwipperV2 swippingRight={swippingRight} />
        </View>

        {sendMessageLoading ? (
          <View
            style={[
              StyleSheet.absoluteFillObject,
              {
                backgroundColor: "rgba(0,0,0,0.3)",
                zIndex: 9999,
                justifyContent: "center",
                alignItems: "center",
              },
            ]}
          >
            <ActivityIndicator size={"large"} color={"white"} />
          </View>
        ) : (
          false
        )}
      </View>

      <SubmitOfferStepsSheet
        onClose={() => SheetManager.hide("Car-Detail-Sheet-v2")}
        vehicle={vehicleDetails}
        isCreated={true}
      />

      <AbstractBottomSheet id={"likeOptions"}>
        <View style={{ width: "100%" }}>
          <View style={{ alignSelf: "center", marginTop: -10 }}>
            <Text style={styles.textThree}>Options</Text>
          </View>
          <AbstractContentContainer>
            <AbstractOptionButton
              onPress={onPressSendOffer}
              textSize={moderateScale(17, 0.1)}
              label={"Send Offer"}
              icon={() => <SavedSvg isFocused size={20} />}
            />
            <AbstractOptionButton
              onPress={onPressSendMessage}
              textSize={moderateScale(17, 0.1)}
              label={"Send Message"}
              icon={() => <MessagesSvg color={"#2674E5"} size={20} />}
            />
          </AbstractContentContainer>
          <View style={{ width: "100%", height: 30 }} />
        </View>
      </AbstractBottomSheet>
    </ScreenContainer>
  );
};

export default HomeScreen;

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: Colors.screenBgColor,
    // backgroundColor: "green",
  },
  txtOne: {
    fontFamily: Fonts.bold,
    fontSize: moderateScale(20, 0.1),
    color: Colors.blueSecondary,
    marginLeft: 10,
  },
  viewOne: {
    width: "100%",
    alignSelf: "center",
  },
  headerView: {
    width: "100%",
    height: moderateScale(55, 0.1),
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textThree: {
    fontFamily: Fonts.bold,
    color: Colors.blackPrimary,
    fontSize: moderateScale(19, 0.1),
    paddingBottom: 5,
  },
  buttonStyle: {
    width: "100%",
    height: 40,
    justifyContent: "center",
  },
  textButton: {
    color: Colors.blackPrimary,
    fontSize: moderateScale(17, 0.1),
    fontFamily: Fonts.default,
  },
});
