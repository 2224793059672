import React, { useState } from "react";
import { View, Text, StyleSheet, ActivityIndicator } from "react-native";
import { moderateScale } from "react-native-size-matters";
import { WebView } from "react-native-webview";
import BackBtnSvg from "../../Assets/Icons/backBtnSvg";
import LogoSvg from "../../Assets/Icons/logoSvg";
import AbstractHeaders from "../../Components/AbstractComponents/abstractHeaders";
import ScreenContainer from "../../Components/AbstractComponents/abstractScreenContainer";
import PPWebView from "../../Components/ModuleComponents/ppWebview";
import { goBack } from "../../Navigation/mainNavigation";
import { Colors, Fonts } from "../../themes";

const WebViewLoader = ({}) => {
  return (
    <View style={{ flexDirection: "row" }}>
      <ActivityIndicator
        color={Colors.blueSecondaryOne}
        style={{ marginRight: -7 }}
      />
      <LogoSvg size={100} />
    </View>
  );
};

const CustomWebViewScreen = (props) => {
  const [loading, setLoading] = useState(true);
  // console.log(props.route.params.url)
  return <PPWebView title={"VEHICLE REPORT"} uri={props.route.params.url} />;
};
export default CustomWebViewScreen;

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
  },
  textOne: {
    fontFamily: Fonts.default,
    color: "black",
    fontSize: moderateScale(19, 0.1),
    paddingRight: 20,
  },
  textTwo: {
    fontFamily: Fonts.default,
    color: "black",
    fontSize: moderateScale(19, 0.1),
    fontWeight: "800",
    textAlign: "center",
  },
  textThree: {
    fontFamily: Fonts.default,
    color: "black",
    fontSize: moderateScale(12, 0.1),
    lineHeight: 18,
  },
});
