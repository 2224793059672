import React from "react";
import { Path, Svg, SvgXml } from "react-native-svg";

const AuctionArrowForwardSvg = ({size}) => {
  const defaultSize = size ? size : 12
  return (
    <Svg
      width={defaultSize}
      height={defaultSize}
      viewBox="0 0 12 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path
        d="M6.86663 8.01654C6.73329 7.8832 6.66929 7.72209 6.67463 7.5332C6.6804 7.34431 6.74996 7.1832 6.88329 7.04987L8.76663 5.16654H1.33329C1.1444 5.16654 0.985959 5.10254 0.857959 4.97454C0.730404 4.84698 0.666626 4.68876 0.666626 4.49987C0.666626 4.31098 0.730404 4.15254 0.857959 4.02454C0.985959 3.89698 1.1444 3.8332 1.33329 3.8332H8.76663L6.86663 1.9332C6.73329 1.79987 6.66663 1.64143 6.66663 1.45787C6.66663 1.27476 6.73329 1.11654 6.86663 0.983203C6.99996 0.84987 7.1584 0.783203 7.34196 0.783203C7.52507 0.783203 7.68329 0.84987 7.81663 0.983203L10.8666 4.0332C10.9333 4.09987 10.9806 4.17209 11.0086 4.24987C11.0362 4.32765 11.05 4.41098 11.05 4.49987C11.05 4.58876 11.0362 4.67209 11.0086 4.74987C10.9806 4.82765 10.9333 4.89987 10.8666 4.96654L7.79996 8.0332C7.67774 8.15543 7.52507 8.21654 7.34196 8.21654C7.1584 8.21654 6.99996 8.14987 6.86663 8.01654Z"
        fill="#2674E5"
      />
    </Svg>
  );
};

export default AuctionArrowForwardSvg;
