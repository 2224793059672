import React from "react";
import { Path, Svg, SvgXml } from "react-native-svg";

const ArrowForwardSvg = () => {
  return (
    <Svg
      width="8"
      height="17"
      viewBox="0 0 8 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path
        d="M1.32738 8.39233e-05C1.49815 -0.000534058 1.66688 0.0395012 1.82117 0.117243C1.97546 0.194984 2.11139 0.308458 2.21897 0.449327L7.73997 7.73435C7.90809 7.95161 8 8.22412 8 8.50535C8 8.78658 7.90809 9.0591 7.73997 9.27635L2.02465 16.5614C1.83063 16.8093 1.55182 16.9653 1.24957 16.9949C0.947313 17.0245 0.646366 16.9253 0.412933 16.7192C0.179501 16.5131 0.0327044 16.217 0.00483656 15.8959C-0.0230312 15.5749 0.0703135 15.2552 0.264335 15.0072L5.37383 8.49928L0.435795 1.99132C0.296018 1.8131 0.207228 1.59608 0.179933 1.36594C0.152637 1.13579 0.187976 0.902155 0.281772 0.692677C0.375566 0.483198 0.52389 0.306644 0.709193 0.183903C0.894495 0.0611629 1.10902 -0.00262642 1.32738 8.39233e-05Z"
        fill="#6D6D6D"
      />
    </Svg>
  );
};

export default ArrowForwardSvg;
