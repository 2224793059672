import {
  Dimensions,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import React from "react";
import { useSelector } from "react-redux";
import ImagePlacer from "../imagePlacer";
import { getFirstImage } from "../../../Controllers/vehiclesController";
import AbstractButton from "../../AbstractComponents/abstractButton";
import { Colors, Fonts, PLATFORM_DETECT } from "../../../themes";
import { moderateScale } from "react-native-size-matters";
import { navigate } from "../../../Navigation/mainNavigation";
import AuctionSaveSvg from "../../../Assets/Icons/auctionSaveSvg";
import { dummyVehicleForBid } from "../../../Utils/Moc";
import { useCountDown } from "../../../Controllers/auctionController";
import { useUserName } from "../../../Controllers/authController";

const SH = Dimensions.get("window").height;
const SW = Dimensions.get("window").width;

const BUTTON_WIDTH = SW * (260 / 414);
const BUTTON_HEIGHT = BUTTON_WIDTH * (60 / 260);

export const CARD_UPPER_PART_WIDTH = SW * (300 / 414);
export const CARD_UPPER_PART_HEIGHT = CARD_UPPER_PART_WIDTH * (230 / 300);

export const CARD_LOWER_PART_WIDTH = SW * (300 / 414);
export const CARD_LOWER_PART_HEIGHT = CARD_LOWER_PART_WIDTH * (141 / 300);

const AuctionCardItem = ({ item, suggestionCard, disabledBtn }) => {
  const userDetail = useUserName();

  const { deviceType } = useSelector((state) => state.DeviceTypeReducer);
  const { days, hours, minutes, seconds } = useCountDown(
    item?.auctionStartDateTime
  );
  return (
    <View
      style={[
        styles.cardContainer,
        {
          width: "100%",
          height: "100%",
        },
      ]}
    >
      <View
        style={{
          height:
            PLATFORM_DETECT.WEB && !deviceType
              ? 220
              : PLATFORM_DETECT.WEB
              ? 250
              : CARD_UPPER_PART_HEIGHT,
          width: "100%",
          borderTopRightRadius: 25,
          borderTopLeftRadius: 25,
        }}
      >
        {suggestionCard ? (
          <>
            <ImagePlacer
              imageUrl={getFirstImage(item?.vehicle?.pictures).image.url}
              avatarUrl={getFirstImage(item?.vehicle?.pictures).avatar.url}
              type={"large"}
            />
            <View style={{ margin: 4, position: "absolute" }}>
              {item?.numberOfBids && (
                <View
                  style={{
                    backgroundColor: "rgba(241,156,56,0.9)",
                    paddingHorizontal: 3,
                    paddingVertical: 3,
                    borderRadius: 5,
                  }}
                >
                  <Text
                    style={{
                      color: "white",
                      fontSize: 10,
                      textAlign: "center",
                      fontFamily: Fonts.default,
                    }}
                  >
                    Reserve met
                  </Text>
                </View>
              )}
            </View>
          </>
        ) : (
          <ImagePlacer
            imageUrl={getFirstImage(item.pictures).image.url}
            avatarUrl={getFirstImage(item.pictures).avatar.url}
            type={"large"}
          />
        )}
        {!suggestionCard ? (
          <View
            style={{
              backgroundColor: Colors.whitePrimary,
              position: "absolute",
              paddingVertical: 6,
              paddingHorizontal: 13,
              borderRadius: 5,
              margin: 20,
            }}
          >
            <Text style={styles.timeTxt}>
              {`${days}D : ${hours}H : ${minutes}M`}
            </Text>
          </View>
        ) : (
          false
        )}
      </View>
      <View
        style={{
          backgroundColor: Colors.blackPrimary,
          height:
            PLATFORM_DETECT.WEB && !deviceType
              ? 115
              : PLATFORM_DETECT.WEB
              ? 126
              : CARD_LOWER_PART_HEIGHT,
          width: "100%",
          borderBottomRightRadius: 25,
          borderBottomLeftRadius: 25,
          paddingTop: 20,
          paddingBottom: 25,
        }}
      >
        <View
          style={{
            flex: 1,
            width: "88%",
            alignSelf: "center",
            justifyContent: "center",
          }}
        >
          {!suggestionCard ? (
            <>
              <Text style={styles.txtOne}>Auction</Text>
              <Text style={styles.txtTwo}>
                {item.title?.length > 30
                  ? item.title.substring(0, 30 - 3) + "..."
                  : item.title}
              </Text>
            </>
          ) : (
            <>
              <Text style={[styles.txtFour, { fontSize: 18 }]}>
                {item?.vehicle?.title?.length > 25
                  ? item?.vehicle?.title?.substring(0, 25 - 3) + "..."
                  : item?.vehicle?.title}
              </Text>
              <View
                style={{
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Text
                  style={[
                    styles.txtFive,
                    { color: Colors.whitePrimary, marginVertical: 5 },
                  ]}
                >
                  {item?.numberOfBids || item?.bids?.length > 0
                    ? "Reserve met" //`Bids: ${item?.numberOfBids} . Last bid: $${item?.lastBidAmount}`
                    : `Bid starting at: $${item?.initialBidAmount}`}
                </Text>
              </View>
            </>
          )}
          {!suggestionCard ? (
            <TouchableOpacity
              activeOpacity={0.8}
              disabled={disabledBtn}
              pointerEvents={"none"}
              onPress={() => navigate("AuctionDetail", { auctionId: item._id })}
              style={{
                width: "100%",
                height: PLATFORM_DETECT.WEB ? 50 : BUTTON_HEIGHT,
                backgroundColor: Colors.blueprimary,

                borderRadius: 10,
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
              }}
            >
              <Text style={styles.textBtnTxt}>View Details</Text>
            </TouchableOpacity>
          ) : (
            <TouchableOpacity
              activeOpacity={0.8}
              pointerEvents={"none"}
              disabled={disabledBtn}
              onPress={() =>
                navigate(
                  userDetail?.fullName
                    ? "CarDetailPlaceBid"
                    : "PlaceBidAndBuyNow",
                  {
                    auctionVehicleId: item._id,
                  }
                )
              }
              style={{
                width: "100%",
                height: PLATFORM_DETECT.WEB ? 50 : BUTTON_HEIGHT,
                backgroundColor: Colors.blueprimary,
                borderRadius: 10,
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center",
              }}
            >
              <Text style={styles.textBtnTxt}>View Car</Text>
            </TouchableOpacity>
          )}
        </View>
      </View>
    </View>
  );
};

export default AuctionCardItem;

const styles = StyleSheet.create({
  cardContainer: {
    alignSelf: "center",
    borderRadius: 25,
    justifyContent: "center",
    alignItems: "center",
  },
  txtOne: {
    fontFamily: Fonts.default,
    color: Colors.blueprimary,
    fontSize: moderateScale(12, 0.1),
    paddingBottom: 5,
  },
  txtTwo: {
    fontFamily: Fonts.default,
    color: Colors.whitePrimary,
    fontSize: moderateScale(14, 0.1),
    paddingBottom: 10,
  },
  textThree: {
    fontFamily: Fonts.medium,
    color: Colors.whitePrimary,
    fontSize: moderateScale(19, 0.1),
    paddingBottom: 10,
  },
  txtFour: {
    fontFamily: Fonts.medium,
    color: Colors.whitePrimary,
    fontSize: moderateScale(13, 0.1),
    // paddingBottom: 5,
  },
  txtFive: {
    fontFamily: Fonts.default,
    color: Colors.whitePrimary,
    fontSize: moderateScale(14, 0.1),
  },
  timeTxt: {
    fontFamily: Fonts.medium,
    color: Colors.blackPrimary,
    fontSize: moderateScale(10, 0.1),
    fontWeight: "600",
  },
  textBtnTxt: {
    fontFamily: Fonts.medium,
    color: Colors.whitePrimary,
    fontSize: moderateScale(16, 0.1),
    fontWeight: "600",
  },
});
