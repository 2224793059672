import React from "react";
import { Platform, View } from "react-native";
import FocusAwareStatusBar from "../../../Components/AbstractComponents/statusbarConfiguration";
import PPWebView from "../../../Components/ModuleComponents/ppWebview";
import { getStatusBarHeight } from "react-native-status-bar-height";
import ScreenContainer from "../../../Components/AbstractComponents/abstractScreenContainer";
import { Colors } from "../../../themes";

const STATUS_BAR_HEIGHT = getStatusBarHeight();

const TermsOfUseScreen = (props) => {
  return (
    <ScreenContainer
      upperStyle={{ backgroundColor: Colors.whitePrimary }}
      bottomStyle={{ backgroundColor: Colors.whitePrimary }}
    >
      <View style={{ flex: 1 }}>
        <FocusAwareStatusBar
          barStyle="dark-content"
          backgroundColor={Colors.whitePrimary}
          translucent={true}
        />
        <View
          style={{ height: Platform.OS === "android" ? STATUS_BAR_HEIGHT : 0 }}
        />

        <PPWebView
          title={"Terms of Use"}
          uri={
            "https://docs.google.com/document/d/e/2PACX-1vTI75_knrdfDopBLr4DdToRHvtblt8pTQeFofl4ksqWr3UqYVWB3yR0cgt1CkxPTQ/pub"
          }
        />
      </View>
    </ScreenContainer>
  );
};
export default TermsOfUseScreen;
