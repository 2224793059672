import produce from "immer";
import { CONVERSATIONS } from "../types";
import GlobalConstants from "../../Controllers/constants";

const state = {
  conversations: [],
  loading: false,
  loadingSingleConversation: false,
};

const sortConversations = (conversations = []) => {
  conversations.sort((a, b) =>
    a.conversation?.lastMessage?.sentTime == undefined ||
    b.conversation?.lastMessage?.sentTime == undefined
      ? conversations
      : new Date(b.conversation?.lastMessage?.sentTime) -
        new Date(a.conversation?.lastMessage?.sentTime)
  );
};

const ConversationsReducer = (mState = { ...state }, action) => {
  switch (action.type) {
    case CONVERSATIONS.SET_CONVERSATIONS:
      return produce(mState, (draftState) => {
        draftState.conversations = action.payload;
        // console.log(`sorting`, draftState.conversations[0].conversation.lastMessage.sentTime)
        sortConversations(draftState.conversations);
      });
    case CONVERSATIONS.SET_KEY_VALUE:
      return produce(mState, (draftState) => {
        draftState[action.payload.key] = action.payload.value;
      });
    case CONVERSATIONS.SET_SINGLE_CONVERSTION:
      return produce(mState, (draftState) => {
        const offer = action.payload;
        const convesationIndex = draftState.conversations.findIndex(
          (c) => c._id === offer._id
        );
        if (convesationIndex >= 0) {
          // console.log("OFFER ->", offer)
          draftState.conversations[convesationIndex] = {
            ...draftState.conversations[convesationIndex],
            lastMessage:
              offer.conversation.message[offer.conversation.message.length - 1],
            messages: offer.conversation.message.reverse(),
            business: offer.business,
            vehicle: offer.vehicle,
          };
        } else {
          // console.log(`Invalid conversation/offerid: not found.`)
        }
      });
    case CONVERSATIONS.PUSH_MESSAGE:
      return produce(mState, (draftState) => {
        const { offerId, message } = action.payload;
        const convesationIndex = draftState.conversations.findIndex(
          (c) => c._id === offerId
        );
        if (convesationIndex >= 0) {
          if (draftState.conversations[convesationIndex].messages) {
            draftState.conversations[convesationIndex].messages.unshift(
              message
            );
          }
          draftState.conversations[convesationIndex].conversation.lastMessage =
            message;
          sortConversations(draftState.conversations);
        } else {
          console.log(`Invalid conversation/offerid ${offerId}: not found.`);
        }
      });
    case CONVERSATIONS.UPDATE_MESSAGE:
      return produce(mState, (draftState) => {
        const { offerId, message, messageId } = action.payload;
        const convesationIndex = draftState.conversations.findIndex(
          (c) => c._id === offerId
        );
        if (convesationIndex >= 0) {
          const messageIndex = draftState.conversations[
            convesationIndex
          ].messages.findIndex((m) => m._id === messageId);
          if (messageIndex >= 0) {
            draftState.conversations[convesationIndex].messages[messageIndex] =
              message;
            draftState.conversations[
              convesationIndex
            ].conversation.lastMessage = message;
            // console.log(`Sent message updated`, draftState.conversations[convesationIndex].messages[messageIndex])
          } else {
            console.log(`Invalid message ${messageId}, not found`);
          }
        } else {
          console.log(`Invalid conversation/offerid ${offerId}: not found.`);
        }
      });
    case CONVERSATIONS.MARK_SEEN:
      return produce(mState, (draftState) => {
        const { offerId } = action.payload;
        const convesationIndex = draftState.conversations.findIndex(
          (c) => c._id === offerId
        );
        if (convesationIndex >= 0) {
          // console.log(`Marking conversation as seen true`)
          draftState.conversations[
            convesationIndex
          ].conversation.buyerLastSeen = true;
        } else {
          console.log(`Invalid conversation/offerid ${offerId}: not found.`);
        }
      });
    case CONVERSATIONS.PUSH_SINGLE_CONVERSATION:
      return produce(mState, (draftState) => {
        draftState.conversations.unshift(action.payload);
      });
    case CONVERSATIONS.CONVERSATION_STATUS_UPDATE:
      return produce(mState, (draftState) => {
        const findConversationIndex = draftState.conversations.findIndex(
          (item) => item._id == action.payload.conversation._id
        );
        if (findConversationIndex >= 0) {
          draftState.conversations[findConversationIndex].status =
            action.payload.status;
          draftState.conversations[findConversationIndex].amount =
            action.payload.conversation.amount;
        }
      });
    case CONVERSATIONS.DELETE_CONVERSATION:
      return produce(mState, (draftState) => {
        const newConversationArray = draftState.conversations.filter(
          (item) => item._id != action.payload
        );
        draftState.conversations = newConversationArray;
      });

    case CONVERSATIONS.UPDATE_BUY_NOW_CONVERSATIONS_STATUS:
      return produce(mState, (draftState) => {
        if (draftState.conversations.length > 0) {
          let matchConversations = draftState.conversations.map((i) => {
            if (
              i?._id != action.payload?.offerId &&
              i?.vehicle?._id === action.payload?.vehicleID &&
              i?.status === GlobalConstants.OFFER_STATUS.CONVERSATION &&
              i?.offerType === "buyNow"
            ) {
              return {
                ...i,
                status: (i.status = GlobalConstants.OFFER_STATUS.EXPIRED),
              };
            } else {
              return { ...i };
            }
          });
          draftState.conversations = matchConversations;
        }
      });

    default:
      return { ...mState };
  }
};
export default ConversationsReducer;
