import React, { useEffect, useRef, useState } from "react";
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ScrollView,
  Dimensions,
  KeyboardAvoidingView,
} from "react-native";
import AbstractHeaders from "../../Components/AbstractComponents/abstractHeaders";
import { Colors, Fonts, PLATFORM_DETECT } from "../../themes";
import ScreenContainer from "../../Components/AbstractComponents/abstractScreenContainer";
import FocusAwareStatusBar from "../../Components/AbstractComponents/statusbarConfiguration";
import BackBtnSvg from "../../Assets/Icons/backBtnSvg";
import {
  clearAndNavigate,
  goBack,
  navigate,
} from "../../Navigation/mainNavigation";
import { moderateScale } from "react-native-size-matters";
import AbstractContentContainer from "../../Components/AbstractComponents/abstractContentContainer";
import ImageUploader from "../../Components/ModuleComponents/imageUploader";
import AbstractTextInput from "../../Components/AbstractComponents/abstractTextInput";
import AbstractButton from "../../Components/AbstractComponents/abstractButton";
import MyImagePicker from "../../Components/CompositeComponents/imagePicker";
import { Formik } from "formik";
import createAccount from "../../Validations/Validatons";
import AuthController from "../../Controllers/authController";
import axios from "axios";
import UploadController from "../../Controllers/UploadController";
import TermsAndConditionsCheckBox from "../../Components/ModuleComponents/termsAndConditionCheckBox";
import { useSelector } from "react-redux";
const SH = Dimensions.get("window").height;

const CreateAccountScreen = (props) => {
  const { deviceType } = useSelector((state) => state.DeviceTypeReducer);
  const [processing, setProcessing] = useState(false);
  const [errorMessgae, setErrorMessage] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [imageSource, setImageSource] = useState(null);
  const [isImageValid, setImageValid] = useState(true);
  const [isUsernameValid, setUsernameValid] = useState(false);
  const [userNameValidaionMessage, setUsernameValidationMessage] = useState("");
  const [checkNameProcessing, setCheckUsernameProcessing] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [termsAcceptedErr, setTermsAcceptedErr] = useState("");

  const lastValidUsername = useRef("");
  useEffect(() => {
    console.log(
      AuthController.isLinkAvailAble,
      "available in create profileScreen"
    );

    setPhoneNumber(props.route?.params?.phoneNumber);
  }, []);

  const handleCheckUserName = (username, submitInfo) => {
    if (username.trim()) {
      setCheckUsernameProcessing(true);
      AuthController.checkUsernameValidity(username)
        .then((isValid) => {
          setUsernameValid(isValid);
          setCheckUsernameProcessing(false);
          if (!isValid) {
            setUsernameValidationMessage(`${username} already taken!`);
          } else {
            lastValidUsername.current = username;
            setUsernameValidationMessage(``);
            if (submitInfo) handleSetupAccountInfo(submitInfo);
          }
        })
        .catch((err) => {
          setCheckUsernameProcessing(false);
          setErrorMessage(err);
        });
    }
  };

  const onSubmit = (formData) => {
    console.log(formData, "formDataformDataformData");
    if (imageSource) {
      setImageValid(true);
      if (isUsernameValid && lastValidUsername.current === formData.userName) {
        handleSetupAccountInfo(formData);
      } else {
        handleCheckUserName(formData.userName, formData);
      }
    } else {
      setImageValid(false);
    }
  };

  const handleSetupAccountInfo = ({ fullName, userName }) => {
    // console.log(
    //   `Submit form with username:${userName} fullName:${fullName}, phoneNumber: ${phoneNumber},image:${imageSource}`
    // );
    setProcessing(true);
    AuthController.setupProfile_web(
      phoneNumber,
      userName,
      fullName,
      imageSource
    )
      .then((success) => {
        setProcessing(false);
      })
      .catch((err) => {
        console.log(err);
        setProcessing(false);
        setErrorMessage(err);
      });
  };

  return (
    <ScreenContainer
      upperStyle={{ backgroundColor: Colors.screenBgColor }}
      bottomStyle={{ backgroundColor: Colors.screenBgColor }}
    >
      <Formik
        initialValues={{ userName: "", fullName: "", termsAccepted: false }}
        onSubmit={onSubmit}
        validateOnMount={true}
        validationSchema={createAccount}
      >
        {({
          handleChange,
          handleBlur,
          handleSubmit,
          setValues,
          values,
          touched,
          errors,
          isValid,
        }) => (
          <React.Fragment>
            <View style={styles.containerTop}>
              <FocusAwareStatusBar
                barStyle="dark-content"
                backgroundColor={"transparent"}
                translucent={true}
              />

              <AbstractHeaders
                leftChild={() => (
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    {<BackBtnSvg />}
                  </View>
                )}
                onPressLeft={() => goBack()}
              />

              <ScrollView
                showsVerticalScrollIndicator={false}
                keyboardShouldPersistTaps={"handled"}
                contentContainerStyle={{ paddingBottom: deviceType ? 40 : 0 }}
              >
                <AbstractContentContainer>
                  <KeyboardAvoidingView enabled behavior={"position"}>
                    <View
                      style={[
                        {
                          backgroundColor: Colors.screenBgColor,
                          width: deviceType ? "60%" : "100%",
                          paddingVertical: deviceType ? 40 : 0,
                          borderRadius: deviceType ? 10 : 0,
                          marginTop: deviceType ? 50 : 0,
                          alignSelf: "center",
                        },
                        deviceType
                          ? {
                              shadowColor: Colors.blueprimary,
                              shadowOffset: {
                                width: 0,
                                height: 4,
                              },
                              shadowOpacity: 0.38,
                              shadowRadius: 12.84,
                              elevation: 2,
                            }
                          : {},
                      ]}
                    >
                      <View
                        style={{
                          marginBottom: 10,

                          alignSelf: deviceType ? "center" : "flex-start",
                        }}
                      >
                        <Text style={styles.headingText}>
                          Create your account
                        </Text>
                      </View>

                      <View style={{ marginTop: 25 }}>
                        <MyImagePicker
                          isValid={isImageValid}
                          onChange={(source) => setImageSource(source)}
                        />
                      </View>

                      <View
                        style={{
                          marginTop: 30,
                          alignItems: deviceType ? "center" : "flex-start",
                        }}
                      >
                        <AbstractTextInput
                          label={"User Name"}
                          placeHolder={"Enter your user name"}
                          onChangeText={handleChange("userName")}
                          onBlur={(e) => {
                            // console.log(`On Blur userame`);
                            handleCheckUserName(values.userName);
                          }}
                          width={"80%"}
                          processing={checkNameProcessing}
                          value={values.userName}
                          overrideErrorMessage={userNameValidaionMessage}
                          errors={errors.userName}
                          touched={touched.userName}
                        />
                      </View>

                      <View
                        style={{
                          marginTop: 10,
                          alignItems: deviceType ? "center" : "flex-start",
                        }}
                      >
                        <AbstractTextInput
                          label={"Full Name"}
                          width={"80%"}
                          placeHolder={"Enter your full name"}
                          onChangeText={handleChange("fullName")}
                          onBlur={handleBlur("fullName")}
                          value={values.fullName}
                          errors={errors.fullName}
                          touched={touched.fullName}
                        />
                      </View>

                      <View
                        style={{
                          width: deviceType ? "80%" : "100%",
                          // backgroundColor: "red",
                          alignSelf: deviceType ? "center" : "flex-start",
                        }}
                      >
                        <TermsAndConditionsCheckBox
                          checked={values.termsAccepted}
                          setChecked={(e) =>
                            setValues({
                              ...values,
                              termsAccepted: !values.termsAccepted,
                            })
                          }
                          error={
                            touched.termsAccepted && (
                              <View style={{}}>
                                <Text style={styles.errorStyle}>
                                  {errors.termsAccepted}
                                </Text>
                              </View>
                            )
                          }
                        />
                      </View>

                      <View
                        style={{
                          marginTop: 30,
                          alignItems: deviceType ? "center" : "flex-start",
                        }}
                      >
                        <AbstractButton
                          width={deviceType ? "80%" : "100%"}
                          disabled={processing || checkNameProcessing}
                          label={"Submit"}
                          processing={processing}
                          onPress={handleSubmit}
                        />
                      </View>
                    </View>

                    <View style={{ alignSelf: "center" }}>
                      <Text style={[styles.errorStyle, { paddingLeft: 10 }]}>
                        {errorMessgae}
                      </Text>
                    </View>
                  </KeyboardAvoidingView>
                </AbstractContentContainer>
              </ScrollView>
            </View>
          </React.Fragment>
        )}
      </Formik>
    </ScreenContainer>
  );
};

export default CreateAccountScreen;

const styles = StyleSheet.create({
  containerTop: {
    // height: SH * 0.9,
    flex: 1,
    width: "100%",
    backgroundColor: Colors.screenBgColor,
  },
  containerBottom: {
    width: "100%",
    flex: 1,
    backgroundColor: Colors.screenBgColor,
    alignItems: "center",
    justifyContent: "center",
  },
  textOne: {
    fontFamily: Fonts.default,
    color: "black",
    fontSize: moderateScale(19, 0.1),
    paddingRight: 20,
  },
  textTwo: {
    fontFamily: Fonts.default,
    color: "black",
    fontSize: moderateScale(19, 0.1),
    fontWeight: "800",
  },
  headingText: {
    fontFamily: Fonts.bold,
    fontSize: moderateScale(23, 0.1),
    color: "#5A616C",
  },
  txtOne: {
    fontSize: moderateScale(12, 0.1),
    fontFamily: Fonts.default,
    color: Colors.darkBluePrimary,
    textAlign: "center",
  },
  errorStyle: {
    fontFamily: Fonts.default,
    fontSize: moderateScale(12.5, 0.1),
    color: "red",
    fontWeight: "600",
  },
});
