import React from "react";
import PPWebView from "../../../Components/ModuleComponents/ppWebview";

const PrivacyPolicyScreen = (props) => {
  return (
    <PPWebView
      title={"Privacy policy"}
      uri={
        "https://docs.google.com/document/d/e/2PACX-1vT9AuOPZBqlMD14oU7lZOz36Nkcy9trq0Lol4miUi6dkTiUt8EE5QeFnoiY5LNN2g/pub"
      }
    />
  );
};
export default PrivacyPolicyScreen;
