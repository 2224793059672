import React, { createRef } from "react";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import AuthStack from "./authStack";
import AppStack from "./appStack";
import { CardStyleInterpolators } from "@react-navigation/stack";
import SplashScreen from "../Screens/AuthScreens/splashScreen";
import { StackActions } from "@react-navigation/native";
import RNBootSplash from "react-native-bootsplash";
import { PLATFORM_DETECT } from "../themes";
import { ActivityIndicator, Linking, View } from "react-native";
import singleAuction from "../Screens/LaunchByLink/singleAuctionScreen";
import PlaceBidAndBuyNow from "../Screens/LaunchByLink/placeBidAndBuyNow";

const Stack = createStackNavigator();

const config = {
  screens: {
    initialRouteName: "Inforide",
    Auction: {
      screens: {
        Home: {
          screens: {
            Auction: {
              path: "Auction",
            },
            Offers: {
              path: "Offers",
            },
            Messages: {
              path: "Messages",
            },
            Settings: {
              path: "Settings",
            },
          },
        },
        AuctionDetail: {
          path: "Auction/Detail/:auctionId",
          parse: {
            id: (id) => `auction_id-${id}`,
          },
        },
        MyBids: {
          path: "Auction/MyBids",
        },
        ExploreCars: {
          path: "ExploreCars",
        },
        Filter: {
          path: "ExploreCars/Filter",
        },
        // Chat: {
        //   path: "Chat/:offerId",
        //   parse: {
        //     id: (id) => id,
        //   },
        // },
      },
    },
    AuthStack: {
      screens: {
        signIn: {
          path: "signIn",
        },
      },
    },
    singleAuction: {
      path: "Auction/singleAuction/:auctionId",
      parse: {
        id: (id) => `auction_id-${id}`,
      },
    },
    PlaceBidAndBuyNow: {
      path: "PlaceBidAndBuyNow/:auctionVehicleId",
      parse: {
        id: (id) => `auction_id-${id}`,
      },
    },
    NoMatch: "*",
  },
};
const linking = {
  prefixes: [], //["inforide://", "https://inforide-landing-page.netlify.app/"],
  config,
};

const MainNavigation = () => {
  return (
    <NavigationContainer
      linking={linking}
      fallback={
        <View
          style={{ flex: 1, justifyContent: "center", alignItems: "center" }}
        >
          <ActivityIndicator color="blue" size="large" />
        </View>
      }
      theme={{ colors: { background: "#F3F5F9" } }}
      ref={navigationRef}
      onReady={() => (!PLATFORM_DETECT.WEB ? RNBootSplash.hide() : false)}
      // onStateChange={handleNavigationBarColor}
    >
      <Stack.Navigator
        initialRouteName="Inforide"
        screenOptions={{ headerShown: false }}
      >
        <Stack.Screen
          name={`Inforide`}
          key={"Splash-key"}
          component={SplashScreen}
          options={{
            cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
            gestureEnabled: true,
            gestureDirection: "horizontal",
          }}
        />

        <Stack.Screen
          name="Welcome to infoRide"
          component={AuthStack}
          options={{
            cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
            gestureEnabled: true,
            gestureDirection: "horizontal",
          }}
        />
        <Stack.Screen
          name="Auction"
          component={AppStack}
          options={{
            cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
            gestureEnabled: true,
            gestureDirection: "horizontal",
          }}
        />
        <Stack.Screen
          name="singleAuction"
          component={singleAuction}
          options={{
            cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
            gestureEnabled: true,
            gestureDirection: "horizontal",
          }}
        />

        <Stack.Screen
          name="PlaceBidAndBuyNow"
          component={PlaceBidAndBuyNow}
          options={{
            cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
            gestureEnabled: true,
            gestureDirection: "horizontal",
          }}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
};

export default MainNavigation;

export const navigationRef = createRef();

export function navigate(name, params) {
  navigationRef.current?.navigate(name, params);
}

export function push(name, params) {
  const pushAction = StackActions.push(name, params);
  navigationRef.current?.dispatch(pushAction);
}

export function clearAndNavigate(name, params) {
  navigationRef.current?.reset({
    index: 0,
    routes: [{ name }],
    params: params,
  });
}
export function clearAndNavigateToNestedRote(root, name, params) {
  navigationRef.current?.reset({
    index: 0,
    routes: [
      {
        name: root,
        state: {
          routes: [{ name, params }],
        },
      },
    ],
    params: params,
  });
}

export function goBack() {
  if (PLATFORM_DETECT.WEB) {
    history.back();
  } else {
    navigationRef.current?.goBack();
  }
}

export const navigateToNestedRoute = (root, screen, params) => {
  navigationRef.current?.navigate(root, { screen, params });
};
