import React, { useEffect, useRef, useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  FlatList,
  RefreshControl,
  ScrollView,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import { moderateScale } from "react-native-size-matters";
import AbstractContentContainer from "../../Components/AbstractComponents/abstractContentContainer";
import AbstractHeaders from "../../Components/AbstractComponents/abstractHeaders";
import FocusAwareStatusBar from "../../Components/AbstractComponents/statusbarConfiguration";
import MyBidItem from "../../Components/ModuleComponents/mybidItem";
import { Colors, Fonts, PLATFORM_DETECT } from "../../themes";
import ScreenContainer from "../../Components/AbstractComponents/abstractScreenContainer";
import OffersController from "../../Controllers/offersController";
import { useSelector } from "react-redux";
import NoCarAvailableSvg from "../../Assets/Icons/noCarAvailableSvg";
import { goBack, navigate } from "../../Navigation/mainNavigation";
import BackBtnSvg from "../../Assets/Icons/backBtnSvg";
import MyBidItemPlaceholderMobile from "../../Components/Placeholders/mybidItemPlaceholderMobile";
import MyBidItemWebPlaceholder from "../../Components/Placeholders/mybidItemWebPlaceholder";
import AuctionController from "../../Controllers/auctionController";

const OffersPlaceholder = () => {
  const { deviceType } = useSelector((state) => state.DeviceTypeReducer);
  return (
    <AbstractContentContainer>
      <ScrollView showsVerticalScrollIndicator={false}>
        {!deviceType ? (
          <>
            <MyBidItemPlaceholderMobile />
            <MyBidItemPlaceholderMobile />
            <MyBidItemPlaceholderMobile />
            <MyBidItemPlaceholderMobile />
            <MyBidItemPlaceholderMobile />
            <MyBidItemPlaceholderMobile />
            <MyBidItemPlaceholderMobile />
            <MyBidItemPlaceholderMobile />
            <MyBidItemPlaceholderMobile />
          </>
        ) : (
          <>
            <MyBidItemWebPlaceholder />
            <MyBidItemWebPlaceholder />
            <MyBidItemWebPlaceholder />
            <MyBidItemWebPlaceholder />
            <MyBidItemWebPlaceholder />
            <MyBidItemWebPlaceholder />
            <MyBidItemWebPlaceholder />
            <MyBidItemWebPlaceholder />
            <MyBidItemWebPlaceholder />
            <MyBidItemWebPlaceholder />
            <MyBidItemWebPlaceholder />
          </>
        )}
      </ScrollView>
    </AbstractContentContainer>
  );
};

const MyBidsScreen = () => {
  const [loadedFirstTime, setLoadedFirstTime] = useState(true);
  const [refreshState, setRefreshState] = useState(false);
  const [loadMoreState, setLoadMoreState] = useState(false);
  const [bidsArray, setBidsArray] = useState([]);
  const [totalNoPage, setTotalNoPage] = useState(undefined);
  const [currentPage, setCurrentPage] = useState(0);

  const handleGetAllList = () => {
    AuctionController.getMyAllBids(0)
      .then((res) => {
        setBidsArray(res.bids);
        setTotalNoPage(res.noOfPages);
        setLoadedFirstTime(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleRefresh = () => {
    AuctionController.getMyAllBids(0)
      .then((res) => {
        setBidsArray(res.bids);
        setTotalNoPage(res.noOfPages);
        setRefreshState(false);
        setCurrentPage(0);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleLoadMore = () => {
    if (currentPage < totalNoPage - 1 && bidsArray.length > 0) {
      setLoadMoreState(true);
      let temp = currentPage + 1;
      setCurrentPage((current) => current + 1);
      AuctionController.getMyAllBids(temp)
        .then((res) => {
          setBidsArray([...bidsArray, ...res.bids]);
          setTotalNoPage(res.noOfPages);
          setLoadMoreState(false);
        })
        .catch((err) => {
          console.log(err), setLoadMoreState(false);
        });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      handleGetAllList();
    }, 500);
  }, []);

  return (
    <ScreenContainer
      upperStyle={{ backgroundColor: Colors.screenBgColor }}
      bottomStyle={{ backgroundColor: Colors.screenBgColor }}
    >
      <View style={styles.mainContainer}>
        <FocusAwareStatusBar
          barStyle="dark-content"
          backgroundColor={"transparent"}
          translucent={true}
        />
        <AbstractHeaders
          leftChild={() => (
            <TouchableOpacity
              onPress={() => goBack()}
              style={[
                {
                  alignItems: "center",
                  height: "75%",
                  flexDirection: "row",
                },
              ]}
            >
              <BackBtnSvg color={Colors.blackPrimary} />
            </TouchableOpacity>
          )}
          middleChild={() => <Text style={styles.textOne}>My Placed Bids</Text>}
        />

        {loadedFirstTime ? (
          <OffersPlaceholder />
        ) : (
          <FlatList
            style={{
              width: PLATFORM_DETECT.WEB ? "95%" : "90%",
              alignSelf: "center",
              paddingLeft: 1,
              paddingRight: 1,
            }}
            refreshControl={
              <RefreshControl
                refreshing={refreshState}
                onRefresh={handleRefresh}
              />
            }
            showsVerticalScrollIndicator={false}
            data={bidsArray}
            onEndReachedThreshold={0.1}
            contentContainerStyle={{ paddingBottom: 200 }}
            ListEmptyComponent={() => (
              <View
                style={{
                  marginTop: 100,
                }}
              >
                <NoCarAvailableSvg label={"No Bid Placed Yet!"} />
              </View>
            )}
            renderItem={({ item, index }) => (
              <MyBidItem
                key={index}
                bid={item}
                onPress={() =>
                  navigate("MyPlacedBidCarDetail", {
                    vehicle: item?.vehicle,
                    bidAmount: item.bidAmount,
                  })
                }
              />
            )}
            keyExtractor={(item) => item._id}
            onEndReached={handleLoadMore}
            ListFooterComponent={
              loadMoreState && (
                <View style={{ width: "100%" }}>
                  <ActivityIndicator
                    size={"large"}
                    color={Colors.blueprimary}
                  />
                </View>
              )
            }
          />
        )}
      </View>
    </ScreenContainer>
  );
};

export default MyBidsScreen;

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: Colors.screenBgColor,
  },
  textOne: {
    fontSize: moderateScale(19, 0.1),
    color: "black",
    fontFamily: Fonts.bold,
    fontWeight: "600",
  },
  textThree: {
    fontFamily: Fonts.bold,
    color: Colors.blackPrimary,
    fontSize: moderateScale(19, 0.1),
    paddingBottom: 10,
  },
});
