import React, { useState } from "react";
import { View, Text, TouchableOpacity, StyleSheet } from "react-native";
import { moderateScale } from "react-native-size-matters";
import ReportSvg from "../../Assets/Icons/reportSvg";
import ConversationsController from "../../Controllers/conversationsController";
import OffersController from "../../Controllers/offersController";
import { goBack } from "../../Navigation/mainNavigation";
import { Fonts } from "../../themes";
import AbstractBottomSheet from "../AbstractComponents/abstractBottomSheet";
import AbstractContentContainer from "../AbstractComponents/abstractContentContainer";
import CloseDealSheet from "./BottomSheets/closeDealSheet";
import ReportSheet from "./BottomSheets/reportSheet";

const ConflictResponseButton = ({ openSheet, conversation }) => {
  const [sheetVisible, setSheetVisible] = useState(false);
  const [conflictModeEnabled, setConflictModeEnabled] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  const handleSubmit = (submitConflict, value) => {
    setErrMsg("");
    if (submitConflict) {
      handleCreateConflict(value);
    } else {
      if (value) {
        setConflictModeEnabled(value);
      } else {
        handleCompleteDeal();
      }
    }
  };

  const handleCreateConflict = (reasonText) => {
    console.log(
      `Creating conflict with reason:${reasonText}.`,
      conversation._id
    );
    setProcessing(true);
    OffersController.createConflict(conversation._id, reasonText)
      .then(() => {
        setProcessing(false);
        goBack();
        ConversationsController.loadConversations();
      })
      .catch((err) => {
        setErrMsg(err);
        setProcessing(false);
      });

    // setTimeout(() => {
    //     setProcessing(false)
    //     setSheetVisible(false);
    // }, 1000)
  };

  const handleCompleteDeal = () => {
    // console.log(`Completing the deal.`)

    setProcessing(true);
    setTimeout(() => {
      setProcessing(false);
      setSheetVisible(false);
    }, 1000);
  };

  const onCloseSheet = () => {
    setSheetVisible(false);
    setConflictModeEnabled(false);
  };

  return (
    <>
      <TouchableOpacity
        onPress={() => setSheetVisible(true)}
        style={styles.reportView}
      >
        <View style={{ marginRight: 5 }}>
          <ReportSvg />
        </View>
        <View>
          <Text style={styles.textReport}>Conflict</Text>
        </View>
      </TouchableOpacity>
      <AbstractBottomSheet
        isVisible={sheetVisible}
        onClose={onCloseSheet}
        bgColor
      >
        <View style={{ width: "100%" }}>
          <AbstractContentContainer>
            <ReportSheet
              processing={processing}
              errorMessage={errMsg}
              onSubmit={handleSubmit}
            />
            {/* {
                        conflictModeEnabled? 
                        <ReportSheet processing={processing}  onSubmit={handleSubmit}/>:
                        <CloseDealSheet processing={processing} onSubmit={handleSubmit} />

                    } */}
          </AbstractContentContainer>
        </View>
      </AbstractBottomSheet>
    </>
  );
};
export default ConflictResponseButton;

const styles = StyleSheet.create({
  reportView: {
    width: moderateScale(97, 0.1),
    height: moderateScale(27, 0.1),
    backgroundColor: "rgba(38, 116, 230, 0.15)",
    borderRadius: 5,
    position: "absolute",
    right: 0,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  textReport: {
    fontFamily: Fonts.default,
    fontSize: moderateScale(12, 0.1),
    color: "#2674E5",
  },
});
