import { PLATFORM_DETECT } from "../themes";

class DateFormatter {
  static formatDateWithUSAFormat(date_) {
    var date = new Date(date_);
    var hr = date.getHours();
    var min = date.getMinutes();
    if (min < 10) {
      min = "0" + min;
    }
    var ampm = "am";
    if (hr > 12) {
      hr -= 12;
      ampm = "pm";
    }
    const prepDateWeb =
      date.toLocaleDateString("en-US", { day: "numeric" }) +
      "-" +
      date.toLocaleDateString("en-US", { month: "short" }) +
      "-" +
      date.toLocaleDateString("en-US", { year: "numeric" }) +
      " " +
      hr +
      ":" +
      min +
      ampm;
    const prepDateNative = date.toDateString() + " " + hr + ampm;
    // console.log(prepDateNative);
    return PLATFORM_DETECT.WEB ? prepDateWeb : prepDateNative;
  }
  static formatDateWithUSAFormatAndAtTheRate(date_, atTheRate) {
    var date = new Date(date_);
    var hr = date.getHours();
    var min = date.getMinutes();
    if (min < 10) {
      min = "0" + min;
    }
    var ampm = "am";
    if (hr > 12) {
      hr -= 12;
      ampm = "pm";
    }

    const prepDateWeb =
      date.toLocaleDateString("en-US", { day: "numeric" }) +
      "-" +
      date.toLocaleDateString("en-US", { month: "short" }) +
      "-" +
      date.toLocaleDateString("en-US", { year: "numeric" }) +
      " " +
      (atTheRate ? "@" : "") +
      hr +
      ampm;
    const prepDateNative =
      date.toDateString() + " " + (atTheRate ? "@" : "") + hr + ampm;
    // console.log(prepDateNative);
    return PLATFORM_DETECT.WEB ? prepDateWeb : prepDateNative;
  }
}
export default DateFormatter;