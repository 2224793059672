import React, { useState } from 'react';
import {View, Image, StyleSheet, TouchableOpacity} from 'react-native';
const defaultProps = {
    size: 50, 
    borderRadius: 100, 
    thumbnail: "https://avatars.dicebear.com/v2/identicon/1b7199e8f18776e5feee2fe7b600b5d7.svg"
}

const Placeholder = ({isImageLoaded, thumbnail, blurhash, width, height}) => {
    if(!isImageLoaded){
        if(thumbnail){
           return <Image source={{uri: thumbnail}} style={[StyleSheet.absoluteFillObject, styles.thumbnailStyle, {width, height}]}/>
        }else{
            return <Image source={require('../../Assets/Images/avatar.png')} style={[StyleSheet.absoluteFillObject, styles.thumbnailStyle, {width, height}]}/>
        }
    }else{
        return <Image source={require('../../Assets/Images/avatar.png')} style={[StyleSheet.absoluteFillObject, styles.thumbnailStyle, {width, height}]}/>
    }
}


const AbstractAvatar = ({size, url, placeholder ,borderRadius, Center, onPressCenter}) => {
    const [isImageLoaded, setImageLoaded] = useState(false);
    return (
        <View style={[styles.container, {width: size, height: size, borderRadius}]}>
            <Placeholder isImageLoaded={isImageLoaded} thumbnail={placeholder} width={size} height={size}/>
            <Image onError={() => setImageLoaded(false)} onLoadEnd={() => setImageLoaded(true)} source={ url? {uri: url}: undefined} style={[StyleSheet.absoluteFillObject, styles.imageStyle, {width: size, height: size}]}/>
           {
               Center?
                <View style={[StyleSheet.absoluteFillObject, {backgroundColor:"transparent", justifyContent:"center", alignItems:"center"}]}>
                    <TouchableOpacity onPress={onPressCenter}>
                        {Center()}
                    </TouchableOpacity>
                </View>:false
           }
        </View>
    )
}
AbstractAvatar.defaultProps = defaultProps;
export default AbstractAvatar;

const styles = StyleSheet.create({
    container: {
        borderRadius: 100,
        overflow: "hidden"
    },
    imageStyle: {
    },
    thumbnailStyle: {

    }
})
