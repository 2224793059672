import { StyleSheet } from "react-native";
import React from "react";
import SkeletonLoader from "expo-skeleton-loader";
import { Colors } from "../../themes";

const SingleLinePlaceholder = ({ w, h, r, mv }) => {
  const width = w ? w : 300;
  const borderRadius = r ? r : 5;
  const height = h ? h : 20;
  const marginVertical = mv ? mv : 5;

  return (
    <SkeletonLoader
      backgroundColor={"white"}
      highlightColor={"#e5e5e5"}
      boneColor={"white"}
      duration={1000}
    >
      <SkeletonLoader.Container
        style={{ width, borderRadius, height, marginVertical }}
      ></SkeletonLoader.Container>
    </SkeletonLoader>
  );
};

export default SingleLinePlaceholder;

const styles = StyleSheet.create({
  mainContainer: {
    width: "100%",
    borderColor: "#e5e5e5",
    borderWidth: 1,
    backgroundColor: Colors.screenBgColor,
  },
});
