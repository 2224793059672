import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import { moderateScale } from 'react-native-size-matters'
import { Colors, Fonts } from '../../themes'
import AddIcon from '../../Assets/Icons/addIcon'
import AbstractButton from '../AbstractComponents/abstractButton'

const defaultProps = {
label:"text",
price:"text"
}


const OfferDetailTile = ({label,price, onPress, offers}) => {
    return (
        <View style={styles.mainContainer}>
            <View style={styles.viewOne}>
                <Text style={styles.textOne} >{label}</Text>
            </View>
            <View style={styles.viewTwo}>
                <AbstractButton
                    label={`$${price}`}
                    icon={() => <AddIcon size={10} />}
                    height={30}
                    width={75}
                    txtSize={15}
                    onPress={() => onPress({price, offers })}
                    // bgcolor={availableOfferButtonText.active?Colors.redPrimary:Colors.blueprimary}
                />
                {/* <Text style={styles.textTwo} >{`$${price}`}</Text> */}
            </View>
        </View>
    )
}

export default OfferDetailTile
OfferDetailTile.defaultProps = defaultProps

const styles = StyleSheet.create({
    mainContainer: {
        width: '100%',
        height: moderateScale(50, 0.1),
        flexDirection: 'row',
        borderBottomColor: Colors.greySecondaryTwo,
        borderBottomWidth: 0.5,
        alignItems: 'center',
        marginTop:5
    },
    viewOne: {
        width: '50%',
        height: '100%',
        justifyContent: "center"
    },
    viewTwo: {
        width: '50%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'flex-end'
    },
    textOne:{
        fontSize:moderateScale(15,0.1),
        fontFamily:Fonts.medium,
        color:Colors.blackPrimary
    },
    textTwo:{
        fontSize:moderateScale(14,0.1),
        fontFamily:Fonts.default,
        color:Colors.blackPrimary
    }
   
})