import React, { useEffect, useRef, useState } from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import BackBtnSvg from "../../Assets/Icons/backBtnSvg";
import AbstractHeaders from "../../Components/AbstractComponents/abstractHeaders";
import { Colors, Fonts, PLATFORM_DETECT } from "../../themes";
import AbstractContentContainer from "../../Components/AbstractComponents/abstractContentContainer";
import AbstractH3 from "../../Components/AbstractComponents/AbstractHeadings/abstractH3";
import AbstractButton from "../../Components/AbstractComponents/abstractButton";
import FocusAwareStatusBar from "../../Components/AbstractComponents/statusbarConfiguration";
import { moderateScale } from "react-native-size-matters";
import CodeVerification from "../../Components/ModuleComponents/codeVerification";
import { goBack, navigate } from "../../Navigation/mainNavigation";
import ScreenContainer from "../../Components/AbstractComponents/abstractScreenContainer";
import AuthController from "../../Controllers/authController";
import { useSelector } from "react-redux";

const OTPVerificationScreen = (props) => {
  const [otp, setOTP] = useState(""); //("547701"); //
  const { deviceType } = useSelector((state) => state.DeviceTypeReducer);

  const [error, setError] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(
    props.route?.params?.phoneNumber
  );
  const [processing, setProcessing] = useState(false);
  const isSubmittedOnce = useRef(false);
  useEffect(() => {
    // console.log(props.route?.params, "??????1");
    setPhoneNumber(props.route?.params?.phoneNumber);
  }, [props.route?.params?.phoneNumber]);

  const handleSubmit = () => {
    // console.log(otp, phoneNumber, "??????");
    if (otp.length < 6) {
      setError("OTP is incorrect");
    } else {
      setError("");
      setProcessing(true);
      // console.log(`OTP: ${otp}, PH: ${phoneNumber}`);
      AuthController.varifyOTP(otp, phoneNumber)
        .then((varified) => {
          setProcessing(false);
        })
        .catch((err) => {
          setProcessing(false);
          setError(err);
        });
    }
  };

  useEffect(() => {
    if (otp.length === 6 && !isSubmittedOnce.current) {
      isSubmittedOnce.current = true;
      handleSubmit();
    }
  }, [otp]);

  return (
    <ScreenContainer
      upperStyle={{ backgroundColor: Colors.screenBgColor }}
      bottomStyle={{ backgroundColor: Colors.screenBgColor }}
    >
      <FocusAwareStatusBar
        barStyle={"dark-content"}
        backgroundColor={"transparent"}
        translucent={true}
      />
      <AbstractHeaders
        onPressLeft={() => goBack()}
        leftChild={() => <BackBtnSvg />}
      />
      <View
        style={{
          flex: 1,
          backgroundColor: Colors.screenBgColor,
          paddingTop: deviceType ? PLATFORM_DETECT.SH * 0.1 : 0,
          alignItems: "center",
        }}
      >
        <AbstractContentContainer>
          <View
            style={[
              {
                backgroundColor: Colors.screenBgColor,
                width: deviceType ? "50%" : "100%",
                padding: deviceType ? 50 : 0,
                borderRadius: deviceType ? 10 : 0,
                alignSelf: "center",
              },
              deviceType
                ? {
                    shadowColor: Colors.blueprimary,
                    shadowOffset: {
                      width: 0,
                      height: 4,
                    },
                    shadowOpacity: 0.38,
                    shadowRadius: 12.84,
                    elevation: 2,
                  }
                : {},
            ]}
          >
            <View>
              <AbstractH3 txtColor={Colors.darkBluePrimary}>
                Phone number verification
              </AbstractH3>
              <TouchableOpacity style={{ marginTop: 5 }}>
                <Text style={styles.txtOne}>
                  Please enter the otp you received to verify your phone number.
                </Text>
              </TouchableOpacity>
            </View>

            <View
              style={{
                marginTop: 50,
                height: moderateScale(69, 0.1),
                width: "100%",
              }}
            >
              <CodeVerification
                onChangeText={(value) => setOTP(value)}
                error={error}
              />
            </View>

            <View style={{ marginTop: 30 }}>
              <AbstractButton
                onPress={handleSubmit}
                processing={processing}
                label={"Verify"}
              />
            </View>
          </View>

          <View style={{ alignSelf: "center", marginTop: 10 }}>
            <Text style={styles.errorStyle}>{error}</Text>
          </View>
        </AbstractContentContainer>
      </View>
    </ScreenContainer>
  );
};

export default OTPVerificationScreen;

const styles = StyleSheet.create({
  mainContainer: {},

  txtOne: {
    fontSize: moderateScale(12, 0.1),
    fontFamily: Fonts.default,
    color: Colors.darkBluePrimary,
    width: "80%",
    lineHeight: 20,
  },
  textTwo: {
    fontSize: moderateScale(12, 0.1),
    fontFamily: Fonts.default,
    color: Colors.blueprimary,
    textDecorationLine: "underline",
  },
  errorStyle: {
    fontFamily: Fonts.default,
    fontSize: moderateScale(12.5, 0.1),
    color: "red",
    fontWeight: "600",
    paddingLeft: 10,
  },
});
