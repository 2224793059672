import store from "../../src/Store";
import ReduxDispatchController from "./reduxDispatchController";
import axios from "axios";
import { ERRORS } from "./errorMessages";
import AuthController from "./authController";
class WalletController {
  static getWallet() {
    const { currentBalance, comission } = store.getState().WalletReducer;
    return { currentBalance, comission };
  }
  static getWalletSummery = () => {
    return new Promise((resolve, reject) => {
      const currentUserId = AuthController.getCurrentUser()._id;
      axios
        .get(`/wallet/payment/summary/${currentUserId}`)
        .then((res) => {
          if (res.data.success) {
            resolve(res.data.data);
          } else {
            reject(res.data.error.message);
          }
        })
        .catch((err) => {
          reject(ERRORS.INTERNET_CONNECTIVITY);
        });
    });
  };
  static loadWallet = (forceLoad = false) => {
    const { currentBalance } = WalletController.getWallet();
    if (!currentBalance || forceLoad) {
      ReduxDispatchController.WALLET.setProcess(true, "loading wallet...");
      WalletController.getWalletSummery()
        .then((walletSummber) => {
          ReduxDispatchController.WALLET.set(
            walletSummber.currentBalance,
            walletSummber.offerCommission
          );
          ReduxDispatchController.WALLET.setProcess(false, "");
        })
        .catch((err) => {
          console.log(`CATCH Error in loading wallet`);
          console.logg(err);
          ReduxDispatchController.WALLET.setProcess(false, "");
        });
    }
  };
  static addFunds = (amount) => {
    return new Promise((resolve, reject) => {
      ReduxDispatchController.WALLET.setProcess(true, "adding funds...");
      const currentUserId = AuthController.getCurrentUser()._id;

      axios
        .post(`/wallet/payment/add/${currentUserId}`, { amount })
        .then((res) => {
          if (res.data.success) {
            ReduxDispatchController.WALLET.addFunds(amount);
            ReduxDispatchController.WALLET.setProcess(false, "");
            resolve(true);
          } else {
            ReduxDispatchController.WALLET.setProcess(false, "");
            reject(res.data.error.message);
          }
        })
        .catch((err) => {
          ReduxDispatchController.WALLET.setProcess(false, "");
          reject(ERRORS.INTERNET_CONNECTIVITY);
        });
    });
  };
}
export default WalletController;
