export const AUTH = {
  SET_CURRENT_USER: "SET_CURRENT_USER",
};
export const DEVICE = {
  DEVICE_TYPE: "DEVICE_TYPE",
};

export const AUCTION = {
  SET_ALL_AUCTION: "set_auction_list",
  SET_SEARCH_LOADING: "SET_SEARCH_LOADING",
  GET_MY_ALL_BIDS: "GET_MY_ALL_BIDS",
};

export const VEHICLES = {
  SET_FILTER: "SET_VEHICLE_FILTER",
  SET_ALL: "SET_ALL_VEHICLES",
  SET_KEY_VALUE: "SET_VEHICLES_KEY_VALUE",
};

export const OFFERS = {
  SET_ALL: "SET_ALL_OFFERS",
  SET_KEY_VALUE: "SET_OFFER_KEY_VALUE",
  PUSH_NEW_OFFER: "PUSH_NEW_OFFER",
  UPDATE_OFFER: "UPDATE_OFFER",
  DELETE_OFFER: "DELETE_OFFER",
  CONFLICT_OFFER: "CONFLICT_OFFER",
  UPDATE_BUY_NOW_OFFERS_STATUS: "UPDATE_BUY_NOW_OFFERS_STATUS",
};

export const WALLET = {
  ADD_FUNDS: "ADD_FUNDS_WALLET",
  REMOVE_FUNDS: "REMOVE_FUNDS_WALLET",
  SET_WALLET_SUMMERY: "SET_WALLET_SUMMERY",
  SET_KEY_VALUE: "SET_WALLET_KEY_VALUE",
};

export const CONVERSATIONS = {
  SET_CONVERSATIONS: "SET_ALL_CONVERSATONS",
  SET_MESSAGES: "SET_CONVERSATON_MESSAGES",
  SET_SINGLE_CONVERSTION: "SET_SINGLE_CONVERSATON",
  PUSH_MESSAGE: "PUSH_CONVERSATION_MESSAGE",
  UPDATE_MESSAGE: "UPDATE_CONVERSATION_MESSAGE",
  SET_KEY_VALUE: "SET_CONVERSATION_KEY_VALUE",
  MARK_SEEN: "MARK_SEEN_CONVERSATION",
  PUSH_SINGLE_CONVERSATION: "PUSH_SINGLE_CONVERSATION",
  CONVERSATION_STATUS_UPDATE: "CONVERSATION_STATUS_UPDATE",
  DELETE_CONVERSATION: "DELETE_CONVERSATION",
  UPDATE_BUY_NOW_CONVERSATIONS_STATUS: "UPDATE_BUY_NOW_CONVERSATIONS_STATUS",
};
