import React from "react";
import Svg, { Path } from "react-native-svg";

const ArrowDownSvg = () => {
  return (
    <Svg
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 3.93067L8.57741 0.251051C8.90285 -0.0836835 9.43048 -0.0836835 9.75592 0.251051C10.0814 0.585786 10.0814 1.1285 9.75592 1.46323L5.58926 5.74895C5.26382 6.08368 4.73618 6.08368 4.41074 5.74895L0.244078 1.46323C-0.0813592 1.1285 -0.0813592 0.585786 0.244078 0.251051C0.569515 -0.0836835 1.09715 -0.0836835 1.42259 0.251051L5 3.93067Z"
        fill="#D3DAE6"
      />
    </Svg>
  );
};

export default ArrowDownSvg;
