import React from "react";
import { Path, Svg } from "react-native-svg";

const DeleteSvg = ({ disabled }) => {
  const defDisabled = disabled ? "#8D8D8D" : "#FF5E51";

  return (
    <Svg
      style={{ height: 20, width: 20 }}
      // width="14"
      // height="16"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path
        d="M4 1.00001C4 0.63182 4.29848 0.333344 4.66667 0.333344H7.33333C7.70152 0.333344 8 0.63182 8 1.00001V1.66668H11.3333C11.7015 1.66668 12 1.96515 12 2.33334C12 2.70153 11.7015 3.00001 11.3333 3.00001H0.666667C0.298477 3.00001 0 2.70153 0 2.33334C0 1.96515 0.298477 1.66668 0.666667 1.66668H4V1.00001Z"
        fill={`${defDisabled}`}
      />
      <Path
        d="M2 4.33334C2.36819 4.33334 2.66667 4.63182 2.66667 5.00001V11.6667C2.66667 12.0349 2.96514 12.3333 3.33333 12.3333H8.66667C9.03486 12.3333 9.33333 12.0349 9.33333 11.6667V5.00001C9.33333 4.63182 9.63181 4.33334 10 4.33334C10.3682 4.33334 10.6667 4.63182 10.6667 5.00001V11.6667C10.6667 12.7712 9.77124 13.6667 8.66667 13.6667H3.33333C2.22876 13.6667 1.33333 12.7712 1.33333 11.6667V5.00001C1.33333 4.63182 1.63181 4.33334 2 4.33334Z"
        fill={`${defDisabled}`}
      />
      <Path
        d="M4 6.33334C4 5.96515 4.29848 5.66668 4.66667 5.66668C5.03486 5.66668 5.33333 5.96515 5.33333 6.33334V10.3333C5.33333 10.7015 5.03486 11 4.66667 11C4.29848 11 4 10.7015 4 10.3333V6.33334Z"
        fill={`${defDisabled}`}
      />
      <Path
        d="M7.33333 5.66668C6.96514 5.66668 6.66667 5.96515 6.66667 6.33334V10.3333C6.66667 10.7015 6.96514 11 7.33333 11C7.70152 11 8 10.7015 8 10.3333V6.33334C8 5.96515 7.70152 5.66668 7.33333 5.66668Z"
        fill={`${defDisabled}`}
      />
    </Svg>
  );
};

export default DeleteSvg;
