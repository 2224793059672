import React from "react";
import Svg, { Path } from "react-native-svg";

const LogoutSvg = () => {
  return (
    <Svg
      width="19"
      height="17"
      viewBox="0 0 19 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path
        d="M10.4028 12.1111V13.0139C10.4028 13.7322 10.1174 14.4211 9.60953 14.929C9.10161 15.4369 8.41274 15.7222 7.69444 15.7222H4.08333C3.36504 15.7222 2.67616 15.4369 2.16825 14.929C1.66034 14.4211 1.375 13.7322 1.375 13.0139V3.9861C1.375 3.26781 1.66034 2.57893 2.16825 2.07102C2.67616 1.56311 3.36504 1.27777 4.08333 1.27777H7.69444C8.41274 1.27777 9.10161 1.56311 9.60953 2.07102C10.1174 2.57893 10.4028 3.26781 10.4028 3.9861V4.88888M14.0139 12.1111L17.625 8.49999L14.0139 12.1111ZM17.625 8.49999L14.0139 4.88888L17.625 8.49999ZM17.625 8.49999H4.98611H17.625Z"
        stroke="#EC2222"
        strokeWidth="1.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default LogoutSvg;
