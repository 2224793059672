import React from "react";
import { StyleSheet, Text, View, ScrollView, Platform } from "react-native";
import AbstractHeaders from "../../Components/AbstractComponents/abstractHeaders";
import { Colors, Fonts, PLATFORM_DETECT } from "../../themes";
import ProfileSetting from "../../Components/ModuleComponents/profileSetting";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import SettingItem from "../../Components/ModuleComponents/settingItem";
import AbstractContentContainer from "../../Components/AbstractComponents/abstractContentContainer";
import ScreenContainer from "../../Components/AbstractComponents/abstractScreenContainer";
import FocusAwareStatusBar from "../../Components/AbstractComponents/statusbarConfiguration";
import {
  clearAndNavigate,
  navigate,
  push,
} from "../../Navigation/mainNavigation";
import LogoutSvg from "../../Assets/Icons/logoutSvg";
import TermsAndContionsSvg from "../../Assets/Icons/termsAndContionsSvg";
import AuthController from "../../Controllers/authController";
import { moderateScale } from "react-native-size-matters";
import { useSelector } from "react-redux";
import DeleteSvgLarge from "../../Assets/Icons/deleteSvgLarge";
import DeleteSvg from "../../Assets/Icons/deleteSvg";

const SettingsScreen = () => {
  const { deviceType } = useSelector((state) => state.DeviceTypeReducer);

  const onPressTerms = () => {
    push("Terms & Conditions", { backGroundColor: "#FFFF" });
  };

  return (
    <ScreenContainer
      upperStyle={{ backgroundColor: Colors.blueSecondary }}
      bottomStyle={{ backgroundColor: Colors.screenBgColor }}
    >
      <ScrollView
        style={{ flex: 1, backgroundColor: Colors.screenBgColor }}
        showsVerticalScrollIndicator={false}
      >
        <View style={styles.mainContainer}>
          <FocusAwareStatusBar
            barStyle="light-content"
            backgroundColor={"transparent"}
            translucent={true}
          />
          <View style={[styles.viewOne, { marginTop: deviceType ? 40 : 0 }]}>
            <AbstractHeaders
              middleChild={() => (
                <View>
                  <Text
                    style={[
                      styles.txtOne,
                      { fontSize: deviceType ? 30 : wp("4.5%") },
                    ]}
                  >
                    Settings
                  </Text>
                </View>
              )}
            />
            <View
              style={{ alignItems: "center", marginTop: 10, zIndex: 999999 }}
            >
              <ProfileSetting onPressEdit={() => navigate("Edit Profile")} />
            </View>
          </View>
          <View style={{ height: 100 }} />
          <View style={styles.secondView}>
            <AbstractContentContainer>
              <View style={{ marginTop: 25 }} />
              {/* <View style={{ marginTop: 25, marginBottom: 10 }}>
                <Text
                  style={[
                    styles.headingTxt,
                    { fontSize: deviceType ? 30 : wp("4.2%") },
                  ]}
                >
                  Payments
                </Text>
              </View> */}

              {/* <SettingItem
                bgColor={"#D4E3FA"}
                label={"Wallet"}
                icon={() => <WalletSvg />}
                border={true}
                // arrow={true}
                // onPress={()=> navigate('WalletScreen',{backGroundColor:'#FFFFFF'})}
                onPress={() => navigate("WalletNewScreen")}
              /> */}

              <SettingItem
                bgColor={"#D9F8ED"}
                label={"Terms & Conditions"}
                icon={() => <TermsAndContionsSvg />}
                onPress={onPressTerms}
              />

              <View style={{ marginTop: 10, marginBottom: 10 }}>
                <Text
                  style={[
                    styles.headingTxt,
                    { fontSize: deviceType ? 30 : wp("4.2%") },
                  ]}
                >
                  Others
                </Text>
              </View>
              <SettingItem
                onPress={() =>
                  navigate("Delete Account", {
                    phoneNumber: "+923065267404",
                    flowType: "deleteAccount",
                  })
                }
                bgColor={"#FFDFDC"}
                icon={() => <DeleteSvg />}
                label={"Delete Account"}
              />

              <SettingItem
                onPress={() => AuthController.logOut()}
                bgColor={"#FFDFDC"}
                icon={() => <LogoutSvg />}
                label={"Logout"}
              />
              <View
                style={{
                  height:
                    Platform.OS === "android"
                      ? moderateScale(88, 0.1)
                      : moderateScale(65, 0.1),
                }}
              />
            </AbstractContentContainer>
          </View>
        </View>
      </ScrollView>
    </ScreenContainer>
  );
};

export default SettingsScreen;

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: Colors.blueSecondaryThree,
    justifyContent: "space-between",
  },
  viewOne: {
    width: "100%",
    flex: 1,
    backgroundColor: Colors.blueSecondaryThree,
    zIndex: 1,
  },
  txtOne: {
    color: "white",
    fontFamily: Fonts.default,
  },

  secondView: {
    width: "100%",
    flex: 1,
    height: "100%",
    paddingBottom: 30,
    backgroundColor: Colors.screenBgColor,
    borderTopLeftRadius: 28,
    borderTopRightRadius: 28,
    zIndex: 999,
  },
  headingTxt: {
    fontFamily: Fonts.default,

    fontWeight: "800",
    color: "black",
  },
});
