import { StyleSheet, Text, View, Dimensions } from "react-native";
import React from "react";
import MultiSlider from "@ptomasroos/react-native-multi-slider";
import { moderateScale } from "react-native-size-matters";
import { useState } from "react";
import { Colors, Fonts } from "../../../themes";
import { useEffect } from "react";

const SW = Dimensions.get("window").width;
const SH = Dimensions.get("window").height;

const MyCustomMarker = (props) => {
  return (
    <View style={styles.markerView}>
      <Text style={{ color: "white", fontSize: 7, fontWeight: "bold" }}>
        {props.currentValue}
      </Text>
    </View>
  );
};

const RangeSlider = ({ searchFilters, years, selectedRange }) => {
  const date_ = new Date(new Date()).getFullYear();

  const [multiSliderValue, setMultiSliderValue] = useState([]);
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);

  const prepareMaxAndMinRange = (years) => {
    setMin(Number(years[0]));
    setMax(
      years[years?.length - 1]
        ? Number(years[years?.length - 1])
        : Number(years[0])
    );
  };

  useEffect(() => {
    if (years.length > 0) {
      prepareMaxAndMinRange(years);
    }
  }, [years]);

  useEffect(() => {
    setMultiSliderValue([searchFilters.startYear, searchFilters.endYear]);
  }, [searchFilters]);

  const multiSliderValuesChange = (values) => {
    selectedRange(values);
    setMultiSliderValue(values);
  };

  return (
    <React.Fragment>
      <View>
        <Text style={styles.textThree}>Model Year</Text>
      </View>
      <View style={styles.viewOne}>
        <MultiSlider
          values={[multiSliderValue[0], multiSliderValue[1]]}
          onValuesChangeFinish={multiSliderValuesChange}
          step={1}
          min={min ? min : 0}
          max={max ? max : date_}
          sliderLength={SW * 0.85}
          allowOverlap={true}
          snapped={true}
          smoothSnapped={true}
          selectedStyle={{ backgroundColor: Colors.blueprimary }}
          unselectedStyle={{ backgroundColor: "#E4E9F2" }}
          customMarker={(props) => <MyCustomMarker {...props} />}
        />
      </View>
      <View style={styles.viewTwo}>
        <View>
          <Text style={styles.textOne}>{min}</Text>
        </View>
        <View>
          <Text style={styles.textOne}>{max}</Text>
        </View>
      </View>
    </React.Fragment>
  );
};

export default RangeSlider;

const styles = StyleSheet.create({
  viewOne: {
    // backgroundColor: "grey",
    width: "95%",
    alignSelf: "center",
  },
  viewTwo: {
    width: "100%",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  markerView: {
    width: 28,
    height: 28,
    borderRadius: 40,
    backgroundColor: Colors.blueprimary,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.22,
    shadowRadius: 2.22,
    elevation: 5,
    borderWidth: 2,
    // borderColor: "#D4E3FD"
    borderColor: Colors.greySecodaryFive,
    justifyContent: "center",
    alignItems: "center",
  },
  textOne: {
    // color: "#5A616C",
    color: Colors.blackPrimary,
    fontFamily: Fonts.medium,
    fontSize: moderateScale(12, 0.1),
  },
  textThree: {
    fontSize: moderateScale(13, 0.1),
    fontFamily: Fonts.bold,
    fontWeight: "900",
  },
});
