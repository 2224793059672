import React, { useEffect, useRef, useState } from "react";
import ActionSheet from "react-native-actions-sheet";
import { View } from "react-native";
const CustomHeaderComponent = ({}) => {
  return (
    <View
      style={{
        width: "100%",
        height: 30,
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        backgroundColor: "white",
      }}
    />
  );
};

const AbstractBottomSheet = ({
  id,
  children,
  customHeaderNotVisible,
  gestureEnable,
  onClose = () => false,
}) => {
  const defaultGestureEnable = gestureEnable ? gestureEnable : true;
  return (
    <ActionSheet
      id={id}
      onClose={onClose}
      containerStyle={{ borderTopRightRadius: 20, borderTopLeftRadius: 20 }}
      initialOffsetFromBottom={1}
      statusBarTranslucent={true}
      bounceOnOpen={true}
      bounciness={2}
      gestureEnabled={false}
      keyboardShouldPersistTaps="always"
      overlayColor={"grey"}
      defaultOverlayOpacity={0.4}
      headerAlwaysVisible={true}
      CustomHeaderComponent={
        customHeaderNotVisible ? <View /> : <CustomHeaderComponent />
      }
    >
      {children}
    </ActionSheet>
  );
};
export default AbstractBottomSheet;
