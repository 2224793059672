import React from "react";
import { Platform, StyleSheet, TouchableOpacity, View } from "react-native";
import { moderateScale } from "react-native-size-matters";
import { getStatusBarHeight } from "react-native-status-bar-height";

const STATUS_BAR_HEIGHT = getStatusBarHeight();

const AbstractHeaders = ({
  leftChild,
  middleChild,
  rightChild,
  onPressLeft,
  onPressRight,
}) => {
  return (
    <React.Fragment>
      <View
        style={{ height: Platform.OS === "android" ? STATUS_BAR_HEIGHT : 0 }}
      />

      <View style={styles.mainContainer}>
        <View style={styles.viewOne}>
          <TouchableOpacity
            activeOpacity={0.9}
            onPress={onPressLeft}
            style={styles.viewOneA}
          >
            {leftChild ? leftChild() : null}
          </TouchableOpacity>
          <View style={styles.viewOneB}>
            {middleChild ? middleChild() : null}
          </View>
          <TouchableOpacity
            activeOpacity={0.9}
            onPress={onPressRight}
            style={styles.viewOneC}
          >
            {rightChild ? rightChild() : null}
          </TouchableOpacity>
        </View>
      </View>
    </React.Fragment>
  );
};

export default AbstractHeaders;

const styles = StyleSheet.create({
  mainContainer: {
    height: moderateScale(60, 0.1),
    width: "100%",
  },
  viewOne: {
    width: "90%",
    height: "100%",
    alignSelf: "center",
    flexDirection: "row",
  },
  viewOneA: {
    width: "25%",
    height: "100%",
    justifyContent: "center",
  },
  viewOneB: {
    width: "50%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  viewOneC: {
    width: "25%",
    height: "100%",
  },
});
