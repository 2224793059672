import { OFFERS } from "../types";
import produce from "immer";
import GlobalConstants from "../../Controllers/constants";
const state = {
  offers: [],
  offersLoading: false,
};

const OffersReducer = (mState = { ...state }, action) => {
  switch (action.type) {
    case OFFERS.SET_KEY_VALUE:
      return produce(mState, (draftState) => {
        draftState[action.payload.key] = action.payload.value;
      });
    case OFFERS.SET_ALL:
      return produce(mState, (draftState) => {
        console.log(action?.payload);
        draftState.offers = action?.payload;
      });
    case OFFERS.UPDATE_OFFER:
      return produce(mState, (draftState) => {
        let offerIndex = draftState.offers.findIndex(
          (o) => o._id === action.payload._id
        );
        if (offerIndex >= 0) {
          draftState.offers[offerIndex] = { ...action.payload };
        }
      });
    case OFFERS.UPDATE_BUY_NOW_OFFERS_STATUS:
      return produce(mState, (draftState) => {
        if (draftState?.offers?.length > 0) {
          let matchOffers = draftState.offers.map((i) => {
            console.log(
              i._id != action.payload.offerId &&
                i.vehicle._id == action.payload.vehicleID &&
                i.status == GlobalConstants.OFFER_STATUS.CONVERSATION &&
                i.offerType == "buyNow" &&
                i._id,
              "in-Offers"
            );
            if (
              i._id != action.payload.offerId &&
              i.vehicle._id === action.payload.vehicleID &&
              i.status === GlobalConstants.OFFER_STATUS.CONVERSATION &&
              i.offerType === "buyNow"
            ) {
              return {
                ...i,
                status: (i.status = GlobalConstants.OFFER_STATUS.EXPIRED),
              };
            } else {
              return { ...i };
            }
          });
          draftState.offers = matchOffers;
        }
      });

    case OFFERS.PUSH_NEW_OFFER:
      return produce(mState, (draftState) => {
        draftState?.offers?.unshift(action.payload);
      });
    case OFFERS.DELETE_OFFER:
      return produce(mState, (draftState) => {
        draftState.offers = draftState.offers.filter(
          (offer) => offer._id !== action.payload
        );
      });
    case OFFERS.CONFLICT_OFFER:
      return produce(mState, (draftState) => {
        const offerIndex = draftState.offers.findIndex(
          (offer) => offer._id === action.payload.offerId
        );
        if (offerIndex >= 0) {
          console.log(`Setting ${offerIndex} to conflict`);
          draftState.offers[offerIndex].status = "conflict";
        } else {
          console.log(
            `Invalid offer, marking conflict.${action.payload.offerId}`
          );
        }
      });

    default:
      return { ...mState };
  }
};

export default OffersReducer;
