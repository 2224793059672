import {
  FlatList,
  Platform,
  StyleSheet,
  Text,
  View,
  ScrollView,
} from "react-native";
import React, { useEffect, useState } from "react";
import CarWithCountTile from "./carWithCountTile";
import { moderateScale } from "react-native-size-matters";
import VehiclesController, {
  useVehicleFilters,
} from "../../Controllers/vehiclesController";
import { useSelector } from "react-redux";
import CarWithCountTilePlaceholder from "../ModuleComponents/carWithCountTilePlaceholder";
const MakeFilterRedesigned = () => {
  const [selectedVehicleMake, setSelectedMake] = useState("All");
  const [vehicleMakes, setMakes] = useState([]);
  const { deviceType } = useSelector((state) => state.DeviceTypeReducer);

  const searchFilters = useSelector(
    (state) => state.VehiclesReducer.searchFilters
  );
  const {
    loadingFilters,
    yearsMeta,
    makesMeta,
    vehicleTypesMeta,
    filtersLoadedOnce,
  } = useVehicleFilters();

  const handleApplyFilter = (selectedVehicleCompany) => {
    VehiclesController.applyFilters(
      "All",
      selectedVehicleCompany,
      "All",
      0,
      1928,
      2023
    );
  };

  const onSelectMakeButton = (selectedItem) => {
    const newArray = [...makesMeta];
    const arrayAfterChanges = newArray.map((item) =>
      item.label == selectedItem.label
        ? { ...item, active: true }
        : { ...item, active: false }
    );
    setMakes(arrayAfterChanges);
    setSelectedMake(selectedItem.label);
    handleApplyFilter(selectedItem.label);
  };

  useEffect(() => {
    if (!filtersLoadedOnce) {
      setTimeout(() => {
        VehiclesController.handleLoadVehicleFilters();
      }, 500);
    }
  }, [filtersLoadedOnce]);

  useEffect(() => {
    if (searchFilters.make) setSelectedMake(searchFilters.make);
  }, [searchFilters]);

  useEffect(() => {
    setMakes(makesMeta);
  }, [loadingFilters]);

  return (
    <View
      style={[
        styles.mainContainer,
        {
          paddingLeft: deviceType ? "4%" : 0,
        },
      ]}
    >
      {loadingFilters ? (
        <ScrollView
          style={{ marginLeft: 20 }}
          horizontal
          showsHorizontalScrollIndicator={false}
        >
          <CarWithCountTilePlaceholder smallWidth />
          <CarWithCountTilePlaceholder />
          <CarWithCountTilePlaceholder />
          <CarWithCountTilePlaceholder />
          <CarWithCountTilePlaceholder />
          <CarWithCountTilePlaceholder />
          <CarWithCountTilePlaceholder />
          <CarWithCountTilePlaceholder />
        </ScrollView>
      ) : (
        <FlatList
          showsHorizontalScrollIndicator={false}
          horizontal={true}
          data={vehicleMakes}
          renderItem={({ item }) => {
            return (
              <CarWithCountTile
                item={item}
                active={true}
                onPress={() => onSelectMakeButton(item)}
              />
            );
          }}
          keyExtractor={(item) => item.label}
          ListHeaderComponent={() => <View style={{ width: 20, height: 20 }} />}
        />
      )}
    </View>
  );
};

export default MakeFilterRedesigned;

const styles = StyleSheet.create({
  mainContainer: {
    width: "100%",
    height: moderateScale(45, 0.1),
    // backgroundColor: "green",
  },
});
