import React, { useEffect, useState } from "react";
import { StyleSheet, Text, View, Keyboard } from "react-native";
import {
  CodeField,
  Cursor,
  useBlurOnFulfill,
  useClearByFocusCell,
} from "react-native-confirmation-code-field";
import { moderateScale } from "react-native-size-matters";
import { Colors, Fonts } from "../../themes";

const CELL_COUNT = 6;

const CodeVerification = (props) => {
  const [value, setValue] = useState("");
  const ref = useBlurOnFulfill({ value, cellCount: CELL_COUNT });
  const [cProps, getCellOnLayoutHandler] = useClearByFocusCell({
    value,
    setValue,
  });

  useEffect(() => {
    requestAnimationFrame(() => {
      if (props.onChangeText) props.onChangeText(value);
    });
  }, [value]);

  return (
    <View>
      <CodeField
        {...cProps}
        value={value}
        textInputStyle={{ outline: 0 }}
        cellCount={CELL_COUNT}
        caretHidden={false}
        onSubmitEditing={() => Keyboard.dismiss()}
        textContentType="oneTimeCode"
        onChangeText={setValue}
        keyboardType="number-pad"
        keyboardAppearance={"light"}
        renderCell={({ index, symbol, isFocused }) => (
          <View
            key={index}
            style={[
              styles.cellView,
              {
                borderColor: isFocused
                  ? "#2674E5"
                  : props.error
                  ? "red"
                  : "#E4E9F2",
              },
            ]}
          >
            <Text
              style={[styles.textTwo, { fontSize: moderateScale(30, 0.1) }]}
            >
              {isFocused ? "-" : symbol}
            </Text>
          </View>
        )}
      />
    </View>
  );
};

export default CodeVerification;

const styles = StyleSheet.create({
  textTwo: {
    fontFamily: Fonts.default,
    color: Colors.darkBlueSecondary,
    fontWeight: "bold",
    fontSize: moderateScale(30, 0.1),
  },
  cellView: {
    height: moderateScale(52, 0.1),
    width: moderateScale(50, 0.1),
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 16,

    borderWidth: 1,
  },
});
