import React from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  Dimensions,
  TouchableOpacity,
} from "react-native";
import { moderateScale } from "react-native-size-matters";
import { Colors, Fonts, PLATFORM_DETECT } from "../../themes";
import AbstractButton from "../AbstractComponents/abstractButton";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import { useSelector } from "react-redux";
import AbstractAvatar from "../AbstractComponents/abstractAvatar";
import useIsMobileBrowser from "../../../src/Utils/useIsMobileBrowser";

const SW = Dimensions.get("window").width;

const ProfileSetting = ({ onPressEdit }) => {
  //   const { deviceType } = useSelector((state) => state.DeviceTypeReducer);;
  const { deviceType } = useSelector((state) => state.DeviceTypeReducer);

  const currentUser = useSelector((state) => state.AuthReducer.currentUser);
  // console.log("currentUser", currentUser);
  return (
    <View style={styles.mainContainer}>
      <View
        style={[
          {
            width: deviceType ? SW * 0.13 : SW * 0.29,
            height: deviceType ? SW * 0.13 : SW * 0.29,
            borderWidth: deviceType ? 3 : 1.5,
            borderRadius: SW * 0.2,
            justifyContent: "center",
            alignItems: "center",
            borderColor: "white",
            // backgroundColor: "red",
          },
        ]}
      >
        <View
          style={[
            styles.viewOne,
            {
              width: deviceType ? SW * 0.2 : SW * 0.24,
              height: deviceType ? SW * 0.2 : SW * 0.24,
              margin: 8,
              justifyContent: "center",
              alignItems: "center",
              borderRadius: SW * 0.2,
            },
          ]}
        >
          <AbstractAvatar
            size={deviceType ? 170 : "100%"}
            url={currentUser?.profilePicture?.image?.url}
          />
        </View>
      </View>

      <View style={{ marginVertical: deviceType ? 20 : 10 }}>
        <Text
          style={[styles.txtOne, { fontSize: deviceType ? 20 : wp("4.8%") }]}
        >
          {currentUser?.fullName}
        </Text>
      </View>

      <View style={{ marginTop: 0 }}>
        <AbstractButton
          onPress={onPressEdit}
          txtColor={Colors.blueprimary}
          txtSize={deviceType ? 20 : wp("3.4%")}
          label={"Edit"}
          height={deviceType ? 40 : SW * 0.18 * 0.42}
          width={deviceType ? 130 : SW * 0.18}
          type={"transparentBlue"}
        />
      </View>
    </View>
  );
};

export default ProfileSetting;

const styles = StyleSheet.create({
  mainContainer: {
    // width: moderateScale(99, 0.1),
    alignItems: "center",
  },
  viewOuter: {},
  viewOne: {},
  txtOne: {
    fontFamily: Fonts.default,

    width: 200,
    textAlign: "center",
    color: "white",
  },
});
