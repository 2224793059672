import { combineReducers } from "redux";
import AuthReducer from "./authReducer";
import VehiclesReducer from "./vehiclesReducer";
import OffersReducer from "./offersReducer";
import WalletReducer from "./walletReducer";
import ConversationsReducer from "./conversationsReducer";
import DeviceTypeReducer from "./deviceTypeReducer";
import AuctionReducer from "./auctionReducer";
const RootReducer = combineReducers({
  AuthReducer,
  VehiclesReducer,
  OffersReducer,
  WalletReducer,
  ConversationsReducer,
  DeviceTypeReducer,
  AuctionReducer,
});
export default RootReducer;
