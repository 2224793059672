import React, { useEffect, useRef, useState } from "react";
import {
  StyleSheet,
  View,
  Text,
  Dimensions,
  Platform,
  TouchableOpacity,
  Animated,
} from "react-native";
import DropDownArrowSvg from "../../Assets/Icons/dropDownArrowSvg";
import AbstractBottomSheet from "./abstractBottomSheet";
import { Picker } from "@react-native-picker/picker";
import { Colors } from "../../themes";
import AbstractBottomSheetV3 from "./abstractBottomSheetV3";
const SW = Dimensions.get("screen").width;

const AbstractDropDown = (props) => {
  const [open, setOpen] = useState(false);
  const animatedChevron = useRef(new Animated.Value(0)).current;
  useEffect(() => {
    if (open) {
      Animated.spring(animatedChevron, {
        toValue: 1,
        useNativeDriver: true,
      }).start();
    } else {
      Animated.spring(animatedChevron, {
        toValue: 0,
        useNativeDriver: true,
      }).start();
    }
  }, [open]);

  const chevronAngleInterpolated = animatedChevron.interpolate({
    inputRange: [0, 1],
    outputRange: ["0deg", "180deg"],
  });

  if (Platform.OS === "ios") {
    return (
      <View
        style={{
          flex: 1,
          width: props?.cWidth ? props?.cWidth : SW * 0.9,
          borderColor: props?.area ? Colors.blueprimary : "#f0f0f0",
          borderWidth: 1,
          borderRadius: 5,
          alignSelf: "center",
          flexDirection: "row",
        }}
      >
        <TouchableOpacity
          style={{ flex: 1, flexDirection: "row" }}
          onPress={() => setOpen(true)}
        >
          <>
            <View
              style={{ flex: 1, justifyContent: "center", paddingLeft: 10 }}
            >
              <Text>{props?.value ? props?.value : props?.defPlaceHolder}</Text>
            </View>
            <View
              style={{
                width: 50,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Animated.View
                style={{ transform: [{ rotate: chevronAngleInterpolated }] }}
              >
                <DropDownArrowSvg />
              </Animated.View>
            </View>
          </>
        </TouchableOpacity>


        {props.sheetWihDisableGesture ?
          <AbstractBottomSheetV3
            onClose={() => {
              setOpen(false)
              props.onChange(props.value)
            }}
            isVisible={open}
          >
            <Picker
              selectedValue={props?.value}
              itemStyle={{ color: "white" }}
              placeholder={props?.defPlaceHolder}
              onValueChange={(itemValue, itemIndex) => {
                props.setSelectedArea(itemValue)
                // props.onChange(itemValue)
                // setOpen(false);
              }}
            >
              {props?.items?.map((item) => (
                <Picker.Item
                  key={item?.label}
                  label={item?.label}
                  value={item?.value}
                />
              ))}
            </Picker>
          </AbstractBottomSheetV3>
          :
          <AbstractBottomSheet onClose={() => setOpen(false)} isVisible={open}>
            <Picker
              selectedValue={props?.value}
              itemStyle={{ color: "white" }}
              placeholder={props?.defPlaceHolder}
              onValueChange={(itemValue, itemIndex) => {
                props.onChange(itemValue), setOpen(false);
              }}
            >
              {props?.items?.map((item) => (
                <Picker.Item
                  key={item?.label}
                  label={item?.label}
                  value={item?.value}
                />
              ))}
            </Picker>
          </AbstractBottomSheet>
        }

      </View>
    );
  } else {
    return (
      <View
        style={{
          flex: 1,
          width: props?.cWidth ? props?.cWidth : SW * 0.9,
          borderColor: props?.area ? Colors.blueprimary : "#f0f0f0",
          height: 50,
          borderWidth: 1,
          borderRadius: 5,
          alignSelf: "center",
          flexDirection: "row",
        }}
      >
        <View
          style={{ flex: 1, flexDirection: "row" }}
          onPress={() => setOpen(true)}
        >
          <>
            <View
              style={{
                flex: 1,
                justifyContent: "center",
                paddingHorizontal: 10,
              }}
            >
              <Picker
                selectedValue={props?.value}
                // itemStyle={{ color: "white" }}
                style={{
                  outline: 0,
                  borderWidth: 0,
                  backgroundColor: "transparent",
                }}
                placeholder={props?.defPlaceHolder}
                onValueChange={(itemValue) => props?.onChange(itemValue)}
              >
                {props?.items?.map((item, index) => (
                  <Picker.Item
                    key={index}
                    label={item?.label}
                    value={item?.value}
                  />
                ))}
              </Picker>
            </View>
          </>
        </View>
      </View>
    );
  }
};

export default AbstractDropDown;

const styles = StyleSheet.create({});
