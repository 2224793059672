import { StyleSheet, Text, View, TouchableOpacity } from "react-native";
import React from "react";
import {
  SenderStyle,
  SenderDateStyle,
  ReceiverStyle,
  ReceiverDateStyle,
} from "./messageStyles";
// import ClockSvg from "../../../Assets/Icons/clockSvg";
import RefreshSvg from "../../../Assets/Icons/refreshIcon";
import MessageController from "../../../Controllers/messageController";
import { dateToShortTime } from "../../../Utils/common";
const MyMessage = ({ children, message, currentUser, onResend }) => {
  const isSender = message.buyer === currentUser._id;
  const handleResend = () => {
    if (onResend) onResend(message);
  };
  return (
    <View
      style={{
        width: "100%",
        justifyContent: "flex-start",
        flexDirection: isSender ? "row-reverse" : "row",
      }}
    >
      <View
        style={{
          flex: 1,
          maxWidth: 24,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {message.status === MessageController.MESSAGE_STATUS.SENDING ? (
          <Text></Text>
        ) : (
          // <ClockSvg size={12}/>
          false
        )}
      </View>
      <View style={isSender ? SenderStyle : ReceiverStyle}>
        {React.cloneElement(children, { isSender })}
        <View style={{ alignItems: isSender ? "flex-end" : "flex-start" }}>
          <Text style={isSender ? SenderDateStyle : ReceiverDateStyle}>
            {dateToShortTime(message.sentTime)}
          </Text>
        </View>
      </View>
      <View
        style={{
          flex: 1,
          maxWidth: 50,
          justifyContent: "center",
          paddingLeft: 5,
        }}
      >
        {message.status === MessageController.MESSAGE_STATUS.FAILD ? (
          <TouchableOpacity
            onPress={handleResend}
            style={{
              width: 40,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <RefreshSvg color={"red"} />
            <Text style={{ fontSize: 10, marginRight: -2, color: "red" }}>
              Resend
            </Text>
          </TouchableOpacity>
        ) : (
          false
        )}
      </View>
    </View>
  );
};

export default MyMessage;

const styles = StyleSheet.create({});
