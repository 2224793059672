import React, { useState, useRef } from "react";
import {
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Image,
  Platform,
  Dimensions,
} from "react-native";
import { moderateScale } from "react-native-size-matters";
import BackBtnSvg from "../../Assets/Icons/backBtnSvg";
import { Colors, Fonts } from "../../themes";
import { getStatusBarHeight } from "react-native-status-bar-height";
// import ReportSvg from "../../Assets/Icons/reportSvg";
import AbstractBottomSheet from "../AbstractComponents/abstractBottomSheet";
import AbstractContentContainer from "../AbstractComponents/abstractContentContainer";
import ReportSheet from "./BottomSheets/reportSheet";
import ImagePlacer from "./imagePlacer";
import { formatCurrencyToUSD } from "../../Utils/common";
import ConflictResponseButton from "./conflictResponseButton";
import { getFirstImage } from "../../Controllers/vehiclesController";
import AbstractButton from "../AbstractComponents/abstractButton";
import SubmitOfferStepsSheet from "../CompositeComponents/submitOfferStepsSheetV4";
import { SheetManager } from "react-native-actions-sheet";
import BuyNowSheet from "./BottomSheets/buyNowSheet";
import GlobalConstants from "../../Controllers/constants";

const STATUS_BAR_HEIGHT = getStatusBarHeight();
const SW = Dimensions.get("window").width;

const ReportBtn = ({ openSheet }) => {
  return (
    <TouchableOpacity onPress={openSheet} style={styles.reportView}>
      <View style={{ marginRight: 5 }}>{/* <ReportSvg /> */}</View>
      <View>
        <Text style={styles.textReport}>Report</Text>
      </View>
    </TouchableOpacity>
  );
};

const HeaderBottomSheet = () => {
  return (
    <React.Fragment>
      <View style={styles.headerViewOne} />

      <View style={styles.headerViewTwo} />
    </React.Fragment>
  );
};

const ChatHeader = ({ onPressBack, conversation, offer, offerType }) => {
  const [sheetVisible, setSheetVisible] = useState(false);
  const title = conversation?.business?.fullName;
  const BUY_NOW_SHEET = "BUY_NOW_SHEET-2";
  const sendOffer = () => {
    if (offerType == "buyNow") {
      SheetManager.show(BUY_NOW_SHEET);
    } else {
      SheetManager.show("Car-Detail-Sheet-v4");
    }
  };
  // console.log(offer._id);
  return (
    <React.Fragment>
      <View
        style={{ height: Platform.OS === "android" ? STATUS_BAR_HEIGHT : 0 }}
      />
      <View style={styles.mainContainer}>
        <View style={styles.viewOne}>
          <TouchableOpacity
            style={{
              flexDirection: "row",
              alignItems: "center",
              paddingVertical: 8,
            }}
            onPress={onPressBack}
          >
            <BackBtnSvg />
            <View style={{ marginLeft: 10 }}>
              <Text style={styles.txtSix}>{title}</Text>
            </View>
          </TouchableOpacity>

          {/* <ReportBtn openSheet={() => setSheetVisible(true)} /> */}
          {conversation?.status == "accepted" ? (
            <ConflictResponseButton conversation={conversation} />
          ) : conversation?.status == "conversation" ? (
            <AbstractButton
              // processing={processing}
              onPress={sendOffer}
              txtSize={moderateScale(12, 0.1)}
              width={moderateScale(97, 0.1)}
              height={moderateScale(27, 0.1)}
              label={"Send Offer"}
            />
          ) : offer.status ==
            GlobalConstants.OFFER_STATUS.PAYMENT_REFUNDABLE ? (
            <View
              style={{
                backgroundColor: "rgba(241,156,56,0.9)",
                paddingHorizontal: 6,
                paddingVertical: 6,
                borderRadius: 5,
              }}
            >
              <Text
                style={{
                  color: "white",
                  fontSize: 13,
                  textAlign: "center",
                  fontFamily: Fonts.default,
                }}
              >
                Refundable Amount:${(offer.amount / 100) * 5}
              </Text>
            </View>
          ) : (
            false
          )}
          {/* <AbstractButton
            // processing={processing}
            onPress={sendOffer}
            txtSize={moderateScale(12, 0.1)}
            width={moderateScale(97, 0.1)}
            height={moderateScale(27, 0.1)}
            label={'Send Offer'}
          /> */}
        </View>
        <View
          style={{
            width: "100%",
            shadowColor: "#000",
            backgroundColor: "#F5F7FA",
            // shadowOffset: { width: 0, height: 1.5 },
            // shadowOpacity: 0.3,
            // shadowRadius: 1,
            // elevation: 1
            borderColor: Colors.txtInputBorderColor,
            borderBottomWidth: 1,
          }}
        >
          <View style={styles.viewTwo}>
            <View
              style={{
                justifyContent: "center",
              }}
            >
              <View style={styles.imgView}>
                <ImagePlacer
                  imageUrl={
                    getFirstImage(conversation?.vehicle?.pictures).image.url
                  }
                  avatarUrl={
                    getFirstImage(conversation?.vehicle?.pictures).avatar.url
                  }
                  type={"small"}
                />
                <View style={{ margin: 4, position: "absolute" }}>
                  {offer.offerType == "buyNow" && (
                    <View
                      style={{
                        backgroundColor: "rgba(0,0,0,0.6)",
                        paddingHorizontal: 3,
                        paddingVertical: 3,
                        borderRadius: 5,
                        marginBottom: 3,
                      }}
                    >
                      <Text
                        style={{
                          color: "white",
                          fontSize: 10,
                          fontFamily: Fonts.default,
                        }}
                      >
                        Buy Now
                      </Text>
                    </View>
                  )}

                  {offer.status ==
                    GlobalConstants.OFFER_STATUS.CONVERSATION && (
                    <View
                      style={{
                        backgroundColor: "rgba(0,0,0,0.6)",
                        paddingHorizontal: 3,
                        paddingVertical: 3,
                        borderRadius: 5,
                      }}
                    >
                      <Text
                        style={{
                          color: "white",
                          fontSize: 10,
                          textAlign: "center",
                          fontFamily: Fonts.default,
                        }}
                      >
                        Draft
                      </Text>
                    </View>
                  )}
                  {offer.status ==
                    GlobalConstants.OFFER_STATUS.PAYMENT_REFUNDED && (
                    <View
                      style={{
                        backgroundColor: "rgba(241,156,56,0.9)",
                        paddingHorizontal: 3,
                        paddingVertical: 3,
                        borderRadius: 5,
                      }}
                    >
                      <Text
                        style={{
                          color: "white",
                          fontSize: 10,
                          textAlign: "center",
                          fontFamily: Fonts.default,
                        }}
                      >
                        Refunded
                      </Text>
                    </View>
                  )}
                </View>
              </View>
            </View>

            <View
              style={{
                flexShrink: 1,
                height: "100%",
                justifyContent: "center",
                paddingLeft: 10,
              }}
            >
              <View>
                <Text numberOfLines={2} style={[styles.txtTwo]}>
                  {conversation?.vehicle?.title}
                </Text>
              </View>

              {conversation?.status === "conversation" ? (
                false
              ) : (
                <View>
                  <Text
                    numberOfLines={1}
                    style={[styles.txtThree, { fontWeight: "500" }]}
                  >
                    {formatCurrencyToUSD(conversation.amount)}
                  </Text>
                </View>
              )}
            </View>
          </View>
        </View>
      </View>

      <AbstractBottomSheet
        // MyHeader={<HeaderBottomSheet />}
        isVisible={sheetVisible}
        onClose={() => setSheetVisible(false)}
        bgColor
      >
        <View style={{ backgroundColor: "white", width: "100%" }}>
          <AbstractContentContainer>
            <ReportSheet closeSheet={() => setSheetVisible(false)} />
          </AbstractContentContainer>
        </View>
      </AbstractBottomSheet>

      <SubmitOfferStepsSheet
        offer={offer}
        vehicle={offer?.vehicle}
        fromInitializeMessageFlow={true}
        onClose={() => SheetManager.hide("Car-Detail-Sheet-v4")}
      />
      <BuyNowSheet
        id={BUY_NOW_SHEET}
        offer={offer}
        vehicle={offer?.vehicle}
        buyNowPrice={offer?.amount}
        isCreateMode={false}
        fromInitializeMessageFlow={true}
        onClose={() => SheetManager.hide(BUY_NOW_SHEET)}
      />
    </React.Fragment>
  );
};

export default ChatHeader;
const styles = StyleSheet.create({
  mainContainer: {
    width: "100%",
  },
  viewOne: {
    width: "90%",
    alignSelf: "center",
    height: moderateScale(50, 0.1),
    backgroundColor: Colors.screenBgColor,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  txtSix: {
    fontFamily: Fonts.default,
    fontWeight: "700",
    fontSize: moderateScale(15, 0.1),
    color: "black",
  },
  viewTwo: {
    width: "90%",
    height: moderateScale(79, 0.1),

    backgroundColor: "#F5F7FA",
    flexDirection: "row",

    alignSelf: "center",
  },
  imgView: {
    width: moderateScale(72, 0.1),
    height: moderateScale(55, 0.1),
    borderRadius: 11,
  },
  deleteBtnView: {
    width: moderateScale(30, 0.1),
    height: moderateScale(21, 0.1),
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Colors.pinkPrimary,
    borderRadius: 5,
  },
  editBtnView: {
    width: moderateScale(56, 0.1),
    height: moderateScale(21, 0.1),
    backgroundColor: "#D4E3FA",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    borderRadius: 5,
  },
  txtOne: {
    color: Colors.blueSecondaryOne,
    fontFamily: Fonts.default,
    fontSize: moderateScale(11, 0.1),
    paddingLeft: 5,
  },
  txtTwo: {
    color: "#16478E",
    fontFamily: Fonts.default,
    fontSize: moderateScale(17, 0.1),
    fontWeight: "700",
  },
  txtThree: {
    color: "#030303",
    fontSize: moderateScale(15, 0.1),
    fontFamily: Fonts.default,
  },
  indicatorView: {
    position: "absolute",
    top: -5,
    right: -5,
    width: 12,
    height: 12,
    borderRadius: 10,
    backgroundColor: "#377AF6",
    zIndex: 1,
  },
  txtFour: {
    fontFamily: Fonts.default,
    color: "#5D5C5D",
    fontSize: moderateScale(11, 0.1),
  },
  txtFive: {
    fontFamily: Fonts.default,
    color: "#292929",
    fontSize: moderateScale(9, 0.1),
  },
  activeIndicator: {
    width: 4,
    height: 4,
    borderRadius: 5,
    backgroundColor: "#00D387",
    position: "absolute",
    bottom: 0,
    right: 0,
  },
  reportView: {
    width: moderateScale(97, 0.1),
    height: moderateScale(27, 0.1),
    backgroundColor: "rgba(38, 116, 230, 0.15)",
    borderRadius: 5,
    position: "absolute",
    right: 0,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  textReport: {
    fontFamily: Fonts.default,
    fontSize: moderateScale(12, 0.1),
    color: "#2674E5",
  },
  headerViewOne: {
    width: "90%",
    height: 50,
    position: "absolute",
    top: -65,
    alignSelf: "center",
    backgroundColor: "#A6A6A6",
    justifyContent: "flex-end",
    borderTopLeftRadius: 29,
    borderTopRightRadius: 29,
  },
  headerViewTwo: {
    width: "100%",
    backgroundColor: "white",
    height: 35,
    position: "absolute",
    top: -35,
    zIndex: 999,
    borderTopLeftRadius: 28,
    borderTopRightRadius: 28,
  },
});
