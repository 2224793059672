import React from 'react';
import {ScrollView, StyleSheet, Text, View} from 'react-native';
import {moderateScale} from 'react-native-size-matters';
import BackBtnSvg from '../../Assets/Icons/backBtnSvg';
import AbstractContentContainer from '../../Components/AbstractComponents/abstractContentContainer';
import AbstractHeaders from '../../Components/AbstractComponents/abstractHeaders';
import ScreenContainer from '../../Components/AbstractComponents/abstractScreenContainer';
import FocusAwareStatusBar from '../../Components/AbstractComponents/statusbarConfiguration';
import {goBack} from '../../Navigation/mainNavigation';
import {Colors, Fonts} from '../../themes';

const TermsAndConditionsScreen = ({route}) => {

  const {backGroundColor} = route.params

  return (
    <ScreenContainer
      upperStyle={{backgroundColor: Colors.screenBgColor}}
      bottomStyle={{backgroundColor: Colors.screenBgColor}}
    >
      <View style={[styles.mainContainer,{backgroundColor:backGroundColor}]}>
        <FocusAwareStatusBar
          barStyle="dark-content"
          backgroundColor={'transparent'}
          translucent={true}
        />
        <AbstractHeaders
          leftChild={() => (
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <BackBtnSvg />
              <Text style={styles.textOne}>Back</Text>
            </View>
          )}
          onPressLeft={() => goBack()}
          middleChild={() => (
            <View>
              <Text style={styles.textTwo}>Terms & conditions</Text>
            </View>
          )}
        />

        <ScrollView showsVerticalScrollIndicator={false}>
          <AbstractContentContainer>
            <View style={{width: '100%'}}>
              <Text style={styles.textThree}>
                {`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Enim neque eget lacus, aliquam arcu praesent id. Arcu sit justo sed imperdiet aliquam. Nisi, amet, pretium suscipit tincidunt sit viverra. Senectus ipsum lectus quis dolor aenean velit id suspendisse. Lectus orci ipsum, nibh tellus sed vestibulum varius.
Est sit eget odio fames viverra cras. Ipsum amet, elit eu condimentum eu est, morbi. Suspendisse erat arcu libero lectus gravida purus et facilisi vestibulum. Massa adipiscing risus, amet non urna. Neque quisque dis semper bibendum vel. Ac curabitur ullamcorper facilisis sagittis aenean neque, cras diam. Pulvinar scelerisque pellentesque eget sed vehicula duis penatibus sed. Amet, quis et consequat feugiat. Pellentesque et praesent nisl cras. Mattis id est turpis leo libero quis. Ac ultricies dui et dapibus. At in ultrices nunc facilisi ac purus condimentum eu. Mattis sit accumsan commodo, dignissim. Pretium aliquet fames platea nam.
Ante neque vivamus diam orci. Ut scelerisque ipsum commodo proin netus massa suspendisse pellentesque. Dolor id fringilla sit phasellus enim adipiscing. Viverra volutpat volutpat vitae faucibus diam sem. Laoreet sodales nec ornare tempus nunc, sit tincidunt. Augue sit non viverra iaculis est, at sem. Vestibulum aliquam eget posuere at suspendisse eu ultrices.
Dictum pellentesque dui sapien erat erat bibendum. Ullamcorper blandit fringilla hac facilisis. Magna scelerisque lacus, id maecenas proin vel, elit, accumsan. Orci donec dictum egestas morbi massa. Mauris molestie ultrices sem dui. Id purus fringilla nulla ac sed mattis feugiat sit tellus. Neque lobortis in est turpis justo, purus congue. Sodales elementum adipiscing consectetur amet tempor. `}
              </Text>
            </View>
          </AbstractContentContainer>
          <View style={{width: '100%', height: 30}} />
        </ScrollView>
      </View>
    </ScreenContainer>
  );
};

export default TermsAndConditionsScreen;

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1
  },
  textOne: {
    fontFamily: Fonts.default,
    color: 'black',
    fontSize: moderateScale(19, 0.1),
    paddingRight: 20,
  },
  textTwo: {
    fontFamily: Fonts.default,
    color: 'black',
    fontSize: moderateScale(19, 0.1),
    fontWeight: '800',
    textAlign: 'center',
  },
  textThree: {
    fontFamily: Fonts.default,
    color: 'black',
    fontSize: moderateScale(12, 0.1),
    lineHeight: 18,
  },
});
