import { StyleSheet, Text, View } from "react-native";
import React, { useEffect, useState } from "react";
import { Colors, Fonts } from "../../themes";
import Checkbox from "expo-checkbox";
import { moderateScale } from "react-native-size-matters";

const FilterMakeItem = ({ item, onChecked, index }) => {
  const [checked, setChecked] = useState(item.checked);

  const onPressCheckBox = () => {
    setChecked((prev) => !prev);
    onChecked({ ...item, checked: !checked }, index);
    // onChecked({ ...item, checked: !checked });
  };

  useEffect(() => {
    setChecked(item.checked);
  }, [item]);

  return (
    <View
      style={{
        width: "100%",
        flexDirection: "row",
        alignItems: "center",
        marginVertical: 5,
      }}
    >
      <View>
        <Checkbox
          color={Colors.blueprimary}
          tintColors={{
            true: Colors.blueprimary,
            false: "lightgrey",
          }}
          //   onCheckColor={Colors.blueprimary}
          //   onTintColor={Colors.blueprimary}
          shouldRasterizeIOS
          // style={{ transform: [{ scale: 0.7 }], marginBottom: -5 }}
          disabled={false}
          boxType="square"
          value={checked}
          onValueChange={onPressCheckBox}
        />
      </View>
      <View style={{ marginLeft: 10 }}>
        <Text style={styles.textOne}>{item.label}</Text>
      </View>
    </View>
  );
};

export default FilterMakeItem;

const styles = StyleSheet.create({
  textOne: {
    // color: "#5A616C",
    color: Colors.blackPrimary,
    fontFamily: Fonts.default,
    fontWeight: "400",
    fontSize: moderateScale(12.5, 0.1),
  },
});
