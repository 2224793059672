import React from "react";
import {
  createStackNavigator,
  CardStyleInterpolators,
} from "@react-navigation/stack";
import BottomTabNavigation from "./bottomTabNavigation";
import CarDetailScreen from "../Screens/AppScreens/carDetailScreen";
import FilterScreen from "../Screens/AppScreens/filterScreen";
import SettingsEditScreen from "../Screens/AppScreens/settingsEditScreen";
import TermsAndConditionsScreen from "../Screens/AppScreens/termsScreen";
import ChatScreen from "../Screens/AppScreens/chatScreen";
import { Platform } from "react-native";
import SetupWalletScreen from "../Screens/AppScreens/setupWalletScreen";
// import WalletScreen from "../Screens/AppScreens/walletScreen";
// import TermsScreenV2 from "../Screens/AppScreens/termsScreenV2";
import WalletNewScreen from "../Screens/AppScreens/walletNewScreen";
import CustomWebViewScreen from "../Screens/AppScreens/customWebViewScreen";
import TermsOfUseScreen from "../Screens/AuthScreens/Terms/termsOfUserScreen";
import AuctionDetailScreen from "../Screens/AppScreens/auctionDetailScreen";
import HomeScreen from "../Screens/AppScreens/homeScreen";
import CarDetailPlaceBidScreen from "../Screens/AppScreens/carDetailPlaceBidScreen";
import MyPlacedBidCarDetailScreen from "../Screens/AppScreens/myPlacedBidcarDetailScreen";

import MyBidsScreen from "../Screens/AppScreens/myBidsScreen";
import GoogleLocSearch from "../Screens/AppScreens/googleLocSearch";
import DeleteAccountScreen from "../Screens/AuthScreens/deleteAccountScreen";

const Stack = createStackNavigator();

const AppStack = () => {
  return (
    <Stack.Navigator
      screenOptions={{ headerShown: false }}
      // initialRouteName="BottomTab"
    >
      <Stack.Screen
        name="Home"
        component={BottomTabNavigation}
        options={{
          cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
          gestureEnabled: false,
          gestureDirection: "horizontal",
        }}
      />
      <Stack.Screen
        name="ExploreCars"
        component={HomeScreen}
        options={{
          cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
          gestureEnabled: false,
          gestureDirection: "horizontal",
        }}
      />

      <Stack.Screen
        name="AuctionDetail"
        component={AuctionDetailScreen}
        options={{
          cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
          gestureEnabled: true,
          gestureDirection: "horizontal",
        }}
      />

      <Stack.Screen
        name="CarDetailPlaceBid"
        component={CarDetailPlaceBidScreen}
        options={{
          cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
          gestureEnabled: true,
          gestureDirection: "horizontal",
        }}
      />
      <Stack.Screen
        name="MyPlacedBidCarDetail"
        component={MyPlacedBidCarDetailScreen}
        options={{
          cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
          gestureEnabled: true,
          gestureDirection: "horizontal",
        }}
      />

      <Stack.Screen
        name="CarDetails"
        component={CarDetailScreen}
        options={{
          cardStyleInterpolator: CardStyleInterpolators.forVerticalIOS,
          gestureEnabled: Platform.OS === "ios",
          gestureDirection: "vertical",
        }}
      />
      <Stack.Screen
        name="Search Location"
        component={GoogleLocSearch}
        options={{
          cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
          gestureEnabled: Platform.OS === "ios",
          gestureDirection: "horizontal",
        }}
      />

      <Stack.Screen
        name="Filter"
        component={FilterScreen}
        options={{
          cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
          gestureEnabled: false,
          gestureDirection: "horizontal",
        }}
      />
      <Stack.Screen
        name="MyBids"
        component={MyBidsScreen}
        options={{
          cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
          gestureEnabled: false,
          gestureDirection: "horizontal",
        }}
      />
      <Stack.Screen
        name="Edit Profile"
        component={SettingsEditScreen}
        options={{
          cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
          gestureEnabled: true,
          gestureDirection: "horizontal",
        }}
      />
      <Stack.Screen
        name="Delete Account"
        component={DeleteAccountScreen}
        options={{
          cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
          gestureEnabled: true,
          gestureDirection: "horizontal",
        }}
      />

      <Stack.Screen
        name="Chat"
        component={ChatScreen}
        options={{
          cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
          gestureEnabled: true,
          gestureDirection: "horizontal",
        }}
      />

      <Stack.Screen
        name="SetupWallet"
        component={SetupWalletScreen}
        options={{
          cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
          gestureEnabled: true,
          gestureDirection: "horizontal",
        }}
      />
      {/* <Stack.Screen
        name="WalletScreen"
        component={WalletScreen}
        options={{
          cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
          gestureEnabled: true,
          gestureDirection: "horizontal",
        }}
      /> */}

      <Stack.Screen
        name="WalletNewScreen"
        component={WalletNewScreen}
        options={{
          cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
          gestureEnabled: true,
          gestureDirection: "horizontal",
        }}
      />

      <Stack.Screen
        name="Terms & Conditions"
        component={TermsOfUseScreen}
        options={{
          cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
          gestureEnabled: true,
          gestureDirection: "horizontal",
        }}
      />

      <Stack.Screen
        name="CustomWebViewScreen"
        component={CustomWebViewScreen}
        options={{
          cardStyleInterpolator: CardStyleInterpolators.forHorizontalIOS,
          gestureEnabled: true,
          gestureDirection: "vertical",
        }}
      />
    </Stack.Navigator>
  );
};

export default AppStack;
