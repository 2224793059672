import { Dimensions } from "react-native";
import { PLATFORM_DETECT } from "../../../themes";

export const WIDTH = Dimensions.get("window").width;
export const HEIGHT = 450;
export const GEAR_WIDTH = PLATFORM_DETECT.SH * 0.4; //315;
export const GEAR_PIN_WIDTH = 5;
export const GEAR_MIDDLE_HEIGHT = 20;
const UPPER_GEAR_HEIGHT = 0;
export const POINTER_ICON_SIZE = 12;
export const UPPER_FONT_STYLE = { fontSize: 18, fontWeight: "bold" };

let rmHeight = GEAR_MIDDLE_HEIGHT / 2;
export const GEAR_UPPER_HEIGHT = UPPER_GEAR_HEIGHT - rmHeight;
export const GEAR_HEIGHT = HEIGHT;
export const GEAR_LOWER_HEIGHT = 100;
export const POINTER_ICON_TOP =
  GEAR_UPPER_HEIGHT - POINTER_ICON_SIZE * 2 + GEAR_MIDDLE_HEIGHT;
