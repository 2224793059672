import React from "react";
import { Svg, Defs, G, Rect, Path, Polygon } from "react-native-svg";

const CarFaxSVG = ({ width, height }) => {
  return (
    <Svg
      height={height}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 300 200"
    >
      <G id="Artboard_Backgrounds" data-name="Artboard Backgrounds">
        <Rect fill="#f0f0f0" width="300" height="200" />
      </G>
      <G id="Graphics">
        <Path
          fill="#fff"
          d="M284.3,96.21H6A2.78,2.78,0,0,0,4,97,2.76,2.76,0,0,0,3.16,99v45.17A2.84,2.84,0,0,0,6,146.85H284.2a2.84,2.84,0,0,0,2.82-2.74l.1-45.19A2.83,2.83,0,0,0,284.3,96.21ZM267.8,115l-1.9,3a62,62,0,0,0-3.3,5.7,55.55,55.55,0,0,0-3.3-5.7l-1.9-3h-11l10.81,15.4-9.44,13h-6.17v-44H283.7v44h-6.62l-9.28-13.2L278.6,115Zm-1.61,28.4h-7.7l.61-.8a57,57,0,0,0,3.3-5.8,59.61,59.61,0,0,0,3.3,5.8Z"
        />
        <Path
          className="cls-2"
          d="M288.47,94.76a6,6,0,0,0-4.17-1.71H6a6,6,0,0,0-6,5.88v45.24A6,6,0,0,0,6,150H284.2a6,6,0,0,0,6-5.84l.1-45.28A6,6,0,0,0,288.47,94.76ZM287,144.11a2.84,2.84,0,0,1-2.82,2.74H6a2.84,2.84,0,0,1-2.82-2.72V99A2.76,2.76,0,0,1,4,97a2.78,2.78,0,0,1,2-.81H284.3a2.83,2.83,0,0,1,2.82,2.71Z"
        />
        <Path
          fill="#fff"
          d="M258.49,143.43l.61-.8a57,57,0,0,0,3.3-5.8,59.61,59.61,0,0,0,3.3,5.8l.49.8Zm25.21,0h-6.62l-9.28-13.2L278.6,115H267.8l-1.9,3a62,62,0,0,0-3.3,5.7,55.55,55.55,0,0,0-3.3-5.7l-1.9-3h-11l10.81,15.4-9.44,13h-6.17v-44H283.7Z"
        />
        <Rect fill="#231f20" x="193.2" y="97.83" width="45.29" height="47.29" />
        <Rect fill="#231f20" x="51.99" y="97.83" width="45.29" height="47.29" />
        <Polygon
          fill="#231f20"
          points="4.78 145.13 50.18 145.13 50.18 97.83 4.89 97.83 4.78 145.13"
        />
        <Rect
          fill="#231f20"
          x="145.99"
          y="97.83"
          width="45.29"
          height="47.29"
        />
        <Rect fill="#231f20" x="98.89" y="97.83" width="45.29" height="47.29" />
        <Path
          fill="#fff"
          d="M28.48,137.93c-9.3,0-14.2-6.9-14.2-16.1,0-10.1,5.9-16.2,14.3-16.2,7.7,0,10.7,3.3,12.9,9.2l-8,3.1c-1-3-2.1-4.8-5-4.8-3.6,0-5.2,3.7-5.2,8.7,0,4.8,1.5,8.6,5.3,8.6,2.7,0,4-1.5,5.6-4.5l7.4,4c-2.1,4-5.6,8-13,8Zm48.91-13.2h-5.2l.6-2c1-4,1.6-5.8,2-8,.4,2.2.9,3.9,2,8Zm13.2,12.4-11.4-31.4h-8.8l-11.31,31.5h9.31l1.7-5.6h9.4l1.7,5.6Zm35.3-20.8c0,2.3-1.1,3.5-4,3.5h-5V113h5.2C124.79,113,125.89,114.43,125.89,116.33Zm9.3,20.9-6.3-12c3.5-1.6,5.6-4.5,5.6-9.2,0-7.4-4.8-10.2-12.2-10.2h-14v31.4h8.6v-10.5h3.5l5,10.5Zm29.7-24v4.4h9.8V125h-9.8v12.2h-8.7v-31.3H181v7.3Zm53.2,11.7H213l.6-2c1.1-4,1.6-5.8,2-7.9.4,2.1.9,3.8,2,7.9Zm13.2,12.4L220,106h-8.7l-11.5,31.2h9.3l1.7-5.5h9.4l1.7,5.5Z"
        />
        <Path
          fill="#231f20"
          d="M240,97.83V145h45.29V97.83Zm18.49,45.6.61-.8a57,57,0,0,0,3.3-5.8,59.61,59.61,0,0,0,3.3,5.8l.49.8Zm25.21,0h-6.62l-9.28-13.2L278.6,115H267.8l-1.9,3a62,62,0,0,0-3.3,5.7,55.55,55.55,0,0,0-3.3-5.7l-1.9-3h-11l10.81,15.4-9.44,13h-6.17v-44H283.7Z"
        />
        <Path
          fill="#fff"
          d="M266.19,143.43h-7.7l.61-.8a57,57,0,0,0,3.3-5.8,59.61,59.61,0,0,0,3.3,5.8Z"
        />
        <Path
          fill="#fff"
          d="M283.7,99.42v44h-6.62l-9.28-13.2L278.6,115H267.8l-1.9,3a62,62,0,0,0-3.3,5.7,55.55,55.55,0,0,0-3.3-5.7l-1.9-3h-11l10.81,15.4-9.44,13h-6.17v-44Z"
        />
        <Path
          fill="#221f1f"
          d="M300,97.73a4,4,0,0,1-1.2,3,3.93,3.93,0,0,1-2.9,1.3,4.1,4.1,0,0,1-4.1-4.2,4.06,4.06,0,0,1,1.2-3,4,4,0,0,1,3-1.2,3.87,3.87,0,0,1,2.8,1.2,4.1,4.1,0,0,1,1.2,3Zm-.7,0a3.51,3.51,0,0,0-1-2.5,3.15,3.15,0,0,0-2.3-1,3.26,3.26,0,0,0-2.5,1,3.62,3.62,0,0,0,0,5,3.28,3.28,0,0,0,2.4,1,3.22,3.22,0,0,0,2.4-1A3.54,3.54,0,0,0,299.3,97.73Zm-1-1a1.45,1.45,0,0,1-.6,1,1.74,1.74,0,0,1-1,.5l1.4,2h-1l-1.4-2h-.9v2h-.7v-5h2.4a1.81,1.81,0,0,1,1.3.4,1.41,1.41,0,0,1,.4,1Zm-.9,0c0-.5-.3-.8-1.1-.8h-1.5v1.6h1.5C297,97.53,297.4,97.26,297.4,96.73Z"
        />
        <Path
          fill="#221f1f"
          d="M78.49,49.12C66.58,49.12,65,56.63,65,68.93s1.6,20,13.51,20V89c12.8,0,13.5-9.2,13.5-19.9S91.29,49.12,78.49,49.12Zm0,33.81-.1.1c-3.5,0-4-3.3-4-13.9s.4-14,4-14,4.3,3.2,4.3,13.8S82.09,82.93,78.49,82.93Z"
        />
        <Polygon
          fill="#221f1f"
          points="122.89 77.13 122.79 77.13 118.49 49.83 110.09 49.83 106.09 77.13 105.99 77.13 102.59 49.83 93.59 49.83 100.39 88.13 110.39 88.13 114.09 60.92 114.19 60.92 118.19 88.13 128.19 88.13 134.79 49.83 126.09 49.83 122.89 77.13"
        />
        <Path
          fill="#221f1f"
          d="M14.28,59.23c0-2.8,1.4-4.1,3.6-4.1a2.88,2.88,0,0,1,2.7,1.4,7.88,7.88,0,0,1,.8,4h8.7v-1c0-5.9-3.3-10.51-12.3-10.51-9.4,0-12.7,5.21-12.7,11.31,0,14.3,16.5,9.7,16.5,18,0,2.2-.9,4.5-4,4.5s-3.9-2.1-3.9-5v-1.4H5.08v2.5c0,8,6,10,12.6,10,8.3,0,13.1-3.5,13.1-12.2C30.78,63.23,14.28,67.23,14.28,59.23Z"
        />
        <Polygon
          fill="#221f1f"
          points="51.18 64.13 43.98 64.13 43.98 49.83 34.98 49.83 34.98 88.13 43.98 88.13 43.98 71.83 51.18 71.83 51.18 88.13 60.19 88.13 60.19 49.83 51.18 49.83 51.18 64.13"
        />
        <Polygon
          fill="#221f1f"
          points="187.91 69.65 202.91 69.65 202.91 66.6 187.91 66.6 187.91 52.35 203.91 52.35 203.91 49.3 184.57 49.3 184.57 88.65 204.41 88.65 204.41 85.6 187.91 85.6 187.91 69.65"
        />
        <Polygon
          fill="#221f1f"
          points="260.37 66.1 244.62 66.1 244.62 49.3 241.27 49.3 241.17 88.75 244.52 88.75 244.52 69.15 260.47 69.15 260.47 88.65 263.62 88.65 263.62 49.3 260.37 49.3 260.37 66.1"
        />
        <Polygon
          fill="#221f1f"
          points="164.53 82.63 154.09 49.67 153.97 49.3 149.37 49.3 149.37 88.65 152.71 88.65 152.71 55.85 162.91 88.75 165.97 88.75 176.17 55.85 176.17 88.55 179.51 88.55 179.51 49.2 174.81 49.2 164.53 82.63"
        />
        <Polygon
          fill="#221f1f"
          points="270.42 85.6 270.42 69.65 285.42 69.65 285.42 66.6 270.42 66.6 270.42 52.35 286.52 52.35 286.52 49.3 266.97 49.3 267.07 88.65 286.92 88.65 286.92 85.6 270.42 85.6"
        />
        <Polygon
          fill="#221f1f"
          points="215.17 52.35 225.47 52.35 225.37 88.75 228.72 88.75 228.72 52.35 238.92 52.35 238.92 49.3 215.17 49.3 215.17 52.35"
        />
      </G>
    </Svg>
  );
};

export default CarFaxSVG;
