import React, { useState, useRef } from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import { moderateScale } from "react-native-size-matters";
import OptionsSvg from "../../Assets/Icons/optionsSvg";
import { Colors, Fonts } from "../../themes";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import SubmitOfferStepsSheet from "../../Components/CompositeComponents/submitOfferStepsSheetV3";
import { Root, Popup } from "react-native-popup-confirm-toast";
import ImagePlacer from "./imagePlacer";
import GlobalConstants from "../../Controllers/constants";
import MessagesSvg from "../../Assets/Icons/bottomTabsSvgs/messagesSvg";
import { navigate } from "../../Navigation/mainNavigation";
import OffersController from "../../Controllers/offersController";
import { getFirstImage } from "../../Controllers/vehiclesController";
import AbstractBottomSheetV2 from "../AbstractComponents/abstractBottomSheetV2";
import AbstractContentContainer from "../AbstractComponents/abstractContentContainer";
import AbstractOptionButton from "../AbstractComponents/abstractOptionButton";
import { SheetManager } from "react-native-actions-sheet";
import EditSvgLarge from "../../Assets/Icons/editSvgLarge";
import DeleteSvgLarge from "../../Assets/Icons/deleteSvgLarge";
import { useSelector } from "react-redux";
import AbstractButton from "../AbstractComponents/abstractButton";
import BuyNowSheet from "./BottomSheets/buyNowSheet";

const OfferButtons = ({ offer, onOpenSheet, sendOffer }) => {
  const [processing, setProcessing] = useState(false);
  const status = offer.status;
  console.log(status);
  const handleNavigateToMessages = () => {
    navigate("Chat", { offer: offer, offerType: offer?.offerType });
  };

  switch (true) {
    case processing:
      return (
        <View
          style={{
            width: "20%",
            height: "100%",
            justifyContent: "center",
            alignItems: "flex-end",
            paddingRight: 0,
          }}
        >
          <ActivityIndicator />
        </View>
      );
    case status == GlobalConstants.OFFER_STATUS.CONVERSATION:
      return (
        <View
          style={{
            // width: "20%",
            height: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            paddingVertical: 5,
            flexDirection: "row",
          }}
        >
          <View>
            <TouchableOpacity
              onPress={handleNavigateToMessages}
              style={[styles.newBtnsView]}
            >
              <MessagesSvg size={12} color={"#2674E5"} />
            </TouchableOpacity>
          </View>
          <View style={{ marginLeft: 10 }}>
            <AbstractButton
              onPress={sendOffer}
              txtSize={moderateScale(12, 0.1)}
              width={moderateScale(75, 0.1)}
              height={moderateScale(25, 0.1)}
              label={"Send Offer"}
            />
          </View>
        </View>
      );
    case status === GlobalConstants.OFFER_STATUS.ACTIVE:
      return (
        <View
          style={{
            height: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            paddingVertical: 5,
            flexDirection: "row",
          }}
        >
          <View>
            <TouchableOpacity
              onPress={handleNavigateToMessages}
              style={[styles.newBtnsView, { marginLeft: 5 }]}
            >
              <MessagesSvg
                size={12}
                color={
                  status === GlobalConstants.OFFER_STATUS.CONFLICT
                    ? "red"
                    : "#2674E5"
                }
              />
            </TouchableOpacity>
          </View>
          <View>
            <TouchableOpacity
              onPress={onOpenSheet}
              style={[
                styles.newBtnsView,
                { marginLeft: 10, backgroundColor: "#DADADA" },
              ]}
            >
              <OptionsSvg />
            </TouchableOpacity>
          </View>
        </View>
      );
    case status === GlobalConstants.OFFER_STATUS.CONFLICT:
      return (
        <View
          style={{
            width: "20%",
            // height: "100%",
            justifyContent: "center",
            alignItems: "flex-end",
            paddingRight: 0,
          }}
        >
          <TouchableOpacity
            onPress={handleNavigateToMessages}
            style={{ padding: 5 }}
          >
            <MessagesSvg color="red" />
          </TouchableOpacity>
        </View>
      );
    case status === GlobalConstants.OFFER_STATUS.ACCEPTED:
      return (
        <View
          style={{
            width: "20%",
            // height: "100%",
            justifyContent: "center",
            alignItems: "flex-end",
            paddingRight: 0,
          }}
        >
          <View>
            <TouchableOpacity
              onPress={handleNavigateToMessages}
              style={[styles.newBtnsView, { marginLeft: 5 }]}
            >
              <MessagesSvg
                size={12}
                color={
                  status === GlobalConstants.OFFER_STATUS.CONFLICT
                    ? "red"
                    : "#2674E5"
                }
              />
            </TouchableOpacity>
          </View>
        </View>
      );
    case status === GlobalConstants.OFFER_STATUS.PAYMENT_REFUNDABLE:
      return (
        <View
          style={{
            width: "20%",
            // height: "100%",
            justifyContent: "center",
            alignItems: "flex-end",
            paddingRight: 0,
          }}
        >
          <View>
            <TouchableOpacity
              onPress={handleNavigateToMessages}
              style={[styles.newBtnsView, { marginLeft: 5 }]}
            >
              <MessagesSvg
                size={12}
                color={
                  status === GlobalConstants.OFFER_STATUS.CONFLICT
                    ? "red"
                    : "#2674E5"
                }
              />
            </TouchableOpacity>
          </View>
        </View>
      );
    case status == GlobalConstants.OFFER_STATUS.PAYMENT_REFUNDED:
      return (
        <View
          style={{
            width: "20%",
            // height: "100%",
            justifyContent: "center",
            alignItems: "flex-end",
            paddingRight: 0,
          }}
        >
          <View>
            <TouchableOpacity
              onPress={handleNavigateToMessages}
              style={[styles.newBtnsView, { marginLeft: 5 }]}
            >
              <MessagesSvg
                size={12}
                color={
                  status === GlobalConstants.OFFER_STATUS.CONFLICT
                    ? "red"
                    : "#2674E5"
                }
              />
            </TouchableOpacity>
          </View>
        </View>
      );
    default:
      return false;
    // return (
    //   <View
    //     style={{
    //       height: "100%",
    //       justifyContent: "space-between",
    //       alignItems: "center",
    //       paddingVertical: 5,
    //       flexDirection: "row",
    //     }}
    //   >
    //     <View>
    //       <TouchableOpacity
    //         onPress={handleNavigateToMessages}
    //         style={[styles.newBtnsView, { marginLeft: 5 }]}
    //       >
    //         <MessagesSvg
    //           size={12}
    //           color={
    //             status === GlobalConstants.OFFER_STATUS.CONFLICT
    //               ? "red"
    //               : "#2674E5"
    //           }
    //         />
    //       </TouchableOpacity>
    //     </View>
    //     <View>
    //       <TouchableOpacity
    //         onPress={onOpenSheet}
    //         style={[
    //           styles.newBtnsView,
    //           { marginLeft: 10, backgroundColor: "#DADADA" },
    //         ]}
    //       >
    //         <OptionsSvg />
    //       </TouchableOpacity>
    //     </View>
    //   </View>
    // );
  }
};

const SavedItem = ({ notBid, offer, setSingleOffer }) => {
  const { deviceType } = useSelector((state) => state.DeviceTypeReducer);
  const { vehicle } = offer;
  const disabled =
    offer.status === GlobalConstants.OFFER_STATUS.PENDING ||
    offer.status === GlobalConstants.OFFER_STATUS.REJECTED ||
    offer.status === GlobalConstants.OFFER_STATUS.EXPIRED;
  const onOpenSheet = () => {
    SheetManager.show(offer._id);
  };
  const sendOffer = () => {
    setSingleOffer(offer);
  };
  const onPressDelete = () => {
    SheetManager.hide(offer._id);
    Popup.show({
      type: "confirm",
      title: "Delete offer permanently?",
      textBody: "Are you sure to delete",
      buttonText: "Confirm",

      modalContainerStyle: { width: deviceType ? "18%" : "90%" },
      okButtonStyle: {
        backgroundColor: "#2674E5",
        padding: deviceType ? 10 : 5,
      },
      confirmText: "Cancel",
      callback: () => {
        OffersController.deleteOffer(offer._id)
          .then(() => {})
          .catch((err) => {
            console.log(err);
            setProcessing(false);
          });
        Popup.hide();
      },
      cancelCallback: () => {
        Popup.hide();
      },
    });
  };

  return (
    <React.Fragment>
      <View
        style={[
          styles.mainContainer,
          {
            backgroundColor: notBid
              ? "#F6F8FA"
              : disabled
              ? "#F4F4F4"
              : Colors.screenBgColor,
            marginBottom: 20,
          },
        ]}
      >
        <View
          style={[
            styles.viewOne,
            {
              paddingHorizontal: deviceType ? 20 : 10,
            },
          ]}
        >
          <View
            style={[
              styles.imgView,
              {
                width: moderateScale(deviceType ? 130 : 72, 0.1),
                height: moderateScale(deviceType ? 100 : 55, 0.1),
                marginVertical: deviceType ? 10 : 10,
              },
            ]}
          >
            <View style={{ ...StyleSheet.absoluteFillObject }}>
              <ImagePlacer
                imageUrl={getFirstImage(vehicle?.pictures).image.url}
                avatarUrl={getFirstImage(vehicle?.pictures).avatar.url}
                type={"small"}
              />
              <View style={{ margin: 4, position: "absolute" }}>
                {offer.offerType == "buyNow" && (
                  <View
                    style={{
                      backgroundColor: "rgba(0,0,0,0.6)",
                      paddingHorizontal: 3,
                      paddingVertical: 3,
                      borderRadius: 5,
                      marginBottom: 3,
                    }}
                  >
                    <Text
                      style={{
                        color: "white",
                        fontSize: 10,
                        fontFamily: Fonts.default,
                      }}
                    >
                      Buy Now
                    </Text>
                  </View>
                )}

                {offer.status == GlobalConstants.OFFER_STATUS.CONVERSATION && (
                  <View
                    style={{
                      backgroundColor: "rgba(0,0,0,0.6)",
                      paddingHorizontal: 3,
                      paddingVertical: 3,
                      borderRadius: 5,
                    }}
                  >
                    <Text
                      style={{
                        color: "white",
                        fontSize: 10,
                        textAlign: "center",
                        fontFamily: Fonts.default,
                      }}
                    >
                      Draft
                    </Text>
                  </View>
                )}

                {offer.status ==
                  GlobalConstants.OFFER_STATUS.PAYMENT_REFUNDABLE && (
                  <View
                    style={{
                      backgroundColor: "rgba(241,156,56,0.9)",
                      paddingHorizontal: 3,
                      paddingVertical: 3,
                      borderRadius: 5,
                    }}
                  >
                    <Text
                      style={{
                        color: "white",
                        fontSize: 10,
                        textAlign: "center",
                        fontFamily: Fonts.default,
                      }}
                    >
                      Refundable
                    </Text>
                  </View>
                )}
                {offer.status ==
                  GlobalConstants.OFFER_STATUS.PAYMENT_REFUNDED && (
                  <View
                    style={{
                      backgroundColor: "rgba(241,156,56,0.9)",
                      paddingHorizontal: 3,
                      paddingVertical: 3,
                      borderRadius: 5,
                    }}
                  >
                    <Text
                      style={{
                        color: "white",
                        fontSize: 10,
                        textAlign: "center",
                        fontFamily: Fonts.default,
                      }}
                    >
                      Refunded
                    </Text>
                  </View>
                )}
              </View>
            </View>
            {disabled ? (
              <View style={{ ...StyleSheet.absoluteFillObject }}>
                <View
                  style={{
                    backgroundColor: "rgba(141, 141, 141, 0.4)",
                    height: "100%",
                    width: "100%",
                    borderRadius: 11,
                  }}
                />
              </View>
            ) : null}
          </View>

          <View
            style={{
              width: "55%",
              flex: 1,
              paddingHorizontal: 10,
              justifyContent: "center",
            }}
          >
            <View>
              <Text
                numberOfLines={2}
                style={[
                  styles.txtTwo,
                  {
                    color: disabled ? "#8D8D8D" : "#16478E",
                    fontSize: deviceType ? 20 : wp("4.2%"),
                  },
                ]}
              >
                {vehicle?.title}
              </Text>
            </View>
            <View style={{ paddingTop: 3 }}>
              <Text
                style={[
                  styles.txtThree,
                  {
                    color: disabled ? "#8D8D8D" : "#030303",
                    fontSize: deviceType ? 20 : wp("3.5%"),
                  },
                ]}
              >
                {offer.status == GlobalConstants.OFFER_STATUS.CONVERSATION
                  ? "$ -- --"
                  : `$${offer.amount}`}
              </Text>
            </View>
          </View>
          <OfferButtons
            offer={offer}
            onOpenSheet={onOpenSheet}
            sendOffer={sendOffer}
          />
        </View>
      </View>

      <AbstractBottomSheetV2 id={offer._id}>
        <View style={{ width: "100%" }}>
          <View style={{ alignSelf: "center" }}>
            <Text style={styles.textThree}>More Options</Text>
          </View>
          <AbstractContentContainer>
            {/* {offer.offerType !== "buyNow" && (
              <>
                <View>
                  <AbstractOptionButton
                    onPress={onPressEdit}
                    label={"Edit"}
                    icon={() => <EditSvgLarge />}
                  />
                </View>

                <View
                  style={{
                    width: "100%",
                    height: 0.5,
                    backgroundColor: Colors.txtInputBorderColor,
                  }}
                />
              </>
            )} */}

            <View style={{ marginBottom: 30 }}>
              <AbstractOptionButton
                onPress={onPressDelete}
                textColor={Colors.redPrimary}
                label={"Delete"}
                icon={() => <DeleteSvgLarge />}
              />
            </View>
          </AbstractContentContainer>
        </View>
      </AbstractBottomSheetV2>
    </React.Fragment>
  );
};

export default SavedItem;

const styles = StyleSheet.create({
  mainContainer: {
    width: "100%",
    alignSelf: "center",
    borderRadius: 15,
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 1,
    borderColor: "#e5e5e5",
    // shadowColor: "#000",
    // shadowOffset: {
    //   width: 0,
    //   height: 1,
    // },
    // shadowOpacity: 0.18,
    // shadowRadius: 1.0,
    // elevation: 1,

    marginTop: 1,
  },
  viewOne: {
    width: "100%",
    flexDirection: "row",
    // backgroundColor: "red",
    // alignItems: "center",
  },
  imgView: {
    borderRadius: 11,
  },
  deleteBtnView: {
    width: moderateScale(30, 0.1),
    height: moderateScale(21, 0.1),
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Colors.pinkPrimary,
    borderRadius: 5,
  },
  editBtnView: {
    width: moderateScale(56, 0.1),
    height: moderateScale(21, 0.1),
    backgroundColor: "#D4E3FA",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    borderRadius: 5,
  },
  txtOne: {
    color: Colors.blueSecondaryOne,
    fontSize: moderateScale(11, 0.1),
    paddingLeft: 5,
    fontFamily: Fonts.default,
  },
  txtTwo: {
    color: "#16478E",

    fontWeight: "700",
    fontFamily: Fonts.default,
  },
  txtThree: {
    color: "#030303",

    fontWeight: "700",
    fontFamily: Fonts.default,
  },
  newBtnsView: {
    width: 25,
    height: 25,
    backgroundColor: "#D4E3FA",
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  textThree: {
    fontFamily: Fonts.bold,
    color: Colors.blackPrimary,
    fontSize: moderateScale(19, 0.1),
    paddingBottom: 10,
  },
});
