import React, { useState, useRef } from "react";
import {
  StyleSheet,
  Text,
  View,
  Platform,
  Dimensions,
  TouchableOpacity,
  Animated,
} from "react-native";
import { moderateScale } from "react-native-size-matters";
import { Colors, Fonts, PLATFORM_DETECT } from "../../themes";
import AbstractContentContainer from "../../Components/AbstractComponents/abstractContentContainer";
import SpeedSvg from "../../Assets/Icons/FeedCardSvgs/speedSvg";
import FocusAwareStatusBar from "../../Components/AbstractComponents/statusbarConfiguration";
import BackBtnSvg from "../../Assets/Icons/backBtnSvg";
import { goBack } from "../../Navigation/mainNavigation";
import ImagePlacer from "../../Components/ModuleComponents/imagePlacer";
import { formatCurrencyToUSD } from "../../Utils/common";
import { getFirstImage } from "../../Controllers/vehiclesController";
import DetailsSvg from "../../Assets/Icons/FeedCardSvgs/detailsSvg";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import { useSelector } from "react-redux";
import DetailItem from "../../Components/ModuleComponents/CarDetailsComponents/detailItem";
import Details from "../../Components/ModuleComponents/CarDetailsComponents/details";
import KnownIssues from "../../Components/ModuleComponents/CarDetailsComponents/knownIssues";

const SW = Dimensions.get("window").width;
const SH = Dimensions.get("window").height;

const TYPES_OF_TOP_BAR = {
  DETAILS: "details",
  KNOWN_ISSUES: "knownIssues",
};

const SpeedTag = ({ miles }) => {
  return (
    <View style={styles.speedTagView}>
      <View style={{ paddingLeft: 12 }}>
        <SpeedSvg />
      </View>
      <View style={{ paddingLeft: 10, paddingRight: 15 }}>
        <Text style={styles.textThree}>{`mileage: ${
          miles ? miles : "--- -"
        }`}</Text>
      </View>
    </View>
  );
};

const CarDetailScreen = (props) => {
  const { deviceType } = useSelector((state) => state.DeviceTypeReducer);

  const BACKGROUNDIMGHEIGHT = deviceType ? 500 : 310;

  const { vehicle } = props.route.params;
  const ScrollY = useRef(new Animated.Value(0)).current;

  const headerTransform = ScrollY.interpolate({
    inputRange: [0, BACKGROUNDIMGHEIGHT - 30],
    outputRange: [0, -BACKGROUNDIMGHEIGHT + 47],
    extrapolate: "clamp",
  });

  const headerTransformWeb = ScrollY.interpolate({
    inputRange: [0, BACKGROUNDIMGHEIGHT - 30],
    outputRange: [0, -BACKGROUNDIMGHEIGHT + 30],
    extrapolate: "clamp",
  });

  const [topBarData, setTopBarData] = useState([
    {
      _id: 0,
      label: "Details",
      value: TYPES_OF_TOP_BAR.DETAILS,
      active: true,
    },
    {
      _id: 1,
      label: "Known Issues",
      value: TYPES_OF_TOP_BAR.KNOWN_ISSUES,
      active: false,
    },
  ]);

  const [selectedType, setSelectedType] = useState(TYPES_OF_TOP_BAR.DETAILS);

  const onPressTopBarButton = (item) => {
    const newArr = [...topBarData];
    const sendingArr = newArr.map((it) =>
      it._id == item._id ? { ...it, active: true } : { ...it, active: false }
    );
    setTopBarData(sendingArr);
    setSelectedType(item.value);
  };

  const renderSpecificComponent = (type) => {
    switch (type) {
      case TYPES_OF_TOP_BAR.DETAILS:
        return <Details vehicle={vehicle} />;
      case TYPES_OF_TOP_BAR.KNOWN_ISSUES:
        return <KnownIssues data={vehicle?.knownIssues} />;
      default:
        <Details vehicle={vehicle} />;
    }
  };

  return (
    <View style={styles.mainConatiner}>
      <FocusAwareStatusBar
        barStyle="light-content"
        backgroundColor={"transparent"}
        translucent={true}
      />

      <View style={{ ...StyleSheet.absoluteFillObject }}>
        <View style={{ width: "100%", height: BACKGROUNDIMGHEIGHT }}>
          <ImagePlacer
            imageUrl={getFirstImage(vehicle.pictures).image.url}
            avatarUrl={getFirstImage(vehicle.pictures).avatar.url}
            type={"large"}
            withNoBorder
          />
        </View>
      </View>

      <View
        style={{
          width: "100%",
          height: PLATFORM_DETECT.WEB ? 40 : 60,
          justifyContent: "flex-end",
          zIndex: 9999,
        }}
      >
        <AbstractContentContainer>
          <TouchableOpacity
            style={{
              paddingRight: 20,
              // alignSelf: "flex-start",
              // marginLeft: 20,
            }}
            onPress={() => goBack()}
          >
            <BackBtnSvg size={18} color={Colors.whitePrimary} />
          </TouchableOpacity>
        </AbstractContentContainer>
      </View>

      <View style={{ ...StyleSheet.absoluteFillObject }}>
        <Animated.ScrollView
          alwaysBounceVertical={false}
          bounces={false}
          showsVerticalScrollIndicator={false}
        >
          <React.Fragment>
            <Animated.View
              style={[
                styles.middleHeader,
                {
                  top: BACKGROUNDIMGHEIGHT - 50,
                  transform: [
                    {
                      translateY: PLATFORM_DETECT.WEB
                        ? headerTransformWeb
                        : headerTransform,
                    },
                  ],
                },
              ]}
            >
              <View
                style={{
                  backgroundColor: Colors.blueSecondary,
                  borderTopLeftRadius: 28,
                  borderTopRightRadius: 28,
                }}
              >
                <View
                  style={
                    {
                      // width: "100%",
                      // height: 50,
                      // backgroundColor: Colors.blueSecondary,
                      // borderTopLeftRadius: 28,
                      // borderTopRightRadius: 28,
                    }
                  }
                />
                <AbstractContentContainer>
                  <View
                    style={
                      {
                        // backgroundColor: Colors.blueSecondary,
                        // paddingBottom: 10,
                      }
                    }
                  >
                    <View style={{ marginTop: 20 }}>
                      <DetailItem
                        priceValue={formatCurrencyToUSD(
                          vehicle?.priceRange?.min
                        )}
                        title={"(Suggested price / average)"}
                      />

                      {vehicle?.highestOfferPlaced ? (
                        <DetailItem
                          priceValue={`${formatCurrencyToUSD(
                            vehicle?.highestOfferPlaced
                          )}`}
                          title={"(Highest offer placed)"}
                          textOpacityControl={true}
                        />
                      ) : (
                        false
                      )}
                    </View>

                    <View style={{ marginVertical: 0 }}>
                      <Text style={styles.textTwo}>{vehicle.title}</Text>
                    </View>

                    {vehicle?.KMDriven ? (
                      <View
                        style={{
                          flexDirection: "row",
                          alignItems: "center",
                          marginTop: 5,
                        }}
                      >
                        <SpeedTag miles={vehicle?.KMDriven} />
                      </View>
                    ) : (
                      false
                    )}
                  </View>
                </AbstractContentContainer>
              </View>
            </Animated.View>
          </React.Fragment>

          <View
            style={{
              width: "100%",
              height: BACKGROUNDIMGHEIGHT,
              backgroundColor: "transparent",
            }}
          />

          <Animated.View
            style={{ width: "100%", backgroundColor: Colors.blueSecondary }}
          >
            <View style={{ height: vehicle?.KMDriven ? 125 : 72 }} />

            <AbstractContentContainer>
              <View
                style={{
                  width: "100%",
                  height: 1,
                  backgroundColor: "#5A616C",
                  marginTop: 20,
                  marginBottom: 10,
                }}
              />

              <View style={styles.viewFive}>
                {topBarData.map((item, index) => {
                  return (
                    <TouchableOpacity
                      onPress={() => onPressTopBarButton(item)}
                      key={item._id}
                      style={[
                        styles.topBarHeaderButton,
                        {
                          backgroundColor: item.active
                            ? Colors.blueprimary
                            : Colors.blueSecondary,
                        },
                      ]}
                    >
                      <Text style={styles.topBarHeaderText}>{item.label}</Text>
                    </TouchableOpacity>
                  );
                })}
              </View>

              <View style={{ flex: 1, marginBottom: 20 }}>
                {renderSpecificComponent(selectedType)}

                <View style={{ height: moderateScale(80, 0.1) }} />
              </View>
            </AbstractContentContainer>
          </Animated.View>
        </Animated.ScrollView>

        <View style={styles.viewLast}>
          <View style={{ width: "100%", alignItems: "center" }}>
            <TouchableOpacity onPress={() => goBack()}>
              <DetailsSvg />
            </TouchableOpacity>
          </View>

          <View style={{ height: Platform.OS === "android" ? 0 : 15 }} />
        </View>
      </View>
    </View>
  );
};

export default CarDetailScreen;

const styles = StyleSheet.create({
  mainConatiner: {
    flex: 1,
    backgroundColor: Colors.blueSecondary,
  },
  viewOne: {
    width: "100%",
    flex: 1,
    backgroundColor: Colors.blueSecondary,
    borderTopLeftRadius: 28,
    borderTopRightRadius: 28,
    marginTop: -28,
  },
  viewLast: {
    width: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.9)",
    // position: "absolute",
    bottom: 0,
    justifyContent: "center",
    paddingVertical: 15,
    // backgroundColor: "green",
  },
  viewFour: {
    width: "65%",
    alignSelf: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textOne: {
    color: Colors.greySecondaryTwo,
    fontSize: moderateScale(15, 0.1),
    fontFamily: Fonts.default,
  },
  textTwo: {
    color: Colors.whitePrimary,
    fontSize: moderateScale(21, 0.1),
    fontFamily: Fonts.default,
    fontWeight: "900",
  },
  speedTagView: {
    height: moderateScale(38, 0.1),
    borderRadius: 90,
    backgroundColor: "#5A616C",
    flexDirection: "row",
    alignItems: "center",
  },
  textThree: {
    color: Colors.whitePrimary,
    fontSize: moderateScale(16, 0.1),
    fontFamily: Fonts.default,
  },
  textFour: {
    color: Colors.whitePrimary,
    fontSize: moderateScale(17, 0.1),
    fontFamily: Fonts.default,
  },
  headerViewOne: {
    width: "90%",
    height: 50,
    position: "absolute",
    top: -65,
    alignSelf: "center",
    backgroundColor: "rgba(0, 10, 26, 0.9)",
    justifyContent: "flex-end",
    borderTopLeftRadius: 29,
    borderTopRightRadius: 29,
  },
  headerViewTwo: {
    width: "100%",
    backgroundColor: "rgba(0, 10, 26, 1)",
    height: 35,
    position: "absolute",
    top: -35,
    zIndex: 999,
    borderTopLeftRadius: 28,
    borderTopRightRadius: 28,
  },
  middleHeader: {
    height: 50,
    backgroundColor: "transparent",
    position: "absolute",

    right: 0,
    left: 0,
    zIndex: 999,
  },
  priceText: {
    fontFamily: Fonts.bold,
    color: Colors.blueSecondaryTwo,
    fontSize: hp("1.9"),
  },
  detailText: {
    fontFamily: Fonts.medium,
    color: Colors.whitePrimary,
    fontSize: hp("1.6"),
  },
  viewFive: {
    width: "100%",
    flexDirection: "row",
  },
  topBarHeaderButton: {
    width: "50%",
    height: 40,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "red",
    borderRadius: 5,
  },
  topBarHeaderText: {
    fontFamily: Fonts.medium,
    color: Colors.whitePrimary,
    fontSize: hp("2"),
  },
});
