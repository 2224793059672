import { StyleSheet, Text, View, Image } from "react-native";
import React from "react";
import ImagePlaceholderSvg from "../../Assets/Icons/imagePlaceholderSvg";
import { Colors } from "../../themes";

const largeBorderRadius = {
  borderTopRightRadius: 25,
  borderTopLeftRadius: 25,
};

const ImagePlacerSmall = ({ imageUrl, avatarUrl }) => {
  return (
    <View style={styles.mainConatiner}>
      <View style={[{ ...StyleSheet.absoluteFillObject }]}>
        <View
          style={[
            {
              width: "100%",
              height: "100%",
              backgroundColor: "grey",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 11,
            },
          ]}
        >
          <ImagePlaceholderSvg height={30} width={20} />
        </View>
      </View>
      {avatarUrl ? (
        <View style={{ ...StyleSheet.absoluteFillObject }}>
          <View
            style={[
              {
                width: "100%",
                height: "100%",
                backgroundColor: "transparent",
                borderRadius: 11,
              },
            ]}
          >
            <Image
              source={
                imageUrl
                  ? { uri: avatarUrl }
                  : require("../../Assets/Images/saved.png")
              }
              style={[{ width: "100%", height: "100%", borderRadius: 11 }]}
            />
          </View>
        </View>
      ) : (
        false
      )}
      {imageUrl ? (
        <View style={{ ...StyleSheet.absoluteFillObject }}>
          <View
            style={[
              {
                width: "100%",
                height: "100%",
                backgroundColor: "transparent",
                borderRadius: 11,
              },
            ]}
          >
            <Image
              source={
                imageUrl
                  ? { uri: imageUrl }
                  : require("../../Assets/Images/saved.png")
              }
              style={[{ width: "100%", height: "100%", borderRadius: 11 }]}
            />
          </View>
        </View>
      ) : (
        false
      )}
    </View>
  );
};

const ImagePlacerLarge = ({ imageUrl, avatarUrl, withNoBorder }) => {
  return (
    <View style={styles.mainConatiner}>
      <View style={[{ ...StyleSheet.absoluteFillObject }]}>
        <View
          style={[
            {
              width: "100%",
              height: "100%",
              backgroundColor: Colors.lightBluePrimary,
              justifyContent: "center",
              alignItems: "center",
            },
            withNoBorder ? false : largeBorderRadius,
          ]}
        >
          <ImagePlaceholderSvg />
        </View>
      </View>

      {avatarUrl ? (
        <View style={{ ...StyleSheet.absoluteFillObject }}>
          <View
            style={[
              {
                width: "100%",
                height: "100%",
                backgroundColor: "transparent",
                borderTopLeftRadius: 25,
                borderTopRightRadius: 25,
              },
            ]}
          >
            <Image
              source={{ uri: avatarUrl }}
              style={[
                { width: "100%", height: "100%" },
                withNoBorder ? false : largeBorderRadius,
              ]}
            />
          </View>
        </View>
      ) : (
        false
      )}
      {imageUrl ? (
        <View style={{ ...StyleSheet.absoluteFillObject }}>
          <View
            style={[
              {
                width: "100%",
                height: "100%",
                backgroundColor: "transparent",
                borderTopLeftRadius: 25,
                borderTopRightRadius: 25,
              },
            ]}
          >
            <Image
              resizeMode="cover"
              source={
                imageUrl
                  ? { uri: imageUrl }
                  : require("../../Assets/Images/carPlaceholder.png")
              }
              style={[
                { width: "100%", height: "100%" },
                withNoBorder ? false : largeBorderRadius,
              ]}
            />
          </View>
        </View>
      ) : (
        false
      )}
    </View>
  );
};
const ImagePlacerLargeDummy = ({ imageUrl, avatarUrl, withNoBorder }) => {
  return (
    <View style={styles.mainConatiner}>
      <View style={[{ ...StyleSheet.absoluteFillObject }]}>
        <View
          style={[
            {
              width: "100%",
              height: "100%",
              backgroundColor: Colors.lightBluePrimary,
              justifyContent: "center",
              alignItems: "center",
            },
            withNoBorder ? false : largeBorderRadius,
          ]}
        >
          <ImagePlaceholderSvg />
        </View>
      </View>

      {avatarUrl ? (
        <View style={{ ...StyleSheet.absoluteFillObject }}>
          <View
            style={[
              {
                width: "100%",
                height: "100%",
                backgroundColor: "transparent",
                borderTopLeftRadius: 25,
                borderTopRightRadius: 25,
              },
            ]}
          >
            <Image
              source={avatarUrl}
              style={[
                { width: "100%", height: "100%" },
                withNoBorder ? false : largeBorderRadius,
              ]}
            />
          </View>
        </View>
      ) : (
        false
      )}

      <View style={{ ...StyleSheet.absoluteFillObject }}>
        <View
          style={[
            {
              width: "100%",
              height: "100%",
              backgroundColor: "transparent",
              borderTopLeftRadius: 25,
              borderTopRightRadius: 25,
            },
          ]}
        >
          <Image
            resizeMode="cover"
            source={imageUrl}
            style={[
              { width: "100%", height: "100%" },
              withNoBorder ? false : largeBorderRadius,
            ]}
          />
        </View>
      </View>
    </View>
  );
};

const ImagePlacer = ({ type, imageUrl, avatarUrl, withNoBorder }) => {
  switch (type) {
    case "small":
      return <ImagePlacerSmall avatarUrl={avatarUrl} imageUrl={imageUrl} />;
      break;
    case "large":
      return (
        <ImagePlacerLarge
          imageUrl={imageUrl}
          avatarUrl={avatarUrl}
          withNoBorder={withNoBorder}
        />
      );
      break;
    case "dummy":
      return (
        <ImagePlacerLargeDummy
          imageUrl={imageUrl}
          withNoBorder={withNoBorder}
        />
      );
      break;
    default:
      return (
        <ImagePlacerLarge
          imageUrl={imageUrl}
          avatarUrl={avatarUrl}
          withNoBorder={withNoBorder}
        />
      );
  }
};

export default ImagePlacer;

const styles = StyleSheet.create({
  mainConatiner: {
    width: "100%",
    height: "100%",
  },
});
