import React, { useState, useRef, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  Dimensions,
  TouchableOpacity,
  Animated,
  NativeModules,
  Platform,
} from "react-native";
import { moderateScale } from "react-native-size-matters";
import { Colors, Fonts, PLATFORM_DETECT } from "../../themes";
import AbstractContentContainer from "../../Components/AbstractComponents/abstractContentContainer";
import SpeedSvg from "../../Assets/Icons/FeedCardSvgs/speedSvg";
import FocusAwareStatusBar from "../../Components/AbstractComponents/statusbarConfiguration";
import BackBtnSvg from "../../Assets/Icons/backBtnSvg";
import {
  goBack,
  navigate,
  navigateToNestedRoute,
} from "../../Navigation/mainNavigation";
import ImagePlacer from "../../Components/ModuleComponents/imagePlacer";
import { formatCurrencyToUSD } from "../../Utils/common";
import { getFirstImage } from "../../Controllers/vehiclesController";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import { useSelector } from "react-redux";
import AbstractButton from "../../Components/AbstractComponents/abstractButton";
import { SheetManager } from "react-native-actions-sheet";
import SubmitBitStepsSheet from "../../Components/CompositeComponents/submitBitStepsSheet";
import AuctionController from "../../Controllers/auctionController";
import SkeletonLoader from "expo-skeleton-loader";
import Details from "../../Components/ModuleComponents/CarDetailsComponents/details";
import DetailItem from "../../Components/ModuleComponents/CarDetailsComponents/detailItem";
import KnownIssues from "../../Components/ModuleComponents/CarDetailsComponents/knownIssues";
import BuyNowSheet from "../../Components/ModuleComponents/BottomSheets/buyNowSheet";
import AuthController, { useUserName } from "../../Controllers/authController";
import alert from "../../Components/AbstractComponents/alert";
const SW = Dimensions.get("window").width;
const SH = Dimensions.get("window").height;

const TYPES_OF_TOP_BAR = {
  DETAILS: "details",
  KNOWN_ISSUES: "knownIssues",
};

const BUY_NOW_SHEET = "BUY_NOW_SHEET";

const SpeedTag = ({ miles, isDataNotAvailable }) => {
  return (
    <View style={styles.speedTagView}>
      {isDataNotAvailable ? (
        <SkeletonLoader
          backgroundColor={"grey"}
          highlightColor={"#5A616C"}
          boneColor={"grey"}
          duration={1000}
        >
          <SkeletonLoader.Item
            style={{
              width: 130,
              borderRadius: 90,
            }}
          />
        </SkeletonLoader>
      ) : (
        <>
          <View style={{ paddingLeft: 12 }}>
            <SpeedSvg />
          </View>
          <View style={{ paddingLeft: 10, paddingRight: 15 }}>
            <Text style={styles.textThree}>{`mileage: ${
              miles ? miles : "--- -"
            }`}</Text>
          </View>
        </>
      )}
    </View>
  );
};

const { StatusBarManager } = NativeModules;

const CarDetailPlaceBidScreen = (props) => {
  const auctionVehicleId = props.route?.params?.auctionVehicleId;
  const userDetail = useUserName();

  const { deviceType } = useSelector((state) => state.DeviceTypeReducer);
  const [loading, setLoading] = useState(true);
  const [singleAuction, setSingleAuction] = useState({});
  const { vehicle } = singleAuction;
  const [loaderFirstTimePlaceHolder, setLoaderFirstTimePlaceHolder] =
    useState(true);

  const [myBidAmount, setMyBidAmount] = useState(0);
  const [bidGap, setBidGap] = useState(0);
  const [initialPriceRange, setInitialPriceRange] = useState(0);
  const BACKGROUNDIMGHEIGHT = deviceType ? 500 : 310;

  const ScrollY = useRef(new Animated.Value(0)).current;
  const sheetRef = useRef();

  const headerTransform = ScrollY.interpolate({
    inputRange: [0, BACKGROUNDIMGHEIGHT - 30],
    outputRange: [0, -BACKGROUNDIMGHEIGHT + 47],
    extrapolate: "clamp",
  });

  const headerTransformWeb = ScrollY.interpolate({
    inputRange: [0, BACKGROUNDIMGHEIGHT - 30],
    outputRange: [0, -BACKGROUNDIMGHEIGHT + 30],
    extrapolate: "clamp",
  });

  const handleLink = () => {
    AuthController.isLinkAvailAble.url = undefined;
    AuthController.isLinkAvailAble.availability = true;
    AuthController.isLinkAvailAble.id = auctionVehicleId;
    navigateToNestedRoute("Welcome to infoRide", "SignIn");
  };
  const handleLogin = () => {
    alert(
      "Login Required!",
      "",
      [
        {
          text: "ok",

          onPress: handleLink,
          style: "destructive",
        },
        { text: "Cancel" },
      ],
      { cancelable: false }
    );
  };

  const [topBarData, setTopBarData] = useState([
    {
      _id: 0,
      label: "Details",
      value: TYPES_OF_TOP_BAR.DETAILS,
      active: true,
    },
    {
      _id: 1,
      label: "Known Issues",
      value: TYPES_OF_TOP_BAR.KNOWN_ISSUES,
      active: false,
    },
  ]);

  const [selectedType, setSelectedType] = useState(TYPES_OF_TOP_BAR.DETAILS);

  useEffect(() => {
    AuctionController.getSingleAuctionCarPublic(auctionVehicleId)
      .then((item) => {
        // console.log(item, "singleVehicleCar");
        setMyBidAmount(
          item.lastBidAmount
            ? Number(item.lastBidAmount)
            : Number(item.initialBidAmount)
        );
        setInitialPriceRange(
          item.lastBidAmount
            ? Number(item.lastBidAmount) + Number(item?.bidGap)
            : Number(item.initialBidAmount)
        );
        setBidGap(Number(item?.bidGap));

        setLoading(false);
        setSingleAuction(item);
        setLoaderFirstTimePlaceHolder(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setLoaderFirstTimePlaceHolder(false);
      });
  }, []);

  const isDataNotAvailable = Object.keys(singleAuction).length === 0;

  const onPressTopBarButton = (item) => {
    const newArr = [...topBarData];
    const sendingArr = newArr.map((it) =>
      it._id == item._id ? { ...it, active: true } : { ...it, active: false }
    );
    setTopBarData(sendingArr);
    setSelectedType(item.value);
  };

  const renderSpecificComponent = (type) => {
    switch (type) {
      case TYPES_OF_TOP_BAR.DETAILS:
        return (
          <Details vehicle={vehicle} isDataNotAvailable={isDataNotAvailable} />
        );
      case TYPES_OF_TOP_BAR.KNOWN_ISSUES:
        return <KnownIssues data={vehicle?.knownIssues} />;
      default:
        <Details vehicle={vehicle} isDataNotAvailable={isDataNotAvailable} />;
    }
  };

  const onPressBuyNow = () => {
    if (userDetail?.fullName) {
      SheetManager.show(BUY_NOW_SHEET);
    } else {
      alert(
        "Login Required!",
        "",
        [
          {
            text: "ok",
            onPress: handleLink,
            style: "destructive",
          },
          { text: "Cancel" },
        ],
        { cancelable: false }
      );
    }
  };

  return (
    <View style={styles.mainContainer}>
      <FocusAwareStatusBar
        barStyle="light-content"
        backgroundColor={"transparent"}
        translucent={true}
      />

      <View style={{ ...StyleSheet.absoluteFillObject }}>
        <View style={{ width: "100%", height: BACKGROUNDIMGHEIGHT }}>
          <ImagePlacer
            imageUrl={getFirstImage(vehicle?.pictures)?.image?.url}
            avatarUrl={getFirstImage(vehicle?.pictures)?.avatar?.url}
            type={"large"}
            withNoBorder
          />
        </View>
      </View>
      <View
        style={{
          height:
            Platform.OS === "android" || Platform.OS === "ios"
              ? StatusBarManager.HEIGHT
              : 10,
        }}
      />
      <View
        style={{
          width: "100%",
          height: PLATFORM_DETECT.WEB ? 40 : 40,
          justifyContent: "flex-end",
          zIndex: 9999,
        }}
      >
        <AbstractContentContainer>
          <TouchableOpacity
            onPress={() => goBack()}
            style={{
              flexDirection: "row",
              padding: 5,
              borderRadius: 8,
              alignItems: "center",
              backgroundColor: "rgba(255,255,255,.7)",
              alignSelf: "flex-start",
            }}
          >
            <BackBtnSvg color={Colors.blackPrimary} />
            <Text
              style={[
                styles.textOne,
                { fontSize: moderateScale(16, 0.1), marginLeft: 4 },
              ]}
            >
              Back
            </Text>
          </TouchableOpacity>
        </AbstractContentContainer>
      </View>

      <View style={{ ...StyleSheet.absoluteFillObject }}>
        <Animated.ScrollView
          alwaysBounceVertical={false}
          bounces={false}
          showsVerticalScrollIndicator={false}
        >
          <React.Fragment>
            <Animated.View
              style={[
                styles.middleHeader,
                {
                  top: BACKGROUNDIMGHEIGHT - 50,
                  transform: [
                    {
                      translateY: PLATFORM_DETECT.WEB
                        ? headerTransformWeb
                        : headerTransform,
                    },
                  ],
                },
              ]}
            >
              <View
                style={{
                  backgroundColor: Colors.blueSecondary,
                  borderTopLeftRadius: 28,
                  borderTopRightRadius: 28,
                }}
              >
                {/* <View
                  style={{
                    width: "100%",
                    height: 50,
                    backgroundColor: Colors.blueSecondary,
                    borderTopLeftRadius: 28,
                    borderTopRightRadius: 28,
                  }}
                /> */}
                <AbstractContentContainer>
                  <View
                    style={
                      {
                        // backgroundColor: Colors.blueSecondary,
                        // paddingBottom: 10,
                      }
                    }
                  >
                    <View style={{ marginTop: 20 }}>
                      {/* {isDataNotAvailable ? (
                        <View
                          style={{
                            width: "40%",
                            height: 55,
                            justifyContent: "center",
                          }}
                        >
                          <SkeletonLoader
                            backgroundColor={"grey"}
                            highlightColor={"#5A616C"}
                            boneColor={"grey"}
                            duration={1000}
                          >
                            <SkeletonLoader.Item
                              style={{
                                height: moderateScale(20, 0.1),
                                width: 80,
                                borderRadius: 8,
                                marginVertical: 5,
                              }}
                            />
                          </SkeletonLoader>
                        </View>
                      ) : (
                        
                        <View style={{ paddingBottom: 8 }}>
                          <Text style={styles.textSix}>
                            Last Bid: ${myBidAmount}
                          </Text>
                        </View>
                      )} */}
                      {isDataNotAvailable ? (
                        <View
                          style={{
                            width: "40%",
                            height: 55,
                            justifyContent: "center",
                          }}
                        >
                          <SkeletonLoader
                            backgroundColor={"grey"}
                            highlightColor={"#5A616C"}
                            boneColor={"grey"}
                            duration={1000}
                          >
                            <SkeletonLoader.Item
                              style={{
                                height: moderateScale(20, 0.1),
                                width: 80,
                                borderRadius: 8,
                                marginVertical: 5,
                              }}
                            />
                          </SkeletonLoader>
                        </View>
                      ) : singleAuction?.lastBidAmount ? (
                        <View style={{ paddingBottom: 8 }}>
                          <Text style={styles.textSix}>
                            Last Bid: ${myBidAmount}
                          </Text>
                        </View>
                      ) : (
                        <View style={{ paddingBottom: 8 }}>
                          <Text style={styles.textSix}>
                            Bid starting at: ${myBidAmount}
                          </Text>
                        </View>
                      )}
                      {isDataNotAvailable ? (
                        <SkeletonLoader
                          backgroundColor={"grey"}
                          highlightColor={"#5A616C"}
                          boneColor={"grey"}
                          duration={1000}
                        >
                          <SkeletonLoader.Item
                            style={{
                              width: 250,
                              borderRadius: 8,
                              height: moderateScale(25, 0.1),
                            }}
                          />
                        </SkeletonLoader>
                      ) : (
                        <DetailItem
                          priceValue={formatCurrencyToUSD(
                            vehicle?.priceRange?.min
                          )}
                          title={"(Suggested price / average)"}
                        />
                      )}

                      {vehicle?.highestOfferPlaced ? (
                        <DetailItem
                          priceValue={`${formatCurrencyToUSD(
                            vehicle?.highestOfferPlaced
                          )}`}
                          title={"(Highest offer placed)"}
                          textOpacityControl={true}
                        />
                      ) : (
                        false
                      )}
                    </View>

                    <View style={{ marginVertical: 0 }}>
                      {isDataNotAvailable ? (
                        <SkeletonLoader
                          backgroundColor={"grey"}
                          highlightColor={"#5A616C"}
                          boneColor={"grey"}
                          duration={1000}
                        >
                          <SkeletonLoader.Item
                            style={{
                              width: 200,
                              borderRadius: 8,
                              height: moderateScale(25, 0.1),
                            }}
                          />
                        </SkeletonLoader>
                      ) : (
                        <Text style={styles.textTwo}>{vehicle?.title}</Text>
                      )}
                    </View>

                    {vehicle?.KMDriven ? (
                      <View
                        style={{
                          flexDirection: "row",
                          alignItems: "center",
                          marginTop: 5,
                        }}
                      >
                        <SpeedTag
                          miles={vehicle?.KMDriven}
                          isDataNotAvailable={isDataNotAvailable}
                        />
                      </View>
                    ) : (
                      false
                    )}
                  </View>
                </AbstractContentContainer>
              </View>
            </Animated.View>
          </React.Fragment>

          <View
            style={{
              width: "100%",
              height: BACKGROUNDIMGHEIGHT,
              backgroundColor: "transparent",
            }}
          />

          <Animated.View
            style={{ width: "100%", backgroundColor: Colors.blueSecondary }}
          >
            <View style={{ height: vehicle?.KMDriven ? 125 : 72 }} />

            <AbstractContentContainer>
              <View
                style={{
                  width: "100%",
                  height: 1,
                  backgroundColor: "#5A616C",
                  marginTop: 20,
                  marginBottom: 10,
                }}
              />

              <View style={styles.viewFive}>
                {topBarData.map((item, index) => {
                  return (
                    <TouchableOpacity
                      onPress={() => onPressTopBarButton(item)}
                      key={item._id}
                      style={[
                        styles.topBarHeaderButton,
                        {
                          backgroundColor: item.active
                            ? Colors.blueprimary
                            : Colors.blueSecondary,
                        },
                      ]}
                    >
                      <Text style={styles.topBarHeaderText}>{item.label}</Text>
                    </TouchableOpacity>
                  );
                })}
              </View>

              <View style={{ flex: 1, marginBottom: 20 }}>
                {renderSpecificComponent(selectedType)}

                <View style={{ height: moderateScale(80, 0.1) }} />
              </View>
            </AbstractContentContainer>
          </Animated.View>
        </Animated.ScrollView>

        <View style={styles.viewLast}>
          <View style={styles.viewLastA}>
            {isDataNotAvailable ? (
              <View
                style={{ width: "40%", height: 55, justifyContent: "center" }}
              >
                <SkeletonLoader
                  backgroundColor={"grey"}
                  highlightColor={"#5A616C"}
                  boneColor={"grey"}
                  duration={1000}
                >
                  <SkeletonLoader.Item
                    style={{
                      height: moderateScale(55, 0.1),
                      width: PLATFORM_DETECT.WEB ? SW * 0.25 : SW * 0.25,
                      borderRadius: 8,
                      // alignSelf: "flex-end",
                    }}
                  />
                </SkeletonLoader>
              </View>
            ) : (
              <View
                style={{ width: "40%", height: 55, justifyContent: "center" }}
              >
                <AbstractButton
                  width={!deviceType ? SW * 0.3 : SW * 0.25}
                  onPress={handleLogin}
                  label={"Message"}
                />
              </View>
            )}

            {isDataNotAvailable ? (
              <View
                style={{
                  flex: 1,
                  height: 55,
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexDirection: "row",
                }}
              >
                <SkeletonLoader
                  backgroundColor={"grey"}
                  highlightColor={"#5A616C"}
                  boneColor={"grey"}
                  duration={1000}
                >
                  <SkeletonLoader.Item
                    style={{
                      height: moderateScale(55, 0.1),
                      width: PLATFORM_DETECT.WEB ? SW * 0.25 : SW * 0.25,
                      borderRadius: 8,
                      // alignSelf: "flex-end",
                    }}
                  />
                </SkeletonLoader>

                <SkeletonLoader
                  backgroundColor={"grey"}
                  highlightColor={"#5A616C"}
                  boneColor={"grey"}
                  duration={1000}
                >
                  <SkeletonLoader.Item
                    style={{
                      height: moderateScale(55, 0.1),
                      width: PLATFORM_DETECT.WEB ? SW * 0.25 : SW * 0.25,
                      borderRadius: 8,
                      // alignSelf: "flex-end",
                    }}
                  />
                </SkeletonLoader>
              </View>
            ) : (
              <View
                style={{
                  flex: 1,
                  height: "100%",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexDirection: "row",
                }}
              >
                <AbstractButton
                  width={PLATFORM_DETECT.WEB ? SW * 0.25 : SW * 0.25}
                  label={"Buy Now"}
                  bgcolor={Colors.redPrimary}
                  onPress={handleLogin}
                />

                <AbstractButton
                  width={PLATFORM_DETECT.WEB ? SW * 0.25 : SW * 0.25}
                  onPress={handleLogin}
                  label={"Place Bid"}
                />
              </View>
            )}
          </View>
        </View>
      </View>

      <SubmitBitStepsSheet
        ref={sheetRef}
        singleAuction={singleAuction}
        setMyBidAmount={setMyBidAmount}
        setInitialPriceRange={setInitialPriceRange}
        initialPriceRange={initialPriceRange}
        bidGap={bidGap}
        lastBidAmount={myBidAmount}
      />

      <BuyNowSheet
        id={BUY_NOW_SHEET}
        vehicle={vehicle}
        isCreateMode={true}
        onClose={() => SheetManager.hide(BUY_NOW_SHEET)}
        buyNowPrice={singleAuction?.buyNowPrice}
      />
    </View>
  );
};

export default CarDetailPlaceBidScreen;

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: Colors.blueSecondary,
  },
  viewOne: {
    width: "100%",
    flex: 1,
    backgroundColor: Colors.blueSecondary,
    borderTopLeftRadius: 28,
    borderTopRightRadius: 28,
    marginTop: -28,
  },
  viewLast: {
    width: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.9)",
    // position: "absolute",
    bottom: 0,
    justifyContent: "center",
    paddingVertical: 15,
    // backgroundColor: "green",
  },
  viewFour: {
    width: "65%",
    alignSelf: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textOne: {
    color: Colors.blackPrimary,
    fontSize: moderateScale(16, 0.1),
    fontFamily: Fonts.default,
  },
  textTwo: {
    color: Colors.whitePrimary,
    fontSize: moderateScale(21, 0.1),
    fontFamily: Fonts.default,
    fontWeight: "900",
  },
  speedTagView: {
    height: moderateScale(38, 0.1),
    borderRadius: 90,
    backgroundColor: "#5A616C",
    flexDirection: "row",
    alignItems: "center",
  },
  textThree: {
    color: Colors.whitePrimary,
    fontSize: moderateScale(16, 0.1),
    fontFamily: Fonts.default,
  },
  textFour: {
    color: Colors.whitePrimary,
    fontSize: moderateScale(17, 0.1),
    fontFamily: Fonts.default,
  },
  headerViewOne: {
    width: "90%",
    height: 50,
    position: "absolute",
    top: -65,
    alignSelf: "center",
    backgroundColor: "rgba(0, 10, 26, 0.9)",
    justifyContent: "flex-end",
    borderTopLeftRadius: 29,
    borderTopRightRadius: 29,
  },
  headerViewTwo: {
    width: "100%",
    backgroundColor: "rgba(0, 10, 26, 1)",
    height: 35,
    position: "absolute",
    top: -35,
    zIndex: 999,
    borderTopLeftRadius: 28,
    borderTopRightRadius: 28,
  },
  middleHeader: {
    height: 50,
    backgroundColor: "transparent",
    position: "absolute",

    right: 0,
    left: 0,
    zIndex: 999,
  },
  viewLastA: {
    width: "90%",
    paddingTop: 10,
    paddingBottom: 15,
    flexDirection: "row",
    alignSelf: "center",
    justifyContent: "center",
  },
  textSix: {
    fontFamily: Fonts.medium,
    fontSize: hp("2.5"),
    color: Colors.whitePrimary,
  },
  textSeven: {
    fontFamily: Fonts.bold,
    fontSize: 24,
    color: Colors.whitePrimary,
  },
  viewFive: {
    width: "100%",
    flexDirection: "row",
  },
  topBarHeaderButton: {
    width: "50%",
    height: 40,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "red",
    borderRadius: 5,
  },
  topBarHeaderText: {
    fontFamily: Fonts.medium,
    color: Colors.whitePrimary,
    fontSize: hp("2"),
  },
});
