import {
  ActivityIndicator,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import React, { useEffect, useState } from "react";
import ScreenContainer from "../../Components/AbstractComponents/abstractScreenContainer";
import { Colors, Fonts, PLATFORM_DETECT } from "../../themes";
import AbstractContentContainer from "../../Components/AbstractComponents/abstractContentContainer";
import FocusAwareStatusBar from "../../Components/AbstractComponents/statusbarConfiguration";
import { moderateScale } from "react-native-size-matters";
import AbstractButton from "../../Components/AbstractComponents/abstractButton";
import AuctionFireSvg from "../../Assets/Icons/auctionFireSvg";
import AuctionArrowForwardSvg from "../../Assets/Icons/auctionArrowForwardSvg";
import { useSelector } from "react-redux";
import { navigate } from "../../Navigation/mainNavigation";
import CardSwipe from "../../Components/ModuleComponents/AuctionComponents/cardSwipe";
import AuctionController, {
  useAllAuctionsList,
} from "../../Controllers/auctionController";
import { useUserName } from "../../Controllers/authController";
import OffersController from "../../Controllers/offersController";
import CardSwipeSkeletonPlaceholder from "../../Components/ModuleComponents/AuctionComponents/cardSwipeSkeletonPlaceholder";
import NoCarAvailableSvg from "../../Assets/Icons/noCarAvailableSvg";
import { getStatusBarHeight } from "react-native-status-bar-height";
import ExpandableSearchBar from "../../Components/ModuleComponents/expandableSearchBar";
import SearchLocationBarWeb from "../../Components/ModuleComponents/searchLocationBarWeb";
import { useConversations } from "../../Controllers/conversationsController";
import DropDownWithLabel from "../../Components/ModuleComponents/dropDownWithLabel";
import ReduxDispatchController from "../../Controllers/reduxDispatchController";

const STATUS_BAR_HEIGHT = getStatusBarHeight();

const AuctionScreen = () => {
  const { deviceType } = useSelector((state) => state.DeviceTypeReducer);
  const {} = useConversations();
  const searchLoading = useSelector(
    (state) => state.AuctionReducer.searchLoading
  );
  const [totalNoPage, setTotalNoPage] = useState(undefined);
  const [currentPage, setCurrentPage] = useState(0);
  const [areaArr, setAreaArr] = useState([
    { label: "All Auctions", value: "" },
  ]);
  const [selectedArea, setSelectedArea] = useState("");
  const [auctionList, setAuctionList] = useState([]);
  const [loaderFirstTimePlaceHolder, setLoaderFirstTimePlaceHolder] =
    useState(true);
  var userDetail = useUserName();

  const loadFirstTime = () => {
    AuctionController.getAuctionWithDistinctAreaFilter("", currentPage)
      .then((res) => {
        setAuctionList(res.auctions);
        setTotalNoPage(res.noOfPages);
        setLoaderFirstTimePlaceHolder(false);
      })
      .catch((err) => console.log("error in loading auction first time:", err));
  };
  const loadDistinctArea = () => {
    AuctionController.getDistinctAreaList()
      .then((list) => {
        if (list.length > 0) {
          const tempList = list.map((i) => ({ label: i, value: i }));
          console.log(tempList, "<=list");
          setAreaArr([...areaArr, ...tempList]);
        }
      })
      .catch((err) =>
        console.log("Error in getting Auction distinct area:", err)
      );
  };
  useEffect(() => {
    setTimeout(() => {
      loadFirstTime();
      loadDistinctArea();
    }, 500);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      OffersController.handleUpdateLocation();
    }, 500);
  }, []);

  const handleFetchAuctionWithArea = (value) => {
    setSelectedArea(value);
    setCurrentPage(0);

    ReduxDispatchController.AUCTION.setSearchLoading(true);
    AuctionController.getAuctionWithDistinctAreaFilter(value, 0)
      .then((res) => {
        setAuctionList(res.auctions);
        setTotalNoPage(res.noOfPages);
        ReduxDispatchController.AUCTION.setSearchLoading(false);
      })
      .catch((err) => {
        console.log("error in loading auction with dropdown:", err);
        ReduxDispatchController.AUCTION.setSearchLoading(false);
      });
  };

  const loadMore = () => {
    if (currentPage < totalNoPage - 1 && auctionList.length > 0) {
      ReduxDispatchController.AUCTION.setSearchLoading(true);
      let temp = currentPage + 1;
      setCurrentPage((current) => current + 1);
      AuctionController.getAuctionWithDistinctAreaFilter(selectedArea, temp)
        .then((res) => {
          setAuctionList([...auctionList, ...res.auctions]);
          setTotalNoPage(res.noOfPages);
          ReduxDispatchController.AUCTION.setSearchLoading(false);
        })
        .catch((err) => {
          console.log("error in loading auction with loadMore", err);
          ReduxDispatchController.AUCTION.setSearchLoading(false);
        });
    }
  };
  return (
    <ScreenContainer
      upperStyle={{ backgroundColor: Colors.screenBgColor }}
      bottomStyle={{ backgroundColor: Colors.screenBgColor }}
    >
      <View style={styles.mainContainer}>
        <FocusAwareStatusBar
          barStyle="dark-content"
          backgroundColor={"transparent"}
          translucent={true}
        />

        <View
          style={[
            {
              ...Platform.select({
                ios: {
                  shadowColor: "#000",
                  shadowOffset: { width: 0, height: 3 },
                  shadowOpacity: 0.1,
                },
                android: {
                  elevation: 3,
                },
              }),
            },
            ,
            {
              width: "100%",
              backgroundColor: Colors.screenBgColor,
              borderBottomLeftRadius: 20,
              borderBottomRightRadius: 20,
            },
          ]}
        >
          <View
            style={{
              height: Platform.OS === "android" ? STATUS_BAR_HEIGHT : 10,
            }}
          />
          <AbstractContentContainer>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Text
                style={[
                  styles.textOne,
                  {
                    marginTop: 10,
                    marginBottom: 15,
                    fontSize: moderateScale(22, 0.1),
                  },
                ]}
              >
                Auction
              </Text>
              {/* {areaArr?.length > 0 && ( */}
              <View
                style={{
                  width: deviceType ? 300 : 200,
                  height: deviceType ? 40 : 30,
                  // backgroundColor: "red",
                }}
              >
                <DropDownWithLabel
                  area={true}
                  cWidth={deviceType ? 300 : 200}
                  cHeight={deviceType ? 40 : 30}
                  placeHolder={"All Auctions"}
                  items={areaArr}
                  value={selectedArea}
                  setSelectedArea={setSelectedArea}
                  onChange={handleFetchAuctionWithArea}
                  zIndex={1000}
                  zIndexInverse={3000}
                  sheetWihDisableGesture={true}
                />
              </View>
              {/* )} */}

              {/* {PLATFORM_DETECT.WEB ? (
                <SearchLocationBarWeb
                  resetFilter={getAuctionByCurrentLocation}
                  onSearchAuction={onSearchAuction}
                />
              ) : (
                <ExpandableSearchBar
                  resetFilter={getAuctionByCurrentLocation}
                  onSearchAuction={onSearchAuction}
                />
              )} */}
            </View>
          </AbstractContentContainer>
        </View>

        <View style={[styles.secondContainer, { zIndex: -1 }]}>
          <AbstractContentContainer>
            <Text style={styles.small}>
              Welcome!{" "}
              <Text style={styles.textTwo}>{userDetail?.fullName}</Text>
            </Text>

            <View style={{ flexDirection: "row", marginTop: 5 }}>
              <AbstractButton
                onPress={() => navigate("ExploreCars")}
                label={"Explore all cars"}
                txtColor={Colors.blueprimary}
                txtSize={18}
                width={180}
                height={45}
                bgcolor="transparent"
                borderStyle={{
                  borderWidth: 1,
                  borderColor: Colors.blueprimary,
                }}
                icon={() => <AuctionArrowForwardSvg />}
              />
              <TouchableOpacity
                style={{
                  paddingHorizontal: 14,
                  paddingVertical: 4,
                  borderRadius: 8,
                  borderWidth: 1,
                  borderColor: Colors.blueprimary,
                  marginLeft: 10,
                  justifyContent: "center",
                }}
                onPress={() => navigate("MyBids")}
              >
                <Text
                  style={{
                    fontSize: moderateScale(16, 0.1),
                    color: Colors.blueprimary,
                    fontFamily: Fonts.default,
                    fontWeight: "500",
                  }}
                >
                  My bids
                </Text>
              </TouchableOpacity>
            </View>
          </AbstractContentContainer>
        </View>
        <View
          style={{
            width: "90%",
            alignSelf: "center",
            height: 2,
            borderRadius: 5,
            backgroundColor: "white",
            marginBottom: 10,
            zIndex: -1,
          }}
        />
        <View style={[styles.thirdContainer, { zIndex: -1 }]}>
          <AbstractContentContainer>
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Text style={styles.textThree}>Upcoming Auctions</Text>
              {/* <AuctionFireSvg /> */}
            </View>
          </AbstractContentContainer>

          <View style={{ flex: 1, justifyContent: "center" }}>
            {loaderFirstTimePlaceHolder || searchLoading ? (
              <CardSwipeSkeletonPlaceholder
                suggestionCard={false}
                data={[0, 0, 0, 0, 0]}
              />
            ) : auctionList?.length > 0 ? (
              <CardSwipe
                loadMore={loadMore}
                suggestionCard={false}
                data={auctionList}
              />
            ) : (
              <NoCarAvailableSvg label={"No auction found!"} />
            )}
            {/* {searchLoading ? (
              <View
                style={{
                  ...StyleSheet.absoluteFillObject,
                  justifyContent: "center",
                  alignItems: "center",
                  // backgroundColor: "rgba(0,0,0,0.1)",
                }}
              >
                <ActivityIndicator color={Colors.blueprimary} size={"large"} />
                <Text style={{ color: Colors.whitePrimary, marginTop: 10 }}>
                  Loading...
                </Text>
              </View>
            ) : (
              false
            )} */}
          </View>
        </View>
        <View
          style={{
            height: PLATFORM_DETECT.WEB
              ? PLATFORM_DETECT.SH * 0.14
              : Platform.OS === "android"
              ? 80
              : 90,
            width: "100%",
          }}
        />
      </View>
    </ScreenContainer>
  );
};

export default AuctionScreen;

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: Colors.screenBgColor,
  },
  secondContainer: {
    marginBottom: 15,
    marginTop: 15,
  },
  thirdContainer: {
    flex: 1,
  },

  textOne: {
    fontSize: moderateScale(20, 0.1),
    color: "black",
    fontFamily: Fonts.medium,
    fontWeight: "600",
  },
  textTwo: {
    fontFamily: Fonts.medium,
    color: Colors.blackPrimary,
    fontSize: moderateScale(18, 0.1),
    paddingBottom: 10,
    fontWeight: "600",
  },
  textThree: {
    fontFamily: Fonts.medium,
    color: Colors.blackPrimary,
    fontSize: moderateScale(20, 0.1),
    paddingRight: 10,
    fontWeight: "500",
  },
  small: {
    fontSize: moderateScale(16, 0.1),
    color: "black",
    fontFamily: Fonts.default,
    fontWeight: "500",
    paddingBottom: 5,
  },
  myBids: {
    fontSize: moderateScale(12, 0.1),
    color: Colors.blackPrimary,
    fontFamily: Fonts.medium,
    fontWeight: "500",
  },
});
