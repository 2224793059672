import { ScrollView, StyleSheet, Text, View } from "react-native";
import React from "react";
import { Colors } from "../../../themes";

const KnownIssues = ({ data }) => {
  if (!data) return false;
  return (
    <View style={{ marginTop: 10 }} showsVerticalScrollIndicator={false}>
      {data.map((label, index) => (
        <View
          key={index}
          style={{ flexDirection: "row", alignItems: "center" }}
        >
          {label != "undefined" ? (
            <>
              <View
                style={{
                  width: 7,
                  height: 7,
                  borderRadius: 20,
                  backgroundColor: Colors.blueprimary,
                  marginRight: 10,
                }}
              />
              <View style={{ marginVertical: 5 }}>
                <Text style={{ color: "white" }}>{label}</Text>
              </View>
            </>
          ) : (
            false
          )}
        </View>
      ))}
    </View>
  );
};

export default KnownIssues;

const styles = StyleSheet.create({});
