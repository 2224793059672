import { AUTH } from "../types"
import produce from 'immer';
const state = {
    currentUser: null
}

const AuthReducer = (mState = {...state}, action ) => {
    switch(action.type){
        case AUTH.SET_CURRENT_USER:
          return produce(mState, draftState => {
            draftState.currentUser = action.payload;
          })
        default:
            return {...mState};
    }   
}
export default AuthReducer;