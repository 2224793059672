import { View } from "react-native";
import React from "react";
import OfferInfoSheet from "../ModuleComponents/BottomSheets/offerInfoSheet";
import ActionSheet, { SheetManager } from "react-native-actions-sheet";
import { Colors } from "../../themes";
import { Popup } from "react-native-popup-confirm-toast";
import { navigate } from "../../Navigation/mainNavigation";

function SubmitOfferStepsSheet({
  vehicle,
  mode,
  offer,
  fromInitializeMessageFlow,
  onClose = () => false,
}) {
  const handleLowBalancePopup = () => {
    Popup.show({
      type: "warning",
      title: "Cannot submit offer",
      textBody:
        "You have used your Offers, if you’d like to make more offers, Click Here",
      buttonText: "Buy Offers",
      callback: () => {
        Popup.hide();
        navigate("WalletNewScreen");
      },
    });
  };

  const handleNoInternet = () => {
    Popup.show({
      type: "danger",
      title: "Network Error",
      textBody: "Please check your internet connection.",
    });
  };

  return (
    <ActionSheet
      id="Car-Detail-Sheet-v4"
      initialOffsetFromBottom={1}
      statusBarTranslucent={true}
      delayActionSheetDrawTime={0}
      openAnimationSpeed={1}
      bounceOnOpen={true}
      containerStyle={{
        backgroundColor: Colors.blueSecondary,
        borderTopLeftRadius: 25,
        borderTopRightRadius: 25,
      }}
      bounciness={2}
      indicatorStyle={{ width: 70, height: 4 }}
      onClose={onClose}
      overlayColor={"grey"}
      defaultOverlayOpacity={0.4}
    >
      <OfferInfoSheet
        vehicle={vehicle}
        offer={offer}
        fromInitializeMessageFlow={fromInitializeMessageFlow}
        onClose={onClose}
      />
    </ActionSheet>
  );
}

export default SubmitOfferStepsSheet;
