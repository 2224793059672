import React, { useEffect } from "react";
import { View, Platform } from "react-native";
import LogoSvg from "../../Assets/Icons/logoSvg";
import AuthController from "../../Controllers/authController";
import * as Device from "expo-device";
import ReduxDispatchController from "../../Controllers/reduxDispatchController";
import { PLATFORM_DETECT } from "../../themes";
// import AppUpdateController from "../../../src/AppUpdateManager/appUpdateManager";
import { clearAndNavigate } from "../../Navigation/mainNavigation";

const SplashScreen = (props) => {
  const getDeviceType = async () => {
    const device = await Device.getDeviceTypeAsync();
    if (Platform.OS == "web" && Device.DeviceType.DESKTOP == device) {
      ReduxDispatchController.DEVICE.setDeviceType(true);
    } else {
      ReduxDispatchController.DEVICE.setDeviceType(false);
    }
  };
  useEffect(() => {
    getDeviceType()
      .then((res) => {
        AuthController.handleRestoreUserAndAuthenticate((value) => {
          if (value) {
            clearAndNavigate("Auction");
          }
        });
      })
      .catch((err) => console.log(err, "error in getting device type"));
    if (!PLATFORM_DETECT.WEB) {
      // AppUpdateController.handleSyncCode();
    }
  }, []);

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: "black",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <LogoSvg size={200} />
    </View>
  );
};

export default SplashScreen;
