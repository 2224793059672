import { StyleSheet, Text, View } from "react-native";
import React from "react";
import { moderateScale } from "react-native-size-matters";
// import SkeletonPlaceholder from "react-native-skeleton-placeholder";
import SkeletonLoader from "expo-skeleton-loader";

import { Colors } from "../../themes";
import { useSelector } from "react-redux";

const messageItemPlaceholderMobile = () => {
  const { deviceType } = useSelector((state) => state.DeviceTypeReducer);

  return (
    <SkeletonLoader
      backgroundColor={"white"}
      highlightColor={"#e5e5e5"}
      boneColor={"white"}
      duration={1000}
    >
      <SkeletonLoader.Container
        style={[styles.mainContainer, { width: deviceType ? "90%" : "95%" }]}
      >
        <SkeletonLoader.Container style={styles.viewA}>
          <SkeletonLoader.Item
            style={{
              width: moderateScale(deviceType ? 130 : 72, 0.1),
              height: moderateScale(deviceType ? 100 : 55, 0.1),
              borderRadius: 15,
              // backgroundColor: "#e5e5e5",
              marginRight: 10,
            }}
          />
          <SkeletonLoader.Container style={{ width: "100%" }}>
            <SkeletonLoader.Item
              style={{
                width: "50%",
                height: 20,
                borderRadius: 5,
                // backgroundColor: "#e5e5e5",
                marginBottom: 5,
              }}
            />
            <SkeletonLoader.Item
              style={{
                width: "30%",
                height: 20,
                borderRadius: 5,

                // backgroundColor: "#e5e5e5",
              }}
            />
          </SkeletonLoader.Container>
        </SkeletonLoader.Container>
        <SkeletonLoader.Container style={styles.viewB}>
          <SkeletonLoader.Container style={{}}>
            <SkeletonLoader.Item
              style={{
                width: 35,
                height: 10,
                borderRadius: 5,
                backgroundColor: "#e5e5e5",
                marginRight: 10,
              }}
            />
            <SkeletonLoader.Item
              style={{
                width: 45,
                height: 10,
                marginTop: 5,

                borderRadius: 5,
                backgroundColor: "#e5e5e5",
              }}
            />
          </SkeletonLoader.Container>
        </SkeletonLoader.Container>
      </SkeletonLoader.Container>
    </SkeletonLoader>
    // <SkeletonLoader
    //   backgroundColor={"#e5e5e5"}
    //   highlightColor={Colors.screenBgColor}
    // >
    //   <View style={styles.mainContainer}>
    //     <View style={styles.viewA}>
    //       <View
    //         style={{
    //           width: moderateScale(72, 0.1),
    //           height: moderateScale(55, 0.1),
    //           borderRadius: 15,
    //           backgroundColor: "red",
    //         }}
    //       />
    //     </View>
    //     <View style={styles.viewB}>
    //       <View style={{ width: "90%", height: 40 }} />
    //     </View>
    //   </View>
    // </SkeletonLoader>
  );
};

export default messageItemPlaceholderMobile;
const styles = StyleSheet.create({
  mainContainer: {
    alignSelf: "center",
    // height: moderateScale(82, 0.1),
    paddingVertical: 10,
    borderRadius: 15,
    // justifyContent: "center",
    paddingLeft: 10,
    alignItems: "center",
    marginTop: 1,
    marginBottom: 10,
    borderBottomWidth: 1,
    borderColor: "#e5e5e5",
    flexDirection: "row",
  },
  viewA: {
    width: "75%",
    flexDirection: "row",
    height: "100%",
    borderTopLeftRadius: 12,
    borderBottomLeftRadius: 12,
    // justifyContent: "center",
    alignItems: "center",
  },
  viewB: {
    width: "25%",
    paddingRight: 20,
    alignSelf: "flex-end",
    height: "100%",
    alignItems: "center",
    justifyContent: "flex-end",
    flexDirection: "row",
  },
});
// const styles = StyleSheet.create({
//   mainContainer: {
//     width: "100%",
//     height: moderateScale(82, 0.1),
//     flexDirection: "row",
//     alignItems: "center",
//     justifyContent: "center",
//     marginBottom: 15,
//     borderColor: "white",
//     borderBottomWidth: 1,
//     flexDirection: "row",
//     // backgroundColor: Colors.screenBgColor,
//   },
//   viewA: {
//     width: "25%",
//     height: "100%",
//     borderTopLeftRadius: 12,
//     borderBottomLeftRadius: 12,
//     justifyContent: "center",
//     alignItems: "center",
//     paddingLeft: 10,
//   },
//   viewB: {
//     width: "75%",
//     height: "100%",
//     justifyContent: "center",
//   },
// });
