import { ActivityIndicator, StyleSheet, View } from "react-native";
import React, { useEffect, useState } from "react";
import AbstractBottomSheet from "../../AbstractComponents/abstractBottomSheetV2";
import AbstractContentContainer from "../../AbstractComponents/abstractContentContainer";
import { Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import WebStripeComponent from "./webComponent";
import { loadStripe } from "@stripe/stripe-js";
import { SheetManager } from "react-native-actions-sheet";
import { Colors } from "../../../themes";

const StripeWebElementWarper = ({
  onClose,
  handleDismissPaymentSheet,
  onSuccess,
  intent,
}) => {
  const stripePromise = loadStripe(intent.publishableKey);
  const options = {
    clientSecret: intent.paymentIntent,
  };
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log(intent, "intent");
    SheetManager.show("web-payment");
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <>
      <AbstractBottomSheet
        onClose={handleDismissPaymentSheet}
        id={"web-payment"}
      >
        {loading ? (
          <View
            style={{
              width: "100%",
              minHeight: 300,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ActivityIndicator size={"large"} color={Colors.blueprimary} />
          </View>
        ) : (
          <View style={{ minHeight: 300 }}>
            <AbstractContentContainer>
              <Elements stripe={stripePromise} options={options}>
                <WebStripeComponent
                  onClose={onClose}
                  onSuccess={onSuccess}
                  intent={intent}
                />
              </Elements>
            </AbstractContentContainer>
          </View>
        )}
      </AbstractBottomSheet>
    </>
  );
};

export default StripeWebElementWarper;

const styles = StyleSheet.create({});
