import { StyleSheet, Text, View } from "react-native";
import React from "react";
import { moderateScale } from "react-native-size-matters";
// import SkeletonPlaceholder from "react-native-skeleton-placeholder";
import SkeletonLoader from "expo-skeleton-loader";

import { Colors, PLATFORM_DETECT } from "../../themes";
import { useSelector } from "react-redux";

const SavedItemPlaceholderMobile = () => {
  const { deviceType } = useSelector((state) => state.DeviceTypeReducer);

  return (
    <SkeletonLoader
      backgroundColor={"white"}
      highlightColor={"#e5e5e5"}
      boneColor={"white"}
      duration={1000}
    >
      <SkeletonLoader.Container
        style={[styles.mainContainer, { paddingLeft: deviceType ? 20 : 10 }]}
      >
        <SkeletonLoader.Container style={styles.viewA}>
          <SkeletonLoader.Item
            style={{
              width: moderateScale(deviceType ? 130 : 72, 0.1),
              height: moderateScale(deviceType ? 100 : 55, 0.1),
              borderRadius: 15,
              // backgroundColor: "#e5e5e5",
              marginRight: 10,
            }}
          />
          {/* <View style={{ width: "100%",height:'100%' }}> */}
          <SkeletonLoader.Container style={{ width: "100%" }}>
            <SkeletonLoader.Item
              style={{
                width: "50%",
                height: 20,
                borderRadius: 5,
                backgroundColor: "#e5e5e5",
                marginBottom: 5,
              }}
            />

            <SkeletonLoader.Item
              style={{
                width: "30%",
                height: 20,
                borderRadius: 5,

                backgroundColor: "#e5e5e5",
              }}
            />
          </SkeletonLoader.Container>
        </SkeletonLoader.Container>

        <SkeletonLoader.Container style={styles.viewB}>
          <SkeletonLoader.Container style={{ flexDirection: "row" }}>
            <SkeletonLoader.Item
              style={{
                width: 25,
                height: 25,
                borderRadius: 5,
                // backgroundColor: "#e5e5e5",
                marginRight: 10,
              }}
            />
            <SkeletonLoader.Item
              style={{
                width: 25,
                height: 25,

                borderRadius: 5,
                // backgroundColor: "#e5e5e5",
              }}
            />
          </SkeletonLoader.Container>
        </SkeletonLoader.Container>
      </SkeletonLoader.Container>
    </SkeletonLoader>
  );
};

export default SavedItemPlaceholderMobile;

const styles = StyleSheet.create({
  mainContainer: {
    width: "99%",
    alignSelf: "center",
    // height: moderateScale(82, 0.1),
    paddingVertical: 10,
    borderRadius: 15,
    // justifyContent: "center",

    alignItems: "center",
    marginTop: 1,
    marginBottom: 15,
    borderColor: "#e5e5e5",
    borderWidth: 1,
    // shadowColor: "#000",
    // shadowOffset: {
    //   width: 0,
    //   height: 1,
    // },
    // shadowOpacity: 0.18,
    // shadowRadius: 1.0,
    // elevation: 1,
    backgroundColor: Colors.screenBgColor,
    flexDirection: "row",
  },
  viewA: {
    width: "75%",
    flexDirection: "row",
    height: "100%",
    borderTopLeftRadius: 12,
    borderBottomLeftRadius: 12,
    // justifyContent: "center",
    alignItems: "center",
  },
  viewB: {
    width: "25%",
    paddingRight: 20,
    alignSelf: "flex-end",
    height: "100%",
    alignItems: "center",
    justifyContent: "flex-end",
    flexDirection: "row",
  },
});
