import React from "react";
import PPWebView from "../../../Components/ModuleComponents/ppWebview"

const EULScreen = (props) => {
  return (
    <PPWebView
      title={"End User License Agreement"}
      uri={
        "https://docs.google.com/document/d/e/2PACX-1vRdZEI-waiMmbh3XJ0bBFN_b29BGybQvt9pBCtAL8IJBc3vkovZpSuv2gfPkVMOXg/pub"
      }
    />
  );
};
export default EULScreen;
