import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import { Colors, Fonts } from '../../../themes';
import {
    widthPercentageToDP as wp,
    heightPercentageToDP as hp,
  } from "react-native-responsive-screen";

const DetailItem = ({ priceValue, title, textOpacityControl }) => {
    const defaultPrice = priceValue ? priceValue : "$0.00";
    const defaultTitle = title ? title : "(text)";
  
    return (
      <View
        style={{ flexDirection: "row", alignItems: "center", marginVertical: 5 }}
      >
        <View style={{ marginRight: 15 }}>
          <Text style={styles.priceText}>{defaultPrice}</Text>
        </View>
  
        <View>
          <Text
            style={[
              styles.detailText,
              {
                color: textOpacityControl
                  ? "rgba(255, 255, 255, 0.6)"
                  : Colors.whitePrimary,
              },
            ]}
          >
            {defaultTitle}
          </Text>
        </View>
      </View>
    );
}

export default DetailItem

const styles = StyleSheet.create({
    priceText: {
        fontFamily: Fonts.bold,
        color: Colors.blueSecondaryTwo,
        fontSize: hp("1.9"),
      },
      detailText: {
        fontFamily: Fonts.medium,
        color: Colors.whitePrimary,
        fontSize: hp("1.6"),
      },
})