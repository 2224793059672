import {
  StyleSheet,
  Text,
  TouchableOpacity,
  Dimensions,
  View,
} from "react-native";
import React from "react";
import { moderateScale } from "react-native-size-matters";
import { Colors, Fonts } from "../../themes";
// import SkeletonPlaceholder from "react-native-skeleton-placeholder";
import SkeletonLoader from "expo-skeleton-loader";

const SH = Dimensions.get("window").height;
const SW = Dimensions.get("window").width;

const tileWidth = SW * (105 / 414);
// const tileHeight = tileWidth * (42 / 100);

const CarWithCountTilePlaceholder = ({ smallWidth }) => {
  return (
    <SkeletonLoader
      speed={0}
      backgroundColor={"#e5e5e5"}
      highlightColor={Colors.screenBgColor}
    >
      <View style={[styles.mainContainer, { width: 100 }]} />
    </SkeletonLoader>
  );
};

export default CarWithCountTilePlaceholder;

const styles = StyleSheet.create({
  mainContainer: {
    // borderRadius: 80,
    // backgroundColor: Colors.blueprimary,
    // justifyContent: "center",
    // alignItems: "center",
    // paddingHorizontal: 20,
    // marginRight: 13,
    height: moderateScale(40, 0.1),
    borderRadius: 80,
    backgroundColor: Colors.blueprimary,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 25,
    marginRight: 13,
  },
});
