import React from "react";
import MainNavigation, { navigate } from "./src/Navigation/mainNavigation";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import { Linking, LogBox, Platform } from "react-native";
import { SafeAreaProvider } from "react-native-safe-area-context";
import AuthController from "./src/Controllers/authController";
import { useEffect } from "react";
import * as Device from "expo-device";
import ReduxDispatchController from "./src/Controllers/reduxDispatchController";
import { PLATFORM_DETECT } from "./src/themes";
import AppUpdateController from "./src/AppUpdateManager/appUpdateManager";

LogBox.ignoreLogs([
  "[react-native-gesture-handler] Seems like you're using an old API with gesture components, check out new Gestures system!",
  "onAnimatedValueUpdate",
]);

const Home = (props) => {
  const getDeviceType = async () => {
    const device = await Device.getDeviceTypeAsync();
    if (Platform.OS == "web" && Device.DeviceType.DESKTOP == device) {
      ReduxDispatchController.DEVICE.setDeviceType(true);
    } else {
      ReduxDispatchController.DEVICE.setDeviceType(false);
    }
  };
  useEffect(() => {
    Linking.getInitialURL()
      .then((url) => {
        console.log(url, ":LaunchByUrl");
        if (url.includes("/Auction/singleAuction")) {
          AuthController.isLinkAvailAble.url = url;
          AuthController.isLinkAvailAble.availability = true;
          const parts = url.split("/");
          console.log(
            parts[parts.length - 1],
            "parts[parts.length-1]parts[parts.length-1]parts[parts.length-1]"
          );
          AuthController.isLinkAvailAble.id = parts[parts.length - 1];
        }
      })
      .catch((err) => console.log("error in opening url:", err));
    Linking.addEventListener("url", ({ url }) => {
      console.log(url, ":already");
      if (url.includes("/Auction/singleAuction")) {
        AuthController.isLinkAvailAble.url = url;
        AuthController.isLinkAvailAble.availability = true;
        const parts = url.split("/");
        console.log(
          parts[parts.length - 1],
          "parts[parts.length-1]parts[parts.length-1]parts[parts.length-1]"
        );
        AuthController.isLinkAvailAble.id = parts[parts.length - 1];
      }
    });
    const getUrl = async () => {
      await getDeviceType()
        .then((res) => {
          AuthController.handleRestoreUserAndAuthenticate();
        })
        .catch((err) => console.log(err, "error in getting device type"));
      if (!PLATFORM_DETECT.WEB) {
        AppUpdateController.handleSyncCode();
      }
    };
    getUrl();
  }, []);

  return (
    <GestureHandlerRootView style={{ flex: 1 }}>
      <SafeAreaProvider>
        <MainNavigation />
      </SafeAreaProvider>
    </GestureHandlerRootView>
  );
};

export default Home;
