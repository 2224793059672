import React from "react";
import { StyleSheet, Text, View, TouchableOpacity } from "react-native";
import { widthPercentageToDP as wp } from "react-native-responsive-screen";
import { moderateScale } from "react-native-size-matters";
import ArrowForwardSvg from "../../Assets/Icons/arrowForwardSvg";
import { Colors, Fonts, PLATFORM_DETECT } from "../../themes";
import useIsMobileBrowser from "../../../src/Utils/useIsMobileBrowser";
import { useSelector } from "react-redux";

const SettingItem = ({ label, icon, arrow, bgColor, border, onPress }) => {
  //   const { deviceType } = useSelector((state) => state.DeviceTypeReducer);;
  const { deviceType } = useSelector((state) => state.DeviceTypeReducer);

  const defLabel = label ? label : "textHere";
  const defbgColor = bgColor ? bgColor : "yellow";

  return (
    <TouchableOpacity
      activeOpacity={0.9}
      onPress={onPress}
      style={[
        styles.mainContainer,
        { borderBottomColor: border ? "#EEEEEE" : Colors.screenBgColor },
      ]}
    >
      <View style={[styles.viewOne]}>
        <View style={[styles.viewInner, { backgroundColor: defbgColor }]}>
          {icon ? icon() : null}
        </View>
      </View>
      <View style={styles.viewTwo}>
        <Text
          style={[styles.textOne, { fontSize: deviceType ? 30 : wp("3.8%") }]}
        >
          {defLabel}
        </Text>
      </View>
      <View style={styles.viewThree}>{arrow ? <ArrowForwardSvg /> : null}</View>
    </TouchableOpacity>
  );
};

export default SettingItem;

const styles = StyleSheet.create({
  mainContainer: {
    width: "100%",
    height: moderateScale(50, 0.1),
    flexDirection: "row",
    borderBottomWidth: 1,
  },
  viewInner: {
    width: moderateScale(40, 0.1),
    height: moderateScale(30, 0.1),
    backgroundColor: "orange",
    borderRadius: 5,
    justifyContent: "center",
    alignItems: "center",
  },
  viewOne: {
    // width: "15%",

    height: "100%",
    justifyContent: "center",
  },
  viewTwo: {
    // width: "75%",
    paddingLeft: "2%",
    height: "100%",
    justifyContent: "center",
    paddingLeft: 8,
  },
  viewThree: {
    width: "10%",
    height: "100%",
    justifyContent: "center",
    alignItems: "flex-end",
  },
  textOne: {
    fontFamily: Fonts.default,

    color: "black",
  },
});
