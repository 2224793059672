import { StyleSheet, Text, View,TouchableOpacity} from 'react-native'
import React from 'react'
import { Colors, Fonts } from '../../themes'
import { moderateScale } from 'react-native-size-matters'


const defaultProps = {
    label:'Text',
    textColor:Colors.blackPrimary
}


const AbstractOptionButton = ({icon,label,textColor,onPress,textSize}) => {

  const defaultTextSize = textSize ? textSize : moderateScale(19,0.1)

  return (
    <TouchableOpacity
    activeOpacity={0.8}
    onPress={onPress}
    style={styles.mainContainer}>
       <View style={{marginLeft:20}}>
         {icon ? icon() :false}
       </View>
       <View style={{marginLeft:15}} >
           <Text style={[styles.textOne,{color:textColor,fontSize:defaultTextSize}]} >{label}</Text>
       </View>
    </TouchableOpacity>
  )
}

export default AbstractOptionButton
AbstractOptionButton.defaultProps = defaultProps

const styles = StyleSheet.create({
    mainContainer:{
        width:'100%',
        height:50,
        flexDirection:'row',
        alignItems:'center'
    },
    textOne:{
        fontSize:moderateScale(19,0.1),
        fontFamily:Fonts.default,
    }
})