import React, { useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  Dimensions,
  TouchableOpacity,
} from "react-native";
import { moderateScale } from "react-native-size-matters";
import CrossSvg from "../../../Assets/Icons/crossSvg";
import AbstractContentContainer from "../../AbstractComponents/abstractContentContainer";
import { Colors, Fonts } from "../../../themes";
import AbstractButton from "../../AbstractComponents/abstractButton";
import ImagePlacer from "../imagePlacer";
import PriceRangePicker from "../PriceRangePicker/indexv2";
import { getFirstImage } from "../../../Controllers/vehiclesController";
import { useSelector } from "react-redux";
import SkeletonLoader from "expo-skeleton-loader";
import PaymentComponent from "../../../Components/ModuleComponents/payment";
import OffersController from "../../../Controllers/offersController";
import { SheetManager } from "react-native-actions-sheet";
import ReduxDispatchController from "../../../Controllers/reduxDispatchController";

const SW = Dimensions.get("window").width;

const OfferInfoSheet = ({
  vehicle,
  buyNowPrice,
  isCreateMode,
  offer,
  fromInitializeMessageFlow,
  offerType,
  onClose,
  id,
}) => {
  console.log(buyNowPrice, isCreateMode, fromInitializeMessageFlow, offerType);

  const [swipperLoading, setSwipperLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [openStripeSheet, setOpenStripeSheet] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [offerAmountData, setAmountData] = useState(null);
  const [intent, setIntent] = useState(undefined);
  const { deviceType } = useSelector((state) => state.DeviceTypeReducer);

  const offerInfoSubmit = (vehicleId, amount, offerType) => {
    setProcessing(true);
    if (isCreateMode) {
      OffersController.createAnOfferWithPayment(
        vehicleId,
        amount,
        vehicle.title,
        offerType
      )
        .then((intent) => {
          setIntent(intent);

          setErrorMessage("");
          setOpenStripeSheet(true);
        })
        .catch((message) => {
          console.log(`Offer create fail`, message);
          setErrorMessage(message);
          setProcessing(false);
        });
    } else {
      if (offer) {
        OffersController.updateAnOffer(offer._id, amount)
          .then((created) => {
            setProcessing(false);
            SheetManager.hideAll();
          })
          .catch((message) => {
            console.log(message);
            setProcessing(false);
            setErrorMessage(message);
          });
      } else {
        console.log(`Invalid Offer`, offer);
      }
    }
  };
  const handleUpdateOffer = () => {
    if (offerAmountData) {
      offerInfoSubmit("", offerAmountData);
    }
  };
  const handleCreateOffer = (offerType) => {
    if (offerType == "buyNow" && buyNowPrice) {
      offerInfoSubmit(vehicle._id, buyNowPrice, offerType);
    }
    if (offerAmountData) {
      offerInfoSubmit(vehicle._id, offerAmountData);
    }
  };
  const onInitializeSendOffer = (amount, offerType) => {
    setProcessing(true);
    console.log(`I2`, amount, offerType);

    OffersController.initializeConversationSendOffer(
      offer._id,
      amount,
      offerType
    )
      .then((intent) => {
        setIntent(intent);
        setErrorMessage("");
        setOpenStripeSheet(true);
        // console.log(`I3`);
      })
      .catch((message) => {
        console.log(`Offer create fail`, message);
        setErrorMessage(message);
        setProcessing(false);
      });
  };
  const onPressInitializeConversationSendOffer = () => {
    if (fromInitializeMessageFlow && offerType == "buyNow" && buyNowPrice) {
      onInitializeSendOffer(buyNowPrice, offerType);
    } else {
      if (offerAmountData || offerAmountData == 0) {
        onInitializeSendOffer(offerAmountData);
      } else {
        console.log("Invalid intialize offer flow", offerAmountData);
      }
    }
  };

  const handleClosePaymentSheet = () => {
    // console.log(`I5`);

    setOpenStripeSheet(false);
    setProcessing(false);
    onClose();
  };

  const handleDismissPaymentSheet = (reason) => {
    setOpenStripeSheet(false);
    setProcessing(false);
    // console.log(`I6`);

    OffersController.updateAnOfferStatusAfterPayment(
      intent.offerId,
      reason,
      false
    )
      .then((res) => {
        // console.log(res, "OfferStatusUpdated");
        // console.log(`I7`);
      })
      .catch((err) => console.log(err, "Error in updating status"));
  };

  const handleSuccessPaymentSheet = () => {
    setOpenStripeSheet(false);
    setProcessing(false);
    if (offerType) {
      console.log(`I8 handle success in buyNow:`, offerType, vehicle._id);
      ReduxDispatchController.OFFERS.updateAllBuyNowOfferStatus(
        vehicle._id,
        intent.offerId
      );
    }
    onClose();

    OffersController.updateAnOfferStatusAfterPayment(
      intent.offerId,
      "Payment hold successfully",
      true
    )
      .then((res) => {
        // console.log(`I9`);
        // console.log(res, "OfferStatusUpdated success case");
      })
      .catch((err) =>
        console.log(err, "Error in updating status success case")
      );
  };

  if (vehicle) {
    const minPriceRange = vehicle?.priceRange?.min
      ? vehicle?.priceRange?.min
      : 0;
    const maxPriceRange = vehicle?.priceRange?.min
      ? vehicle?.priceRange?.min
      : minPriceRange + 50000;

    return (
      <View style={styles.mainConatiner}>
        {offerType === "buyNow" ? (
          <View style={{ width: "100%" }}>
            <AbstractContentContainer>
              <View style={{ marginTop: 25, marginBottom: 15 }}>
                <Text style={styles.textOne_}>
                  You can buy it at this price:
                </Text>
              </View>
              <View style={{ marginBottom: 20 }}>
                <Text style={styles.textTwo_}>Price: ${buyNowPrice}</Text>
              </View>

              <View>
                <>
                  <AbstractButton
                    processing={processing}
                    onPress={() =>
                      fromInitializeMessageFlow
                        ? onPressInitializeConversationSendOffer()
                        : handleCreateOffer(offerType)
                    }
                    height={deviceType ? 60 : SW * 0.52 * 0.23}
                    label={"Buy"}
                  />
                  <View style={{ marginTop: 10, height: 18 }}>
                    <Text numberOfLines={1} style={{ color: "red" }}>
                      {errorMessage}
                    </Text>
                  </View>
                </>
              </View>

              <View style={{ width: "100%", height: 20 }} />
            </AbstractContentContainer>
          </View>
        ) : (
          <AbstractContentContainer>
            <View style={{ width: "100%", alignItems: "center" }}>
              <View
                style={{
                  alignItems: "flex-end",
                  position: "absolute",
                  right: 10,
                  top: 10,
                }}
              >
                <TouchableOpacity onPress={onClose} style={{ padding: 0 }}>
                  <CrossSvg color={"white"} />
                </TouchableOpacity>
              </View>

              <View>
                <Text style={styles.textOne}>
                  {isCreateMode || fromInitializeMessageFlow
                    ? "Make an offer"
                    : "Update offer"}
                </Text>
              </View>

              <View style={{ marginBottom: 20 }}>
                <Text style={[styles.textOne, { fontWeight: "800" }]}>
                  {vehicle.title}
                </Text>
              </View>

              <View>
                <View
                  style={{
                    width: 200,
                    height: 150,
                    borderRadius: 30,
                    overflow: "hidden",
                  }}
                >
                  <ImagePlacer
                    imageUrl={getFirstImage(vehicle.pictures).image.url}
                    avatarUrl={getFirstImage(vehicle.pictures).avatar.url}
                  />
                </View>
              </View>

              <View
                style={{
                  flexDirection: "row",
                  marginTop: 20,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {/* <TagSvg /> */}
                <View>
                  <Text style={[styles.textThree, { paddingLeft: 10 }]}>
                    Suggested
                  </Text>
                </View>
              </View>

              <View
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                {vehicle?.priceRange?.min != undefined ? (
                  <Text
                    style={[styles.textOne, { color: Colors.blueSecondaryTwo }]}
                  >{`$${vehicle?.priceRange?.min}`}</Text>
                ) : (
                  false
                )}
              </View>

              <View>
                <View style={{ height: 10 }} />
                <Text style={styles.textOne}>My offer</Text>
                <View style={{ height: 10 }} />
              </View>

              <PriceRangePicker
                initialValue={offer?.amount}
                onChange={setAmountData}
                minRange={minPriceRange}
                maxRange={maxPriceRange}
                setSwipperLoading={() => setSwipperLoading(false)}
                swipperLoading={swipperLoading}
              />

              <View style={{ height: 30 }} />
              <View style={{ marginBottom: 10, width: "100%" }}>
                {isCreateMode && !fromInitializeMessageFlow ? (
                  <View style={{ width: "100%", alignItems: "center" }}>
                    <View
                      style={{
                        width: deviceType ? 140 : SW * 0.52,
                        height: deviceType ? 60 : SW * 0.52 * 0.23,
                      }}
                    >
                      {swipperLoading ? (
                        <SkeletonLoader
                          backgroundColor={"rgb(28,42,74)"}
                          highlightColor={"rgb(28,42,74)"}
                          boneColor={"rgb(28,42,74)"}
                          duration={1000}
                        >
                          <SkeletonLoader.Item
                            style={{
                              width: deviceType ? 140 : SW * 0.52,
                              height: deviceType ? 60 : SW * 0.52 * 0.23,
                              borderRadius: 8,
                            }}
                          />
                        </SkeletonLoader>
                      ) : (
                        <>
                          <AbstractButton
                            processing={processing}
                            onPress={handleCreateOffer}
                            width={deviceType ? 140 : SW * 0.52}
                            height={deviceType ? 60 : SW * 0.52 * 0.23}
                            label={"Send Offer"}
                          />
                          <View style={{ marginTop: 10, height: 18 }}>
                            <Text numberOfLines={1} style={{ color: "red" }}>
                              {errorMessage}
                            </Text>
                          </View>
                        </>
                      )}
                    </View>
                  </View>
                ) : fromInitializeMessageFlow ? (
                  <View style={{ width: "100%", alignItems: "center" }}>
                    <View
                      style={{
                        width: deviceType ? 140 : SW * 0.52,
                        height: deviceType ? 60 : SW * 0.52 * 0.23,
                      }}
                    >
                      {swipperLoading ? (
                        <SkeletonLoader
                          backgroundColor={"rgb(28,42,74)"}
                          highlightColor={"rgb(28,42,74)"}
                          boneColor={"rgb(28,42,74)"}
                          duration={1000}
                        >
                          <SkeletonLoader.Item
                            style={{
                              width: deviceType ? 140 : SW * 0.52,
                              height: deviceType ? 60 : SW * 0.52 * 0.23,
                              borderRadius: 8,
                            }}
                          />
                        </SkeletonLoader>
                      ) : (
                        <AbstractButton
                          processing={processing}
                          onPress={onPressInitializeConversationSendOffer}
                          width={deviceType ? 140 : SW * 0.52}
                          height={deviceType ? 60 : SW * 0.52 * 0.23}
                          label={"Send Offer"}
                        />
                      )}
                    </View>
                  </View>
                ) : (
                  <View style={{ width: "100%", alignItems: "center" }}>
                    <View
                      style={{
                        width: deviceType ? 140 : SW * 0.52,
                        height: deviceType ? 60 : SW * 0.52 * 0.23,
                      }}
                    >
                      {swipperLoading ? (
                        <SkeletonLoader
                          backgroundColor={"rgb(28,42,74)"}
                          highlightColor={"rgb(28,42,74)"}
                          boneColor={"rgb(28,42,74)"}
                          duration={1000}
                        >
                          <SkeletonLoader.Item
                            style={{
                              width: deviceType ? 140 : SW * 0.52,
                              height: deviceType ? 60 : SW * 0.52 * 0.23,
                              borderRadius: 8,
                            }}
                          />
                        </SkeletonLoader>
                      ) : (
                        <>
                          <AbstractButton
                            processing={processing}
                            onPress={handleUpdateOffer}
                            width={deviceType ? 140 : SW * 0.52}
                            height={deviceType ? 60 : SW * 0.52 * 0.23}
                            label={"Update Offer"}
                          />
                          <View style={{ marginTop: 10, height: 18 }}>
                            <Text style={{ color: "red" }}>{errorMessage}</Text>
                          </View>
                        </>
                      )}
                    </View>
                  </View>
                )}
              </View>

              <View style={{ height: 50 }} />
            </View>
          </AbstractContentContainer>
        )}
        {openStripeSheet && (
          <PaymentComponent
            intent={intent}
            handleDismissPaymentSheet={handleDismissPaymentSheet}
            onSuccess={handleSuccessPaymentSheet}
            onClose={handleClosePaymentSheet}
          />
        )}
      </View>
    );
  } else {
    return false;
  }
};

export default OfferInfoSheet;

const styles = StyleSheet.create({
  mainConatiner: {
    // backgroundColor: Colors.blueSecondary,
    paddingTop: 10,
    // borderTopLeftRadius: 25,
    // borderTopRightRadius: 25,
  },

  textOne: {
    color: "white",
    fontSize: moderateScale(18, 0.1),
    fontFamily: Fonts.default,
    marginTop: 10,
  },
  textTwo: {
    color: "white",
    fontSize: moderateScale(17, 0.1),
    fontFamily: Fonts.default,
    marginTop: 10,
    textAlign: "center",
  },
  textThree: {
    color: "white",
    fontSize: moderateScale(17, 0.1),
    fontFamily: Fonts.default,
    textAlign: "center",
  },
  textOne_: {
    color: Colors.whitePrimary,
    fontSize: moderateScale(23, 0.1),
    fontFamily: Fonts.bold,
    fontWeight: "900",
  },
  textTwo_: {
    color: Colors.whitePrimary,
    fontSize: moderateScale(18, 0.1),
    fontFamily: Fonts.default,
    fontWeight: "900",
  },
});
