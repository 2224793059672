import React from "react";
import { StyleSheet, Text, View, Image, TouchableOpacity } from "react-native";
import { Colors, Fonts, PLATFORM_DETECT } from "../../themes";
import { moderateScale } from "react-native-size-matters";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import ImagePlacer from "./imagePlacer";
import AuthController from "../../Controllers/authController";
import {
  dateToInteligentDate,
  dateToShortTime,
} from "../../../src/Utils/common";
import { getFirstImage } from "../../Controllers/vehiclesController";
import useIsMobileBrowser from "../../../src/Utils/useIsMobileBrowser";
import { useSelector } from "react-redux";
import GlobalConstants from "../../Controllers/constants";

const MessageItem = ({ item, unReadMsg, Active, onPress }) => {
  const { deviceType } = useSelector((state) => state.DeviceTypeReducer);

  const lastMessageIsMine =
    item.conversation?.lastMessage?.buyer ===
    AuthController.getCurrentUser()._id;

  return (
    <TouchableOpacity
      onPress={onPress}
      style={[
        styles.mainContainer,
        {
          borderBottomWidth: deviceType ? 1 : 1,
          height: deviceType ? undefined : moderateScale(82, 0.1),
        },
      ]}
    >
      <View
        style={[
          styles.imgView,
          {
            width: moderateScale(deviceType ? 130 : 72, 0.1),
            height: moderateScale(deviceType ? 100 : 55, 0.1),
            marginVertical: deviceType ? 10 : 10,
          },
        ]}
      >
        {!item?.conversation?.buyerLastSeen ? (
          <View style={styles.indicatorView} />
        ) : null}
        <ImagePlacer
          imageUrl={getFirstImage(item?.vehicle?.pictures)?.image?.url}
          avatarUrl={getFirstImage(item?.vehicle?.pictures)?.avatar?.url}
          type={"small"}
        />

        <View style={{ margin: 4, position: "absolute" }}>
          {item.offerType == "buyNow" && (
            <View
              style={{
                backgroundColor: "rgba(0,0,0,0.6)",
                paddingHorizontal: 3,
                paddingVertical: 3,
                borderRadius: 5,
                marginBottom: 3,
              }}
            >
              <Text
                style={{
                  color: "white",
                  fontSize: 10,
                  fontFamily: Fonts.default,
                }}
              >
                Buy Now
              </Text>
            </View>
          )}

          {item.status == GlobalConstants.OFFER_STATUS.CONVERSATION && (
            <View
              style={{
                backgroundColor: "rgba(0,0,0,0.6)",
                paddingHorizontal: 3,
                paddingVertical: 3,
                borderRadius: 5,
              }}
            >
              <Text
                style={{
                  color: "white",
                  fontSize: 10,
                  textAlign: "center",
                  fontFamily: Fonts.default,
                }}
              >
                Draft
              </Text>
            </View>
          )}
          {item.status == GlobalConstants.OFFER_STATUS.PAYMENT_REFUNDABLE && (
            <View
              style={{
                backgroundColor: "rgba(241,156,56,0.9)",
                paddingHorizontal: 3,
                paddingVertical: 3,
                borderRadius: 5,
              }}
            >
              <Text
                style={{
                  color: "white",
                  fontSize: 10,
                  textAlign: "center",
                  fontFamily: Fonts.default,
                }}
              >
                Refundable
              </Text>
            </View>
          )}
          {item.status == GlobalConstants.OFFER_STATUS.PAYMENT_REFUNDED && (
            <View
              style={{
                backgroundColor: "rgba(241,156,56,0.9)",
                paddingHorizontal: 3,
                paddingVertical: 3,
                borderRadius: 5,
              }}
            >
              <Text
                style={{
                  color: "white",
                  fontSize: 10,
                  textAlign: "center",
                  fontFamily: Fonts.default,
                }}
              >
                Refunded
              </Text>
            </View>
          )}
        </View>
      </View>

      <View
        style={{
          flex: 1,
          justifyContent: "center",
          paddingHorizontal: 10,
        }}
      >
        <View>
          <Text
            numberOfLines={2}
            style={[styles.txtTwo, { fontSize: deviceType ? 20 : wp("4.2%") }]}
          >
            {item?.vehicle?.title}
          </Text>
        </View>
        <View>
          <Text
            numberOfLines={1}
            style={[
              styles.txtThree,
              {
                fontWeight: !item?.conversation?.buyerLastSeen ? "500" : "200",
                fontSize: deviceType ? 20 : wp("3.2%"),
              },
            ]}
          >
            {`${lastMessageIsMine ? "me: " : " "}${
              item?.conversation?.lastMessage
                ? item.conversation?.lastMessage?.body
                : "..."
            }`}
          </Text>
        </View>
      </View>

      <View
        style={{
          height: "100%",
          justifyContent: "center",
          alignItems: "flex-end",
        }}
      >
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <View>
            <View
              style={{
                width: 14,
                height: 14,
                borderRadius: 10,
                backgroundColor: "gray",
              }}
            >
              <Image
                source={{ uri: item?.business?.profilePicture?.avatar?.url }}
                style={{ width: "100%", height: "100%", borderRadius: 10 }}
              />
              {Active ? <View style={styles.activeIndicator} /> : null}
            </View>
          </View>
          <View style={{ paddingLeft: 5, paddingRight: 5 }}>
            <Text
              style={[
                styles.txtFive,
                { fontSize: deviceType ? 14 : wp("2.5%") },
              ]}
            >
              {item?.business?.fullName}
            </Text>
          </View>
        </View>
        <View style={{ marginTop: 5 }}>
          {item?.conversation?.lastMessage ? (
            <Text
              style={[
                styles.txtFour,
                { fontSize: deviceType ? 14 : wp("2.4%") },
              ]}
            >{`${dateToInteligentDate(
              item?.conversation?.lastMessage?.sentTime
            )} ${dateToShortTime(
              item?.conversation?.lastMessage?.sentTime
            )}`}</Text>
          ) : (
            // <Text style={styles.txtFour}>{`${ dateToInteligentDate(item.conversation.lastMessage?.sentTime)} ${dateToShortTime(item.conversation.lastMessage?.sentTime)}`}</Text>:
            <Text style={styles.txtFour}>{`...`}</Text>
          )}
        </View>
      </View>
    </TouchableOpacity>
  );
};

export default MessageItem;

const styles = StyleSheet.create({
  mainContainer: {
    width: "100%",
    borderBottomWidth: 1,
    borderColor: "#e5e5e5",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: 15,
    backgroundColor: Colors.screenBgColor,
  },
  imgView: {
    // width: moderateScale(72, 0.1),
    // height: moderateScale(55, 0.1),
    borderRadius: 11,
    zIndex: 0,
  },
  deleteBtnView: {
    width: moderateScale(30, 0.1),
    height: moderateScale(21, 0.1),
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Colors.pinkPrimary,
    borderRadius: 5,
  },
  editBtnView: {
    width: moderateScale(56, 0.1),
    height: moderateScale(21, 0.1),
    backgroundColor: "#D4E3FA",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    borderRadius: 5,
  },
  txtOne: {
    color: Colors.blueSecondaryOne,
    fontFamily: Fonts.default,
    fontSize: moderateScale(11, 0.1),
  },
  txtTwo: {
    color: "#16478E",
    fontFamily: Fonts.default,

    fontWeight: "700",
  },
  txtThree: {
    color: "#030303",
    fontFamily: Fonts.default,

    fontWeight: "700",
  },
  indicatorView: {
    position: "absolute",
    top: -5,
    right: -5,
    width: 12,
    height: 12,
    borderRadius: 10,
    backgroundColor: "#377AF6",
    zIndex: 1,
  },
  txtFour: {
    fontFamily: Fonts.default,
    color: "#5D5C5D",
  },
  txtFive: {
    fontFamily: Fonts.default,
    color: "#292929",
    fontStyle: "italic",
  },
  activeIndicator: {
    width: 4,
    height: 4,
    borderRadius: 5,
    backgroundColor: "#00D387",
    position: "absolute",
    bottom: 0,
    right: 0,
  },
});
