import React, { useEffect, useRef, useState } from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import BackBtnSvg from "../../Assets/Icons/backBtnSvg";
import AbstractHeaders from "../../Components/AbstractComponents/abstractHeaders";
import { Colors, Fonts, PLATFORM_DETECT } from "../../themes";
import AbstractContentContainer from "../../Components/AbstractComponents/abstractContentContainer";
import AbstractH3 from "../../Components/AbstractComponents/AbstractHeadings/abstractH3";
import AbstractButton from "../../Components/AbstractComponents/abstractButton";
import FocusAwareStatusBar from "../../Components/AbstractComponents/statusbarConfiguration";
import { moderateScale } from "react-native-size-matters";
import CodeVerification from "../../Components/ModuleComponents/codeVerification";
import { goBack, navigate } from "../../Navigation/mainNavigation";
import ScreenContainer from "../../Components/AbstractComponents/abstractScreenContainer";
import AuthController from "../../Controllers/authController";
import { useSelector } from "react-redux";
import alert from "../../Components/AbstractComponents/alert";
import { ActivityIndicator } from "react-native";

const DeleteAccountScreen = (props) => {
  const [otp, setOTP] = useState(""); //("547701"); //
  const { deviceType } = useSelector((state) => state.DeviceTypeReducer);
  const [error, setError] = useState("");
  const [phoneNumber, setPhoneNumber] = useState(
    props.route?.params?.phoneNumber
  );
  const [deleteAccErr, setDeleteAccErr] = useState("");
  const [processing, setProcessing] = useState(false);
  const [loading, setLoading] = useState(true);
  const isSubmittedOnce = useRef(false);
  useEffect(() => {
    setPhoneNumber(props.route?.params?.phoneNumber);
    sendOtp();
  }, [props.route?.params]);
  const sendOtp = () => {
    AuthController.sendOTPForDeleteAccount(props.route?.params?.phoneNumber)
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(err);
      });
  };
  const handleSubmit = () => {
    if (otp.length < 6) {
      setError("OTP is incorrect");
    } else {
      setError("");
      setProcessing(true);
      AuthController.verifyOTPForDeleteAccount(otp, phoneNumber)
        .then(() => {
          setProcessing(false);
          alert(
            "Delete Account",
            "Are you sure to delete this account.",
            [
              {
                text: "ok",

                onPress: () => handleDeleteAccount(),
                style: "destructive",
              },
              { text: "Cancel", onPress: () => goBack() },
            ],
            { cancelable: false }
          );
        })
        .catch((err) => {
          setProcessing(false);
          setError(err);
        });
    }
  };
  const handleDeleteAccount = () => {
    setLoading(true);
    AuthController.deleteAccount()
      .then()
      .catch((err) => {
        // setLoading(false);
        setDeleteAccErr(err);
      });
  };
  useEffect(() => {
    if (otp.length === 6 && !isSubmittedOnce.current) {
      isSubmittedOnce.current = true;
      handleSubmit();
    }
  }, [otp]);

  return (
    <ScreenContainer
      upperStyle={{ backgroundColor: Colors.screenBgColor }}
      bottomStyle={{ backgroundColor: Colors.screenBgColor }}
    >
      <FocusAwareStatusBar
        barStyle={"dark-content"}
        backgroundColor={"transparent"}
        translucent={true}
      />
      <AbstractHeaders
        onPressLeft={() => goBack()}
        leftChild={() => <BackBtnSvg />}
      />
      <View
        style={{
          flex: 1,
          backgroundColor: Colors.screenBgColor,
          paddingTop: deviceType ? PLATFORM_DETECT.SH * 0.1 : 0,
          alignItems: "center",
        }}
      >
        <AbstractContentContainer>
          <View
            style={[
              {
                backgroundColor: Colors.screenBgColor,
                width: deviceType ? "50%" : "100%",
                padding: deviceType ? 50 : 0,
                borderRadius: deviceType ? 10 : 0,
                alignSelf: "center",
              },
              deviceType
                ? {
                    shadowColor: Colors.blueprimary,
                    shadowOffset: {
                      width: 0,
                      height: 4,
                    },
                    shadowOpacity: 0.38,
                    shadowRadius: 12.84,
                    elevation: 2,
                  }
                : {},
            ]}
          >
            <View>
              <AbstractH3 txtColor={Colors.darkBluePrimary}>
                Phone number verification
              </AbstractH3>
              <TouchableOpacity style={{ marginTop: 5 }}>
                <Text style={styles.txtOne}>
                  Please enter the otp you received to verify your phone number.
                </Text>
              </TouchableOpacity>
            </View>

            <View
              style={{
                marginTop: 50,
                height: moderateScale(69, 0.1),
                width: "100%",
              }}
            >
              <CodeVerification
                onChangeText={(value) => setOTP(value)}
                error={error}
              />
            </View>

            <View style={{ marginTop: 30 }}>
              <AbstractButton
                onPress={handleSubmit}
                processing={processing}
                label={"Verify"}
              />
            </View>
          </View>

          <View style={{ alignSelf: "center", marginTop: 10 }}>
            <Text style={styles.errorStyle}>{error}</Text>
          </View>
        </AbstractContentContainer>
        {loading ? (
          <View
            style={{
              ...StyleSheet.absoluteFillObject,
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(0,0,0,0.1)",
            }}
          >
            <ActivityIndicator color={Colors.blueprimary} size={"large"} />
            <Text
              style={{
                color: deleteAccErr ? "red" : Colors.whitePrimary,
                marginTop: 10,
              }}
            >
              {deleteAccErr ? deleteAccErr : "Loading..."}
            </Text>
          </View>
        ) : (
          false
        )}
      </View>
    </ScreenContainer>
  );
};

export default DeleteAccountScreen;

const styles = StyleSheet.create({
  mainContainer: {},

  txtOne: {
    fontSize: moderateScale(12, 0.1),
    fontFamily: Fonts.default,
    color: Colors.darkBluePrimary,
    width: "80%",
    lineHeight: 20,
  },
  textTwo: {
    fontSize: moderateScale(12, 0.1),
    fontFamily: Fonts.default,
    color: Colors.blueprimary,
    textDecorationLine: "underline",
  },
  errorStyle: {
    fontFamily: Fonts.default,
    fontSize: moderateScale(12.5, 0.1),
    color: "red",
    fontWeight: "600",
    paddingLeft: 10,
  },
});
