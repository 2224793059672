import React from "react";
import Svg, { Circle, Path } from "react-native-svg";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";

const DetailsSvg = () => {
  return (
    <Svg
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Circle cx="21" cy="21" r="20" stroke="#2674E6" strokeWidth="2" />
      <Path
        d="M21 18H25C25.55 18 26 17.55 26 17C26 16.45 25.55 16 25 16H21C20.45 16 20 16.45 20 17C20 17.55 20.45 18 21 18ZM21 22H25C25.55 22 26 21.55 26 21C26 20.45 25.55 20 25 20H21C20.45 20 20 20.45 20 21C20 21.55 20.45 22 21 22ZM21 26H25C25.55 26 26 25.55 26 25C26 24.45 25.55 24 25 24H21C20.45 24 20 24.45 20 25C20 25.55 20.45 26 21 26ZM16 16H18V18H16V16ZM16 20H18V22H16V20ZM16 24H18V26H16V24ZM29 12H13C12.45 12 12 12.45 12 13V29C12 29.55 12.45 30 13 30H29C29.55 30 30 29.55 30 29V13C30 12.45 29.55 12 29 12ZM28 28H14V14H28V28Z"
        fill="#2674E6"
      />
    </Svg>
  );
};

export default DetailsSvg;
