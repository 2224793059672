import axios from "axios";
import { ERRORS } from "./errorMessages";
import AsyncStorage from "@react-native-async-storage/async-storage";
import ReduxDispatchController from "./reduxDispatchController";
import {
  navigate,
  clearAndNavigate,
  clearAndNavigateToNestedRote,
  navigateToNestedRoute,
  goBack,
} from "../../src/Navigation/mainNavigation";
import UploadController from "./UploadController";
import store from "../../src/Store";
import { Keyboard } from "react-native";
import MySocketController from "../../src/SocketController";
import {
  ASYNC_STORAGE_TOKEN_KEY,
  notificationListener,
  requestUserPermisssion,
} from "../../src/pushNotificationHelper";
import { Platform } from "react-native";
import { createObjectURL, base64StringToBlob, createBlob } from "blob-util";
import ImageManipulationController from "./UploadController/imageManipultionController";
import uuid from "react-native-uuid";
import { PLATFORM_DETECT } from "../themes";
import { useSelector } from "react-redux";
import alert from "../Components/AbstractComponents/alert";

class AuthController {
  static AUTH_STORAGE_KEY = "inforide@v0.1.0";
  static LOGGEDOUT_FLAG = false;
  static isLinkAvailAble = {
    id: undefined,
    url: undefined,
    availability: undefined,
  };

  static getCurrentUser() {
    return store.getState().AuthReducer.currentUser;
  }

  static setUserAndAuthenticate(user, bearerToken) {
    axios.defaults.headers.common["Authorization"] = bearerToken;
    ReduxDispatchController.AUTH.setCurrentUser(user);
    if (AuthController.isLinkAvailAble.availability) {
      clearAndNavigateToNestedRote("Auction", "CarDetailPlaceBid", {
        auctionVehicleId: AuthController.isLinkAvailAble.id,
      });
    } else {
      clearAndNavigate("Auction");
    }
  }

  static presistAuthDataAndAuthenticate(user, bearerToken) {
    return new Promise((resolve, reject) => {
      AsyncStorage.setItem(
        AuthController.AUTH_STORAGE_KEY,
        JSON.stringify({ user, bearerToken })
      )
        .then((presistedUser) => {
          AuthController.setUserAndAuthenticate(user, bearerToken);
          resolve(presistedUser);
        })
        .catch((err) => {
          console.log(`Catch error in presisting auth data.`);
          console.log(err);
          reject(err);
        });
    });
  }

  static updatePresistedUser(user) {
    return new Promise((resolve, reject) => {
      ReduxDispatchController.AUTH.setCurrentUser(user);
      AsyncStorage.getItem(AuthController.AUTH_STORAGE_KEY)
        .then((presistedAuthStr) => {
          let presistedAuthData = JSON.parse(presistedAuthStr);
          if (presistedAuthData) {
            presistedAuthData.user = { ...user };
            AsyncStorage.setItem(
              AuthController.AUTH_STORAGE_KEY,
              JSON.stringify(presistedAuthData)
            )
              .then((presistedUser) => {
                resolve(presistedUser);
              })
              .catch((err) => {
                console.log(`Catch error in presisting auth data.`);
                console.log(err);
                reject(err);
              });
          } else {
            console.log(`Persisted user not updated.`);
            reject(`Persisted user not found.`);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(err);
        });
    });
  }

  static logOut() {
    AuthController.LOGGEDOUT_FLAG = true;
    axios.defaults.headers.common["Authorization"] = undefined;

    AsyncStorage.removeItem(AuthController.AUTH_STORAGE_KEY);
    clearAndNavigate("Inforide");
  }

  static handleRestoreUserAndAuthenticate(_callback = () => false) {
    console.log(AuthController.isLinkAvailAble);
    if (!AuthController.LOGGEDOUT_FLAG) {
      AsyncStorage.getItem(AuthController.AUTH_STORAGE_KEY)
        .then((presistedAuthStr) => {
          let presistedAuthData = JSON.parse(presistedAuthStr);
          if (presistedAuthData) {
            axios.defaults.headers.common["Authorization"] =
              presistedAuthData.bearerToken;
            ReduxDispatchController.AUTH.setCurrentUser(presistedAuthData.user);
            MySocketController.init();
            if (AuthController.isLinkAvailAble.availability) {
              clearAndNavigateToNestedRote("Auction", "AuctionDetail", {
                auctionId: AuthController.isLinkAvailAble.id,
              });
            }
            _callback(true);
          } else {
            if (AuthController.isLinkAvailAble.availability) {
              navigate("singleAuction", {
                auctionId: AuthController.isLinkAvailAble.id,
              });
            } else {
              clearAndNavigate("Welcome to infoRide");
            }
            _callback();
          }
        })
        .catch((err) => {
          axios.defaults.headers.common["Authorization"] = undefined;
          console.log(err);

          if (AuthController.isLinkAvailAble.availability) {
            navigate("singleAuction", {
              auctionId: AuthController.isLinkAvailAble.id,
            });
          } else {
            clearAndNavigate("Welcome to infoRide");
          }
          _callback();
        });
    } else {
      axios.defaults.headers.common["Authorization"] = undefined;
      setTimeout(() => {
        if (AuthController.isLinkAvailAble.availability) {
          navigate("singleAuction", {
            auctionId: AuthController.isLinkAvailAble.id,
          });
        } else {
          clearAndNavigate("Welcome to infoRide");
        }
        _callback();
      }, 2000);
    }
  }

  static sendOTP(phoneNumber) {
    return new Promise((resolve, reject) => {
      Keyboard.dismiss();
      axios
        .post(`/buyer/auth/signin`, { phoneNumber })
        .then((res) => {
          if (res.data.success) {
            // {data: {token, phoneNumber}, success}
            axios.defaults.headers.common["Authorization"] =
              res.data.data.token;
            navigate("Verification", { phoneNumber });
            resolve(true);
          } else {
            console.log(`Error`, res.data);
            reject(res.data.error.message);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(ERRORS.INTERNET_CONNECTIVITY);
        });
    });
  }
  static varifyOTP(otp, phoneNumber) {
    return new Promise((resolve, reject) => {
      Keyboard.dismiss();
      axios
        .post(`/buyer/auth/verify-otp`, { otp, phoneNumber })
        .then((res) => {
          if (res.data.success) {
            /// TODO: create session
            axios.defaults.headers.common["Authorization"] =
              res.data.data.token;
            if (res.data.data.code === 200) {
              /// TODO: login user=> res.data.data.buyer
              AuthController.presistAuthDataAndAuthenticate(
                res.data.data.buyer,
                res.data.data.token
              )
                .then((userPresisted) => {
                  resolve(true);
                })
                .catch((err) => {
                  AuthController.setUserAndAuthenticate(
                    res.data.data.buyer,
                    res.data.data.token
                  );
                  resolve(true);
                });
            } else {
              navigate("Setup Profile", { phoneNumber });
              resolve(true);
            }
          } else {
            reject(res.data.error.message);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(ERRORS.INTERNET_CONNECTIVITY);
        });
    });
  }
  static sendOTPForDeleteAccount() {
    let currentUser = AuthController.getCurrentUser()._id;

    return new Promise((resolve, reject) => {
      axios
        .get(`/buyer/auth/send-otp-to-archive-buyer/${currentUser}`)
        .then((res) => {
          if (res.data.success) {
            resolve(true);
          } else {
            console.log(`Error`, res.data);
            reject(res.data.error.message);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(ERRORS.INTERNET_CONNECTIVITY);
        });
    });
  }

  static verifyOTPForDeleteAccount(otp) {
    let phoneNumber = AuthController.getCurrentUser().phoneNumber;

    return new Promise((resolve, reject) => {
      Keyboard.dismiss();
      axios
        .post(`/buyer/auth/verify-otp-to-archive-buyer`, { otp, phoneNumber })
        .then((res) => {
          if (res.data.success) {
            resolve(true);
          } else {
            reject(res.data.error.message);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(ERRORS.INTERNET_CONNECTIVITY);
        });
    });
  }

  static deleteAccount() {
    let currentUser = AuthController.getCurrentUser()._id;

    return new Promise((resolve, reject) => {
      axios
        .patch(`/buyer/auth/archive/${currentUser}`)
        .then((res) => {
          console.log(res.data, "res of delete  account");
          if (res.data.success) {
            AuthController.logOut();

            resolve(true);
          } else {
            reject(res.data.error.message);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(ERRORS.INTERNET_CONNECTIVITY);
        });
    });
  }

  static setupProfile(phoneNumber, username, fullName, profileImageSource) {
    return new Promise((resolve, reject) => {
      Keyboard.dismiss();
      UploadController.uploadPicture(profileImageSource)
        .then((uploadedSource) => {
          let data = {
            fullName,
            phoneNumber,
            username,
            ...uploadedSource,
          };

          // console.log("FINAL DATA:", data);
          axios
            .post(`/buyer/auth/signup`, data)
            .then((res) => {
              console.log(res.data, "signUp res");
              if (res.data.success) {
                console.log(res.data, "1");

                AuthController.presistAuthDataAndAuthenticate(
                  res.data.data.buyer,
                  res.data.data.token
                )
                  .then((userPresisted) => {
                    resolve(true);
                    console.log(res.data, "2");
                  })
                  .catch((err) => {
                    console.log(res.data, "3");

                    AuthController.setUserAndAuthenticate(
                      res.data.data.buyer,
                      res.data.data.token
                    );
                    resolve(true);
                  });
              } else {
                console.log(res.data, "4");

                reject(res.data.error.message);
              }
            })
            .catch((err) => {
              console.log(`CATCH error in setup profile5`);
              console.log(err);
              reject(ERRORS.INTERNET_CONNECTIVITY);
            });
        })
        .catch((err) => {
          console.log(`CATCH Error in uploading images6`);
          console.log(err);
        });
    });
  }

  static setupProfile_web(phoneNumber, username, fullName, profileImageSource) {
    return new Promise((resolve, reject) => {
      Keyboard.dismiss();

      let data = {
        fullName,
        phoneNumber,
        username,
      };
      const fileName = uuid.v4();
      ImageManipulationController.ResizeImg(profileImageSource.uri)
        .then((imgs) => {
          const fData = new FormData();
          fData.append("data", JSON.stringify(data));
          if (PLATFORM_DETECT.WEB) {
            fData.append("image", imgs[0], `image_${fileName}.jpeg`);
          } else {
            fData.append("image", {
              uri: imgs[0],
              name: `image_${fileName}.jpeg`,
              type: profileImageSource.type,
            });
          }
          axios({
            method: "post",
            url: `/buyer/auth/signup`,
            data: fData,
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
            .then((res) => {
              console.log(res.data, "???data successfully posted");
              if (res.data.success) {
                AuthController.presistAuthDataAndAuthenticate(
                  res.data.data.buyer,
                  res.data.data.token
                )
                  .then((userPresisted) => {
                    resolve(true);
                  })
                  .catch((err) => {
                    AuthController.setUserAndAuthenticate(
                      res.data.data.buyer,
                      res.data.data.token
                    );
                    resolve(true);
                  });
              } else {
                reject(res.data.error.message);
              }
            })
            .catch((err) => {
              console.log(`CATCH error in setup profile:`, err);
              reject(ERRORS.INTERNET_CONNECTIVITY);
            });
        })
        .catch((err) => {
          console.log("err in catch of resize imgs for signup", err);
          reject(ERRORS.INTERNET_CONNECTIVITY);
        });
    });
  }

  static checkAuthStatus(userId) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/buyer/auth/check-auth-status/${userId}`)
        .then((res) => {
          if (res.data.success) {
            /// TODO: create session with res.data.data
          } else {
            reject(res.data.error.message);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(ERRORS.INTERNET_CONNECTIVITY);
        });
    });
  }
  static addCard(userId) {
    let id = AuthController.getCurrentUser()?._id;

    return new Promise((resolve, reject) => {
      axios
        .post(`/stripe/payment/add-card/${id}`)
        .then((res) => {
          if (res.data.success) {
            resolve(res.data.data);
            /// TODO: create session with res.data.data
          } else {
            reject(res.data.error.message);
          }
        })
        .catch((err) => {
          console.log(err);
          reject(ERRORS.INTERNET_CONNECTIVITY);
        });
    });
  }

  static checkUsernameValidity(username) {
    return new Promise((resolve, reject) => {
      // axios.defaults.headers.common['Authorization'] = "bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI5MjMzMzgzNzc4MjAiLCJpYXQiOjE2NDUxNjc4NjAsImV4cCI6MTY0NTE3MTQ2MH0.s7EY20n9ONDyPRcL4UWq_-J55XiA8Q6FELS3EySxJP0"
      axios
        .get(`/buyer/auth/check-is-username-exist?username=${username}`)
        .then((res) => {
          if (res.data.success) {
            resolve(!res.data.data.isAlreadyExist);
          } else {
            reject(res.data.error.message);
          }
        })
        .catch((err) => {
          reject(ERRORS.INTERNET_CONNECTIVITY);
        });
    });
  }

  static updateProfileInfo_web(fullName, imageSource = undefined) {
    return new Promise((resolve, reject) => {
      let currentUser = AuthController.getCurrentUser();

      if (imageSource) {
        const fileName = uuid.v4();
        ImageManipulationController.ResizeImg(imageSource.uri)
          .then((imgs) => {
            const fData = new FormData();
            fData.append("data", JSON.stringify({ fullName }));
            if (PLATFORM_DETECT.WEB) {
              fData.append("image", imgs[0], `image_${fileName}.jpeg`);
            } else {
              fData.append("image", {
                uri: imgs[0],
                name: `image_${fileName}.jpeg`,
                type: imageSource.type,
              });
            }

            axios({
              method: "patch",
              url: `/buyer/auth/update-profile/${currentUser._id}`,
              data: fData,
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
              .then((res) => {
                if (res.data.success) {
                  AuthController.updatePresistedUser(res.data.data)
                    .then((presistedUserUpdated) => {
                      resolve(true);
                    })
                    .catch((err) => {
                      reject(err);
                    });
                } else {
                  reject(res.data.error.message);
                }
              })
              .catch((err) => {
                console.error(err?.response?.data); // NOTE - use "error.response.data` (not "error")

                console.log("err in catch of axios", err);
                reject(ERRORS.INTERNET_CONNECTIVITY);
              });
          })
          .catch((err) => {
            console.log("err in catch of resize imgs", err);

            reject(ERRORS.INTERNET_CONNECTIVITY);
          });
      } else {
        const fData = new FormData();
        fData.append("data", JSON.stringify({ fullName }));

        // axios({
        //   method: "patch",
        //   url: `/buyer/auth/update-profile/${currentUser._id}`,
        //   data: fData,
        //   headers: {
        //     "Content-Type": "multipart/form-data",
        //   },
        // })
        axios({
          method: "patch",
          url: `/buyer/auth/update-profile/${currentUser._id}`,
          data: fData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then((res) => {
            if (res.data.success) {
              AuthController.updatePresistedUser(res.data.data)
                .then((presistedUserUpdated) => {
                  resolve(true);
                })
                .catch((err) => {
                  reject(err);
                });
            } else {
              // console.log(res.data, "???LLL");
              reject(res.data?.error?.message);
            }
          })
          .catch((err) => {
            console.error(err, "Error in updating profile");
            reject(ERRORS.INTERNET_CONNECTIVITY);
          });
      }
    });
  }

  static updateMeta = (stripe_id) => {
    return new Promise((resolve, reject) => {
      const { _id } = AuthController.getCurrentUser();

      const bodyObject = {
        paymentMeta: {
          stripeAccountId: stripe_id,
        },
      };

      axios
        .patch(`/buyer/auth/update-payment-meta/${_id}`, bodyObject)
        .then((res) => {
          if (res.data.success) {
            AuthController.updatePresistedUser(res.data.data)
              .then((presistedUserUpdated) => {
                resolve("updateData");
              })
              .catch((err) => {
                reject(err);
              });
          } else {
            reject(res.data.error.message);
          }
        })
        .catch((err) => {
          reject(ERRORS.INTERNET_CONNECTIVITY);
        });
    });
  };

  static deviceTokenUpdateInDatabase(token) {
    return new Promise((resolve, reject) => {
      let currentUser = AuthController.getCurrentUser();

      const bodyObject = {
        deviceType: `${Platform.OS}`,
        value: `${token}`,
      };

      axios
        .post(`/buyer/auth/notify-tokens/${currentUser._id}`, bodyObject)
        .then((res) => {
          if (res.data.success) {
            resolve(res.data.data);
          } else {
            reject(res.data.error.message);
          }
        })
        .catch((err) => {
          reject(ERRORS.INTERNET_CONNECTIVITY);
        });
    });
  }

  static fcmTokenSetInAsyncStorageAndDB(token) {
    return new Promise((resolve, reject) => {
      AuthController.deviceTokenUpdateInDatabase(token)
        .then((response) => {
          // console.log(response, "Api Response");
          AsyncStorage.setItem(ASYNC_STORAGE_TOKEN_KEY, JSON.stringify(token))
            .then((result) => {
              resolve(result);
            })
            .catch((err) => {
              console.log("error", err);
              reject(err);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    });
  }

  static fcmTokenGetFromAsyncStorage() {
    return new Promise((resolve, reject) => {
      AsyncStorage.getItem(ASYNC_STORAGE_TOKEN_KEY)
        .then((result) => {
          let token = JSON.parse(result);
          resolve(token);
        })
        .catch((error) => {
          console.log(`ERROR:`, error);
          reject(error);
        });
    });
  }

  static fcmTokenHandler() {
    AuthController.fcmTokenGetFromAsyncStorage()
      .then((result) => {
        console.log(
          result == null
            ? "token doesnot exist in the Asyncstorage"
            : "token exists in the Asyncstorage"
        );
        // if (result == null) {
        //   // requestUserPermisssion()
        //     .then(() => {
        //       notificationListener();
        //     })
        //     .catch((error) => {
        //       console.log("PermissionError", error);
        //     });
        // } else {
        console.log(result, "token in the AsyncStorage");
        // }
      })
      .catch((error) => {
        console.log(`ERROR:`, error);
      });
  }

  static fcmTokenDestroyFromAsyncStorage() {
    AsyncStorage.removeItem(ASYNC_STORAGE_TOKEN_KEY)
      .then((result) => {
        console.log("fcmTokenDestroyFromAsyncStorage");
      })
      .catch((error) => {
        console.log(`ERROR:`, error);
      });
  }
}
export default AuthController;

export const useUserName = () => {
  const { currentUser } = useSelector((state) => state.AuthReducer);
  return currentUser;
};
