import React, { useState } from "react";
import { View, Animated, StyleSheet } from "react-native";
import { WIDTH, GEAR_WIDTH } from "./configs";
import SideSwipe from "react-native-sideswipe";
import AuctionCardItem, {
  CARD_LOWER_PART_HEIGHT,
  CARD_UPPER_PART_HEIGHT,
} from "./auctionCardItem";
import { TouchableOpacity } from "react-native-gesture-handler";
import { PLATFORM_DETECT } from "../../../themes";
import { useSelector } from "react-redux";

const WIDTH_FOR_CARD_SWIPE_CARD = 300;

const GearItem = ({
  index,
  animatedValue,
  item,
  onPress,
  suggestionCard,
  currentIndex,
  deviceType,
}) => {
  const myIndex = index;
  const animatedScale = animatedValue.interpolate({
    inputRange: [myIndex - 1, myIndex, myIndex + 1],
    outputRange: [0.85, 0.9, 0.85],
    extrapolate: "clamp",
  });
  const animatedOpacity = animatedValue.interpolate({
    inputRange: [
      myIndex - 3,
      myIndex - 2,
      myIndex - 1,
      myIndex,
      myIndex + 1,
      myIndex + 2,
      myIndex + 3,
    ],
    outputRange: [0.5, 0.5, 0.5, 1, 0.5, 0.5, 0.5],
    extrapolate: "clamp",
  });

  return (
    <Animated.View
      style={[
        {
          opacity: animatedOpacity,
          transform: [{ scale: animatedScale }],
          width: WIDTH_FOR_CARD_SWIPE_CARD,
          height:
            PLATFORM_DETECT.WEB && !deviceType
              ? 315
              : PLATFORM_DETECT.WEB
              ? 376
              : CARD_UPPER_PART_HEIGHT + CARD_LOWER_PART_HEIGHT,
          marginVertical: 3,
        },
      ]}
    >
      {PLATFORM_DETECT.WEB ? (
        <TouchableOpacity
          style={{
            width: WIDTH_FOR_CARD_SWIPE_CARD,
            height:
              PLATFORM_DETECT.WEB && !deviceType
                ? 315
                : PLATFORM_DETECT.WEB
                ? 376
                : CARD_UPPER_PART_HEIGHT + CARD_LOWER_PART_HEIGHT,
          }}
          disabled={currentIndex !== index ? false : true}
          onPress={onPress}
        >
          <AuctionCardItem
            suggestionCard={suggestionCard}
            item={item}
            disabledBtn={currentIndex == index ? false : true}
          />
        </TouchableOpacity>
      ) : (
        <View
          style={{
            width: WIDTH_FOR_CARD_SWIPE_CARD,
            height:
              PLATFORM_DETECT.WEB && !deviceType
                ? 315
                : PLATFORM_DETECT.WEB
                ? 376
                : CARD_UPPER_PART_HEIGHT + CARD_LOWER_PART_HEIGHT,
          }}
          disabled={currentIndex !== index ? false : true}
          onPress={onPress}
        >
          <AuctionCardItem
            suggestionCard={suggestionCard}
            item={item}
            disabledBtn={currentIndex == index ? false : true}
          />
        </View>
      )}
    </Animated.View>
  );
};

GearItem.WIDTH = GEAR_WIDTH;

const CardSwipe = (props) => {
  const contentOffsetNew = (WIDTH - WIDTH_FOR_CARD_SWIPE_CARD) / 2;
  const [currentIndex, setCurrentIndex] = useState(0);
  const { deviceType } = useSelector((state) => state.DeviceTypeReducer);

  const shouldSwipe = ({ dx, dy }) => {
    return dx < -30 || dx > 30;
  };

  return (
    <SideSwipe
      index={currentIndex ? currentIndex : 0}
      itemWidth={WIDTH_FOR_CARD_SWIPE_CARD}
      data={props.data}
      contentOffset={contentOffsetNew}
      threshold={0}
      extractKey={(item, index) => `${item._id},-${index}`}
      shouldRelease={(state) => false}
      useVelocityForIndex={false}
      useNativeDriver={true}
      shouldCapture={(gestureState) => shouldSwipe(gestureState)}
      onEndReached={props.loadMore ? props.loadMore : () => false}
      onEndReachedThreshold={0.1}
      renderItem={({ itemIndex, currentIndex, item, animatedValue }) => (
        <GearItem
          onPress={() => {
            setCurrentIndex(itemIndex);
          }}
          suggestionCard={props.suggestionCard}
          item={item}
          index={itemIndex}
          currentIndex={currentIndex ? currentIndex : 0}
          animatedValue={animatedValue}
          deviceType={deviceType}
        />
      )}
    />
  );
};
export default CardSwipe;

const styles = StyleSheet.create({
  gearPinContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 2,
  },
});
