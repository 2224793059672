import { StyleSheet, Text, View } from "react-native";
import React from "react";
import CarSvg from "./carSvg";

const NoCarAvailableSvg = ({ label }) => {
  const myColor = "grey";

  return (
    <View
      style={{
        width: 150,
        height: 130,
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center",
      }}
    >
      <CarSvg color={myColor} />
      <View style={{ width: "100%", alignItems: "center" }}>
        <Text style={[styles.textOne, { color: "lightgrey" }]}>
          {label ? label : "Nothing found..."}
        </Text>
      </View>
    </View>
  );
};

export default NoCarAvailableSvg;

const styles = StyleSheet.create({
  textOne: {
    fontFamily: "Roboto-Regular",
    fontSize: 18,
  },
});
