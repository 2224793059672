import produce from "immer";
import { WALLET } from "../types";

const state = {
    currentBalance: 0,
    comission: 0,
    process: {
        message: "", 
        processing: false,
    }
}

const WalletReducer = (mState = {...state}, action) => {
    switch(action.type){
        case WALLET.SET_KEY_VALUE:
            return produce(mState, draftState => {
                draftState[action.payload.key] = action.payload.value;
            })
        case WALLET.ADD_FUNDS:
            return produce(mState, draftState => {
                draftState.currentBalance += parseFloat(action.payload);
            })
        case WALLET.REMOVE_FUNDS:
            return produce(mState, draftState => {
                draftState.currentBalance -= parseFloat(action.payload);
            })
        case WALLET.SET_WALLET_SUMMERY:
            return produce(mState, draftState => {
                draftState.currentBalance = action.payload.currentBalance;
                draftState.comission = action.payload.comission;
            })
        default:
            return {...mState};
    }
}
export default WalletReducer;