import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  TextInput,
  TouchableOpacity,
  ActivityIndicator,
  Platform,
} from "react-native";
import { moderateScale } from "react-native-size-matters";
import { Colors, Fonts, PLATFORM_DETECT } from "../../themes";
import ClearTextSvg from "../../Assets/Icons/TextInputSvgs/clearTextSvg";
import useIsMobileBrowser from "../../../src/Utils/useIsMobileBrowser";
import { useSelector } from "react-redux";

const AbstractTextInput = React.forwardRef((props, ref) => {
  const defplaceHolder = props.placeHolder ? props.placeHolder : "Text";
  const defLabel = props.label ? props.label : "TextHere";
  const [focus, setFocus] = useState(false);
  //   const { deviceType } = useSelector((state) => state.DeviceTypeReducer);;
  const { deviceType } = useSelector((state) => state.DeviceTypeReducer);

  const _clearingField = () => {
    props.onChangeText("");
  };

  return (
    <React.Fragment>
      <View
        style={[
          styles.mainContainer,
          { width: deviceType ? (props.width ? props.width : "50%") : "100%" },
        ]}
      >
        <View style={styles.viewOne}>
          <Text
            style={[
              styles.txtOne,
              {
                color: focus
                  ? Colors.darkBlueSecondary
                  : Colors.darkBluePrimary,
              },
            ]}
          >
            {defLabel}
          </Text>
        </View>
        <View
          style={[
            styles.viewTwo,
            {
              backgroundColor: props.value ? Colors.screenBgColor : "white",
              borderColor: focus ? "#2674E5" : Colors.txtInputBorderColor,
            },
          ]}
        >
          <View style={styles.viewTwoA}>
            <TextInput
              onSubmitEditing={props.nextTextinputref}
              ref={ref}
              onFocus={() => setFocus(true)}
              blurOnSubmit={true}
              onBlur={(e) => {
                setFocus(false);
                if (props.onBlur) props.onBlur(e);
              }}
              autoCapitalize="none"
              keyboardType={props.keyBorType}
              placeholder={defplaceHolder}
              selectionColor={"#2674E5"}
              placeholderTextColor={Colors.greyPrimary}
              style={
                Platform.OS === "web"
                  ? {
                      outline: 0,
                      color: focus
                        ? Colors.darkBlueSecondary
                        : Colors.darkBlueSecondary,
                      paddingLeft: 15,
                      fontSize: moderateScale(15, 0.1),
                      fontFamily: Fonts.default,
                      height: "100%",
                      width: "100%",
                    }
                  : {
                      color: focus
                        ? Colors.darkBlueSecondary
                        : Colors.darkBlueSecondary,
                      paddingLeft: 15,
                      fontSize: moderateScale(15, 0.1),
                      fontFamily: Fonts.default,
                      height: "100%",
                      width: "100%",
                    }
              }
              value={props.value}
              onChangeText={props.onChangeText}
            />
          </View>
          <TouchableOpacity onPress={_clearingField} style={styles.viewTwoB}>
            {props.processing ? (
              <ActivityIndicator color={Colors.blueprimary} />
            ) : props.value != "" ? (
              <ClearTextSvg />
            ) : null}
          </TouchableOpacity>
        </View>
        {props.overrideErrorMessage ? (
          <Text style={[styles.errorStyle]}>{props.overrideErrorMessage}</Text>
        ) : (
          props.errors &&
          props.touched && (
            <Text style={[styles.errorStyle]}>{props.errors}</Text>
          )
        )}
      </View>
    </React.Fragment>
  );
});

export default AbstractTextInput;

const styles = StyleSheet.create({
  mainContainer: {
    height: moderateScale(92, 0.1),
  },
  viewOne: {
    width: "100%",
    height: moderateScale(25, 0.1),
  },
  viewTwo: {
    width: "100%",
    height: moderateScale(48, 0.1),
    borderRadius: 8,
    borderWidth: 1,
    borderColor: Colors.txtInputBorderColor,
    flexDirection: "row",
  },
  viewTwoA: {
    width: "90%",
    height: "100%",
  },
  viewTwoB: {
    width: "10%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  errorStyle: {
    fontFamily: Fonts.default,
    fontSize: moderateScale(12.5, 0.1),
    color: "red",
    fontWeight: "600",
    paddingLeft: 10,
  },
  txtOne: {
    fontSize: moderateScale(13, 0.1),
    fontFamily: Fonts.bold,
    fontWeight: "900",
  },
});
