import React, { useEffect, useRef, useState } from "react";
import {
  StyleSheet,
  Text,
  View,
  FlatList,
  RefreshControl,
  ActivityIndicator,
  Platform,
  ScrollView,
} from "react-native";
import { moderateScale } from "react-native-size-matters";
import AbstractContentContainer from "../../Components/AbstractComponents/abstractContentContainer";
import AbstractHeaders from "../../Components/AbstractComponents/abstractHeaders";
import FocusAwareStatusBar from "../../Components/AbstractComponents/statusbarConfiguration";
import SavedItem from "../../Components/ModuleComponents/savedItem";
import { Colors, Fonts, PLATFORM_DETECT } from "../../themes";
import ScreenContainer from "../../Components/AbstractComponents/abstractScreenContainer";
import OffersController from "../../Controllers/offersController";
import { useSelector } from "react-redux";
import NoResultSvg from "../../Assets/Icons/noResultSvg";
import { Root } from "react-native-popup-confirm-toast";
import SavedItemPlaceholder from "../../Components/Placeholders/savedItemPlaceholder";
import SavedItemPlaceholderMobile from "../../Components/Placeholders/savedItemPlaceholderMobile";
import NoCarAvailableSvg from "../../Assets/Icons/noCarAvailableSvg";
import SubmitOfferStepsSheet from "../../Components/CompositeComponents/submitOfferStepsSheetV3";
import BuyNowSheet from "../../Components/ModuleComponents/BottomSheets/buyNowSheet";
import { SheetManager } from "react-native-actions-sheet";
import ReduxDispatchController from "../../Controllers/reduxDispatchController";

const OffersPlaceholder = () => {
  const { deviceType } = useSelector((state) => state.DeviceTypeReducer);
  return (
    <AbstractContentContainer>
      <ScrollView showsVerticalScrollIndicator={false}>
        {!deviceType ? (
          <>
            <SavedItemPlaceholderMobile />
            <SavedItemPlaceholderMobile />
            <SavedItemPlaceholderMobile />
            <SavedItemPlaceholderMobile />
            <SavedItemPlaceholderMobile />
            <SavedItemPlaceholderMobile />
            <SavedItemPlaceholderMobile />
            <SavedItemPlaceholderMobile />
            <SavedItemPlaceholderMobile />
          </>
        ) : (
          <>
            <SavedItemPlaceholder />
            <SavedItemPlaceholder />
            <SavedItemPlaceholder />
            <SavedItemPlaceholder />
            <SavedItemPlaceholder />
            <SavedItemPlaceholder />
            <SavedItemPlaceholder />
            <SavedItemPlaceholder />
            <SavedItemPlaceholder />
            <SavedItemPlaceholder />
            <SavedItemPlaceholder />
          </>
        )}
      </ScrollView>
    </AbstractContentContainer>
  );
};
const BUY_NOW_SHEET = "BUY_NOW_SHEET-1";

const SavedScreen = (props) => {
  const [loadedFirstTime, setLoadedFirstTime] = useState(true);
  const [totalNoPage, setTotalNoPage] = useState(undefined);
  const [currentPage, setCurrentPage] = useState(0);
  // const [offers, setOffers] = useState([]);
  const [singleOffer, setSingleOffer] = useState(undefined);

  const { loading, offers } = useSelector((state) => ({
    loading: state.OffersReducer.offersLoading,
    offers: state.OffersReducer.offers,
  }));
  const loadFirstTime = () => {
    setCurrentPage(0);
    ReduxDispatchController.OFFERS.setOffersLoading(true);

    OffersController.getAllOffers(0)
      .then((res) => {
        if (res?.offers) {
          ReduxDispatchController.OFFERS.setAll(res.offers);
          setTotalNoPage(res.noOfPages);
        } else {
          ReduxDispatchController.OFFERS.setAll(res);
        }
        setLoadedFirstTime(false);
        ReduxDispatchController.OFFERS.setOffersLoading(false);
      })
      .catch((err) => {
        console.log("error in loading auction first time:", err);
        setLoadedFirstTime(false);
        ReduxDispatchController.OFFERS.setOffersLoading(false);
      });
  };
  useEffect(() => {
    setTimeout(() => {
      loadFirstTime();
    }, 300);
  }, []);
  const loadMore = () => {
    if (currentPage < totalNoPage - 1) {
      ReduxDispatchController.OFFERS.setOffersLoading(true);
      let temp = currentPage + 1;
      setCurrentPage((current) => current + 1);
      OffersController.getAllOffers(temp)
        .then((res) => {
          if (res?.offers) {
            ReduxDispatchController.OFFERS.setAll([...offers, ...res.offers]);
            setTotalNoPage(res.noOfPages);
          } else {
            ReduxDispatchController.OFFERS.setAll([...offers, ...res]);
          }
          ReduxDispatchController.OFFERS.setOffersLoading(false);
        })
        .catch((err) => {
          console.log("error in loading auction with loadMore", err);
          ReduxDispatchController.OFFERS.setOffersLoading(false);
        });
    }
  };

  const handleSendOffer = (offer) => {
    setSingleOffer(offer);
    if (offer.offerType == "buyNow") {
      SheetManager.show(BUY_NOW_SHEET);
    } else {
      SheetManager.show("Car-Detail-Sheet-v3");
    }
  };

  return (
    <ScreenContainer
      upperStyle={{ backgroundColor: Colors.screenBgColor }}
      bottomStyle={{ backgroundColor: Colors.screenBgColor }}
    >
      <Root>
        <View style={styles.mainContainer}>
          <FocusAwareStatusBar
            barStyle="dark-content"
            backgroundColor={"transparent"}
            translucent={true}
          />
          <AbstractContentContainer>
            <AbstractHeaders
              middleChild={() => (
                <Text
                  // onPress={() =>
                  //   ReduxDispatchController.OFFERS.updateAllBuyNowOfferStatus(
                  //     "63dc006e1fb3daefd35cc21a"
                  //   )
                  // }
                  style={styles.textOne}
                >
                  Offers
                </Text>
              )}
            />
          </AbstractContentContainer>

          {loadedFirstTime ? (
            <OffersPlaceholder />
          ) : (
            <FlatList
              style={{
                width: PLATFORM_DETECT.WEB ? "95%" : "90%",
                alignSelf: "center",
                paddingLeft: 1,
                paddingRight: 1,
              }}
              refreshControl={
                <RefreshControl
                  refreshing={loading}
                  onRefresh={loadFirstTime}
                />
              }
              showsVerticalScrollIndicator={false}
              data={offers}
              contentContainerStyle={{ paddingBottom: 200 }}
              ListEmptyComponent={() => (
                <View
                  style={{
                    marginTop: 100,
                  }}
                >
                  <NoCarAvailableSvg label={"No offers!"} />
                </View>
              )}
              renderItem={({ item, index }) => (
                <SavedItem
                  key={index}
                  offer={item}
                  setSingleOffer={(offer) => handleSendOffer(offer)}
                />
              )}
              onEndReached={loadMore}
              onEndReachedThreshold={0.1}
              keyExtractor={(item) => item._id}
            />
          )}
          <View
            style={{
              height:
                Platform.OS === "android"
                  ? moderateScale(78, 0.1)
                  : moderateScale(65, 0.1),
            }}
          />
        </View>
        <BuyNowSheet
          id={BUY_NOW_SHEET}
          offer={singleOffer}
          vehicle={singleOffer?.vehicle}
          buyNowPrice={singleOffer?.amount}
          isCreateMode={false}
          fromInitializeMessageFlow={true}
          onClose={() => SheetManager.hide(BUY_NOW_SHEET)}
        />
        <SubmitOfferStepsSheet
          offer={singleOffer}
          vehicle={singleOffer?.vehicle}
          fromInitializeMessageFlow={true}
          onClose={() => SheetManager.hide("Car-Detail-Sheet-v3")}
        />
      </Root>
    </ScreenContainer>
  );
};

export default SavedScreen;

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: Colors.screenBgColor,
  },
  textOne: {
    fontSize: moderateScale(19, 0.1),
    color: "black",
    fontFamily: Fonts.bold,
    fontWeight: "600",
  },
  textThree: {
    fontFamily: Fonts.bold,
    color: Colors.blackPrimary,
    fontSize: moderateScale(19, 0.1),
    paddingBottom: 10,
  },
});
