import { Alert, StyleSheet, View } from "react-native";
import React from "react";
import OffersController from "../../Controllers/offersController";
import ActionSheet, { SheetManager } from "react-native-actions-sheet";
import { Colors } from "../../themes";
import BidInfoSheet from "../ModuleComponents/BottomSheets/bidInfoSheet";
import BidInfoSheetV2 from "../ModuleComponents/BottomSheets/bidInfoSheetV2";
import AuthController from "../../Controllers/authController";

class SubmitBitStepsSheet extends React.Component {
  render() {
    return (
      <ActionSheet
        id="Car-Detail-PLace-Bid-Sheet"
        initialOffsetFromBottom={1}
        statusBarTranslucent={true}
        delayActionSheetDrawTime={0}
        openAnimationSpeed={1}
        bounceOnOpen={true}
        containerStyle={{
          backgroundColor: Colors.blueSecondary,
          borderRadius: 28,
        }}
        bounciness={2}
        indicatorStyle={{ width: 70, height: 4 }}
        gestureEnabled={true}
        overlayColor={"grey"}
        defaultOverlayOpacity={0.4}
      >
        <View style={{ height: 10 }} />
        <BidInfoSheetV2
          singleAuction={this.props?.singleAuction}
          initialPriceRange={this.props.initialPriceRange}
          close={() => SheetManager.hide("Car-Detail-PLace-Bid-Sheet")}
          lastBidAmount={this.props.lastBidAmount}
          setMyBidAmount={this.props.setMyBidAmount}
          setInitialPriceRange={this.props.setInitialPriceRange}
          bidGap={this.props.bidGap}
        />
      </ActionSheet>
    );
  }
}

export default SubmitBitStepsSheet;

const styles = StyleSheet.create({
  headerViewOne: {
    width: "90%",
    height: 50,
    position: "absolute",
    top: -65,
    alignSelf: "center",
    backgroundColor: "rgba(0, 10, 26, 0.9)",
    justifyContent: "flex-end",
    borderTopLeftRadius: 29,
    borderTopRightRadius: 29,
  },
  headerViewTwo: {
    width: "100%",
    backgroundColor: "rgba(0, 10, 26, 1)",
    height: 35,
    position: "absolute",
    top: -35,
    zIndex: 999,
    borderTopLeftRadius: 28,
    borderTopRightRadius: 28,
  },
});
