import { useStripe, useElements } from "@stripe/react-stripe-js";
import { PaymentElement } from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import { Alert, View } from "react-native";
import { Text } from "react-native";
import { useUserName } from "../../../../Controllers/authController";
import AbstractButton from "../../../AbstractComponents/abstractButton";

export default function WebStripeComponent({ onClose, onSuccess, intent }) {
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const { fullName, phoneNumber } = useUserName();

  useEffect(() => {
    if (!stripe) {
      // console.log("stripe is not ready");
      return;
    }
    if (!intent) {
      // console.log("stripe intent is not ready", intent);
      return;
    }
    if (isLoading) {
      stripe
        .retrieveSetupIntent(intent.setupIntent)
        .then((res) => {
          console.log(res, "payment stripe...............");
          switch (res?.paymentIntent.status) {
            case "succeeded":
              setMessage("Payment succeeded!");
              break;
            case "processing":
              setMessage("Your payment is processing.");
              break;
            case "requires_payment_method":
              setMessage("Your payment was not successful, please try again.");
              break;
            default:
              setMessage("Something went wrong.");
              break;
          }
        })
        .catch((error) => console.log(error, "Error in retrieving intent"));
    }
  }, [stripe]);

  const stripeHandleSubmit = async () => {
    if (!stripe || !elements) {
      // console.log("stripe and element is not ready", stripe, elements);
      return;
    }
    setIsLoading(true);
    setMessage("");
    return new Promise((resolve, reject) => {
      // console.log(intent, "??????{{{");
      stripe
        .confirmSetup({
          elements,
          redirect: "if_required",
          payment_method: {
            billing_details: {
              name: fullName,
            },
          },
        })
        .then((res) => {
          if (res.error) {
            reject(res.error);
          } else {
            resolve(res);
          }
        })
        .catch((err) => reject(err));
    });
  };

  const handleAddCard = () => {
    stripeHandleSubmit()
      .then((result) => {
        // console.log("payment success:", result);
        setIsLoading(false);
        Alert.alert("Success", "Payment processed.!");
        onSuccess();
      })
      .catch((error) => {
        console.log("payment Failed:", error);
        if (error.type === "card_error" || error.type === "validation_error") {
          setMessage(error.message);
        } else {
          setMessage("An unexpected error occurred.");
        }
        setIsLoading(false);
      });
  };

  return (
    <>
      <PaymentElement />
      <View style={{ height: 20, width: "100%" }} />
      <AbstractButton
        width={"100%"}
        processing={isLoading}
        label={`Add`}
        onPress={handleAddCard}
      />
      <Text
        style={{
          color: "red",
          marginVertical: 5,
          width: "100%",
          alignSelf: "center",
        }}
      >
        {message}
      </Text>
    </>
  );
}
