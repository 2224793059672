import * as yup from "yup";

let createAccount = yup.object().shape({
  userName: yup
    .string()
    .min(1, ({ min }) => `*UserName field should be filled`)
    .required(`*UserName is required`),
  fullName: yup
    .string()
    .min(1, ({ min }) => `*FullName field should be filled`)
    .required(`*FullName is required`),
  termsAccepted: yup.bool().oneOf([true], "*Accept Terms & Conditions"),
});

export default createAccount;
