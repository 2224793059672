import React from "react";
import Svg, { Path } from "react-native-svg";

const ImagePlaceholderSvg = ({ height, width }) => {
  const defHeight = height ? height : 100;
  const defWidth = width ? width : 100;

  return (
    <Svg
      width="45"
      height="57"
      viewBox="0 0 45 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path
        d="M16.8227 9.71992C16.8227 10.8104 16.3895 11.8561 15.6184 12.6272C14.8474 13.3983 13.8016 13.8314 12.7111 13.8314C11.6207 13.8314 10.5749 13.3983 9.80385 12.6272C9.03279 11.8561 8.59961 10.8104 8.59961 9.71992C8.59961 8.62948 9.03279 7.5837 9.80385 6.81264C10.5749 6.04158 11.6207 5.6084 12.7111 5.6084C13.8016 5.6084 14.8474 6.04158 15.6184 6.81264C16.3895 7.5837 16.8227 8.62948 16.8227 9.71992V9.71992Z"
        fill="#C9C9C9"
      />
      <Path
        d="M4.48262 0.126465C3.39217 0.126465 2.34639 0.559642 1.57533 1.3307C0.804271 2.10176 0.371094 3.14754 0.371094 4.23799L0.371094 28.9071C0.371094 29.9976 0.804271 31.0434 1.57533 31.8144C2.34639 32.5855 3.39217 33.0187 4.48262 33.0187H40.1158C41.2063 33.0187 42.252 32.5855 43.0231 31.8144C43.7942 31.0434 44.2273 29.9976 44.2273 28.9071V4.23799C44.2273 3.14754 43.7942 2.10176 43.0231 1.3307C42.252 0.559642 41.2063 0.126465 40.1158 0.126465H4.48262ZM40.1158 2.86748C40.4793 2.86748 40.8279 3.01187 41.0849 3.26889C41.3419 3.52591 41.4863 3.87451 41.4863 4.23799V20.6841L31.139 15.3473C30.8819 15.2186 30.5909 15.1739 30.3071 15.2196C30.0233 15.2654 29.761 15.3992 29.5574 15.6022L19.3883 25.7714L12.0972 20.9143C11.8339 20.7391 11.5182 20.6602 11.2034 20.6912C10.8887 20.7222 10.5944 20.8611 10.3703 21.0843L3.11759 27.5366V29.0168C3.11431 28.9803 3.11249 28.9437 3.11211 28.9071V4.23799C3.11211 3.87451 3.2565 3.52591 3.51352 3.26889C3.77054 3.01187 4.11914 2.86748 4.48262 2.86748H40.1158Z"
        fill="#C9C9C9"
      />
      <Path
        d="M10.0545 43.735V55.3713H7.94656V43.735H10.0545ZM12.3372 43.735H14.9167L18.3713 52.1668H18.5076L21.9622 43.735H24.5417V55.3713H22.519V47.377H22.411L19.1951 55.3372H17.6838L14.4679 47.36H14.3599V55.3713H12.3372V43.735ZM34.6326 47.4509C34.5379 47.1441 34.4073 46.8694 34.2406 46.627C34.0777 46.3808 33.8807 46.1706 33.6497 45.9963C33.4224 45.8221 33.161 45.6914 32.8656 45.6043C32.5701 45.5134 32.2482 45.4679 31.8997 45.4679C31.2747 45.4679 30.7179 45.6251 30.2292 45.9395C29.7406 46.2539 29.3561 46.716 29.0758 47.3259C28.7993 47.9319 28.661 48.6706 28.661 49.5418C28.661 50.4206 28.7993 51.1649 29.0758 51.7747C29.3523 52.3846 29.7368 52.8486 30.2292 53.1668C30.7217 53.4812 31.2936 53.6384 31.9451 53.6384C32.536 53.6384 33.0474 53.5247 33.4792 53.2975C33.9148 53.0702 34.2501 52.7482 34.4849 52.3316C34.7198 51.9111 34.8372 51.4187 34.8372 50.8543L35.3145 50.9282H32.1554V49.2804H36.877V50.6782C36.877 51.6744 36.6648 52.5361 36.2406 53.2634C35.8163 53.9907 35.233 54.5513 34.4906 54.9452C33.7482 55.3354 32.8959 55.5304 31.9338 55.5304C30.8618 55.5304 29.9205 55.2899 29.1099 54.8088C28.3031 54.324 27.6724 53.6365 27.2179 52.7463C26.7671 51.8524 26.5417 50.7918 26.5417 49.5645C26.5417 48.6251 26.6743 47.7861 26.9395 47.0475C27.2084 46.3088 27.5834 45.6819 28.0645 45.1668C28.5455 44.6479 29.1099 44.2539 29.7576 43.985C30.4054 43.7122 31.1099 43.5759 31.8713 43.5759C32.5152 43.5759 33.1156 43.6706 33.6724 43.86C34.2292 44.0456 34.7235 44.3107 35.1554 44.6554C35.591 45.0001 35.9489 45.4092 36.2292 45.8827C36.5095 46.3562 36.6932 46.8789 36.7804 47.4509H34.6326Z"
        fill="#C9C9C9"
      />
    </Svg>
  );
};

export default ImagePlaceholderSvg;
