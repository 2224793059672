import AsyncStorage from "@react-native-async-storage/async-storage";
// import messaging from '@react-native-firebase/messaging';
import AuthController from "./Controllers/authController";

export const ASYNC_STORAGE_TOKEN_KEY = "asyncStorageKey";

export const requestUserPermisssion = () => {
  return new Promise((resolve, reject) => {
    messaging()
      .requestPermission()
      .then((authorizationStatus) => {
        const enabled =
          authorizationStatus === messaging.AuthorizationStatus.AUTHORIZED ||
          authorizationStatus === messaging.AuthorizationStatus.PROVISIONAL;

        if (enabled) {
          resolve("Permission Granted and Generating Token");
          generateFCMTokenAndSync();
        }
      })
      .then((error) => {
        reject(error);
      });
  });
};

export const generateFCMTokenAndSync = () => {
  messaging()
    .getToken()
    .then((token) => {
      console.log("fcmToken generated ===>", token);
      AuthController.fcmTokenSetInAsyncStorageAndDB(token)
        .then((result) => {
          console.log("fcmToken Saved In Database And AsyncStorage");
        })
        .catch((error) => {
          console.log("Error", error);
        });
    })
    .catch((error) => {
      console.log(error, "Token generation Error");
    });
};

export const notificationListener = () => {
  messaging().onNotificationOpenedApp((remoteMessage) => {
    console.log(
      "Notification caused app to open from background state:",
      remoteMessage.notification
    );
    console.log("background State", remoteMessage.notification);
  });

  // Check whether an initial notification is available
  messaging()
    .getInitialNotification()
    .then((remoteMessage) => {
      if (remoteMessage) {
        console.log(
          "Notification caused app to open from quit state:",
          remoteMessage.notification
        );
      }
    });
};
