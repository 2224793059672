import React, { useEffect, useState } from "react";
import { StyleSheet, Text, View, ScrollView, FlatList } from "react-native";
import { moderateScale } from "react-native-size-matters";
import AbstractContentContainer from "../../Components/AbstractComponents/abstractContentContainer";
import AbstractHeaders from "../../Components/AbstractComponents/abstractHeaders";
import FocusAwareStatusBar from "../../Components/AbstractComponents/statusbarConfiguration";
import MessageItem from "../../Components/ModuleComponents/messageItem";
import { Colors, Fonts, PLATFORM_DETECT } from "../../themes";
import ScreenContainer from "../../Components/AbstractComponents/abstractScreenContainer";
import { navigate } from "../../Navigation/mainNavigation";
import { useConversations } from "../../Controllers/conversationsController";
import MessagesSvg from "../../Assets/Icons/bottomTabsSvgs/messagesSvg";
import MessageItemPlaceholder from "../../Components/Placeholders/messageItemPlaceholder";
import MessageItemPlaceholderMobile from "../../Components/Placeholders/messageItemPlaceholderMobile";
import { useSelector } from "react-redux";

const Placeholder = (props) => {
  return (
    <View
      style={{
        width: 150,
        height: 130,
        borderRadius: 20,
        justifyContent: "center",
        alignItems: "center",
        opacity: 0.2,
        marginTop: 100,
        alignSelf: "center",
      }}
    >
      <MessagesSvg size={90} color={"grey"} />
      <Text
        style={{
          fontSize: 18,
          color: "grey",
          fontFamily: "Roboto-Regular",
          marginTop: 5,
        }}
      >
        No conversation.
      </Text>
    </View>
  );
};

const MessagesPlaceholder = ({ deviceType }) => {
  return (
    <ScrollView showsVerticalScrollIndicator={false}>
      {deviceType ? (
        <>
          <MessageItemPlaceholder />
          <MessageItemPlaceholder />
          <MessageItemPlaceholder />
          <MessageItemPlaceholder />
          <MessageItemPlaceholder />
          <MessageItemPlaceholder />
          <MessageItemPlaceholder />
          <MessageItemPlaceholder />
        </>
      ) : (
        <>
          <MessageItemPlaceholderMobile />
          <MessageItemPlaceholderMobile />
          <MessageItemPlaceholderMobile />
          <MessageItemPlaceholderMobile />
          <MessageItemPlaceholderMobile />
          <MessageItemPlaceholderMobile />
          <MessageItemPlaceholderMobile />
          <MessageItemPlaceholderMobile />
        </>
      )}
    </ScrollView>
  );
};

const MessagesScreen = (props) => {
  const [loadedFirstTime, setLoadedFirstTime] = useState(true);
  const { loading, conversations, loadConversations } = useConversations(() =>
    setLoadedFirstTime(false)
  );
  const { deviceType } = useSelector((state) => state.DeviceTypeReducer);

  return (
    <ScreenContainer
      upperStyle={{ backgroundColor: Colors.screenBgColor }}
      bottomStyle={{ backgroundColor: Colors.screenBgColor }}
    >
      <View style={styles.mainContainer}>
        <FocusAwareStatusBar
          barStyle="dark-content"
          backgroundColor={"transparent"}
          translucent={true}
        />
        <AbstractContentContainer>
          <AbstractHeaders
            middleChild={() => <Text style={styles.textOne}>Messages</Text>}
          />
        </AbstractContentContainer>

        {loadedFirstTime && conversations.length == 0 ? (
          <MessagesPlaceholder deviceType={deviceType} />
        ) : (
          <FlatList
            style={{
              width: PLATFORM_DETECT.WEB ? "95%" : "90%",
              alignSelf: "center",
              paddingLeft: 1,
              paddingRight: 1,
            }}
            onRefresh={loadConversations}
            showsVerticalScrollIndicator={false}
            refreshing={loading}
            data={conversations}
            ListEmptyComponent={Placeholder}
            renderItem={({ item, index }) => (
              <>
                <MessageItem
                  onPress={() =>
                    navigate("Chat", {
                      offer: item,
                      offerType: item.offerType,
                    })
                  }
                  item={item}
                />
                {index === conversations.length - 1 ? (
                  <View style={{ height: moderateScale(160, 0.1) }} />
                ) : (
                  false
                )}
              </>
            )}
            keyExtractor={(item) => item._id}
          />
        )}
      </View>
    </ScreenContainer>
  );
};

export default MessagesScreen;

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: Colors.screenBgColor,
  },
  textOne: {
    fontSize: moderateScale(19, 0.1),
    color: "black",
    fontFamily: Fonts.bold,
    fontWeight: "600",
  },
});
