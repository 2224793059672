import React, {useEffect, useRef, useState} from 'react';
import ActionSheet from 'react-native-actions-sheet';
import {Colors} from '../../themes';

const AbstractBottomSheet = props => {
  const actionSheetRef = useRef();
  const scrollViewRef = useRef();

  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef();

  useEffect(() => {
    if (isVisible) {
      actionSheetRef.current?.setModalVisible(true);
    } else {
      actionSheetRef.current?.setModalVisible(false);
    }
  }, [isVisible]);

  useEffect(() => {
    if (props.isVisible) {
      setIsVisible(true);
    } else {
      closeModal();
    }
  }, [props.isVisible]);

  const onClose = () => {
    setIsVisible(false);
    if (props.onClose) {
      props.onClose();
    }
  };

  const onOpen = () => {
    if (isVisible) {
      scrollViewRef.current?.setNativeProps({
        scrollEnabled: true,
      });
    }
  };

  const closeModal = () => {
    actionSheetRef.current?.setModalVisible();
    setTimeout(() => {
      if (props.onRequestClose) {
        props.onRequestClose();
        setIsVisible(false);
      }
    }, 700);
  };

  if (isVisible) {
    return (
      <ActionSheet
        id={props.id}
        ref={actionSheetRef}
        initialOffsetFromBottom={1}
        onOpen={onOpen}
        statusBarTranslucent={false}
        bounceOnOpen={true}
        containerStyle={{backgroundColor:props.bgColor? 'white':Colors.blueSecondary, borderTopLeftRadius:28,borderTopRightRadius:28}}
        bounciness={2}
        indicatorStyle={{width: 70, height: 4}}
        // drawUnderStatusBar
        gestureEnabled={true}
        onClose={onClose}
        keyboardShouldPersistTaps="always"
        CustomHeaderComponent={props.MyHeader}
        overlayColor={'grey'}
        defaultOverlayOpacity={0.4}
      >
        {props.children}
      </ActionSheet>
    );
  } else {
    return <></>;
  }
};
export default AbstractBottomSheet;
