import React from "react";
import Svg, { Circle } from "react-native-svg";

const OptionsSvg = () => {
  return (
    <Svg
      width="5"
      height="15"
      viewBox="0 0 5 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Circle cx="2.5" cy="2" r="2" fill="#2674E5" />
      <Circle cx="2.5" cy="7.33325" r="2" fill="#2674E5" />
      <Circle cx="2.5" cy="12.6666" r="2" fill="#2674E5" />
    </Svg>
  );
};

export default OptionsSvg;
