import { StyleSheet, Text, View, Image } from 'react-native'
import React from 'react'
import { SenderText, ReceiverText } from '../../ModuleComponents/myMessage/messageStyles'


const MessageImage = ({message}) => {
    return (
        <View style={{ width: 200, maxHeight: 200, margin: 2 }}>
            <Image source={message.messageBody} resizeMethod='resize' style={{ width: 200, height: 200, borderTopLeftRadius: 16, borderTopRightRadius: 16 }} />
        </View>
    )
}


const MessageText = ({message, isSender}) => {
    return (
        <View>
            <Text style={isSender ? SenderText : ReceiverText}>{message.body}</Text>
        </View>
    )
}


const MessgaeBody = ({ message,isSender }) => {

    switch (message.type) {
        case 'Image':
            return <MessageImage isSender={isSender} message={message} />
            break;
        case 'Text':
            return <MessageText isSender={isSender} message={message} />
            break
        default: return <MessageText isSender={isSender} message={message} />
    }

}

export default MessgaeBody

const styles = StyleSheet.create({})